import { Injectable } from '@angular/core';
import { RouterActions } from '@apx-ui/apx-core';
import { NotificationActions } from '@apx-ui/apx-shared-ui';
import { ApxSolsticeWebClientService, UserActions } from '@apx-ui/apx-web-api-v1';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, forkJoin, of } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { AccountActions, PageConfigActions } from '../actions';
import { AccountStateService, PageConfigStateService } from '../states';

@Injectable()
export class AccountEffects {

  handleSetAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AccountActions.setAccount,
      ),
      filter(({ boot }) => !boot),
      switchMap(() => combineLatest([
        this.accountState.getAccount$().pipe(take(1), filter(account => !!account)),
      ])),
      switchMap(([account]) => {

        this.pageConfigState.loadPageConfig(account.getId());

        return forkJoin([
          this.client.getPageConfig(account.getId()),
          this.client.getAccountById(account.getId()),
        ]).pipe(
          switchMap(([pageConfig, extendedAccount]) => [
            UserActions.setAccRoles({ roles: extendedAccount.AccountRoles }),
            AccountActions.setExtendedAccount({ account: extendedAccount }),
            PageConfigActions.loadPageConfigSuccess({ accountId: account.getId(), pageConfig }),
          ]),
          catchError(err => of(
            PageConfigActions.loadPageConfigFailure({ err }),
            RouterActions.navigate({
              path: ['/error'],
              query: {
                type: err?.status ?? 503,
              },
              extras: {
                skipLocationChange: true,
              },
            }),
            NotificationActions.error({
              message: 'Cannot load account related data!',
            }),
          )),
        );

      }),
    ),
  );

  handleSetAccountFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AccountActions.loadAccountFiles,
      ),
      switchMap(({ accountId }) => {

        return this.client.loadAccountFiles(accountId).pipe(
          switchMap((files) => [
            AccountActions.setAccountFiles({ files }),
          ]),
          catchError(err => {
            return of (err);
          }),
        );

      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly accountState: AccountStateService,
    private readonly pageConfigState: PageConfigStateService,
    private readonly client: ApxSolsticeWebClientService,
  ) {
  }

}
