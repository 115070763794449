import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'apx-ui-shared-check-suspend-date',
  templateUrl: './check-suspend-date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckSuspendDateComponent {

  decisionCtrl = new UntypedFormControl(null, Validators.required);

  constructor(
    private readonly dialogRef: MatDialogRef<CheckSuspendDateComponent>,
  ) {
  }

  onConfirm(): void {
    this.dialogRef.close(this.decisionCtrl.value);
  }

}
