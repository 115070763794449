<ng-container *ngIf="notifications?.today?.items?.length">
  <div class="day mat-caption">TODAY</div>
  <mat-divider></mat-divider>

  <mat-list class="pt-0">
    <ng-container *ngFor="let item of notifications.today.items">
      <mat-list-item class="cursor-pointer"
                     [class.unread-item]="!item.read"
                     (click)="showSummary(item.category, item.subCategory, notifications.today.checkedDate, unitSystemId)">
        <span class="count-items">{{item.count}}</span> {{ item.text }}
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>
  </mat-list>
</ng-container>

<ng-container *ngIf="notifications?.yesterday?.items?.length">
  <div class="day mat-caption">
    {{notifications.yesterday.checkedDate | date :'MM/dd/yyyy' : 'UTC+0' }}
  </div>
  <mat-divider></mat-divider>

  <mat-nav-list class="pt-0">
    <ng-container *ngFor="let item of notifications.yesterday.items">
      <mat-list-item class="cursor-pointer"
                     [class.unread-item]="!item.read"
                     (click)="showSummary(item.category, item.subCategory, notifications.yesterday.checkedDate, unitSystemId)">
        <span class="count-items">{{item.count}}</span> {{ item.text }}
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>
  </mat-nav-list>
</ng-container>

<div *ngIf="!notifications?.today?.items?.length && !notifications?.yesterday?.items?.length"
     fxLayout="column"
     fxLayoutAlign="center center"
     fxFlexFill>
  <div>There are no notifications at this time</div>
</div>
