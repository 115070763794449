import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@apx-ui/apx-auth';
import { Permissions } from '@apx-ui/apx-config';
import { RouterStateService } from '@apx-ui/apx-core';
import { ApxNotificationsListContainerComponent } from '@apx-ui/apx-notifications';
import { NotificationStateService } from '@apx-ui/apx-notifications-store';
import { AccountModel, UserModel, UserStateService } from '@apx-ui/apx-web-api-v1';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Component({
  selector: 'apx-ui-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderComponent implements OnInit {

  @Input() user!: UserModel | null;
  @Input() accounts!: AccountModel[];
  @Input() account!: AccountModel | null;
  @ViewChild('settingsBtn') settingsBtn: MatButton;

  totalNotifications$: Observable<number>;
  isLandingPage$: Observable<boolean>;
  showFieldTasksButton$: Observable<boolean>;
  showNotificationButton$: Observable<boolean>;

  constructor(
    private readonly auth: AuthService,
    private readonly notificationState: NotificationStateService,
    private readonly dialog: MatDialog,
    private readonly routerState: RouterStateService,
    private readonly userStateService: UserStateService,
  ) {
  }

  ngOnInit(): void {
    this.showFieldTasksButton$ = this.userStateService.hasRight$(Permissions.FieldTasksViewNavButton);
    this.showNotificationButton$ = this.userStateService.hasRight$(Permissions.NotificationsView);
    this.totalNotifications$ = this.notificationState.getNotificationCount$();
    this.isLandingPage$ = this.routerState.getUrl$().pipe(
      map(url => !!url?.includes('landing')),
    );

    combineLatest([
      this.showNotificationButton$,
      this.routerState.getParam$('accountId'),
    ]).pipe(
      filter(([right]) => !!right),
      tap(([, accountId]) => this.notificationState.loadNotificationCount(accountId ?? '')),
      )
     .subscribe();
  }

  logout(): void {
    this.auth.logout();
    this.removeUserFromLocStorage(this.user['data']['email']);
  }

  onClickNotifications(): void {
    this.dialog.open(ApxNotificationsListContainerComponent, {
      width: '500px',
      maxWidth: '80vw',
      height: '100%',
      position: {
        right: '0',
        top: '0',
      },
    });
  }

  getCount(countNotifications: number): string {
    const count = countNotifications.toString();

    return countNotifications < 1000 ? count : `..${count.slice(count.length - 2, count.length)}`;
  }

  settingsMenuWidth(): number {
    return this.settingsBtn?._elementRef.nativeElement.clientWidth || 0;
  }

  removeUserFromLocStorage(userEmail: string): void {
    localStorage.removeItem(`user_${userEmail}`);
    localStorage.removeItem(`user_date_visit_${userEmail}`);
  }
}
