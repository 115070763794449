import { Status } from '@apx-ui/apx-core';
import { createReducer, on } from '@ngrx/store';

import { IDeliveryData } from '../../interfaces';
import { OrdersForDeliveryActions } from '../actions';

export interface OrdersForDeliveryStates {
  delivery: IDeliveryData | null;
  active: boolean;
  deliveryType: string;
  status: {
    load: Status;
  };
}

export const initialState: OrdersForDeliveryStates = {
  delivery: null,
  active: false,
  deliveryType: '',
  status: {
    load: {
      pending: false,
      resolved: true,
      rejected: false,
      err: null,
    },
  },
};

export const reducer = createReducer(
  initialState,
  on(OrdersForDeliveryActions.loadOrdersForDelivery,
    state => {
      return {
        ...state,
        status: {
          ...state.status,
          load: {
            pending: true,
            resolved: false,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(OrdersForDeliveryActions.loadOrdersForDeliverySuccess,
    (state, { deliveryData }) => {
      return {
        ...state,
        delivery: deliveryData,
        active: deliveryData.Active,
        deliveryType: deliveryData.DeliveryType,
        status: {
          ...state.status,
          load: {
            pending: false,
            resolved: true,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(OrdersForDeliveryActions.updateOrder,
    (state, { order }) => {
      const delivery = {
        ...state.delivery,
        ...state.delivery.OrderDelivery && {
          OrderDelivery: [...state.delivery.OrderDelivery.filter(o => o.OrderId !== order.OrderId), order],
        },
      };

      return {
        ...state,
        delivery,
        status: {
          ...state.status,
          load: {
            pending: false,
            resolved: true,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(OrdersForDeliveryActions.loadOrdersForDeliveryFailure,
    (state, { err }) => {
      return {
        ...state,
        status: {
          ...state.status,
          load: {
            pending: false,
            resolved: false,
            rejected: true,
            err,
          },
        },
      };
    },
  ),
  on(OrdersForDeliveryActions.clear,
    state => ({
      ...state,
      ...initialState,
    }),
  ),
);
