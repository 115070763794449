import { DeliveryListEffects } from './delivery-list.effects';
import { OrdersFiltersEffects } from './filters.effects';
import { OffCycleBatchOrdersEffects } from './off-cycle-batch-orders.effects';
import { OffCycleContinuousOrdersEffects } from './off-cycle-continuous-orders.effects';
import { OrderEffects } from './order.effects';
import { OrdersForDeliveryEffects } from './orders-for-delivery.effects';

export const effects = [
  OrdersForDeliveryEffects,
  DeliveryListEffects,
  OrderEffects,
  OrdersFiltersEffects,
  OffCycleBatchOrdersEffects,
  OffCycleContinuousOrdersEffects,
];

export * from './delivery-list.effects';
export * from './filters.effects';
export * from './off-cycle-batch-orders.effects';
export * from './off-cycle-continuous-orders.effects';
export * from './order.effects';
export * from './orders-for-delivery.effects';
