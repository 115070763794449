<apx-ui-shared-dialog>
  <ng-container header>
    <h2 data-test-id="title">
      Continue with report
    </h2>
  </ng-container>
  <ng-container body>
    <p data-test-id="body">
      The report Loading may take several minutes. Are you sure you want to proceed with this request?
    </p>
  </ng-container>
  <ng-container footer>
    <div>
      <button mat-stroked-button
              class="mr-auto"
              type="button"
              data-test-id="cancelBtn"
              [mat-dialog-close]="false">
        Cancel
      </button>
      <button mat-flat-button
              type="submit"
              color="primary"
              data-test-id="allRowsButton"
              [mat-dialog-close]="true">
        Next
      </button>
    </div>
  </ng-container>
</apx-ui-shared-dialog>
