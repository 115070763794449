<apx-ui-shared-layout-header *ngIf="{
        totalNotifications: totalNotifications$ | async,
        showNotificationButton: showNotificationButton$ | async,
        isLanding: isLandingPage$ | async
      } as data">

  <apx-ui-assignment-counter></apx-ui-assignment-counter>

  <ng-container logo>
    <a routerLink="."
       mat-icon-button
       aria-label="home"
       matTooltip="Home">
      <mat-icon>home</mat-icon>
    </a>
    <a routerLink="."
       fxLayout="column"
       data-test-id="landingPage"
       fxLayoutAlign="center"
       matTooltip="Home">
      <img src="assets/HALAPXlogo.svg"
           width="247"
           height="22"
           alt="HAL_Logo">
    </a>
  </ng-container>

  <ng-container left-section>

    <div *ngIf="account"
         fxLayout="column"
         fxLayoutAlign="center start">
      <div class="mat-body-2">
        {{ account?.getName() }}
      </div>
      <div class="mat-body-2">
        {{ account.getAccountHeader() }}
      </div>
    </div>

    <div fxLayout
         fxLayoutGap="8px"
         fxLayoutAlign="end center">
      <a mat-button
         data-test-id="accountsPage"
         routerLink="landing"
         routerLinkActive="active-nav-dark-list-item">
        Accounts
      </a>
      <a *ngIf="showFieldTasksButton$ | async"
         mat-button
         data-test-id="fieldTaskPage"
         routerLink="field-tasks"
         routerLinkActive="active-nav-dark-list-item">
        Field Tasks
      </a>
    </div>
  </ng-container>

  <ng-container right-section>

    <div fxLayout
         fxLayoutAlign="end center"
         fxLayoutGap="8px">

      <button *ngIf="!data.isLanding && data.showNotificationButton"
              mat-icon-button
              aria-label="notification"
              (click)="onClickNotifications()">
        <mat-icon matBadgeColor="accent"
                  matBadgeSize="small"
                  matBadge="{{ getCount(data.totalNotifications) }}"
                  [matBadgeHidden]="!data.totalNotifications"
                  [title]="data.totalNotifications || ''">
          notifications_none
        </mat-icon>
      </button>

      <button [matMenuTriggerFor]="menu"
              #settingsBtn
              data-test-id="settingsMenu"
              mat-button
              class="h-100" >
        <mat-icon>account_circle</mat-icon>
        <span class="mat-h3 px-8">{{ user?.getDisplayName() }}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <div [style.width.px]="settingsMenuWidth()">
          <a mat-menu-item
             routerLink="settings">
            <mat-icon>manage_accounts</mat-icon>
            Settings
          </a>
          <a mat-menu-item
             data-test-id="signOut"
             (click)="logout()">
            <mat-icon>logout</mat-icon>
            Sign out
          </a>
        </div>
      </mat-menu>

    </div>

  </ng-container>

</apx-ui-shared-layout-header>
