<mat-nav-list *ngIf="{
  expanded: expanded$ | async,
  showDashboardNavButton: showDashboardNavButton$ | async,
  showManagementNavButton: showManagementNavButton$ | async,
  showPerformanceNavButton: showPerformanceNavButton$ | async,
  showSupportNavButton: showSupportNavButton$ | async,
  showSettingsNavButton: showSettingsNavButton$ | async,
  showKpiControlsNavButton: showKpiControlsNavButton$ | async,
  showCustomizationReportsNavButton: showCustomizationReportsNavButton$ | async
} as data">

  <mat-list-item *ngIf="data.showManagementNavButton"
                 routerLinkActive="active-nav-list-item"
                 routerLink="./manage/assets">
    <span *ngIf="data.expanded">Management</span>
    <mat-icon mat-list-icon
              fontSet="material-icons-outlined"
              class="mr-12"
              title="Management">
      manage_accounts
    </mat-icon>
  </mat-list-item>

  <div *ngIf="data.expanded && data.showManagementNavButton">
    <a *ngIf="data.showKpiControlsNavButton"
       mat-list-item
       routerLinkActive="active-nav-list-item"
       routerLink="./manage/kpi">
      <span class="pl-44">KPI Controls</span>
    </a>
    <a mat-list-item
       data-test-id="assetsPage"
       routerLinkActive="active-nav-list-item"
       routerLink="./manage/assets">
      <span class="pl-44">Assets</span>
    </a>
    <a mat-list-item
       routerLinkActive="active-nav-list-item"
       routerLink="./manage/contacts">
      <span class="pl-44">Contacts</span>
    </a>
  </div>

  <mat-list-item *ngIf="data.showPerformanceNavButton"
                 routerLinkActive="active-nav-list-item"
                 routerLink="./performance">
    <span *ngIf="data.expanded">Performance</span>
    <mat-icon mat-list-icon
              fontSet="material-icons-outlined"
              class="mr-12"
              title="Performance">
      speed
    </mat-icon>
  </mat-list-item>

  <div *ngIf="data.expanded && data.showPerformanceNavButton">
    <a mat-list-item
       routerLinkActive="active-nav-list-item"
       routerLink="./performance/advanced-reports">
      <span class="pl-44">Advanced Reports</span>
    </a>

    <a *ngIf="data.showCustomizationReportsNavButton"
       mat-list-item
       routerLinkActive="active-nav-list-item"
       routerLink="./performance/customization-reports">
      <span class="pl-44">Customization Reports</span>
    </a>

    <a mat-list-item
       routerLinkActive="active-nav-list-item"
       routerLink="./performance/reports">
      <span class="pl-44"
              data-test-id="reportsSideBar">
        Reports
      </span>
    </a>

    <a mat-list-item
       routerLinkActive="active-nav-list-item"
       routerLink="./performance/documents">
      <span class="pl-44">Documents</span>
    </a>

    <a *ngFor="let item of performanceConfig$ | async"
       mat-list-item
       routerLinkActive="active-nav-list-item"
       [routerLink]="['performance/charts/', item?.pageId ?? '']"
       class="ml-44">
      {{ item.pageTitle | titlecase }}
    </a>

  </div>

  <mat-list-item *ngIf="data.showSupportNavButton"
                 routerLinkActive="active-nav-list-item"
                 routerLink="./support">
    <span *ngIf="data.expanded">Support</span>
    <mat-icon mat-list-icon
              fontSet="material-icons-outlined"
              class="mr-12"
              title="Support">
      info
    </mat-icon>
  </mat-list-item>

  <mat-list-item *ngIf="data.showSettingsNavButton"
                 routerLinkActive="active-nav-list-item"
                 routerLink="./settings">
    <span *ngIf="data.expanded">Settings</span>
    <mat-icon mat-list-icon
              fontSet="material-icons-outlined"
              class="mr-12"
              title="Settings">
      settings
    </mat-icon>
  </mat-list-item>

</mat-nav-list>
