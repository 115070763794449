import { Account, AccountExtended, AccountFile } from '@apx-ui/apx-web-api-v1';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { AccountActions } from '../actions';

export const adapter: EntityAdapter<Account> = createEntityAdapter<Account>({
  selectId: a => a.Id,
  sortComparer: (a, b) => a.CustomerName.localeCompare(b.CustomerName),
});

export interface AccountState extends EntityState<Account> {
  selectedAccountId: string | null;
  extendedAccount: AccountExtended | null;
  selectedAccountFiles: AccountFile[];
}

export const initialState: AccountState = adapter.getInitialState({
  selectedAccountId: null,
  extendedAccount: null,
  selectedAccountFiles: [],
});

export const reducer = createReducer(
  initialState,
  on(AccountActions.addAccount,
    (state, { account }) => {
      return adapter.upsertOne(account, state);
    },
  ),
  on(AccountActions.addAccounts,
    (state, { accounts }) => {
      return adapter.upsertMany(accounts, state);
    },
  ),
  on(AccountActions.setAccount,
    (state, { accountId }) => ({
      ...state,
      selectedAccountId: accountId,
    }),
  ),
  on(AccountActions.setExtendedAccount,
    (state, { account }) => ({
      ...state,
      extendedAccount: account,
    }),
  ),
  on(AccountActions.setAccountFiles,
    (state, { files }) => ({
      ...state,
      selectedAccountFiles: files,
    }),
  ),
  on(AccountActions.deselectAccount,
    (state) => ({
      ...state,
      selectedAccountId: null,
      extendedAccount: null,
      selectedAccountFiles: [],
    }),
  ),

);

const { selectAll, selectEntities } = adapter.getSelectors();

export const selectAccounts = selectAll;
export const selectAccountEntities = selectEntities;
