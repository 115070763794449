<mat-form-field appearance="outline"
                class="no-space">
  <input matInput
       [matDatepicker]="datePicker"
       [min]="minDate"
       [max]="maxDate"
       [formControl]="dateCtrl">
<mat-datepicker-toggle matSuffix
                       [for]="datePicker">
</mat-datepicker-toggle>
<mat-datepicker #datePicker
                panelClass="ag-mat-datepicker"
                (closed)="onClose()"></mat-datepicker>
</mat-form-field>
