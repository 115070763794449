<div fxFlexFill
     fxLayout="column"
     class="position-relative"
     data-test-id="pendingAssignmentNotification">
  <div mat-dialog-title
       class="position-absolute mat-dialog-close">
    <button mat-dialog-close
            mat-icon-button
            aria-label="close"
            data-test-id="offCycleOrderCloseBtn">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="dialog-body">
    <h1 class="text-center font-weight-semibold mt-2">
      <p class="m-0">Hello, {{dialogData.name}}</p>
      <p class="m-0" *ngIf="dialogData.count">you have {{(dialogData.count)}}</p>
      <p class="m-0" *ngIf="!dialogData.count">you don`t have</p>
      <p class="m-0">assignments for today.</p>
    </h1>
  </mat-dialog-content>

  <mat-dialog-actions>

    <div class="open-assignments">
      <button mat-flat-button
              mat-dialog-close
              type="submit"
              color="primary"
              data-test-id="apply"
              style="width : 190px; height: 50px"
              (click)="goToAssignments()">
        OPEN ASSIGNMENTS
      </button>
    </div>

    <p class="text-center"
       style="width : 100%; margin-top: 10px">
      Please, click here to see the details
    </p>

  </mat-dialog-actions>
</div>
