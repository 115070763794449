<apx-ui-shared-dialog>

  <ng-container body>
    <div fxLayout="column"
         fxLayoutGap="16px">
      <span>Pending order(s) currently exists. Please select desired action.</span>
      <mat-radio-group fxLayout="column"
                       fxLayoutGap="10px"
                       class="pl-20"
                       [formControl]="decisionCtrl">
        <mat-radio-button color="primary"
                          class="mr-32"
                          [value]="true">
          Suspend and KEEP pending order(s)
        </mat-radio-button>
        <mat-radio-button color="primary"
                          class="mr-32"
                          [value]="false">
          Suspend and CANCEL pending order(s)
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </ng-container>

  <ng-container footer>

    <div>
      <button mat-stroked-button
              class="mr-auto"
              type="button"
              data-test-id="cancelBtn"
              [mat-dialog-close]="null">
        Close
      </button>

      <button mat-flat-button
              type="button"
              color="primary"
              data-test-id="confirmBtn"
              [disabled]="decisionCtrl.invalid"
              (click)="onConfirm()">
        Confirm
      </button>
    </div>

  </ng-container>

</apx-ui-shared-dialog>