import { Status } from '@apx-ui/apx-core';
import {
  OffCycleBatchOrder,
  OffCycleBatchOrderValidationInfo,
} from '@apx-ui/apx-web-api-v1';
import { createReducer, on } from '@ngrx/store';

import {
  CreateOffCycleBatchOrderStep,
  OffCycleBatchOrderFormData,
} from '../../interfaces';
import { OffCycleBatchOrdersActions } from '../actions';

export interface CreateOffCycleBatchOrdersState {
  step: CreateOffCycleBatchOrderStep;
  validationInfo: OffCycleBatchOrderValidationInfo | null;
  message: string;
  form_data: OffCycleBatchOrderFormData;
  status: {
    load_validation: Status;
  };
  result: OffCycleBatchOrder | null;
}

const initialState: CreateOffCycleBatchOrdersState = {
  step: CreateOffCycleBatchOrderStep.Create,
  validationInfo: null,
  message: '',
  form_data: {
    locationId: '',
    accountProductId: '',
    injectionPoint: '',
    deliveryVolume: 0,
    deliveryVolumeUnit: '',
    flushVolume: 0,
    deliveryDateTime: '',
    comment: '',
  },
  status: {
    load_validation: {
      resolved: false,
      rejected: false,
      pending: false,
      err: null,
    },
  },
  result: null,
};

export const reducer = createReducer(
  initialState,
  on(OffCycleBatchOrdersActions.setStep,
    (state, { step }) => ({
    ...state,
    step,
  })),
  on(OffCycleBatchOrdersActions.saveCreateForm,
    (state, { order }) => ({
      ...state,
      step: CreateOffCycleBatchOrderStep.Confirm,
      form_data: { ...state.form_data, ...order },
    }),
  ),
  on(OffCycleBatchOrdersActions.saveConfirm,
    (state, { order }) => ({
      ...state,
      step: CreateOffCycleBatchOrderStep.Pending,
      form_data: { ...state.form_data, ...order },
    }),
  ),
  on(OffCycleBatchOrdersActions.success,
    state => ({
      ...state,
      step: CreateOffCycleBatchOrderStep.Success,
    }),
  ),
  on(OffCycleBatchOrdersActions.failure,
    state => ({
      ...state,
      step: CreateOffCycleBatchOrderStep.Failure,
    }),
  ),
  on(OffCycleBatchOrdersActions.validateOrder,
    (state) => ({
      ...state,
      status: {
        ...state.status,
        load_validation: { ...state.status.load_validation, pending: true },
      },
    }),
  ),
  on(OffCycleBatchOrdersActions.validateOrderSuccess,
    (state, { validationInfo }) => ({
      ...state,
      validationInfo,
      status: {
        ...state.status,
        load_validation: { ...state.status.load_validation, pending: false, resolved: true, rejected: false, err: null },
      },
    }),
  ),
  on(OffCycleBatchOrdersActions.validateOrderFailure,
    (state, { err }) => ({
      ...state,
      status: {
        ...state.status,
        load_validation: { ...state.status.load_validation, pending: false, resolved: false, rejected: true, err },
      },
    }),
  ),
  on(OffCycleBatchOrdersActions.success,
    (state, { orderData }) => ({
      ...state,
      result: orderData,
    }),
  ),
  on(OffCycleBatchOrdersActions.failure,
    (state, { message }) => ({
      ...state,
      message,
    }),
  ),
  on(OffCycleBatchOrdersActions.destroy,
    () => ({ ...initialState }),
  ),
  on(OffCycleBatchOrdersActions.cleanStatus,
    (state) => ({
      ...state,
      status: initialState.status,
    }),
  ),
);
