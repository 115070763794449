import { HttpErrorResponse } from '@angular/common/http';
import { GenericFilter } from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

export const loadAssetFilters = createAction(
  '[APX Assets Filters] Load',
  props<{
    accountId: string;
  }>(),
);

export const loadAssetFiltersSuccess = createAction(
  '[APX Assets Filters] Load Success',
  props<{
    filters: GenericFilter;
  }>(),
);

export const loadAssetFiltersFailure = createAction(
  '[APX Assets Filters] Load Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const clear = createAction(
  '[APX Assets Filters] Clear',
);
