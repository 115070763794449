<apx-ui-shared-dialog>

  <ng-container header>

    <h2 data-test-id="title"
        [innerHTML]="title">
    </h2>

  </ng-container>

  <ng-container body>

    <p data-test-id="body"
       [innerHTML]="body">
    </p>

  </ng-container>

  <ng-container footer>

    <div>
      <button *ngIf="displayBtn"
              mat-stroked-button
              class="mr-auto"
              type="button"
              data-test-id="cancelBtn"
              [mat-dialog-close]="false">
        {{rejectBtn}}
      </button>

      <button mat-flat-button
              type="submit"
              color="primary"
              data-test-id="confirmBtn"
              [mat-dialog-close]="true">
        {{confirmBtn}}
      </button>
    </div>

  </ng-container>

</apx-ui-shared-dialog>
