<form *ngIf="form"
      [formGroup]="form">

  <div fxLayout="column"
       fxLayoutAlign="start start">

    <mat-form-field appearance="outline">
      <mat-label>Order Status</mat-label>
      <mat-select formControlName="deliveryStatus"
                  data-test-id="deliveryStatus"
                  multiple>
        <mat-option *ngFor="let filter of filters.Status.DeliveryStatus"
                    [value]="filter">
          {{filter}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Order Management Type</mat-label>
      <mat-select formControlName="orderManagementType"
                  data-test-id="orderManagementType"
                  multiple>
        <mat-option *ngFor="let item of orderManagementTypes"
                    [value]="item">
          {{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>
</form>
