import { AgEditorComponent } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

interface InitParams extends ICellEditorParams {
  field: string;
  keyField: string;
  displayField: string;
  values: any[];
}

@Component({
  selector: 'apx-ui-shared-check-box-list',
  templateUrl: './check-box-list-advanced-cell.component.html',
  styleUrls: ['./check-box-list-advanced-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckBoxListAdvancedCellComponent implements AgEditorComponent, OnDestroy {

  form: UntypedFormGroup;
  sourceValues: any[];
  filteredList: any[];

  private params: InitParams;
  private subscription = new Subscription();

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      options: '',
      search: '',
    });

    this.subscription.add(
      this.searchControl.valueChanges.subscribe(label => {
        this.filteredList = this.sourceValues
          .filter(v => !label
            || this.optionsControl.value.includes(v[this.keyField])
            || v[this.displayField].toLowerCase().includes(label.toLowerCase()));
      }),
    );
  }

  get searchControl(): AbstractControl {
    return this.form.get('search');
  }

  get optionsControl(): AbstractControl {
    return this.form.get('options');
  }

  get keyField(): string {
    return this.params.keyField;
  }

  get displayField(): string {
    return this.params.displayField;
  }

  get width(): number {
    return Math.max(150, this.params?.column?.getActualWidth());
  }

  agInit(params: InitParams): void {
    this.params = params;
    this.sourceValues = params.values;
    this.filteredList = [...this.sourceValues];

    this.optionsControl.setValue((params.data[params.field] ?? []).map(e => e[this.keyField]));
  }

  getValue(): string[] {
    return (this.optionsControl.value ?? [])
      .map(o => this.sourceValues.find(v => v[this.keyField] === o))
      .filter(v => !!v);
  }

  isPopup(): boolean { return true; }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
