import { Injectable } from '@angular/core';
import { AccountExtended, AccountFile, AccountModel } from '@apx-ui/apx-web-api-v1';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AccountActions } from '../actions';
import * as fromAccountSelectors from '../selectors/account.selectors';

@Injectable()
export class AccountStateService {

  constructor(
    private store: Store<never>,
  ) { }

  setAccount(accountId: string): void {
    this.store.dispatch(
      AccountActions.setAccount({ accountId }),
    );
  }

  deselectAccount(): void {
    this.store.dispatch(
      AccountActions.deselectAccount(),
    );
  }

  getAccount$(): Observable<AccountModel>;
  getAccount$(preserve: boolean = true): Observable<AccountModel | null> {
    const account$ = this.store.pipe(
      select(fromAccountSelectors.selectCurrentAccount),
    );

    if (preserve) {
      return account$.pipe(filter(a => !!a));
    }

    return account$;
  }

  getAccounts$(): Observable<AccountModel[]> {
    return this.store.pipe(
      select(fromAccountSelectors.selectAccounts),
    );
  }

  getAccountExtended$(): Observable<AccountExtended> {
    return this.store.pipe(
      select(fromAccountSelectors.selectCurrentExtendedAccount),
    );
  }

  getAccountExtendedContacts$(): Observable<any> {
    return this.store.pipe(
      select(fromAccountSelectors.selectCurrentExtendedAccountContacts),
    );
  }

  loadAccountFiles(accountId: string): void {
    this.store.dispatch(
      AccountActions.loadAccountFiles({ accountId }),
    );
  }

  getAccountFiles$(): Observable<AccountFile[]> {
    return this.store.pipe(
      select(fromAccountSelectors.selectCurrentAccountFiles),
    );
  }

}
