import { Injectable } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import { Customer } from '@apx-ui/apx-web-api-v1';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CustomerActions } from '../actions';
import { selectCustomerAll, selectLoadCustomersStatus } from '../selectors';

@Injectable()
export class ManageCustomersStateService {

  constructor(private store: Store<never>) {
  }

  loadCustomers(): void {
    this.store.dispatch(CustomerActions.loadCustomers());
  }

  clearStatus(): void {
    this.store.dispatch(CustomerActions.clearState());
  }

  getCustomers$(): Observable<(Customer | undefined)[]> {
    return this.store.pipe(select(selectCustomerAll));
  }

  getStatus$(): Observable<Status> {
    return this.store.pipe(select(selectLoadCustomersStatus));
  }

}
