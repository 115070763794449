<table *ngIf="displayedColumns"
       mat-table
       [dataSource]="notifications"
       class="w-100">
  <ng-container matColumnDef="location">
    <th *matHeaderCellDef
        mat-header-cell>
      Location
    </th>
    <td *matCellDef="let element"
        mat-cell
        class="mat-caption">
      <div> {{element.accountName}} </div>
      <div> {{element.locationName}} </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="samplePoint">
    <th *matHeaderCellDef
        mat-header-cell> Sample Point</th>
    <td *matCellDef="let element"
        mat-cell
        class="mat-caption">
      {{element.samplePoint}}
    </td>
  </ng-container>

  <ng-container matColumnDef="expiredDayCount">
    <th *matHeaderCellDef
        mat-header-cell>
      Days Late
    </th>
    <td *matCellDef="let element"
        mat-cell
        class="mat-caption">
      {{element.expiredDayCount}}
    </td>
  </ng-container>

  <tr *matHeaderRowDef="displayedColumns"
      mat-header-row></tr>
  <tr *matRowDef="let row; columns: displayedColumns;"
      mat-row></tr>
</table>
