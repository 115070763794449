<form [formGroup]="form">
  <mat-form-field *ngIf="reasonCtrl as control"
                  appearance="outline">
    <mat-label>Reason</mat-label>
    <mat-select formControlName="reason"
                data-test-id="reason">
      <mat-option *ngFor="let item of reasons"
                  [value]="item.Id">
        {{item.Name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="displayComment || isOtherOption"
                  appearance="outline">
    <mat-label>Enter Comment</mat-label>
    <textarea matInput
              class="comment"
              formControlName="comment"></textarea>
  </mat-form-field>

</form>
