import { Injectable } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import { AccountModel } from '@apx-ui/apx-web-api-v1';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ManageAccountActions } from '../actions';
import { selectManageAccounts, selectManageAccountsStatus } from '../selectors';

@Injectable()
export class ManageAccountStateService {

  constructor(private store: Store<never>) {
  }

  loadAccount(): void {
    this.store.dispatch(ManageAccountActions.loadAccounts());
  }

  getAccounts$(): Observable<AccountModel[]> {
    return this.store.pipe(select(selectManageAccounts));
  }

  getStatus$(): Observable<Status> {
    return this.store.pipe(select(selectManageAccountsStatus));
  }

}
