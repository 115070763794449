import { Status } from '@apx-ui/apx-core';
import { createReducer, on } from '@ngrx/store';

import { OrderActions } from '../actions';

export interface OrderStates {
  status: {
    save: Status;
  };
}

export const initialState: OrderStates = {
  status: {
    save: {
      pending: false,
      resolved: true,
      rejected: false,
      err: null,
    },
  },
};

export const reducer = createReducer(
  initialState,
  on(OrderActions.addComment,
    OrderActions.cancelOrder,
    OrderActions.partialUpdate,
    OrderActions.captureDeliveryOrder,
    OrderActions.captureOrder,
    OrderActions.approveOrder,
    OrderActions.adjustCaptureOrder,
    state => {
      return {
        ...state,
        status: {
          ...state.status,
          save: {
            pending: true,
            resolved: false,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(OrderActions.addCommentSuccess,
    OrderActions.cancelOrderSuccess,
    OrderActions.partialUpdateSuccess,
    OrderActions.captureDeliveryOrderSuccess,
    OrderActions.captureOrderSuccess,
    OrderActions.approveOrderSuccess,
    OrderActions.adjustCaptureOrderSuccess,
    state => {

      return {
        ...state,
        status: {
          ...state.status,
          save: {
            pending: false,
            resolved: true,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(OrderActions.addCommentFailure,
    OrderActions.cancelOrderFailure,
    OrderActions.partialUpdateFailure,
    OrderActions.captureDeliveryOrderFailure,
    OrderActions.captureOrderFailure,
    OrderActions.approveOrderFailure,
    OrderActions.adjustCaptureOrderFailure,
    (state, { err }) => {

      return {
        ...state,
        status: {
          ...state.status,
          save: {
            pending: false,
            resolved: false,
            rejected: true,
            err,
          },
        },
      };
    },
  ),
  on(OrderActions.clearStatus,
    () => ({ ...initialState }),
  ),
);
