import { Status } from '@apx-ui/apx-core';
import { createReducer, on } from '@ngrx/store';

import { DeliveryListActions } from '../actions';

export interface DeliveryListStates {
  orderIds: string[];
  status: {
    save: Status;
    unload: Status;
    append: Status;
  };
}

export const initialState: DeliveryListStates = {
  orderIds: [],
  status: {
    save: {
      pending: false,
      resolved: true,
      rejected: false,
      err: null,
    },
    unload: {
      pending: false,
      resolved: true,
      rejected: false,
      err: null,
    },
    append: {
      pending: false,
      resolved: true,
      rejected: false,
      err: null,
    },
  },
};

export const reducer = createReducer(
  initialState,
  on(DeliveryListActions.loadTruck,
    state => {
      return {
        ...state,
        status: {
          ...state.status,
          save: {
            pending: true,
            resolved: false,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(DeliveryListActions.loadTruckSuccess,
    state => {

      return {
        ...state,
        status: {
          ...state.status,
          save: {
            pending: false,
            resolved: true,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(DeliveryListActions.loadTruckFailure,
    (state, { err }) => {

      return {
        ...state,
        status: {
          ...state.status,
          save: {
            pending: false,
            resolved: false,
            rejected: true,
            err,
          },
        },
      };
    },
  ),
  on(DeliveryListActions.addToDeliveryList,
    state => {
      return {
        ...state,
        status: {
          ...state.status,
          append: {
            pending: true,
            resolved: false,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(DeliveryListActions.addToDeliveryListSuccess,
    state => {

      return {
        ...state,
        status: {
          ...state.status,
          append: {
            pending: false,
            resolved: true,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(DeliveryListActions.addToDeliveryListFailure,
    (state, { err }) => {

      return {
        ...state,
        status: {
          ...state.status,
          append: {
            pending: false,
            resolved: false,
            rejected: true,
            err,
          },
        },
      };
    },
  ),
  on(DeliveryListActions.unloadTruck,
    state => {
      return {
        ...state,
        status: {
          ...state.status,
          unload: {
            pending: true,
            resolved: false,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(DeliveryListActions.unloadTruckSuccess,
    state => {

      return {
        ...state,
        status: {
          ...state.status,
          unload: {
            pending: false,
            resolved: true,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(DeliveryListActions.unloadTruckFailure,
    (state, { err }) => {

      return {
        ...state,
        status: {
          ...state.status,
          unload: {
            pending: false,
            resolved: false,
            rejected: true,
            err,
          },
        },
      };
    },
  ),
  on(DeliveryListActions.addOneToSelected,
    (state, { orderId }) => ({
      ...state,
      orderIds: [...state.orderIds, orderId],
    }),
  ),
  on(DeliveryListActions.addManyToSelected,
    (state, { orderIds }) => ({
      ...state,
      orderIds: [...state.orderIds, ...orderIds],
    }),
  ),
  on(DeliveryListActions.removeFromSelected,
    (state, { orderId }) => ({
      ...state,
      orderIds: state.orderIds.filter(o => o !== orderId),
    }),
  ),
  on(DeliveryListActions.clear,
    state => ({
      ...state,
      ...initialState,
    }),
  ),
  on(DeliveryListActions.clearStatus,
    state => ({
      ...state,
      status: {
        save: {
          pending: false,
          resolved: true,
          rejected: false,
          err: null,
        },
        unload: {
          pending: false,
          resolved: true,
          rejected: false,
          err: null,
        },
        append: {
          pending: false,
          resolved: true,
          rejected: false,
          err: null,
        },
      },
    }),
  ),
);
