import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'apx-ui-shared-slow-report-dialog',
  templateUrl: './slow-report-dialog.component.html',
  styleUrls: ['./slow-report-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlowReportDialogComponent {
}
