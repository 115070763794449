import { Injectable } from '@angular/core';
import { ApxSolsticeWebClientService } from '@apx-ui/apx-web-api-v1';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap } from 'rxjs';

import { PlantActions } from '../actions';

@Injectable()
export class PlantEffects {

  handlePlantLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PlantActions.loadPlants,
      ),
      switchMap(() => {

        return this.client.getPlants().pipe(
          switchMap(plants => {

            return [
              PlantActions.loadPlantsSuccess({ plants }),
              PlantActions.addPlants({ plants }),
            ];
          }),
          catchError(err => of(PlantActions.loadPlantsFailure({ err }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private readonly client: ApxSolsticeWebClientService,
  ) {
  }

}
