import { HttpErrorResponse } from '@angular/common/http';
import {
  OffCycleContinuousOrder,
  OffCycleContinuousOrderValidationInfo,
} from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

import {
  CreateOffCycleContinuousOrderStep,
  OffCycleContinuousOrderFormData,
} from '../../interfaces';

export const setStep = createAction(
  '[APX Off Cycle Continuous Orders Dialog] Set Step',
  props<{
    step: CreateOffCycleContinuousOrderStep;
  }>(),
);

export const saveCreateForm = createAction(
  '[APX Off Cycle Continuous Orders Dialog] Create Form',
  props<{
    order: OffCycleContinuousOrderFormData;
  }>(),
);

export const validateOrder = createAction(
  '[APX Off Cycle Continuous Orders Dialog] Validate Order',
  props<{
    locationId: string;
    tankId: string;
    injectionPoint: string;
    accountId: string;
  }>(),
);

export const validateOrderSuccess = createAction(
  '[APX Off Cycle Continuous Orders Dialog] Validate Order Success',
  props<{
    locationId: string;
    tankId: string;
    injectionPoint: string;
    validationInfo: OffCycleContinuousOrderValidationInfo;
  }>(),
);

export const validateOrderFailure = createAction(
  '[APX Off Cycle Continuous Orders Dialog] Validate Order Failure',
  props<{
    locationId: string;
    tankId: string;
    injectionPoint: string;
    validationInfo?: OffCycleContinuousOrderValidationInfo;
    err?: HttpErrorResponse;
  }>(),
);

export const saveConfirm = createAction(
  '[APX Off Cycle Continuous Orders Dialog] Save Confirm',
  props<{
    order: OffCycleContinuousOrderFormData;
  }>(),
);

export const success = createAction(
  '[APX Off Cycle Continuous Orders Dialog] Success',
  props<{
    orderData: OffCycleContinuousOrder;
  }>(),
);

export const failure = createAction(
  '[APX Off Cycle Continuous Orders Dialog] Failure',
  props<{
    message: string;
  }>(),
);

export const destroy = createAction(
  '[APX Off Cycle Continuous Orders Dialog] Destroy',
);

export const cleanStatus = createAction(
  '[APX Off Cycle Continuous Orders Dialog] Clean Status',
);
