<div class="chip-container" (click)="$event.stopPropagation();">
  <div #myIdentifier
       fxLayout="row"
       fxLayoutAlign="space-between start"
       class="text-no-wrap position-relative">

    <mat-chip-list fxFlex="calc(100% - 40px)"
                   class='pt-4'>
      <mat-chip *ngFor="let chip of tags"
                class="text-no-wrap">
        {{chip.Tag}}
      </mat-chip>
    </mat-chip-list>

    <button title="More Tags" 
            mat-icon-button
            class="ml-auto"
            aria-label="openPopover"
            (click)="openPopover($event, showDeleteOption);">
      <mat-icon>more_horiz</mat-icon>
    </button>

    <div class="hidden-block"></div>
  </div>
</div>
