import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RouterStateService } from '@apx-ui/apx-core';
import { of, Subscription, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { UIDialogService } from '../../services';

@Component({
  selector: 'apx-ui-shared-select-row-count',
  templateUrl: './select-row-count.component.html',
  styleUrls: ['./select-row-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectRowCountComponent implements OnInit {
  rows = of([
    '1000', '5000', '10000', '25000', '35000', '45000', 'All',
  ]);

  rowsSelectCtrl = new UntypedFormControl();

  private subscription: Subscription;

  constructor(
    private readonly routerState: RouterStateService,
    private readonly dialog: UIDialogService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.routerState.getQueryParam$('rows').subscribe(rows => {
      this.rowsSelectCtrl.setValue(rows ?? '1000', { onlySelf: true, emitEvent: false });
    });

    this.subscription = this.rowsSelectCtrl.valueChanges
      .pipe(
        switchMap(rows => {

            return !rows || rows === '1000' ? of(rows) : this.dialog.openSlowReportConfirmation()
              .afterClosed()
              .pipe(
                map(res => res ? rows : undefined),
              );
          },
        ),
        tap(rows => {
          if (rows) {
            this.routerState.navigate([], { rows }, { queryParamsHandling: 'merge' });
          } else {
            this.rowsSelectCtrl.setValue(
              this.activatedRoute.snapshot.queryParams.rows ?? '1000',
              { onlySelf: true, emitEvent: false },
            );
          }
        }),
      ).subscribe();
  }

}
