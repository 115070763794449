import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromDeliveryListReducer from './delivery-list.reducer';
import * as fromFiltersReducer from './filters.reducer';
import * as fromOffCycleBatchOrdersReducer from './off-cycle-batch-orders.reducer';
import * as fromOffCycleContinuousOrdersReducer from './off-cycle-continuous-orders.reducer';
import * as fromOrderReducer from './order.reducer';
import * as fromActiveDeliveryReducer from './orders-for-delivery.reducer';

export const featureName = 'apx-orders';

export interface State {
  deliveryList: fromDeliveryListReducer.DeliveryListStates;
  activeDelivery: fromActiveDeliveryReducer.OrdersForDeliveryStates;
  order: fromOrderReducer.OrderStates;
  filters: fromFiltersReducer.OrdersFilterStates;
  CreateOffCycleBatchOrders: fromOffCycleBatchOrdersReducer.CreateOffCycleBatchOrdersState;
  CreateOffCycleContinuousOrders: fromOffCycleContinuousOrdersReducer.CreateOffCycleContinuousOrdersState;
}

export function reducers(state: State | undefined, action: Action): State {
  return combineReducers({
    deliveryList: fromDeliveryListReducer.reducer,
    activeDelivery: fromActiveDeliveryReducer.reducer,
    order: fromOrderReducer.reducer,
    filters: fromFiltersReducer.reducer,
    CreateOffCycleBatchOrders: fromOffCycleBatchOrdersReducer.reducer,
    CreateOffCycleContinuousOrders: fromOffCycleContinuousOrdersReducer.reducer,
  })(state, action);
}

export const selectAPXOrdersFeature = createFeatureSelector<State>(featureName);

export const selectedDeliveryListState = createSelector(selectAPXOrdersFeature, state => state.deliveryList);
export const selectedActiveDeliveryState = createSelector(selectAPXOrdersFeature, state => state.activeDelivery);
export const selectedOrderState = createSelector(selectAPXOrdersFeature, state => state.order);
export const selectedFiltersState = createSelector(selectAPXOrdersFeature, state => state.filters);
export const selectOffCycleBatchState =
  createSelector(selectAPXOrdersFeature, state => state.CreateOffCycleBatchOrders);
export const selectOffCycleContinuousState =
  createSelector(selectAPXOrdersFeature, state => state.CreateOffCycleContinuousOrders);