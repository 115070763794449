import { EntityMetadataMap, EntityPluralNames } from '@ngrx/data';

export const entityMetadataMap: EntityMetadataMap = {
  BatchOrderNotifications: {},
  ContinuousOrderNotifications: {},
  SampleNotifications: {},
};

export const pluralNames: EntityPluralNames = {
  BatchOrderNotifications: 'order-notification/get-expired-batch-order-notifications',
  ContinuousOrderNotifications: 'order-notification/get-expired-continuous-order-notifications',
  SampleNotifications: 'sample-notification/get-expired-notifications',
};
