import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { OrdersForDeliveryState } from '@apx-ui/apx-orders-store';
import { UILayoutStateService } from '@apx-ui/apx-shared-ui';
import { Observable } from 'rxjs';

@Component({
  selector: 'apx-ui-shared-side-bar-field-task',
  templateUrl: './side-bar-field-task.component.html',
  styleUrls: ['./side-bar-field-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideBarFieldTaskComponent implements OnInit {
  expanded$: Observable<boolean>;
  isDeliveryActive$: Observable<boolean>;

  showStimulation$: Observable<boolean>;

  constructor(
    private readonly state: UILayoutStateService,
    private readonly activeDeliveryState: OrdersForDeliveryState,
  ) {
  }

  ngOnInit(): void {
    this.activeDeliveryState.loadActiveDelivery();

    this.expanded$ = this.state.isAsideExpanded$();

    this.isDeliveryActive$ = this.activeDeliveryState.isDeliveryActive$();

    this.showStimulation$ = this.activeDeliveryState.showStimulationDelivery$();
  }

}
