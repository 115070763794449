import { AbstractModel } from '@apx-ui/apx-core';

import { Sample } from '../interfaces';

export enum SAMPLE_TYPE {
  WATER,
  GAS,
  BACTERIA,
  OIL,
  RESIDUAL,
  MILLIPORE,
  PHASE_SEPARATION,
  COUPON,
  INFO
}

const SAMPLE_ICONS = {
  [SAMPLE_TYPE.WATER]: 'halWaterIcon',
  [SAMPLE_TYPE.GAS]: 'halGasIcon',
  [SAMPLE_TYPE.BACTERIA]: 'halBacteriaIcon',
  [SAMPLE_TYPE.OIL]: 'halOilIcon',
  [SAMPLE_TYPE.RESIDUAL]: 'halResidualIcon',
  [SAMPLE_TYPE.MILLIPORE]: 'halMilliporeIcon',
  [SAMPLE_TYPE.PHASE_SEPARATION]: 'halPhaseSeparationIcon',
  [SAMPLE_TYPE.COUPON]: 'coupon_icon',
  [SAMPLE_TYPE.INFO]: 'halInfo',
};

export class SampleModel extends AbstractModel<Sample> {

  static getIconName(type: SAMPLE_TYPE): string {
    return SAMPLE_ICONS[type];
  }

  static getType(analysisType: string): SAMPLE_TYPE {
    if (analysisType.toLowerCase().indexOf('water') > -1) {
      return SAMPLE_TYPE.WATER;
    } else if (analysisType.toLowerCase().indexOf('gas') > -1) {
      return SAMPLE_TYPE.GAS;
    } else if (analysisType.toLowerCase().indexOf('bacteria') > -1) {
      return SAMPLE_TYPE.BACTERIA;
    } else if (analysisType.toLowerCase().indexOf('oil') > -1) {
      return SAMPLE_TYPE.OIL;
    } else if (analysisType.toLowerCase().indexOf('coupon') > -1) {
      return SAMPLE_TYPE.COUPON;
    } else if (analysisType.toLowerCase().indexOf('millipore') > -1) {
      return SAMPLE_TYPE.MILLIPORE;
    } else if (analysisType.toLowerCase().indexOf('phase separation') > -1) {
      return SAMPLE_TYPE.PHASE_SEPARATION;
    } else if (analysisType.toLowerCase().indexOf('residual') > -1) {
      return SAMPLE_TYPE.RESIDUAL;
    }

    return SAMPLE_TYPE.INFO;
  }

  constructor(protected override data: Sample) {
    super(data);
  }

  getId(): number {
    return this.data.Id;
  }

  getAnalysisType(): string {
    return this.data.AnalysisType;
  }

  getAnalysisIcon(): string {
    const type = SampleModel.getType(this.getAnalysisType());

    return SampleModel.getIconName(type);
  }

  getSampleMethod(): string {
    return this.data.SampleMethod;
  }

  getSamplePoint(): string {
    return this.data.SamplePoint;
  }

  getFrequency(): number {
    return this.data.Frequency;
  }

  getIsActive(): boolean {
    return this.data.IsActive;
  }

  getTextColor(): string {
    return this.data.TextColor;
  }

  getDueText(): string {
    return this.data.DueText;
  }

  getDays(): number {
    return this.data.Days;
  }

  getStartDate(): Date {
    return this.data.StartDate;
  }

  getTitle(): string {
    return `${this.data.SampleMethod} ᛫ ${this.data.SamplePoint}`;
  }
}
