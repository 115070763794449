<table *ngIf="displayedColumns"
       mat-table
       [dataSource]="notifications"
       class="w-100">
  <ng-container matColumnDef="accountName">
    <th *matHeaderCellDef
        mat-header-cell>
      Account
    </th>
    <td *matCellDef="let element"
        mat-cell
        [title]="element.accountName">
      {{element.accountName}}
    </td>
  </ng-container>

  <ng-container matColumnDef="locationName">
    <th *matHeaderCellDef
        mat-header-cell>
      Asset Name
    </th>
    <td *matCellDef="let element"
        mat-cell
        [title]="element.locationName">
      {{element.locationName}}
    </td>
  </ng-container>

  <ng-container matColumnDef="samplePoint">
    <th *matHeaderCellDef
        mat-header-cell>
      Sample Point
    </th>
    <td *matCellDef="let element"
        mat-cell>
      {{element.samplePoint}}
    </td>
  </ng-container>

  <ng-container matColumnDef="expiredDayCount">
    <th *matHeaderCellDef
        mat-header-cell>
      Days Late
    </th>
    <td *matCellDef="let element"
        mat-cell>
      {{element.expiredDayCount}}
    </td>
  </ng-container>

  <tr *matHeaderRowDef="displayedColumns"
      mat-header-row></tr>
  <tr *matRowDef="let row; columns: displayedColumns;"
      mat-row></tr>
</table>
