import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { BreadCrumb, BREADCRUMBS_PROVIDER, BreadcrumbsProvider } from './page-breadcrumb.token';

@Component({
  selector: 'apx-ui-shared-page-breadcrumb',
  templateUrl: './page-breadcrumb.component.html',
  styleUrls: ['./page-breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIPageBreadcrumbComponent {

  breadcrumbs$: Observable<BreadCrumb[]>;

  constructor(@Inject(BREADCRUMBS_PROVIDER) public breadcrumbsProvider: BreadcrumbsProvider) {
    this.breadcrumbs$ = this.breadcrumbsProvider.getBreadcrumbs();
  }

  getBreadcrumbLeftText(name: string): string {
    return name?.length > 10 ? name.slice(0, name.length - 3) : name;
  }

  getBreadcrumbRightText(name: string): string {
    return name?.length > 10 ? name.slice(-3) : '';
  }

}
