import { AbstractModel } from '@apx-ui/apx-core';

import {
  Asset,
  BatchTreatment,
  ContinuousTreatment,
  CustomTag,
  Package,
  StimProduct,
  StimulationTreatment,
} from '../interfaces';
import { ProductModel } from './product.model';
import { SampleModel } from './sample.model';
import { TankModel } from './tank.model';

export enum STIMULATION_JOB_TYPE {
  ON_SITE = 'On Site',
  DROP_OFF = 'Drop Off'
}

export enum LOCATION_TYPES {
  WELL = 'Well',
  NON_WELL = 'Non-Well'
}

export enum TREATMENT_TYPE {
  CONTINUOUS,
  BATCH,
  STIMULATION,
  SAMPLES
}

export enum TREATMENT_RATE_METHOD_ENUM {
  CALCULATED = 'Calculated',
  MANUAL = 'Manual'
}

export enum MEASUREMENT_UNIT_ENUM {
  GALLONS = 'gallons',
  GPT = 'gpt'
}

export enum PRODUCTION_FLUID_VOLUME {
  '00000000-0000-0000-0000-000000000001' = 'bpd',
  '00000000-0000-0000-0000-000000000002' = 'm3pd'
}

export enum PRODUCTION_FLUID_GAS {
  '00000000-0000-0000-0000-000000000001' = 'mcfd',
  '00000000-0000-0000-0000-000000000002' = 'm3pd'
}

export enum PRODUCTION_FLUID_INDEX {
  '00000000-0000-0000-0000-000000000001' = 1,
  '00000000-0000-0000-0000-000000000002' = 0.158987
}

export class AssetModel extends AbstractModel<Asset> {

  private samples: SampleModel[] = [];

  constructor(protected override data: Asset) {
    super(data);
    this.samples = data?.Samples?.map(s => new SampleModel(s)) ?? [];
  }

  getTanks(tanks: { [key: string]: TankModel }): TankModel[] {
    const tanksMap = this.data.ContinuousTreatments
      ?.reduce((acc, treatment) => {
        return tanks[treatment.Tank] ?  { ...acc, [treatment.Tank]: tanks[treatment.Tank] } : acc;
      }, {}) ?? [];

    return Object.values(tanksMap);
  }

  getTanksNames(tanks: { [key: string]: TankModel }): string[] {
    const tanksMap = this.data.ContinuousTreatments
      ?.reduce((acc, treatment) => {
        return tanks[treatment.Tank] ?  { ...acc, [treatment.Tank]: tanks[treatment.Tank].getTankName() } : acc;
      }, {}) ?? [];

    return Object.values(tanksMap);
  }

  getProductsIds(tanks: { [key: string]: TankModel }): string[] {
    const batchProducts = this.data.BatchTreatments?.map(b => b.Product) ?? [];

    const tanksProducts = this.getTanks(tanks)
      ?.reduce((acc, t) => [...acc, ...t.getData().TankProducts.map(tp => tp.ProductId)], []);

    const stimProducts = this.data.StimulationJobs?.reduce((acc, t) => {
      return [...acc, ...t.products.map(p => p.product)];
    }, []) ?? [];

    return [...tanksProducts, ...batchProducts, ...stimProducts];
  }

  getPackagesIds(tanks: { [key: string]: TankModel }): string[] {
    const batchPackageIds = this.data.BatchTreatments?.
    reduce((acc, b) => [...acc, ...b.packageIds], []) ?? [];

    const tanksPackagesIds = this.getTanks(tanks)
      ?.reduce((acc, t) => [...acc, ...t.getData().packageIds ?? []], []);

    const stimPackagesIds = this.data.StimulationJobs?.reduce((acc, t) => {
      return [...acc, ...t.products.reduce((acct, p) => [...acct, ...p.packageIds ?? []], [])];
    }, []) ?? [];

    return [...batchPackageIds, ...tanksPackagesIds, ...stimPackagesIds];
  }

  getInjectionPoints(): string[] {
    const batchInjectionPoints = this.data.BatchTreatments.map(b => b.InjectionPoint);
    const contiInjectionPoints = this.data.ContinuousTreatments.map(c => c.InjectionPoint);

    const stimInjectionPoints = this.data.StimulationJobs?.reduce((acc, t) => {
      return [...acc, ...t.products.reduce((acct, p) => [...acct, ...p.injectionPoint ?? []], [])];
    }, []) ?? [];

    return [...batchInjectionPoints, ...contiInjectionPoints, ...stimInjectionPoints];
  }

  getRouteItemId(): string {
    return this.data.routeItemId;
  }

  getAccountId(): string {
    return this.data.AccountId;
  }

  getId(): string {
    return this.data.Id;
  }

  getLocationDescription(): string {
    return this.data.LocationDescription;
  }

  getLocationType(): string {
    return this.data.LocationType;
  }

  getRouteName(): string {
    return this.data.routeName;
  }

  getLocationName(): string {
    return this.data.LocationName;
  }

  getField(): string {
    return this.data.Field;
  }

  getSite(): string {
    return this.data.SiteName;
  }

  getName(): string {
    return this.data.LocationType === LOCATION_TYPES.WELL
      ? this.data.LocationName
      : `${this.data.SiteName} ᛫ ${this.data.LocationDescription} ${this.data.UIDNumber
        ? `᛫ ${this.data.UIDNumber}` : ''}`;
  }

  getTitleArr(): any[] {
    return this.data.LocationType === LOCATION_TYPES.WELL
      ? [this.data.LocationName]
      : [this.data.SiteName, this.data.LocationDescription, this.data.UIDNumber];
  }

  getJobType(): string {
    return this.data.StimulationJobs && this.data.StimulationJobs[0]?.jobType;
  }

  isOnSite(): boolean {
    return this.getJobType() === STIMULATION_JOB_TYPE.ON_SITE;
  }

  isDropOff(): boolean {
    return this.getJobType() === STIMULATION_JOB_TYPE.DROP_OFF;
  }

  countBatchTreatments(): number {
    return this.data.BatchTreatments?.filter(b => !b.InActive)?.length || 0;
  }

  countContinuousTreatments(): number {
    return this.data.ContinuousTreatments?.filter(b => !b.InActive)?.length || 0;
  }

  continuousTreatments(tanks?: TankModel[]): ContinuousTreatment[] {
    return (this.data.ContinuousTreatments ?? [])
      .filter(c => !c.InActive)
      .map(c => ({
        ...c,
        tankModel: tanks?.find(t => t.getId() === c.Tank),
      }));
  }

  batchTreatments(products?: ProductModel[], packages?: Package[]): BatchTreatment[] {
    return (this.data.BatchTreatments ?? [])
      .filter(c => !c.InActive)
      .map(c => ({
        ...c,
        productModel: products?.find(t => t.getId() === c.Product),
        packages: c.packageIds.map(p => packages?.find(k => p === k.Id)) || [],
      }));
  }

  countStimulationJobsDropOff(): number {
    return this.data.StimulationJobs && this.data.StimulationJobs[0]?.products
      && this.data.StimulationJobs[0].jobType === STIMULATION_JOB_TYPE.DROP_OFF
      && this.data.StimulationJobs[0].products?.length || 0;
  }

  countStimulationJobsOnSite(): number {
    return this.data.StimulationJobs && this.data.StimulationJobs[0]?.products
      && this.data.StimulationJobs[0].jobType === STIMULATION_JOB_TYPE.ON_SITE
      && this.data.StimulationJobs[0].products?.length || 0;
  }

  stimulationJobs(): StimulationTreatment {
    return this.data.StimulationJobs && this.data.StimulationJobs[0];
  }

  stimulationProducts(packages?: Package[]): StimProduct[] {
    return this.stimulationJobs()?.products
      .map(c => ({
        ...c,
        packages: c.packageIds.map(p => packages?.find(k => p === k.Id)) || [],
      }));
  }

  noOfStages(): number | string {
    return this.stimulationJobs()?.noOfStages || 'N/A';
  }

  stagesCapturedCount(): number {
    return this.stimulationJobs()?.stagesCapturedCount;
  }

  horsePowerCompany(): string {
    return this.stimulationJobs()?.horsePowerCompany;
  }

  groupSamplesByType(): any {
    const groupped = this.samples.reduce((acc, curr) => {
      const type = SampleModel.getType(curr.getAnalysisType());

      acc[type] = acc[type] || [];
      acc[type].push(curr);
      return acc;
    }, {});

    return Object.keys(groupped).map((k) => {
      return {
        type: k,
        icon: SampleModel.getIconName(k as any),
        samples: groupped[k],
      };
    });
  }

  getAnalysisTypeList(): string[] {
    return Array.from(new Set(this.samples.map(s => s.getAnalysisIcon())));
  }

  getCustomTags(): CustomTag[] {
    return this.data.CustomTags;
  }

  getAggregatedCustomTags(): CustomTag[] {
    return this.data.aggregatedCustomTags;
  }

  getOilProductionFluid(): number | null {
    return this.data.OilProductionFluid;
  }

  getWaterProductionFluid(): number | null {
    return this.data.WaterProductionFluid;
  }

  getGasProductionFluid(): number | null {
    return this.data.GasProductionFluid;
  }

  getSamples(): SampleModel[] {
    return this.samples;
  }

  getIcon(): string {
    return this.data?.LocationType === LOCATION_TYPES.WELL ? 'halNounOilIcon' : '';
  }

  isWell(): boolean {
    return this.data?.LocationType === LOCATION_TYPES.WELL;
  }

  getUnitSystemId(): string {
    return this.data?.unitSystemId;
  }

  getProductionFluidUoMKoef(): number {
    return this.data?.productionFluidUoMKoef;
  }
}
