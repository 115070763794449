import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'apx-ui-shared-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UIDialogComponent {
  @Input() header = true;
  @Input() footer = true;
  @Input() flush = false;
  @HostBinding('class.apx-ui-shared-dialog') apxUiDialog = true;
}
