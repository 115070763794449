import { Injectable } from '@angular/core';
import { BaseEntityCollectionService } from '@apx-ui/apx-core';
import { UnitSystems } from '@apx-ui/apx-web-api-v1';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable({ providedIn: 'root' })
export class AccountUnitSystemService extends BaseEntityCollectionService<UnitSystems> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('AccountUnitSystem', serviceElementsFactory);
  }
}
