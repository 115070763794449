import { Injectable } from '@angular/core';
import { ApxSolsticeWebClientService } from '@apx-ui/apx-web-api-v1';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap } from 'rxjs';

import { OrdersFilters } from '../actions';

@Injectable()
export class OrdersFiltersEffects {

  handleContinuousOrdersFiltersLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrdersFilters.loadContinuousFilters,
      ),
      switchMap(() => {

        return this.client.getContinuousOrdersFilters().pipe(
          switchMap(filters => {

            return [
              OrdersFilters.loadContinuousFiltersSuccess({ filters }),
            ];
          }),
          catchError(err => of(
            OrdersFilters.loadContinuousFiltersFailure({ err }),
          )),
        );
      }),
    ),
  );

  handleStimulationOrdersFiltersLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrdersFilters.loadStimulationFilters,
      ),
      switchMap(() => {

        return this.client.getStimulationFilters().pipe(
          switchMap(filters => {

            return [
              OrdersFilters.loadStimulationFiltersSuccess({ filters }),
            ];
          }),
          catchError(err => of(
            OrdersFilters.loadStimulationFiltersFailure({ err }),
          )),
        );
      }),
    ),
  );

  handleDeliveryOrdersFiltersLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrdersFilters.loadDeliveryFilters,
      ),
      switchMap(() => {

        return this.client.getDeliveryFilters().pipe(
          switchMap(filters => {

            return [
              OrdersFilters.loadDeliveryFiltersSuccess({ filters }),
            ];
          }),
          catchError(err => of(
            OrdersFilters.loadDeliveryFiltersFailure({ err }),
          )),
        );
      }),
    ),
  );

  handleBatchOrdersFiltersLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrdersFilters.loadBatchFilters,
      ),
      switchMap(() => {

        return this.client.getBatchOrdersFilters().pipe(
          switchMap(filters => {

            return [
              OrdersFilters.loadBathcFiltersSuccess({ filters }),
            ];
          }),
          catchError(err => of(
            OrdersFilters.loadBatchFiltersFailure({ err }),
          )),
        );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly client: ApxSolsticeWebClientService,
  ) {
  }

}
