import { Injectable } from '@angular/core';
import { BaseEntityCollectionService } from '@apx-ui/apx-core';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable({
  providedIn: 'root',
})
export class ContinuousTreatmentForUserService extends BaseEntityCollectionService<any> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('ContinuousTreatmentForUser', serviceElementsFactory);
  }
}
