import { EntityMetadataMap, EntityPluralNames } from '@ngrx/data';

export const entityMetadataMap: EntityMetadataMap = {
  UnitSystems: {
    selectId: o => o.unitSystemId,
  },
  UnitTypes: {
    selectId: o => o.id,
  },
  Uoms: {
    selectId: o => o.id,
  },
  UserUnitSystem: {
    selectId: o => o.userID,
  },
  AccountUnitSystem: {
    selectId: o => o.accountId,
  },
  UserAccountUnitSystem: {
    selectId: o => o.accountId,
  },
};

export const pluralNames: EntityPluralNames = {
  UnitSystems: 'units-of-measures/unit-systems',
  UnitTypes: 'units-of-measures/unit-types',
  Uoms: 'units-of-measures/uoms',
  UserUnitSystem: 'units-of-measures/user-unit-system',
  AccountUnitSystem: 'units-of-measures/account-unit-system',
  UserAccountUnitSystem: 'units-of-measures/user-account-unit-system',
};
