import { Status } from '@apx-ui/apx-core';
import { Asset } from '@apx-ui/apx-web-api-v1';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { AssetActions } from '../actions';

export const adapter: EntityAdapter<Asset> = createEntityAdapter<Asset>({
  selectId: a => a.Id,
  sortComparer: (a, b) => a.LocationName.localeCompare(b.LocationName),
});

export interface AssetState extends EntityState<Asset> {
  assets: string[];
  status: {
    load: Status;
    loadForUser: Status;
    save: Status;
    loadAssetById: Status;
  };
  selectedAssetId: string | null;
  assetsForUser: Asset[];
}

export const initialState: AssetState = adapter.getInitialState({
  assets: [],
  status: {
    load: {
      pending: false,
      resolved: false,
      rejected: false,
      err: null,
    },
    loadForUser: {
      pending: false,
      resolved: false,
      rejected: false,
      err: null,
    },
    save: {
      pending: false,
      resolved: true,
      rejected: false,
      err: null,
    },
    loadAssetById: {
      pending: false,
      resolved: true,
      rejected: false,
      err: null,
    },
  },
  selectedAssetId: null,
  assetsForUser: [],
});

export const reducer = createReducer(
  initialState,
  on(AssetActions.addAsset,
    (state, { asset }) => {
      return adapter.upsertOne(asset, state);
    },
  ),
  on(AssetActions.addAssets,
    (state, { assets }) => {
      return adapter.upsertMany(assets, state);
    },
  ),
  on(AssetActions.updateAsset,
    (state, { assetId, changes }) => {
      return adapter.updateOne({ id: assetId, changes }, state);
    },
  ),
  on(AssetActions.setAsset,
    (state, { assetId }) => ({
      ...state,
      selectedAssetId: assetId,
    }),
  ),
  on(AssetActions.deselectAsset,
    (state) => ({
      ...state,
      selectedAssetId: null,
    }),
  ),
  on(AssetActions.loadAssetById, state => ({
      ...state,
      status: {
        ...state.status,
        loadAssetById: {
          ...state.status.load,
          pending: true,
          err: null,
        },
      },
    }),
  ),
  on(AssetActions.loadAssetByIdSuccess,
    state => ({
        ...state,
        status: {
          ...state.status,
          loadAssetById: {
            ...state.status.load,
            pending: false,
            resolved: true,
            rejected: false,
          },
        },
      }
    ),
  ),
  on(AssetActions.loadAssetByIdFailure,
    (state, { err }) => ({
      ...state,
      status: {
        ...state.status,
        loadAssetById: {
          ...state.status.load,
          pending: false,
          rejected: true,
          err,
        },
      },
    }),
  ),

  on(AssetActions.loadAssets, state => ({
      ...state,
      status: {
        ...state.status,
        load: {
          ...state.status.load,
          pending: true,
          err: null,
        },
      },
    }),
  ),
  on(AssetActions.loadAssetsSuccess,
    (state, { assets }) => ({
        ...state,
        assets: assets.map(a => a.Id),
        status: {
          ...state.status,
          load: {
            ...state.status.load,
            pending: false,
            resolved: true,
            rejected: false,
          },
        },
      }
    ),
  ),
  on(AssetActions.loadAssetsFailure,
    (state, { err }) => ({
      ...state,
      assets: [],
      status: {
        ...state.status,
        load: {
          ...state.status.load,
          pending: false,
          rejected: true,
          err,
        },
      },
    }),
  ),
  on(AssetActions.loadAssetsForUser, state => ({
      ...state,
      status: {
        ...state.status,
        loadForUser: {
          ...state.status.loadForUser,
          pending: true,
          err: null,
        },
      },
    }),
  ),
  on(AssetActions.loadAssetsForUserSuccess,
    (state, { assets }) => ({
        ...state,
        assetsForUser: assets,
        status: {
          ...state.status,
          loadForUser: {
            ...state.status.loadForUser,
            pending: false,
            resolved: true,
            rejected: false,
          },
        },
      }
    ),
  ),
  on(AssetActions.loadAssetsForUserFailure,
    (state, { err }) => ({
      ...state,
      assetsForUser: [],
      status: {
        ...state.status,
        loadForUser: {
          ...state.status.loadForUser,
          pending: false,
          rejected: true,
          err,
        },
      },
    }),
  ),
  on(AssetActions.createWell,
    AssetActions.updateWell,
    state => {

      return {
        ...state,
        status: {
          ...state.status,
          save: {
            pending: true,
            resolved: false,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(AssetActions.createWellSuccess,
    AssetActions.updateWellSuccess,
    (state, { well }) => {

      return {
        ...adapter.upsertOne(well as Asset, state),
        assets: [well.Id as string, ...state.assets],
        status: {
          ...state.status,
          save: {
            pending: false,
            resolved: true,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(AssetActions.createWellFailure,
    AssetActions.updateWellFailure,
    (state, { err }) => {

      return {
        ...state,
        status: {
          ...state.status,
          save: {
            pending: false,
            resolved: false,
            rejected: true,
            err,
          },
        },
      };
    },
  ),
  on(AssetActions.clear,
    state => ({
      ...state,
      assets: [],
      status: {
        load: {
          pending: false,
          resolved: false,
          rejected: false,
          err: null,
        },
        loadForUser: {
          pending: false,
          resolved: false,
          rejected: false,
          err: null,
        },
        save: {
          pending: false,
          resolved: false,
          rejected: false,
          err: null,
        },
        loadAssetById: {
          pending: false,
          resolved: true,
          rejected: false,
          err: null,
        },
      },
      selectedAssetId: null,
      assetsForUser: [],
    }),
  ),
);

const { selectAll, selectEntities } = adapter.getSelectors();

export const selectAssets = selectAll;
export const selectAssetEntities = selectEntities;
