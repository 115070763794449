import { Manager } from '@apx-ui/apx-web-api-v1';
import { createSelector } from '@ngrx/store';

import * as fromReducer from '../reducers';
import * as fromManagerReducer from '../reducers/manager.reducer';

export const selectManagerAll = createSelector(
  fromReducer.selectManagerState,
  fromManagerReducer.selectManagers,
);

export const selectManagerEntities = createSelector(
  fromReducer.selectManagerState,
  fromManagerReducer.selectManagerEntities,
);

export const selectManagers = createSelector(
  selectManagerAll, managers => managers ? managers : [],
);

export const selectLoadManagersStatus = createSelector(
  fromReducer.selectManagerState,
  state => state.status,
);

export const selectLoadedManagers = createSelector(
  fromReducer.selectManagerState,
  selectManagerEntities,
  (s, e) => s.managers.map(a => e[a] as Manager),
);
