import { HttpErrorResponse } from '@angular/common/http';
import { CaptureDeliveryData, OrderPartialUpdate } from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

import { BatchOrder, ContinuousOrder, IOrderDeliveryData, StimulationOrder, TaskOrder } from '../../interfaces';
import { OrderModels } from '../../models';

export const addComment = createAction(
  '[APX Orders] Add Comment',
  props<{
    authorId: string;
    authorName: string;
    comment: string;
    order: TaskOrder | IOrderDeliveryData;
    timeStamp?: string;
  }>(),
);

export const addCommentSuccess = createAction(
  '[APX Orders] Add Comment Success',
  props<{
    comment: string;
    order: TaskOrder
  }>(),
);

export const addCommentFailure = createAction(
  '[APX Orders] Add Comment Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const cancelOrder = createAction(
  '[APX Orders] Cancel Order',
  props<{
    authorId: string;
    authorName: string;
    comment: string;
    order: TaskOrder;
  }>(),
);

export const cancelStimulationOrder = createAction(
  '[APX Orders] Cancel Stimulation Order',
  props<{
    order: TaskOrder;
  }>(),
);

export const cancelStimulationCompletedOrder = createAction(
  '[APX Orders] Cancel Stimulation Completed Order',
  props<{
    order: TaskOrder;
  }>(),
);

export const cancelOrderSuccess = createAction(
  '[APX Orders] Cancel Order Success',
  props<{
    comment?: string;
    order: TaskOrder;
  }>(),
);

export const cancelOrderFailure = createAction(
  '[APX Orders] Cancel Order Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const partialUpdate = createAction(
  '[APX Orders] Partial Order Update',
  props<{
    order: ContinuousOrder | BatchOrder;
    payload: OrderPartialUpdate;
  }>(),
);

export const partialUpdateSuccess = createAction(
  '[APX Orders] Partial Order Update Success',
  props<{
    order: ContinuousOrder | BatchOrder;
    payload: OrderPartialUpdate;
  }>(),
);

export const partialUpdateFailure = createAction(
  '[APX Orders] Partial Order Update Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const captureDeliveryOrder = createAction(
  '[APX Orders] Capture Delivery Order',
  props<{
    order: OrderModels;
    captureData: CaptureDeliveryData;
    mode: 'edit' | 'report' | 'new';
  }>(),
);

export const captureDeliveryOrderSuccess = createAction(
  '[APX Orders] Capture Delivery Order Success',
  props<{
    order: IOrderDeliveryData;
  }>(),
);

export const captureDeliveryOrderFailure = createAction(
  '[APX Orders] Capture Delivery Order Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const captureOrder = createAction(
  '[APX Orders] Capture Order',
  props<{
    order: OrderModels;
    captureData: CaptureDeliveryData;
    mode: 'edit' | 'report' | 'new';
  }>(),
);

export const captureOrderSuccess = createAction(
  '[APX Orders] Capture Order Success',
  props<{
    order: IOrderDeliveryData;
  }>(),
);

export const captureOrderFailure = createAction(
  '[APX Orders] Capture Order Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const adjustCaptureOrder = createAction(
  '[APX Orders] Adjust Capture Order',
  props<{
    order: OrderModels;
    captureData: CaptureDeliveryData;
  }>(),
);

export const adjustCaptureOrderSuccess = createAction(
  '[APX Orders] Adjust Order Success',
  props<{
    order: IOrderDeliveryData;
  }>(),
);

export const adjustCaptureOrderFailure = createAction(
  '[APX Orders] Adjust Order Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const approveOrder = createAction(
  '[APX Orders] Approve Order',
  props<{
    order: StimulationOrder;
    volume: number;
  }>(),
);

export const approveOrderSuccess = createAction(
  '[APX Orders] Approve Order Success',
  props<{
    order: StimulationOrder;
  }>(),
);

export const approveOrderFailure = createAction(
  '[APX Orders] Approve Order Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const clearStatus = createAction(
  '[APX Orders] Clear Status',
);
