<form *ngIf="form"
      [formGroup]="form">

  <div fxLayout="column"
       fxLayoutAlign="start start">

    <apx-ui-shared-multi-select placeholder="Assigned To"
                                formControlName="assignedTo"
                                data-test-id="assignedTo"
                                [items]="filters.Status.AssignedTo">
    </apx-ui-shared-multi-select>

    <mat-form-field fxFlexFill
                    fxFlex.gt-md
                    appearance="outline">

      <mat-label>Assignment Date</mat-label>

      <mat-date-range-input [rangePicker]="picker">

        <input matStartDate
               formControlName="assignmentDateFrom"
               placeholder="Start date">
        <input matEndDate
               formControlName="assignmentDateTo"
               placeholder="End date">

      </mat-date-range-input>

      <mat-datepicker-toggle matSuffix
                             [for]="picker">
      </mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <ng-container *ngIf="expandFilters">

      <apx-ui-shared-multi-select placeholder="Assignment Status"
                                  formControlName="assignmentStatus"
                                  data-test-id="assignmentStatus"
                                  [items]="filters.Status.AssignmentStatus">
      </apx-ui-shared-multi-select>

      <mat-form-field fxFlexFill
                      fxFlex.gt-md
                      appearance="outline">
        <mat-label>Tank Capacity</mat-label>
        <apx-ui-shared-range-input formControlName="tankCapacity"></apx-ui-shared-range-input>
      </mat-form-field>

      <mat-form-field fxFlexFill
                      fxFlex.gt-md
                      appearance="outline">
        <mat-label>Est. Delivery Volume</mat-label>
        <apx-ui-shared-range-input formControlName="estDeliveryVolume"></apx-ui-shared-range-input>
      </mat-form-field>
    </ng-container>


  </div>
</form>
