<table mat-table
       [dataSource]="notifications"
       class="w-100">

  <ng-container matColumnDef="icon">
    <th *matHeaderCellDef
        mat-header-cell
        width="20px"
        class="pl-0">
    </th>
    <td *matCellDef="let element"
        mat-cell
        class="pl-0">
      <mat-icon class="md-12"
                color="primary"
                [svgIcon]="element.isNonRoutineOrder? 'halOrderOffCycle'
                    : element.isScheduleContinuous ? 'halOrderScheduled' : 'halOrderBase'">
      </mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="location">
    <th *matHeaderCellDef
        mat-header-cell>
      Location
    </th>
    <td *matCellDef="let element"
        mat-cell>
      <div class="mat-caption text-truncate">{{element.accountName}}</div>
      <div class="mat-caption text-truncate text-blue">{{element.locationName}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="tank">
    <th *matHeaderCellDef
        mat-header-cell>
      Tank name
    </th>
    <td *matCellDef="let element"
        mat-cell>
      <div class="mat-caption text-truncate">{{element.tankName}}</div>
      <div>&nbsp;</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="delivery">
    <th *matHeaderCellDef
        mat-header-cell
        class="pr-0"
        width="80px">
      Est delivery
    </th>
    <td *matCellDef="let element"
        mat-cell
        class="text-right pr-0">
      <div class="mat-caption">{{element.estDeliveryDate | date: 'MM/dd/yyyy'}}</div>
      <div class="mat-caption text-blue"><b>{{element.estDeliveryVolume}}</b></div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
