import { BatchOrder } from '../../interfaces';

export const BATCH_ORDERS_FIXTURE: BatchOrder[] = [
  {
    Id: '6256567ba18e421e708b7238',
    LocationId: '60b792d73b013223c4f3e22b',
    AccountId: '5f1e12c13b01321b4cfdb8b5',
    AccountRoles: [
      'SuperAdmin',
    ],
    LocationName: '1-0 Luxsoft 1-1',
    Route: null,
    routeOrderIndex: null,
    routePlantOrderIndex: null,
    Field: 'Luxsoft Field',
    DeliveryStatus: 'Pending',
    TargetVolume: 10,
    VolumeUoM: 'gallon',
    Comment: null,
    PrimaryProduct: {
      ProductId: '5f1e14ed3b01321b4cfdb8bb',
      DilutionPercentage: 0,
      UofM: 'gallon',
      ProductName: 'MC B-8614',
      ProductType: null,
      TotalDeliveryVolume: 0,
    },
    Flush: {
      flushVolume: 100,
      flushUofM: 'gallon',
    },
    DueDate: '2022-04-28T00:00:00Z',
    DeliveryDate: null,
    InjectionPoint: 'Backside/Annular',
    CustomerName: 'Digital Solutions',
    DeliveryType: 'Batch',
    PreTankLevel: null,
    PostTankLevel: null,
    FlushVolume: null,
    FlushUofM: 'gallon',
    Site: null,
    AssetDescription: 'Sucker Rod',
    AssetType: 'Well',
    TimeZone: 'Central Standard Time',
    TotalDeliveryVolume: 0,
    packageIds: [
      '611bb6d23b013213241eab43',
      '611bb7273b013213241eab44',
    ],
    isNonRoutineOrder: false,
  } as any,
  {
    Id: '6256567ba18e421e708b7238-1',
    LocationId: '60b792d73b013223c4f3e22b',
    AccountId: '5f1e12c13b01321b4cfdb8b5',
    AccountRoles: [
      'SuperAdmin',
    ],
    LocationName: '1-0 Luxsoft 1-1-1',
    Route: null,
    routeOrderIndex: null,
    routePlantOrderIndex: null,
    Field: 'Luxsoft Field',
    DeliveryStatus: 'Pending',
    TargetVolume: 10,
    VolumeUoM: 'gallon',
    Comment: null,
    PrimaryProduct: {
      ProductId: '5f1e14ed3b01321b4cfdb8bb',
      DilutionPercentage: 0,
      UofM: 'gallon',
      ProductName: 'MC B-8614',
      ProductType: null,
      TotalDeliveryVolume: 0,
    },
    Flush: {
      flushVolume: 100,
      flushUofM: 'gallon',
    },
    DueDate: '2022-04-28T00:00:00Z',
    DeliveryDate: null,
    InjectionPoint: 'Backside/Annular',
    CustomerName: 'Digital Solutions',
    DeliveryType: 'Batch',
    PreTankLevel: null,
    PostTankLevel: null,
    FlushVolume: null,
    FlushUofM: 'gallon',
    Site: null,
    AssetDescription: 'Sucker Rod',
    AssetType: 'Well',
    TimeZone: 'Central Standard Time',
    TotalDeliveryVolume: 0,
    packageIds: [
      '611bb6d23b013213241eab43',
      '611bb7273b013213241eab44',
    ],
    isNonRoutineOrder: false,
  } as any,
];
