import { Injectable } from '@angular/core';
import { ApxSolsticeWebClientService } from '@apx-ui/apx-web-api-v1';
import { EntityActionFactory, EntityOp } from '@ngrx/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { CreateOffCycleBatchOrderStep, entityTypes } from '../../interfaces';
import { OffCycleBatchOrdersActions } from '../actions';

@Injectable()
export class OffCycleBatchOrdersEffects {
  handleLoadBatchTreatments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OffCycleBatchOrdersActions.validateOrder),
      switchMap(({ locationId, accountProductId, injectionPoint, accountId }) => {
        return this.client
          .getBatchOrderOffCycleValidationInfo(locationId, accountProductId, injectionPoint, {
            headers: {
              accounts: accountId,
            },
          })
          .pipe(
            switchMap((validationInfo) => {
              const payload = { locationId, accountProductId, injectionPoint, validationInfo };

              return validationInfo.errors?.length
                ? [
                    OffCycleBatchOrdersActions.validateOrderFailure(payload),
                    OffCycleBatchOrdersActions.failure({ message: validationInfo.errors?.join(' ') }),
                  ]
                : [
                    OffCycleBatchOrdersActions.validateOrderSuccess(payload),
                    OffCycleBatchOrdersActions.setStep({ step: CreateOffCycleBatchOrderStep.Confirm }),
                  ];
            }),
            catchError((err) =>
              of(
                OffCycleBatchOrdersActions.validateOrderFailure({ locationId, accountProductId, injectionPoint, err }),
                OffCycleBatchOrdersActions.failure({
                  message: Object.values(err.error?.FieldErrors || [])?.join(' ') || err.message,
                }),
              ),
            ),
          );
      }),
    ),
  );

  handleSaveConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OffCycleBatchOrdersActions.saveConfirm),
      switchMap(({ order }) => {
        return this.client
          .createOffCycleBatchOrders(order, {
            headers: {
              accounts: order?.accountId,
            },
            params: {
              unitSystemID: order.unitSystemId,
            },
          })
          .pipe(
            switchMap((res) => {
              return [
                this.entityActionFactory.create(entityTypes['Batch'], EntityOp.QUERY_MANY, {
                  unitSystemID: order.unitSystemId,
                }),
                OffCycleBatchOrdersActions.success({ orderData: res }),
              ];
            }),
            catchError((err) => {
              return of(
                OffCycleBatchOrdersActions.failure({
                  message: Object.values(err.error?.FieldErrors || [])?.join(' ') || err.message,
                }),
              );
            }),
          );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly entityActionFactory: EntityActionFactory,
    private client: ApxSolsticeWebClientService,
  ) {}
}
