import { StimulationOrder } from '../../interfaces';

export const STIM_ORDERS_FIXTURE: StimulationOrder[] = [
  {
    Id: '61bc7ff93b0132139cf8f8eb',
    LocationId: '60bbc1f93b013702b4e77395',
    AccountId: '5f1e12c13b01321b4cfdb8b5',
    AccountRoles: [
      'SuperAdmin',
    ],
    LocationName: '00StimAsset',
    CriticalDate: new Date('2021-12-17T12:18:01.779Z'),
    EmptyDate: null,
    DeliveryStatus: 'Pending Approval',
    PrevDeliveryStatus: 'NA',
    Route: null,
    Field: 'Blackman Ranch 1',
    Comment: null,
    TargetVolume: 1,
    TotalDeliveryVolume: 0,
    VolumeUoM: 'gallon',
    PrimaryProduct: {
      ProductId: '611ba4db3b013213241ea8fb',
      DilutionPercentage: 0,
      UofM: 'gallon',
      ProductName: '2K7 SOLUTION W-M',
      ProductType: null,
      TotalDeliveryVolume: 1,
      TargetVolume: null,
    },
    CustomerName: 'Digital Solutions',
    DeliveryType: 'Stimulation',
    InjectionPoint: 'AST',
    Site: null,
    SiteShipToID: null,
    AssetShipToId: null,
    AccountCustomTags: [
      {
        Id: 0,
        Tag: null,
      },
    ],
    AssetDescription: 'Intermittent Gas Lift',
    AssetType: 'Well',
    TimeZone: 'Central Standard Time',
    DeliveryDate: null,
    JobType: 'On Site',
    DeliveryDateArray: null,
    packageIds: [
      '611ba0363b013213241ea8f5',
      '611b9f9c3b013213241ea8f2',
    ],
  } as any,
  {
    Id: '61bc7ff93b0132139cf8f8eb-1',
    LocationId: '60bbc1f93b013702b4e77395',
    AccountId: '5f1e12c13b01321b4cfdb8b5',
    AccountRoles: [
      'SuperAdmin',
    ],
    LocationName: '00StimAsset-1',
    CriticalDate: new Date('2021-12-17T12:18:01.779Z'),
    EmptyDate: null,
    DeliveryStatus: 'Pending Approval',
    PrevDeliveryStatus: 'NA',
    Route: null,
    Field: 'Blackman Ranch 1',
    Comment: null,
    TargetVolume: 1,
    TotalDeliveryVolume: 0,
    VolumeUoM: 'gallon',
    PrimaryProduct: {
      ProductId: '611ba4db3b013213241ea8fb',
      DilutionPercentage: 0,
      UofM: 'gallon',
      ProductName: '2K7 SOLUTION W-M',
      ProductType: null,
      TotalDeliveryVolume: 1,
      TargetVolume: null,
    },
    CustomerName: 'Digital Solutions',
    DeliveryType: 'Stimulation',
    InjectionPoint: 'AST',
    Site: null,
    SiteShipToID: null,
    AssetShipToId: null,
    AccountCustomTags: [
      {
        Id: 0,
        Tag: null,
      },
    ],
    AssetDescription: 'Intermittent Gas Lift',
    AssetType: 'Well',
    TimeZone: 'Central Standard Time',
    DeliveryDate: null,
    JobType: 'On Site',
    DeliveryDateArray: null,
    packageIds: [
      '611ba0363b013213241ea8f5',
      '611b9f9c3b013213241ea8f2',
    ],
  } as any,
];
