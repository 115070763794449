<div fxLayout="row" fxLayoutAlign="center center">
  <!-- <mat-icon class="mr-4 ml--12"
            [color]="params.value ? 'primary' : null"
            (click)="params.value && openPdf(params.value)">
    picture_as_pdf
  </mat-icon> -->

  <button mat-icon-button
          aria-label="PDF"
          matTooltip="PDF"
          class="pl-2"
          [disabled]="!params.value"
          (click)="openPdf(params.value)">
    <mat-icon>
      picture_as_pdf
    </mat-icon>
  </button>
</div>