import { ILoadingCellRendererAngularComp } from '@ag-grid-community/angular';
import { ILoadingCellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'apx-ui-shared-ag-grid-loader',
  templateUrl: './ag-grid-loader.component.html',
  styleUrls: ['./ag-grid-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridLoaderComponent implements ILoadingCellRendererAngularComp  {
  public params!: ILoadingCellRendererParams & { loadingMessage: string };

    agInit(params: ILoadingCellRendererParams & { loadingMessage: string }): void {
        this.params = params;
    }
}
