import { AgEditorComponent } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

interface InitParams extends ICellEditorParams {
  field: string;
  values: Record<string, string>;
}

interface Items {
  id: string;
  name: string;
}

@Component({
  selector: 'apx-ui-shared-check-box-list',
  templateUrl: './check-box-list-cell.component.html',
  styleUrls: ['./check-box-list-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckBoxListCellComponent implements AgEditorComponent, OnDestroy {

  form: UntypedFormGroup;
  sourceValues: Record<string, string>;
  filteredList: Items[];

  private params: InitParams;
  private subscription = new Subscription();

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      options: '',
      search: '',
    });

    this.subscription.add(
      this.searchControl.valueChanges.subscribe(label => {
        this.filteredList = Object.entries(this.sourceValues)
          .filter(([k, v]) => !label
            || this.optionsControl.value?.includes(k)
            || v.toLowerCase().includes(label.toLowerCase()))
          .map(([k, v]) => ({ id: k, name: v }))
          .sort((a, b) => a.name.trim().toUpperCase() > b.name.trim().toUpperCase() ? 1 : -1);
      }),
    );
  }

  get searchControl(): AbstractControl {
    return this.form.get('search');
  }

  get optionsControl(): AbstractControl {
    return this.form.get('options');
  }

  get width(): number {
    return Math.max(150, this.params?.column?.getActualWidth());
  }

  agInit(params: InitParams): void {
    this.params = params;
    this.sourceValues = params.values;
    this.filteredList = Object.entries(this.sourceValues)
      .map(([k, v]) => ({ id: k, name: v }))
      .sort((a, b) => a.name.trim().toUpperCase() > b.name.trim().toUpperCase() ? 1 : -1);

    this.optionsControl.setValue(params.data[params.field]);
  }

  getValue(): string[] {
    return this.optionsControl.value;
  }

  isPopup(): boolean {
    return true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
