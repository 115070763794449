<input #from
       placeholder="From"
       (input)="onChange()"
       (blur)="onBlur()"
       [formControl]="innerCtrlFrom"
       (focusin)="onFocusIn()"
       (focusout)="onFocusOut()"
       [max]="to.value"
       matInput
       type="number"
/>
<span class="spacer">&ndash;</span>
<input #to
       (input)="onChange()"
       placeholder="To"
       (blur)="onBlur()"
       [formControl]="innerCtrlTo"
       (focusin)="onFocusIn()"
       (focusout)="onFocusOut()"
       [min]="from.value"
       matInput
       type="number"
/>

