import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Notifications } from '@apx-ui/apx-web-api-v1';

import {
  ApxNotificationsSummaryContainerComponent,
} from '../../apx-notifications-summary-container/apx-notifications-summary-container.component';

@Component({
  selector: 'apx-ui-notifications-list-view',
  templateUrl: './apx-notifications-list-view.component.html',
  styleUrls: ['./apx-notifications-list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApxNotificationsListViewComponent {
  @Input() notifications: Notifications;
  @Input() account_id: string;
  @Input() unitSystemId: string;
  @Output() closedDialog: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly dialog: MatDialog,
  ) {
  }

  showSummary(category, subCategory, checkedDate, unitSystemId): void {

    this.dialog.open(ApxNotificationsSummaryContainerComponent, {
      width: '80vw',
      maxWidth: '80vw',
      data: {
        account_id: this.account_id,
        checked_date: checkedDate,
        category: category,
        sub_category: subCategory,
        unitSystemId: unitSystemId,
      },
    });

    this.closedDialog.emit();
  }
}
