<div fxLayout="column"
     fxFlexFill>
  <div fxLayout="row"
       fxLayoutAlign="space-between starts">
    <h2 mat-dialog-title
        fxLayout="row"
        fxLayoutAlign="space-between starts">
      <mat-icon class="mr-16"
                color="primary">
        notifications_none
      </mat-icon>
      Notification Summary
    </h2>

    <button mat-dialog-close
            mat-icon-button
            color="primary"
            class="mr--24 mt--24"
            data-test-id="notificationsContainerCloseBtn">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider color="primary"></mat-divider>

  <mat-dialog-content>

      <ng-container *ngIf="data.category === 'sample_expired'">
        <apx-ui-notifications-summary-samples [data]="data">
        </apx-ui-notifications-summary-samples>
      </ng-container>

      <ng-container *ngIf="data.category === 'order_expired'">

        <ng-container *ngIf="data.sub_category === 'Continuous Order'">
          <apx-ui-notifications-summary-continuous-orders [data]="data">
          </apx-ui-notifications-summary-continuous-orders>
        </ng-container>

        <ng-container *ngIf="data.sub_category === 'Batch Order'">
          <apx-ui-notifications-summary-batch-orders [data]="data">
          </apx-ui-notifications-summary-batch-orders>
        </ng-container>

      </ng-container>

    </mat-dialog-content>

  <mat-dialog-actions align="center">
      <button mat-flat-button
              type="submit"
              color="primary"
              data-test-id="confirmBtn"
              class="mr-auto"
              (click)="onExport()">
        Export
      </button>

      <button mat-stroked-button
              mat-dialog-close
              data-test-id="cancelBtn"
              type="button">
        Cancel
      </button>
    </mat-dialog-actions>

</div>
