import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterStateService } from '@apx-ui/apx-core';
import { UnitSystems } from '@apx-ui/apx-web-api-v1';

@Component({
  selector: 'apx-ui-shared-user-unit-system-switcher',
  templateUrl: './user-unit-system-switcher.component.html',
  styleUrls: ['./user-unit-system-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserUnitSystemSwitcherComponent {
  @Input() value: string;
  @Input() unitSystems: UnitSystems[];

  constructor(
    private readonly routerState: RouterStateService,
  ) {}

  changeUS(name: string): void {
    this.routerState.navigate(
      [],
      { unitSystem: name },
      { queryParamsHandling: 'merge' });
  }
}
