import { createSelector } from '@ngrx/store';

import * as fromReducer from '../reducers';

export const selectAssetFilters = createSelector(
  fromReducer.selectFilterState,
  state => state.assetFilter,
);

export const selectAssetFiltersStatus = createSelector(
  fromReducer.selectFilterState,
  state => state.status.asset,
);
