import { Injectable } from '@angular/core';
import { ApxSolsticeWebClientService } from '@apx-ui/apx-web-api-v1';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap } from 'rxjs';

import { ManagerActions } from '../actions';

@Injectable()
export class ManagerEffects {

  handleManagerLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ManagerActions.loadManagers,
      ),
      switchMap(() => {

        return this.client.getPlantsManagers().pipe(
          switchMap(managers => {

            return [
              ManagerActions.loadManagersSuccess({ managers }),
              ManagerActions.addManagers({ managers }),
            ];
          }),
          catchError(err => of(ManagerActions.loadManagersFailure({ err }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private readonly client: ApxSolsticeWebClientService,
  ) {
  }

}
