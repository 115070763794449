import { Injectable } from '@angular/core';
import { BaseEntityCollectionService } from '@apx-ui/apx-core';
import { EntityActionOptions, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { map, Observable } from 'rxjs';

import { ContinuousOrder } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class ContinuousOrdersService extends BaseEntityCollectionService<ContinuousOrder> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('ContinuousOrder', serviceElementsFactory);
  }

  override getAll(options?: EntityActionOptions): Observable<ContinuousOrder[]> {
    return super.getAll(options).pipe(
      map(orders => orders.filter(o => o.LocationName)),
    );
  }

}
