<div *ngIf="header"
     class="apx-ui-dialog-header">
  <ng-content select="[header]"></ng-content>
</div>

<mat-dialog-content class="apx-ui-dialog-body"
                    [class.apx-ui-dialog-body--flush]="flush">
  <ng-content select="[body]"></ng-content>
</mat-dialog-content>

<mat-dialog-actions *ngIf="footer"
                    class="apx-ui-dialog-footer"
                    align="end">
  <ng-content select="[footer]"></ng-content>
</mat-dialog-actions>
