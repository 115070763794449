import { Status } from '@apx-ui/apx-core';
import { Customer } from '@apx-ui/apx-web-api-v1';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { CustomerActions } from '../actions';

export const adapter: EntityAdapter<Customer> = createEntityAdapter<Customer>({
  selectId: a => a.soldToID,
  sortComparer: (a, b) => a.soldToID - b.soldToID,
});

export interface CustomerState extends EntityState<Customer> {
  customers: number[];
  status: Status;
}

export const initialState: CustomerState = adapter.getInitialState({
  customers: [],
  status: {
    pending: false,
    resolved: false,
    rejected: false,
    err: null,
  },
});

export const reducer = createReducer(
  initialState,
  on(CustomerActions.addCustomer,
    (state, { customer }) => {
      return adapter.upsertOne(customer, state);
    },
  ),
  on(CustomerActions.addCustomers,
    (state, { customers }) => {
      return adapter.upsertMany(customers, state);
    },
  ),
  on(CustomerActions.loadCustomers, state => ({
      ...state,
      status: {
        ...state.status,
        pending: true,
        err: null,
      },
    }),
  ),
  on(CustomerActions.loadCustomersSuccess,
    (state, { customers }) => ({
        ...state,
        customers: customers.map(a => a.soldToID),
        status: {
          pending: false,
          resolved: true,
          rejected: false,
          err: null,
        },
      }
    ),
  ),
  on(CustomerActions.loadCustomersFailure,
    (state, { err }) => ({
      ...state,
      customers: [],
      status: {
        ...state.status,
        pending: false,
        rejected: true,
        err,
      },
    }),
  ),
  on(CustomerActions.clearState,
    (state) => ({
      ...state,
      status: {
        pending: false,
        resolved: false,
        rejected: false,
        err: null,
      },
    }),
  ),
);

const { selectAll, selectEntities } = adapter.getSelectors();

export const selectCustomers = selectAll;
export const selectCustomerEntities = selectEntities;
