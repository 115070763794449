import { Status } from '@apx-ui/apx-core';
import { createReducer, on } from '@ngrx/store';

import { ManageAccountActions } from '../actions';

export interface ManageAccountsState {
  accounts: string[];
  status: Status;
}

export const initialState: ManageAccountsState = {
  accounts: [],
  status: {
    pending: false,
    resolved: false,
    rejected: false,
    err: null,
  },
};

export const reducer = createReducer(
  initialState,
  on(ManageAccountActions.loadAccounts, state => ({
      ...state,
      status: {
        ...state.status,
        pending: true,
        err: null,
      },
    }),
  ),
  on(ManageAccountActions.loadAccountsSuccess,
    (state, { accounts }) => ({
        accounts: accounts.map(a => a.Id),
        status: {
          pending: false,
          resolved: true,
          rejected: false,
          err: null,
        },
      }
    ),
  ),
  on(ManageAccountActions.addAccount,
    (state, { account }) => ({
        accounts: [account.Id, ...state.accounts],
        status: {
          pending: false,
          resolved: true,
          rejected: false,
          err: null,
        },
      }
    ),
  ),
  on(ManageAccountActions.loadAccountsFailure,
    (state, { err }) => ({
      ...state,
      accounts: [],
      status: {
        ...state.status,
        pending: false,
        rejected: true,
        err,
      },
    }),
  ),
);
