import { Status } from '@apx-ui/apx-core';
import { createReducer, on } from '@ngrx/store';

import { SuspendStep } from '../../interfaces';
import { SuspendActions } from '../actions';

export interface SuspendState {
  step: SuspendStep,
  saveContinuousTreatment: Status;
  saveBatchTreatment: Status;
  saveAsset: Status;
  saveSample: Status;
  close: boolean;
}

const initialState: SuspendState = {
  step: SuspendStep.Create,
  close: false,
  saveContinuousTreatment: {
    pending: false,
    resolved: true,
    rejected: false,
    err: null,
  },
  saveBatchTreatment: {
    pending: false,
    resolved: true,
    rejected: false,
    err: null,
  },
  saveAsset: {
    pending: false,
    resolved: true,
    rejected: false,
    err: null,
  },
  saveSample: {
    pending: false,
    resolved: true,
    rejected: false,
    err: null,
  },
};

export const reducer = createReducer(
  initialState,
  on(SuspendActions.suspendContinuousTreatment,
    state => {
      return {
        ...state,
        saveContinuousTreatment: {
          pending: true,
          resolved: false,
          rejected: false,
          err: null,
        },
      };
    },
  ),
  on(SuspendActions.suspendContinuousTreatmentSuccess,
    state => {
      return {
        ...state,
        saveContinuousTreatment: {
          pending: false,
          resolved: true,
          rejected: false,
          err: null,
        },
      };
    },
  ),
  on(SuspendActions.suspendContinuousTreatmentFailure,
    (state, { err }) => {
      return {
        ...state,
        saveContinuousTreatment: {
          pending: false,
          resolved: false,
          rejected: true,
          err,
        },
      };
    },
  ),
  on(SuspendActions.suspendBatchTreatment,
    state => {
      return {
        ...state,
        saveBatchTreatment: {
          pending: true,
          resolved: false,
          rejected: false,
          err: null,
        },
      };
    },
  ),
  on(SuspendActions.suspendBatchTreatmentSuccess,
    state => {
      return {
        ...state,
        saveBatchTreatment: {
          pending: false,
          resolved: true,
          rejected: false,
          err: null,
        },
      };
    },
  ),
  on(SuspendActions.suspendBatchTreatmentFailure,
    (state, { err }) => {
      return {
        ...state,
        saveBatchTreatment: {
          pending: false,
          resolved: false,
          rejected: true,
          err,
        },
      };
    },
  ),
  on(SuspendActions.suspendAsset,
    state => {
      return {
        ...state,
        saveAsset: {
          pending: true,
          resolved: false,
          rejected: false,
          err: null,
        },
      };
    },
  ),
  on(SuspendActions.suspendAssetSuccess,
    state => {
      return {
        ...state,
        saveAsset: {
          pending: false,
          resolved: true,
          rejected: false,
          err: null,
        },
      };
    },
  ),
  on(SuspendActions.suspendAssetFailure,
    (state, { err }) => {
      return {
        ...state,
        saveAsset: {
          pending: false,
          resolved: false,
          rejected: true,
          err,
        },
      };
    },
  ),
  on(SuspendActions.suspendSample,
    state => {
      return {
        ...state,
        saveSample: {
          pending: true,
          resolved: false,
          rejected: false,
          err: null,
        },
      };
    },
  ),
  on(SuspendActions.suspendSampleSuccess,
    state => {
      return {
        ...state,
        saveSample: {
          pending: false,
          resolved: true,
          rejected: false,
          err: null,
        },
      };
    },
  ),
  on(SuspendActions.suspendSampleFailure,
    (state, { err }) => {
      return {
        ...state,
        saveSample: {
          pending: false,
          resolved: false,
          rejected: true,
          err,
        },
      };
    },
  ),
  on(SuspendActions.setStep,
    (state, { step }) => {
      return {
        ...state,
        step,
      };
    },
  ),
  on(SuspendActions.close,
    (state, { close }) => {
      return {
        ...state,
        close,
      };
    },
  ),
  on(SuspendActions.destroy,
    () => ({ ...initialState }),
  ),
);
