import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { CellAction } from '../../../interfaces';

@Component({
  selector: 'apx-ui-shared-action-cell',
  templateUrl: './action-cell.component.html',
  styleUrls: ['./action-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionCellComponent<T extends { _state: string; error: string; }> implements ICellRendererAngularComp {

  private params: CellAction<T>;

  agInit(params: CellAction<T>): void {
    this.params = params;
  }

  rowState(): string {
    return this.params.data._state;
  }

  error(): string {
    return this.params.data.error;
  }

  btnDeleteHandler(): void {
    this.params.deleteClicked(this.params);
  }

  canDelete(): boolean {
    return !!this.params.deleteClicked;
  }

  btnUpdateHandler(): void {
    this.params.updateClicked(this.params);
  }

  btnCancelHandler(): void {
    this.params.cancelClicked(this.params);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refresh(params: CellAction<T>): boolean {
    return false;
  }

  isRowValid(): boolean {
    return Object.entries(this.params.context.columns ?? {})
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, v]) => !!(v as any).validator)
      .every(([k, v]) =>
        !(v as any).validator({ ...this.params.columnApi.getColumn(k), data: this.params.data }));
  }

}
