import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'apx-ui-shared-suspend',
  templateUrl: './suspend.component.html',
  styleUrls: ['./suspend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuspendComponent {
  @Input() start;
  @Input() end;
}
