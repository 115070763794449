import { Injectable } from '@angular/core';
import { BaseEntityCollectionService } from '@apx-ui/apx-core';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { BatchOrderNotification } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class BatchOrderNotificationService extends BaseEntityCollectionService<BatchOrderNotification> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('BatchOrderNotifications', serviceElementsFactory);
  }

}
