<div fxLayout="row"
     fxLayoutAlign="space-between center"
     class="mb-4">
  <div>How To</div>
  <button mat-icon-button
          (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<p>To Paste copied data from Excel, mouse click in the appropriate column and row. Then on your
  keyboard, press Esc and then Ctrl+V.</p>

<div class="arrow"
     apxUiSharedPopoverArrow></div>
