import { CacheService } from './cache.service';
import { CacheHttpRequestService } from './cache-http-request.service';

export const services = [
  CacheHttpRequestService,
  CacheService,
];

export * from './cache.service';
export * from './cache-http-request.service';
