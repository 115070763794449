import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@apx-ui/apx-config';
import { ClientFileService, ClientOptions } from '@apx-ui/apx-core';
import { Observable } from 'rxjs';

import { FileInfo, FolderInfo } from '../interfaces/file.interface';

@Injectable()
export class ApxSolsticeWebFileClientService extends ClientFileService {

  constructor(http: HttpClient,
              config: ConfigService,
  ) {
    super(http, config);
  }

  getDocuments(accountId: string, folderId: string, options: ClientOptions = {}): Observable<FolderInfo> {
    const _options = {
      ...options,
      params: {
        ...options.params,
        accountId,
        ...folderId && { folderId },
      },
    };

    return this.get(`/Folders/items`, _options);
  }

  getSearchDocuments(accountId: string, folderId: string, options: ClientOptions = {}): Observable<FolderInfo> {
    const _options = {
      ...options,
      params: {
        ...options.params,
        accountId,
        ...folderId && { folderId },
      },
    };

    return this.get(`/File/search`, _options);
  }

  deleteDocument(accountId: string, id: string, options: ClientOptions = {}): Observable<FileInfo> {
    const _options = {
      ...options,
      params: {
        ...options.params,
        accountId,
        id,
      },
    };

    return this.delete(`/File`, _options);
  }

  deleteDocuments(accountId: string, ids: string[], options: ClientOptions = {}): Observable<FileInfo> {
    const _options = {
      ...options,
      params: {
        ...options.params,
        accountId,
        ids,
      },
    };

    return this.delete(`/File/batch`, _options);
  }

  updateDocument(accountId: string, id: string, name: string, parentFolderId: string,
                 tagIds: string[], version: string, accessLevel: string, uuid: string = null,
                 options: ClientOptions = {}): Observable<FileInfo> {
    const _options = { ...options, params: { ...options.params } };

    return this.put(`/File`, { accountId, id, uuid, name, parentFolderId, tagIds, version, accessLevel }, _options);
  }

  createFolderDocument(accountId: string, name: string,
                       parentFolderId: string, options: ClientOptions = {}): Observable<FileInfo> {
    const _options = { ...options, params: { ...options.params } };

    return this.post(`/File/create-folder`, { accountId, name, parentFolderId }, _options);
  }

  uploadDocument(accountId, parentFolderId, file, tags = [], version = '', accessLevel = '',
                 options: ClientOptions = {}): Observable<FileInfo> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('accountId', accountId);
    tags.forEach(tag => formData.append('tagIds', tag.id));
    formData.append('version', version);
    formData.append('accessLevel', accessLevel);

    if (parentFolderId) {
      formData.append('parentFolderId', parentFolderId);
    }

    return this.post(`/File/upload`, formData, options);
  }

  downloadFile(accountId: string, documentId: string, options: ClientOptions = {}): Observable<HttpRequest<string>> {
    const _options = {
      ...options,
      observe: 'response',
      responseType: 'arraybuffer',
      params: {
        ...options.params,
        accountId,
      },
    };

    return this.get(`/File/download/${documentId}`, _options);
  }

  downloadFiles(accountId: string, ids: string[], timeZone: string,
                options: ClientOptions = {}): Observable<HttpRequest<string>> {
    const _options = {
      ...options,
      observe: 'response',
      responseType: 'arraybuffer',
      params: {
        ids,
        accountId,
      },
    };

    return this.get(`/File/download/batch?timeZone=${timeZone}`, _options);
  }

  moveTo(ids: string[], parenFolderId: string, accountId: string, options: ClientOptions = {}): Observable<FileInfo> {
    const _options = {
      ...options,
      accountId,
      ids,
      parenFolderId,
    };

    return this.put(`/File/batch`, _options);
  }
}
