<form *ngIf="form"
      [formGroup]="form">

  <div fxLayout="column"
       fxLayoutAlign="start start">

    <mat-radio-group formControlName="orderExist"
                     class="mb-20">
      <mat-radio-button color="primary"
                        class="mr-32"
                        data-test-id="well"
                        [value]="true">
        Existing
      </mat-radio-button>

      <mat-radio-button color="primary"
                        data-test-id="non-well"
                        class="mr-32"
                        [value]="false">
        Non-Existing
      </mat-radio-button>

      <mat-radio-button color="primary"
                        data-test-id="non-well"
                        [value]="null">
        Both
      </mat-radio-button>
    </mat-radio-group>

    <mat-form-field appearance="outline">
      <mat-label>Order Method</mat-label>
      <mat-select formControlName="orderMethod"
                  data-test-id="orderMethod"
                  multiple>
        <mat-option *ngFor="let filter of filters.Status.OrderMethod"
                    [value]="filter">
          {{filter}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlexFill
                    fxFlex.gt-md
                    appearance="outline">

      <mat-label>Projected Empty Date</mat-label>

      <mat-date-range-input [rangePicker]="picker">

        <input matStartDate
               formControlName="projectedEmptyDateFrom"
               placeholder="Start date">
        <input matEndDate
               formControlName="projectedEmptyDateTo"
               placeholder="End date">

      </mat-date-range-input>

      <mat-datepicker-toggle matSuffix
                             [for]="picker">
      </mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

  </div>
</form>
