import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContinuousOrderNotification } from '@apx-ui/apx-notifications-store';

@Component({
  selector: 'apx-ui-notifications-summary-continuous-desktop',
  templateUrl: './apx-notifications-summary-continuous-desktop.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApxNotificationsSummaryContinuousDesktopComponent {
  @Input() notifications: ContinuousOrderNotification[];
  displayedColumns: string[] = [
    'isNonRoutineOrder',
    'accountName',
    'locationName',
    'tankName',
    'estDeliveryDate',
    'estDeliveryVolume',
    'uomName',
  ];
}
