import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'apx-ui-shared-pdf-icon-cell',
  templateUrl: './pdf-icon-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfIconCellComponent {

  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  openPdf(pdfUrl: string): void {
    window.open(pdfUrl, '_blank');
  }

}
