import { Injectable } from '@angular/core';
import { Statuses } from '@apx-ui/apx-core';
import {
  OffCycleBatchOrder,
  OffCycleBatchOrderValidationInfo,
} from '@apx-ui/apx-web-api-v1';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import {
  CreateOffCycleBatchOrderStep,
  OffCycleBatchOrderFormData,
} from '../../interfaces';
import { OffCycleBatchOrdersActions } from '../actions';
import {
  selectOffCycleBatchOrdersFormData,
  selectOffCycleBatchOrdersMessage,
  selectOffCycleBatchOrdersResult,
  selectOffCycleBatchOrdersStatus,
  selectOffCycleBatchOrdersStep,
  selectOffCycleBatchOrdersValidationInfo,
} from '../selectors';

@Injectable()
export class OffCycleBatchOrdersService {

  close$: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store<any>) {
  }

  getStep$(): Observable<CreateOffCycleBatchOrderStep> {
    return this.store.select(selectOffCycleBatchOrdersStep);
  }

  getStatus$(): Observable<Statuses> {
    return this.store.select(selectOffCycleBatchOrdersStatus);
  }

  getResult$(): Observable<OffCycleBatchOrder> {
    return this.store.select(selectOffCycleBatchOrdersResult);
  }

  getFormData$(): Observable<OffCycleBatchOrderFormData> {
    return this.store.select(selectOffCycleBatchOrdersFormData);
  }

  getValidationInfo$(): Observable<OffCycleBatchOrderValidationInfo> {
    return this.store.select(selectOffCycleBatchOrdersValidationInfo);
  }

  getMessage$(): Observable<string> {
    return this.store.select(selectOffCycleBatchOrdersMessage);
  }

  getClose$(): Observable<boolean> {
    return this.close$;
  }

  setStep(step: CreateOffCycleBatchOrderStep): void {
    this.store.dispatch(OffCycleBatchOrdersActions.setStep({ step }));
  }

  saveCreateForm(order: OffCycleBatchOrderFormData): void {
    this.store.dispatch(OffCycleBatchOrdersActions.saveCreateForm({ order }));
  }

  saveConfirm(order: OffCycleBatchOrderFormData): void {
    this.store.dispatch(OffCycleBatchOrdersActions.saveConfirm({ order }));
  }

  destroy(): void {
    this.store.dispatch(OffCycleBatchOrdersActions.destroy());
  }

  cleanStatus(): void {
    this.store.dispatch(OffCycleBatchOrdersActions.cleanStatus());
  }

  validateOrder(locationId: string, accountProductId: string, injectionPoint: string, accountId: string): void {
    this.store.dispatch(OffCycleBatchOrdersActions.validateOrder({
      locationId, accountProductId, injectionPoint, accountId,
    }));
  }

}
