<mat-sidenav-container [autosize]="true">

  <mat-sidenav *ngIf="leftSideBar$ | async"
               data-test-id="leftSideBar"
               opened
               mode="side"
               [fxFlex]="(isAsideCollapsed$ | async) ? '70px' : '240px'">

    <router-outlet name="sideBar"></router-outlet>

    <div [ngSwitch]="isAsideCollapsed$ | async"
         class="mt-auto text-right">

      <button *ngSwitchCase="true"
              mat-icon-button
              aria-label="expand"
              (click)="expandAside()">

        <mat-icon>
          keyboard_double_arrow_right
        </mat-icon>

      </button>

      <button *ngSwitchDefault
              mat-icon-button
              aria-label="collapse"
              (click)="collapseAside()">

        <mat-icon>
          keyboard_double_arrow_left
        </mat-icon>

      </button>

    </div>

  </mat-sidenav>

  <mat-sidenav-content>

    <router-outlet></router-outlet>

  </mat-sidenav-content>

</mat-sidenav-container>
