import { IOrderDeliveryData } from '../../interfaces';

export const DELIVERY_ORDERS_FIXTURE: IOrderDeliveryData[] = [
  {
    'OrderId': '624bce5558b92527b00f88fd',
    'AccountId': '5f1e12c13b01321b4cfdb8b5',
    'DeliveryCaptured': false,
    'PreTankLevel': null,
    'PostTankLevel': null,
    'ReasonId': null,
    'Comment': {
      'Comment': 'Bad Valve',
      'TimeStamp': '2022-06-05T20:15:13.913Z',
      'AuthorName': 'Oleksandr Andriiets',
      'AuthorId': 'oleksandr.andriiets@halliburton.com',
    } as any,
    'CompanySoldTo': 999999,
    'OrderType': 'Continuous',
    'TankId': '611c9f663b01320848b333c4',
    'TankName': '2K7 BUG STICK ᛫ 120',
    'LocationId': '60bbc1f93b013702b4e77395',
    'LocationName': '00StimAsset',
    'FlushVolume': null,
    'CapturedTimeStamp': '0001-01-01T00:00:00Z',
    'Id': null,
    'CriticalDate': new Date(),
    'EmptyDate': new Date(),
    'DeliveryStatus': 'Out for Delivery',
    'Route': null,
    'RouteOrderIndex': null,
    'RoutePlantOrderIndex': null,
    'Field': 'Blackman Ranch 1',
    'VolumeUoM': 'gallon',
    'PrimaryProduct': {
      'ProductId': '611ba25b3b013213241ea8f6',
      'DilutionPercentage': 0,
      'UofM': 'gallon',
      'ProductName': '2K7 BUG STICK',
      'LocationName': '00StimAsset',
      'TankId': '611c9f663b01320848b333c4',
      'InjectionPoint': 'Casing',
      'TargetVolume': 18,
      'CapturedVolume': 0,
      'ReconciledVolume': 0,
      'TotalDeliveryVolume': 0,
      'OrderId': '624bce5558b92527b00f88fd',
      'LocationId': '60bbc1f93b013702b4e77395',
      'ProductType': 'Biocides - Registered',
    },
    'DilutionProduct': [],
    'Flush': null,
    'DueDate': new Date(),
    'InjectionPoint': 'Casing',
    'SoldTo': 0,
    'TargetVolume': 18,
    'CustomerName': 'Digital Solutions',
    'SiteName': null,
    'AssetType': 'Well',
    'AssetDescription': 'Intermittent Gas Lift',
    'AccountCustomTags': [
      {
        'Id': 0,
        'Tag': 'Howie Rileys Well',
      },
      {
        'Id': 1,
        'Tag': 'Sales Gas Line',
      },
      {
        'Id': 2,
        'Tag': 'Oil Shipping Line',
      },
      {
        'Id': 3,
        'Tag': 'Capacity 500',
      },
      {
        'Id': 4,
        'Tag': "Micah's Test EB1",
      },
      {
        'Id': 5,
        'Tag': 'Really Good Scale Control',
      },
      {
        'Id': 6,
        'Tag': "Howie Riley's Well",
      },
      {
        'Id': 7,
        'Tag': 'Product Tag - Test1',
      },
      {
        'Id': 8,
        'Tag': 'Duplicate - Marked to be removed',
      },
      {
        'Id': 9,
        'Tag': 'TANK 1',
      },
      {
        'Id': 10,
        'Tag': 'TANK 2',
      },
      {
        'Id': 11,
        'Tag': 'TANK 3',
      },
      {
        'Id': 12,
        'Tag': 'TANK 4',
      },
      {
        'Id': 13,
        'Tag': 'Started 9/2020',
      },
      {
        'Id': 14,
        'Tag': 'Ends 9/2021',
      },
      {
        'Id': 15,
        'Tag': 'Steel City',
      },
      {
        'Id': 16,
        'Tag': 'Disconnected',
      },
      {
        'Id': 17,
        'Tag': 'Bo Gentry',
      },
      {
        'Id': 18,
        'Tag': 'AS TEST PACKAGE',
      },
      {
        'Id': 19,
        'Tag': 'Asset Tag 10',
      },
      {
        'Id': 20,
        'Tag': 'Tank Tag 10',
      },
      {
        'Id': 21,
        'Tag': 'Product Tag 10',
      },
      {
        'Id': 22,
        'Tag': 'Lesia_custom_tag',
      },
      {
        'Id': 23,
        'Tag': 'vne tag 12/6',
      },
      {
        'Id': 24,
        'Tag': 'jhjhj',
      },
      {
        'Id': 25,
        'Tag': 'vne cust tag 3/2',
      },
      {
        'Id': 26,
        'Tag': 'test-1-2-3',
      },
      {
        'Id': 27,
        'Tag': 'test-1',
      },
      {
        'Id': 28,
        'Tag': 'test-21',
      },
      {
        'Id': 29,
        'Tag': 'test-tag-1',
      },
      {
        'Id': 30,
        'Tag': 'test-tag-2',
      },
      {
        'Id': 31,
        'Tag': 'test-tag-3',
      },
      {
        'Id': 32,
        'Tag': 'test-tag-4',
      },
      {
        'Id': 33,
        'Tag': 'test-tag-5',
      },
      {
        'Id': 34,
        'Tag': 'test-tag-7',
      },
      {
        'Id': 35,
        'Tag': 'test-tag-8',
      },
      {
        'Id': 36,
        'Tag': 'test-tag-10',
      },
      {
        'Id': 37,
        'Tag': 'test-tag-11',
      },
      {
        'Id': 38,
        'Tag': 'test-tag-12-1',
      },
      {
        'Id': 39,
        'Tag': 'test-tag-13-1-1',
      },
      {
        'Id': 40,
        'Tag': 'кукуекеуdfdfd',
      },
    ],
    'GeoLocation': null,
    'TimeZone': 'Central Standard Time',
    'AssetShipToId': null,
    'modifiedDetails': null,
    'packageIds': [
      '611ba0363b013213241ea8f5',
      '611b9f9c3b013213241ea8f2',
      '611c283b3b013223b83f2215',
      '611c9db53b01320848b333bd',
    ],
    'isNonRoutineOrder': false,
    isScheduleContinuous: false,
  },
];
