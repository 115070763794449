import { Injectable } from '@angular/core';
import { ApxSolsticeWebClientService } from '@apx-ui/apx-web-api-v1';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, of, switchMap, withLatestFrom } from 'rxjs';

import { AssetActions, CreateSampleActions } from '../actions';
import * as fromSelectors from '../selectors';

@Injectable()
export class CreateAccountEffects {

  handleSaveConfirm$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(
          CreateSampleActions.saveConfirm,
        ),
        withLatestFrom(
          this.store.select(fromSelectors.selectCreateSampleFormData),
          this.store.select(fromSelectors.selectEditedAsset),
        ),
        switchMap(([, formData, asset]) => {
          return this.client.updateLocationAnalysis(asset.getId(), formData.samples).pipe(
            switchMap(res => {
              return [
                CreateSampleActions.success({ samples: res }),
                AssetActions.updateAsset({ assetId: asset.getId(), changes: { Samples: res } }),
              ];
            }),
            catchError(err => {
              return of(
                CreateSampleActions.failure({ message: err.error?.Message ?? err.message ?? 'Something went wrong' }),
              );
            }),
          );
        }),
      );
    },
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private readonly client: ApxSolsticeWebClientService,
  ) {
  }

}
