import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RouterStateService } from '@apx-ui/apx-core';
import { combineLatest, filter, Subscription, tap } from 'rxjs';

@Component({
  selector: 'apx-ui-shared-select-date-range',
  templateUrl: './select-date-range.component.html',
  styleUrls: ['./select-date-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectDateRangeComponent implements OnInit, OnDestroy {

  fromSelectCtrl = new UntypedFormControl();
  toSelectCtrl = new UntypedFormControl();

  private subscription: Subscription;

  constructor(
    private readonly routerState: RouterStateService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {

    this.routerState.getQueryParam$('from').subscribe(from => {
      if (!this.fromSelectCtrl.value) {
        this.fromSelectCtrl.setValue(from ?? '', { emitEvent: false });
      }

    });

    this.routerState.getQueryParam$('to').subscribe(to => {
      if (!this.toSelectCtrl.value) {
        this.toSelectCtrl.setValue(to ?? '', { emitEvent: false });

      }
    });

    this.subscription = combineLatest([
      this.fromSelectCtrl.valueChanges,
      this.toSelectCtrl.valueChanges,
    ])
      .pipe(
        filter(([from, to]) => !!from && !!to),
        tap(([from, to]) => {
          const fromDate = from ? new Date(from).toISOString() : null;
          const toDate = to ? new Date(to).toISOString() : null;

          this.routerState.navigate([], { from: fromDate, to: toDate }, { queryParamsHandling: 'merge' });
        }),
      ).subscribe();
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

}
