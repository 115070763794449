import { EntityMetadataMap, EntityPluralNames } from '@ngrx/data';

export const entityMetadataMap: EntityMetadataMap = {
  AssetsList: {
    selectId: o => o.Id,
  },
};

export const pluralNames: EntityPluralNames = {
  AssetsList: 'Location/GetLocationById',
};
