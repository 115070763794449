import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApxNotificationsStoreModule } from '@apx-ui/apx-notifications-store';
import { ApxSharedUiModule } from '@apx-ui/apx-shared-ui';

import { summaryComponents } from './apx-notifications-summary-container/components';
import { components } from './index';

@NgModule({
  declarations: [
    ...components,
    ...summaryComponents,
  ],
  imports: [
    CommonModule,
    ApxNotificationsStoreModule,
    ApxSharedUiModule,
    RouterModule,
  ],
})
export class ApxNotificationsModule {
}
