/* eslint-disable  @typescript-eslint/no-explicit-any */
import identity from 'lodash.identity';
import pickBy from 'lodash.pickby';

export const removeFalsyValues = (obj: any): any => {
  return pickBy(obj, identity);
};

export const removeNullValues = (obj: any): any => {
  return Object.keys(obj).reduce((acc: any, o) => {
    if (obj[o] !== null && obj[o] !== undefined) {
      acc[o] = obj[o];
    }
    return acc;
  }, { });
};

export const removeUndefinedValues = (obj: any): any => {
  return Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
};

export const replaceFalsyValuesWithNull = (obj: any): any => {
  return Object.keys(obj).reduce((acc: any, o) => {
    acc[o] = !obj[o] ? null : obj[o];
    return acc;
  }, { });
};

export const removeNullValuesAndEmptyArrays = (obj: any): any => {
  return Object.keys(obj).reduce((acc: any, o) => {
    if ((!Array.isArray(obj[o]) && obj[o] !== null && obj[o] !== undefined && obj[o] !== '')
      || (Array.isArray(obj[o]) && obj[o].length > 0)) {
      acc[o] = obj[o];
    }

    return acc;
  }, { });
};
