import { CustomTag } from './custom-tag.interface';
import { HumanResource } from './hr.interface';
import { KpiSection } from './kpi.interface';
import { APXNotificationSection } from './notification.interface';

export enum ACCOUNT_TYPES {
  STIMULATION = 'Stimulation',
  UPSTREAM = 'Upstream',
  MIDSTREAM = 'Midstream',
  DOWNSTREAM = 'Downstream'
}

export enum ACCOUNT_STATUSES {
  INACTIVE = 0,
  PENDING = 1,
  ACTIVE = 2
}

export interface AccountAnalysis {
  AnalysisId?: number;
  AnalysisType: string;
  SampleMethod: string;
  Frequency: number;
  CustomTags: {Id: number, Tag: string}[];
}

export interface EditableChart {
  ChartId: number;
  SeriesSelected: number[];
  CustomTitle: string;
}

export interface Account {
  Id: string;
  ID?: string;
  SoldTo?: number;
  soldTo?: number;
  ShipTo?: number;
  CustomerName: string;
  companyName?: string;
  RegionID?: number;
  RegionName: string;
  DistrictID?: number;
  DistrictName: string;
  PlantID: string;
  PlantName: string;
  AccountType: ACCOUNT_TYPES;
  AccountManagerID?: number;
  AccountManager: string;
  AccountStatusID: ACCOUNT_STATUSES;
  AccountStatus: string;
  SupervisorEmail: string;
  CrewId: number;
  AccountRoles: string[];
  plant?: string;
  plant_id?: string;
  analysis?: any;
}

export interface AccountExtended extends Account {
  DOSPctComplete: number;
  PrepResourcesPctComplete: number;
  ExecServicePctComplete: number;
  PerfReviewPctComplete: number;
  Analysis: AccountAnalysis[];
  HumanResources: HumanResource[];
  Notes: any[];
  KpiSectionA: KpiSection;
  KpiSectionB: KpiSection;
  KpiSectionC: KpiSection;
  KpiSectionD: KpiSection;
  KpiSectionE: KpiSection;
  KpiSectionF: KpiSection;
  KpiSectionG: KpiSection;
  KpiSectionH: KpiSection;
  KpiSectionI: KpiSection;
  NotificationSections: APXNotificationSection[];
  NotificationsTotalCount: number;
  EditableCharts: EditableChart[];
  CustomTags: CustomTag[];
  CrewIds: string[];
  ID?: string;
}

export interface AccountFile {
  fileContentType: string;
  fileName: string;
  fileTemplateType: string;
  guiName?: string;
}

export interface AccountPlant {
  PlantId: string;
  AccountId: string;
}
