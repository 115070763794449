export function isBigInt(value): boolean {
    if (typeof value !== 'number') {
        return false;
    }

    const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER;

    if (value > MAX_SAFE_INTEGER || value < -MAX_SAFE_INTEGER) {
        return true;
    }

    return false;
}