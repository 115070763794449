import { Status } from '@apx-ui/apx-core';
import { Manager } from '@apx-ui/apx-web-api-v1';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ManagerActions } from '../actions';

export const adapter: EntityAdapter<Manager> = createEntityAdapter<Manager>({
  selectId: a => a.plantId,
  sortComparer: (a, b) => a.plantManagerName.localeCompare(b.plantManagerName),
});

export interface ManagerState extends EntityState<Manager> {
  managers: string[];
  status: Status;
}

export const initialState: ManagerState = adapter.getInitialState({
  managers: [],
  status: {
    pending: false,
    resolved: false,
    rejected: false,
    err: null,
  },
});

export const reducer = createReducer(
  initialState,
  on(ManagerActions.addManager,
    (state, { manager }) => {
      return adapter.upsertOne(manager, state);
    },
  ),
  on(ManagerActions.addManagers,
    (state, { managers }) => {
      return adapter.upsertMany(managers, state);
    },
  ),
  on(ManagerActions.loadManagers, state => ({
      ...state,
      status: {
        ...state.status,
        pending: true,
        err: null,
      },
    }),
  ),
  on(ManagerActions.loadManagersSuccess,
    (state, { managers }) => ({
        ...state,
        managers: managers.map(a => a.plantId),
        status: {
          pending: false,
          resolved: true,
          rejected: false,
          err: null,
        },
      }
    ),
  ),
  on(ManagerActions.loadManagersFailure,
    (state, { err }) => ({
      ...state,
      managers: [],
      status: {
        ...state.status,
        pending: false,
        rejected: true,
        err,
      },
    }),
  ),
);

const { selectAll, selectEntities } = adapter.getSelectors();

export const selectManagers = selectAll;
export const selectManagerEntities = selectEntities;
