<ng-container *ngIf="{
        notifications: notifications$ | async,
        count: count$ | async,
        status: status$ | async,
        accountId: accountId$ | async,
        accountUnitSystem: accountUnitSystem$ | async,
        userUnitSystem: userUnitSystem$ | async
        } as dataNotifications">
  <div fxLayout="column"
       fxFlexFill
       data-test-id="notificationsContainer">

    <div fxLayout="row"
         fxLayoutAlign="space-between start">
      <h2 fxLayout="row"
          fxLayoutAlign="start center"
          mat-dialog-title>
        <mat-icon [matBadge]="getCount(dataNotifications.count)"
                  [matBadgeHidden]="!dataNotifications.count"
                  [title]="dataNotifications.count || ''"
                  class="mr-16"
                  color="primary"
                  matBadgeColor="accent"
                  matBadgeSize="small">
          notifications_none
        </mat-icon>
        Notifications
      </h2>

      <div fxLayout="row"
           class="mr--24 mt--24">
        <a mat-dialog-close
           mat-icon-button
           color="primary"
           data-test-id="notificationsContainerCloseBtn"
           [routerLink]="[ reportUrl(dataNotifications.accountId) ]">
          <mat-icon>open_in_new</mat-icon>
        </a>
        <button mat-dialog-close
                mat-icon-button
                color="primary"
                data-test-id="notificationsContainerCloseBtn">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <mat-divider color="primary"></mat-divider>

    <mat-dialog-content>
      <apx-ui-shared-state-container height="300px"
                             [resolved]="resolved"
                             [state]="dataNotifications.status">
          <ng-template #resolved>
            <apx-ui-notifications-list-view [account_id]="dataNotifications.accountId ?? ''"
                                            [notifications]="dataNotifications.notifications"
                                            [unitSystemId]="dataNotifications.accountId
                                                            ? dataNotifications.accountUnitSystem.unitSystemId
                                                            : dataNotifications.userUnitSystem[0].unitSystemId"
                                            (closedDialog)="onCloseDialog()">
            </apx-ui-notifications-list-view>
          </ng-template>
        </apx-ui-shared-state-container>
      </mat-dialog-content>
  </div>
</ng-container>
