import { ACCOUNT_TYPES } from '@apx-ui/apx-web-api-v1';

import { CreateAccountFormData } from '../../interfaces';

export const ACCOUNT_FORM_DATA_FIXTURE: CreateAccountFormData = {
  customerName: 'VENOCO INC-EBUS',
  soldTo: 345860,
  plantId: '7120',
  crewId: 123,
  accountType: ACCOUNT_TYPES.UPSTREAM,
  unitSystems: {
    unitSystemId: '00000000-0000-0000-0000-000000000002',
    name: '',
    isDefault: false,
  },
};
