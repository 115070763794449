import { Status } from '@apx-ui/apx-core';
import {
  OffCycleContinuousOrder,
  OffCycleContinuousOrderValidationInfo,
} from '@apx-ui/apx-web-api-v1';
import { createReducer, on } from '@ngrx/store';

import {
  CreateOffCycleContinuousOrderStep,
  OffCycleContinuousOrderFormData,
} from '../../interfaces';
import { OffCycleContinuousOrdersActions } from '../actions';

export interface CreateOffCycleContinuousOrdersState {
  step: CreateOffCycleContinuousOrderStep;
  validationInfo: OffCycleContinuousOrderValidationInfo | null;
  message: string;
  form_data: OffCycleContinuousOrderFormData;
  status: {
    load_validation: Status;
  };
  result: OffCycleContinuousOrder | null;
}

const initialState: CreateOffCycleContinuousOrdersState = {
  step: CreateOffCycleContinuousOrderStep.Create,
  validationInfo: null,
  message: '',
  form_data: {
    locationId: '',
    tankId: '',
    injectionPoint: '',
    deliveryVolume: 0,
    deliveryVolumeUnit: '',
    deliveryDateTime: '',
    comment: '',
  },
  status: {
    load_validation: {
      resolved: false,
      rejected: false,
      pending: false,
      err: null,
    },
  },
  result: null,
};

export const reducer = createReducer(
  initialState,
  on(OffCycleContinuousOrdersActions.setStep,
    (state, { step }) => ({
    ...state,
    step,
  })),
  on(OffCycleContinuousOrdersActions.saveCreateForm,
    (state, { order }) => ({
      ...state,
      step: CreateOffCycleContinuousOrderStep.Confirm,
      form_data: { ...state.form_data, ...order },
    }),
  ),
  on(OffCycleContinuousOrdersActions.saveConfirm,
    (state, { order }) => ({
      ...state,
      step: CreateOffCycleContinuousOrderStep.Pending,
      form_data: { ...state.form_data, ...order },
    }),
  ),
  on(OffCycleContinuousOrdersActions.success,
    state => ({
      ...state,
      step: CreateOffCycleContinuousOrderStep.Success,
    }),
  ),
  on(OffCycleContinuousOrdersActions.failure,
    (state, { message }) => ({
      ...state,
      step: CreateOffCycleContinuousOrderStep.Failure,
      message,
    }),
  ),
  on(OffCycleContinuousOrdersActions.validateOrder,
    (state) => ({
      ...state,
      status: {
        ...state.status,
        load_validation: { ...state.status.load_validation, pending: true },
      },
    }),
  ),
  on(OffCycleContinuousOrdersActions.validateOrderSuccess,
    (state, { validationInfo }) => ({
      ...state,
      validationInfo,
      status: {
        ...state.status,
        load_validation: { ...state.status.load_validation, pending: false, resolved: true, rejected: false, err: null },
      },
    }),
  ),
  on(OffCycleContinuousOrdersActions.validateOrderFailure,
    (state, { err }) => ({
      ...state,
      status: {
        ...state.status,
        load_validation: { ...state.status.load_validation, pending: false, resolved: false, rejected: true, err },
      },
    }),
  ),
  on(OffCycleContinuousOrdersActions.success,
    (state, { orderData }) => ({
      ...state,
      result: orderData,
    }),
  ),

  on(OffCycleContinuousOrdersActions.destroy,
    () => ({ ...initialState }),
  ),
  on(OffCycleContinuousOrdersActions.cleanStatus,
    (state) => ({
      ...state,
      status: initialState.status,
    }),
  ),
);
