import { HttpErrorResponse } from '@angular/common/http';
import { Customer } from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

export const loadCustomers = createAction(
  '[APX Account] Load Customers',
);

export const loadCustomersSuccess = createAction(
  '[APX Account] Load Customers Success',
  props<{
    customers: Customer[];
  }>(),
);

export const loadCustomersFailure = createAction(
  '[APX Account] Load Customers Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const clearState = createAction(
  '[APX Account] Clear Customers State',
);

export const addCustomer = createAction(
  '[APX Account] Add Customer',
  props<{
    customer: Customer;
  }>(),
);

export const addCustomers = createAction(
  '[APX Account] Add Customers',
  props<{
    customers: Customer[];
  }>(),
);
