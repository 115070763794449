import { Injectable } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import { Manager } from '@apx-ui/apx-web-api-v1';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ManagerActions } from '../actions';
import { selectLoadedManagers, selectLoadManagersStatus } from '../selectors';

@Injectable()
export class ManagerStateService {

  constructor(
    private store: Store<never>,
  ) {
  }

  loadManager(): void {
    this.store.dispatch(ManagerActions.loadManagers());
  }

  getManagers$(): Observable<Manager[]> {
    return this.store.pipe(select(selectLoadedManagers));
  }

  getStatus$(): Observable<Status> {
    return this.store.pipe(select(selectLoadManagersStatus));
  }

}
