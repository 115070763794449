<ng-container>
  <h1 *ngIf="option?.name"
       data-test-id="title"
       class="mb-24">
    {{option.name}}
  </h1>

  <div fxLayout="row wrap"
       fxLayoutAlign="start stretch"
       fxLayoutGap.gt-sm="5%"
       class="pb-32">
    <a *ngFor="let card of option?.items"
       data-test-id="linkItem"
       fxFlex="100"
       fxFlex.gt-sm="45"
       fxFlex.gt-md="28"
       fxFlex.gt-lg="20"
       fxLayoutAlign="stretch"
       matRipple
       class="mb-36 mat-elevation-z2"
       [routerLink]="card.link">
      <mat-card class="mat-elevation-z0 p-32 w-100">
        <mat-card-title fxLayout="row"
                        fxLayoutAlign="start center">
          <mat-icon fxFlex="32px"
                    color="primary"
                    class="md-32 mr-20"
                    svgIcon="halNounOilIcon"></mat-icon>
          <div>{{card.name}}</div>
        </mat-card-title>
      </mat-card>
    </a>
  </div>
</ng-container>

