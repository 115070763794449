import { EntityMetadataMap, EntityPluralNames } from '@ngrx/data';

export const entityMetadataMap: EntityMetadataMap = {
  ContinuousOrder: {
    selectId: o => o.Id,
  },
  BatchOrder: {
    selectId: o => o.Id,
  },
  StimulationOrder: {
    selectId: o => o.Id,
    sortComparer: (a, b) => (a.LocationName ?? '').localeCompare(b.LocationName ?? ''),
  },
  ProductsForUser: {
    selectId: c => c.Id,
  },
  ContinuousTreatmentForUser: {
    selectId: c => c.TreatmentId,
  },
  TanksForUser: {
    selectId: c => c.Id,
  },
  DeliveryOrder: {
    selectId: c => c.Id,
  },
};

export const pluralNames: EntityPluralNames = {
  ContinuousOrder: 'Order/GetContinuousOrdersForUser/',
  BatchOrder: 'Order/GetBatchOrdersForUser/',
  StimulationOrder: 'Order/GetStimulationOrdersForUser/',
  ProductsForUser: 'Products/GetAccountProducts/ForUser',
  ContinuousTreatmentForUser: 'location/GetAllContinuousTreatment/ForUser',
  TanksForUser: 'Tank/GetAccountTanks/ForUser',
  DeliveryOrder: 'delivery/GetDeliveryOrders',
};
