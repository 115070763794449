import { InjectionPointComment } from './';

export const TIMEZONE_CST = 'CST6CDT';

export interface InjectionPointCommentPayload {
  locationId: string;
  comment: InjectionPointComment;
  injectionPoint: string;
  tankId: string;
}

export interface CaptureInventoryFormData {
  ActivityDate: string;
  ActivityTime: string;
  FieldActivityDate: string;
  TankLevel: number;
  treatments: CaptureInventoryFormDataTreatment[];
  TimeZone: string;
  TankInventoryHistoryID: string;
  IsUpdate: boolean;
}

export interface CaptureInventoryFormDataTreatment {
  locationId: string;
  injectionPoint: string;
  comment: string;
  observedRate: number;
}
