import { Injectable } from '@angular/core';
import { RouterActions } from '@apx-ui/apx-core';
import { NotificationActions } from '@apx-ui/apx-shared-ui';
import { ApxSolsticeWebClientService } from '@apx-ui/apx-web-api-v1';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { SuspendStep } from '../../interfaces';
import { AssetActions, SuspendActions } from '../actions';

@Injectable()
export class SuspendEffects {

  handleSuspendContinuousTreatment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SuspendActions.suspendContinuousTreatment,
      ),
      switchMap(({
        accountId,
        locationId,
        treatment,
        reasonForm,
        startAt,
        endAt,
        leaveOrdersAsPending,
        unitSystemId,
      }) => {

        const data = {
          treatmentType: 'continuous',
          treatmentId: +treatment.Id,
          locationId,
          reasonId: reasonForm.reason,
          customPauseReason: reasonForm.comment,
          startDate: startAt,
          endDate: endAt,
          LeaveOrdersAsPending: leaveOrdersAsPending,
        };

        return this.client.setSuspendTreatment(data, accountId, unitSystemId, {}).pipe(
          switchMap(asset => {

            return [
              AssetActions.addAsset({ asset }),
              SuspendActions.close({ close: true }),
              SuspendActions.suspendContinuousTreatmentSuccess({
                accountId, treatment, reasonForm, startAt, endAt,
              }),
              NotificationActions.success({
                message: 'Successfully set to suspend.',
              }),
            ];
          }),
          catchError(err => of(
            SuspendActions.close({ close: true }),
            SuspendActions.suspendContinuousTreatmentFailure({
              accountId, treatment, reasonForm, startAt, endAt, err,
            }),
            NotificationActions.error({
              message: 'Cannot set to suspend.',
            }),
          )),
        );
      }),
    ),
  );

  handleSuspendBatchTreatment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SuspendActions.suspendBatchTreatment,
      ),
      switchMap(({
        accountId,
        locationId,
        treatment,
        reasonForm,
        startAt,
        endAt,
        leaveOrdersAsPending,
        unitSystemId,
      }) => {

        const data = {
          treatmentType: 'batch',
          treatmentId: treatment.Id,
          locationId,
          reasonId: reasonForm.reason,
          customPauseReason: reasonForm.comment,
          startDate: startAt,
          endDate: endAt,
          leaveOrdersAsPending: leaveOrdersAsPending,
          unitSystemId,
        };

        return this.client.setSuspendTreatment(data, accountId, unitSystemId, {}).pipe(
          switchMap(asset => {

            return [
              AssetActions.addAsset({ asset }),
              SuspendActions.close({ close: true }),
              SuspendActions.suspendBatchTreatmentSuccess({
                accountId, treatment, reasonForm, startAt, endAt,
              }),
              NotificationActions.success({
                message: 'Successfully set to suspend.',
              }),
            ];
          }),
          catchError(err => of(
            SuspendActions.close({ close: true }),
            SuspendActions.suspendBatchTreatmentFailure({
              accountId, treatment, reasonForm, startAt, endAt, err,
            }),
            NotificationActions.error({
              message: 'Cannot set to suspend.',
            }),
          )),
        );
      }),
    ),
  );

  handleSuspendAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SuspendActions.suspendAsset,
      ),
      switchMap(({ accountId, locationId, reasonForm, startAt, endAt, leaveOrdersAsPending }) => {

        const data = {
          locationId,
          reasonId: reasonForm.reason,
          customPauseReason: reasonForm.comment,
          startDate: startAt,
          endDate: endAt,
          LeaveOrdersAsPending: leaveOrdersAsPending,
        };

        return this.client.setSuspendAsset(data, accountId, {}).pipe(
          switchMap(asset => {

            return [
              AssetActions.addAsset({ asset }),
              SuspendActions.close({ close: true }),
              SuspendActions.suspendAssetSuccess({
                accountId, locationId, reasonForm, startAt, endAt,
              }),
              // NotificationActions.success({
              //   message: 'Successfully set to suspend.',
              // }),
              // RouterActions.navigate({
              //   path: [ '/', accountId, 'manage', 'assets', locationId],
              // }),
            ];
          }),
          catchError(err => of(
            SuspendActions.close({ close: true }),
            SuspendActions.suspendAssetFailure({
              accountId, locationId, reasonForm, startAt, endAt, err,
            }),
            NotificationActions.error({
              message: 'Cannot set to suspend.',
            }),
          )),
        );
      }),
    ),
  );

  handleSuspendAssetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SuspendActions.suspendAssetSuccess,
      ),
      switchMap(({ accountId, locationId }) => {

        return [
          NotificationActions.success({
            message: 'Successfully set to suspend.',
          }),
          RouterActions.navigate({
            path: [ '/', accountId, 'manage', 'assets', locationId],
            query: { edit: Date.now() },
          }),
        ];
      }),
    ),
  );

  handleSuspendSample$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SuspendActions.suspendSample,
      ),
      switchMap(({ accountId, locationId, sample, reasonForm, startAt, endAt }) => {

        const data = {
          locationId,
          AnalysisType: sample.AnalysisType,
          SampleMethod: sample.SampleMethod,
          SamplePoint: sample.SamplePoint,
          reasonId: reasonForm.reason,
          customPauseReason: reasonForm.comment,
          pauseStartDate: startAt,
          pauseEndDate: endAt,
        };

        return this.client.setSuspendSample(data, accountId, {}).pipe(
          switchMap(asset => {

            return [
              AssetActions.addAsset({ asset }),
              SuspendActions.close({ close: true }),
              SuspendActions.suspendSampleSuccess({
                accountId, locationId, sample, reasonForm, startAt, endAt,
              }),
              NotificationActions.success({
                message: 'Successfully set to suspend.',
              }),
            ];
          }),
          catchError(err => of(
            SuspendActions.close({ close: true }),
            SuspendActions.suspendSampleFailure({
              accountId, locationId, sample, reasonForm, startAt, endAt, err,
            }),
            NotificationActions.error({
              message: 'Cannot set to suspend.',
            }),
          )),
        );
      }),
    ),
  );

  handlecheckAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SuspendActions.checkAsset,
      ),
      switchMap(({ accountId, locationId, reasonForm, startAt, endAt }) => {

        const data = {
          locationId,
          reasonId: reasonForm.reason,
          customPauseReason: reasonForm.comment,
          startDate: startAt,
          endDate: endAt,
        };

        return this.client.checkAsset(data, accountId, {}).pipe(
          switchMap(({ ShowAlert }) => {

            if (ShowAlert) {
              return [
                SuspendActions.setStep({
                  step: SuspendStep.Confirm,
                }),
              ];
            }

            return [
              SuspendActions.suspendAsset({
                accountId, locationId, reasonForm, startAt, endAt, leaveOrdersAsPending: null,
              }),
            ];
          }),
          catchError(() => of(
            SuspendActions.close({ close: true }),
            NotificationActions.error({
              message: 'Cannot check to suspend.',
            }),
          )),
        );
      }),
    ),
  );

  handleCheckBatchTreatment$ = createEffect(() =>
  this.actions$.pipe(
    ofType(
      SuspendActions.checkBatchTreatment,
    ),
    switchMap(({ accountId, locationId, treatment, reasonForm, startAt, endAt, unitSystemId }) => {

      const data = {
        treatmentType: 'batch',
        treatmentId: treatment.Id,
        locationId,
        reasonId: reasonForm.reason,
        customPauseReason: reasonForm.comment,
        startDate: startAt,
        endDate: endAt,
        unitSystemId,
      };

      return this.client.checkTreatment(data, accountId, unitSystemId, {}).pipe(
        switchMap(({ ShowAlert }) => {

          if (ShowAlert) {
            return [
              SuspendActions.setStep({
                step: SuspendStep.Confirm,
              }),
            ];
          }

          return [
            SuspendActions.suspendBatchTreatment({
              accountId, locationId, treatment, reasonForm, startAt, endAt, leaveOrdersAsPending: null, unitSystemId,
            }),
          ];
        }),
        catchError(() => of(
          SuspendActions.close({ close: true }),
          NotificationActions.error({
            message: 'Cannot check to suspend.',
          }),
        )),
      );
    }),
  ),
);

handlecheckContinuousTreatment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SuspendActions.checkContinuousTreatment,
      ),
      switchMap(({ accountId, locationId, treatment, reasonForm, startAt, endAt, unitSystemId }) => {

        const data = {
          treatmentType: 'continuous',
          treatmentId: +treatment.Id,
          locationId,
          reasonId: reasonForm.reason,
          customPauseReason: reasonForm.comment,
          startDate: startAt,
          endDate: endAt,
        };

        return this.client.checkTreatment(data, accountId, unitSystemId, {}).pipe(
          switchMap(({ ShowAlert }) => {

            if (ShowAlert) {
              return [
                SuspendActions.setStep({
                  step: SuspendStep.Confirm,
                }),
              ];
            }

            return [
              SuspendActions.suspendContinuousTreatment({
                accountId, locationId, treatment, reasonForm, startAt, endAt, leaveOrdersAsPending: null, unitSystemId,
              }),
            ];
          }),
          catchError(() => of(
            SuspendActions.close({ close: true }),
            NotificationActions.error({
              message: 'Cannot check to suspend.',
            }),
          )),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private client: ApxSolsticeWebClientService,
  ) {
  }

}
