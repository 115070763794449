import { Injectable } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import { Notifications } from '@apx-ui/apx-web-api-v1';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { NotificationAction } from '../stores/actions';
import * as fromNotificationSelectors from '../stores/selectors';

@Injectable()
export class NotificationStateService {

  constructor(private readonly store: Store<any>) {
  }

  loadNotificationCount(accountId = ''): void {
    this.store.dispatch(NotificationAction.loadNotificationsCount({ accountId }));
  }

  loadNotification(accountId = ''): void {
    this.store.dispatch(NotificationAction.loadNotifications({ accountId }));
  }

  markNotificationAsRead(ids: { id: string }[], accountId): void {
    this.store.dispatch(NotificationAction.markNotifications({ ids, accountId }));
  }

  getNotificationCount$(): Observable<number> {
    return this.store.pipe(select(fromNotificationSelectors.selectNotificationsCount));
  }

  getNotifications$(): Observable<Notifications> {
    return this.store.pipe(select(fromNotificationSelectors.selectNotifications));
  }

  getNotificationCountStatus$(): Observable<Status> {
    return this.store.pipe(select(fromNotificationSelectors.selectNotificationsCountStatus));
  }

  getNotificationStatus$(): Observable<Status> {
    return this.store.pipe(select(fromNotificationSelectors.selectNotificationsStatus));
  }

  exportBatchOrderNotifications(checkedDate, accountId, unitSystemId: string): void  {
    this.store.dispatch(NotificationAction.exportBatchOrderNotifications({ checkedDate, accountId, unitSystemId }));
  }

  exportContinuousOrderNotifications(checkedDate, accountId, unitSystemId: string): void  {
    this.store.dispatch(NotificationAction.exportContinuousOrderNotifications({ checkedDate, accountId, unitSystemId }));
  }

  exportSamplesNotifications(subCategory, checkedDate, accountId): void  {
    this.store.dispatch(NotificationAction.exportSamplesNotifications({ subCategory, checkedDate, accountId }));
  }
}
