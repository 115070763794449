import { createSelector } from '@ngrx/store';

import { selectCreateSampleState } from '../reducers';

export const selectCreateSampleStep = createSelector(
  selectCreateSampleState,
  state => state.step,
);

export const selectCreateSampleFormData = createSelector(
  selectCreateSampleState,
  (state) => state.form_data,
);

export const selectEditedAsset = createSelector(
  selectCreateSampleState,
  (state) => state.asset,
);

export const selectOperationType = createSelector(
  selectCreateSampleState,
  (state) => state.operationType,
);

export const selectCreateSampleResult = createSelector(
  selectCreateSampleState,
  state => state.result,
);

export const selectCreateSampleMessage = createSelector(
  selectCreateSampleState,
  state => state.message,
);
