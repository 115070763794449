import { Injectable } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import { Plant } from '@apx-ui/apx-web-api-v1';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { PlantActions } from '../actions';
import { selectLoadedPlants, selectPlantStatus } from '../selectors';

@Injectable()
export class PlantStateService {

  constructor(private store: Store<never>) {
  }

  loadPlant(): void {
    this.store.dispatch(PlantActions.loadPlants());
  }

  clearStatus(): void {
    this.store.dispatch(PlantActions.clearState());
  }

  getPlants$(): Observable<Plant[]> {
    return this.store.pipe(select(selectLoadedPlants));
  }

  getStatus$(): Observable<Status> {
    return this.store.pipe(select(selectPlantStatus));
  }

}
