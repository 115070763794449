import { Injectable } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import { Reason } from '@apx-ui/apx-web-api-v1';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

import { BatchOrder, ContinuousOrder, StimulationOrder } from '../../interfaces';
import { BatchOrderModel, ContinuousOrderModel, StimulationOrderModel } from '../../models';
import { DeliveryListActions } from '../actions';
import * as fromDeliveryListSelectors from '../selectors';

@Injectable()
export class DeliveryListState {

  constructor(private readonly store: Store<any>) {
  }

  addOrder(orderId: string): void {
    this.store.dispatch(DeliveryListActions.addOneToSelected({ orderId }));
  }

  loadTruck(orders: (ContinuousOrder | BatchOrder | StimulationOrder)[],
            products: {
              product: string;
              productName: string;
              total: number;
              amount: number;
            }[],
            equipment: string[],
            deliveryType: string,
            reason: Reason | null,
            email: string | null,
            deliveryMethod = 'Internal',
  ): void {
    this.store.dispatch(DeliveryListActions.loadTruck({
      orders, products, equipment, deliveryType, reason, email, deliveryMethod }));
  }

  addToDeliveryList(orders: (ContinuousOrder | BatchOrder | StimulationOrder)[],
                    products: {
                      product: string;
                      productName: string;
                      total: number;
                      amount: number;
                    }[],
                    equipment: string[],
                    deliveryType: string,
                    reason: Reason | null,
                    email: string | null,
                    deliveryMethod = 'Internal',
  ): void {
    this.store
      .dispatch(DeliveryListActions.addToDeliveryList(
        { orders, products, equipment, deliveryType, reason, email, deliveryMethod },
      ));
  }

  getAddToDeliveryListStatus$(): Observable<Status> {
    return this.store.pipe(select(fromDeliveryListSelectors.selectAddToDeliveryStatus));
  }

  unLoadTruck(payload: any): void {
    this.store.dispatch(DeliveryListActions.unloadTruck({ payload }));
  }

  removeOrder(orderId: string): void {
    this.store.dispatch(DeliveryListActions.removeFromSelected({ orderId }));
  }

  addOrders(orderIds: string[]): void {
    this.store.dispatch(DeliveryListActions.addManyToSelected({ orderIds }));
  }

  clear(): void {
    this.store.dispatch(DeliveryListActions.clear());
  }

  clearStatus(): void {
    this.store.dispatch(DeliveryListActions.clearStatus());
  }

  getLoadTruckStatus$(): Observable<Status> {
    return this.store.pipe(select(fromDeliveryListSelectors.selectDeliveryListStatus));
  }

  getUnLoadTruckStatus$(): Observable<Status> {
    return this.store.pipe(select(fromDeliveryListSelectors.selectUnloadTruckStatus));
  }

  getSelectedOrders$(): Observable<string[]> {
    return this.store.pipe(select(fromDeliveryListSelectors.selectDeliveryList));
  }

  getSelectContinuousOrders$(): Observable<ContinuousOrderModel[]> {
    return this.store.pipe(select(fromDeliveryListSelectors.selectContinuousDeliveryList));
  }

  getSelectBatchOrders$(): Observable<BatchOrderModel[]> {
    return this.store.pipe(select(fromDeliveryListSelectors.selectBatchDeliveryList));
  }

  getSelectStimOrders$(): Observable<StimulationOrderModel[]> {
    return this.store.pipe(select(fromDeliveryListSelectors.selectStimulationDeliveryList));
  }

  isSelected$(orderId: string): Observable<boolean> {
    return this.store.pipe(
      select(fromDeliveryListSelectors.selectDeliveryList),
      map(orders => !!orders?.find(i => i === orderId)),
    );
  }

}
