import { HttpErrorResponse } from '@angular/common/http';
import { GenericFilter } from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

export const loadContinuousFilters = createAction(
  '[APX Continuous Orders Filters] Load',
);

export const loadContinuousFiltersSuccess = createAction(
  '[APX Continuous Orders Filters] Load Success',
  props<{
    filters: GenericFilter;
  }>(),
);

export const loadContinuousFiltersFailure = createAction(
  '[APX Continuous Orders Filters] Load Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const loadDeliveryFilters = createAction(
  '[APX Delivery Orders Filters] Load',
);

export const loadDeliveryFiltersSuccess = createAction(
  '[APX Delivery Orders Filters] Load Success',
  props<{
    filters: GenericFilter;
  }>(),
);

export const loadDeliveryFiltersFailure = createAction(
  '[APX Delivery Orders Filters] Load Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const loadBatchFilters = createAction(
  '[APX Batch Orders Filters] Load',
);

export const loadBathcFiltersSuccess = createAction(
  '[APX Batch Orders Filters] Load Success',
  props<{
    filters: GenericFilter;
  }>(),
);

export const loadBatchFiltersFailure = createAction(
  '[APX Batch Orders Filters] Load Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const loadStimulationFilters = createAction(
  '[APX Stimulation Orders Filters] Load',
);

export const loadStimulationFiltersSuccess = createAction(
  '[APX Stimulation Orders Filters] Load Success',
  props<{
    filters: GenericFilter;
  }>(),
);

export const loadStimulationFiltersFailure = createAction(
  '[APX Stimulation Orders Filters] Load Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const clear = createAction(
  '[APX Orders Filters] Clear',
);
