import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

  addTime(date: Date): Date {
    const now = new Date();

    date.setHours(now.getHours());
    date.setMinutes(now.getMinutes());
    date.setSeconds(now.getSeconds());

    return date;
  }

  override createDate(year: number, month: number, date: number): Date {
    return this.addTime(super.createDate(year, month, date));
  }

  override parse(value: any): Date | null {
    const date = super.parse(value);
    return date ? this.addTime(date) : date;
  }

}
