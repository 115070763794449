export enum CreateSampleStep {
  SamplesList = 'samplesList',
  Pending = 'pending',
  Confirm = 'confirm',
  Success = 'success',
  Failure = 'failure'
}

export enum OperationType {
  CreateSample = 'create',
  EditSample = 'edit'
}
