import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'apx-ui-shared-field-error-message',
  templateUrl: './field-error-message.component.html',
  styleUrls: ['./field-error-message.component.scss'],
})
export class FieldErrorMessageComponent {
  @Input() control?: AbstractControl;
  @Input() error?: string;
  @Input() displayFirstError = false;

  showFirst(): boolean {
    const firstError = Object.keys(this.control.errors || [])?.[0];

    return !this.displayFirstError || firstError === this.error;
  }

}
