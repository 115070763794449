import { createSelector } from '@ngrx/store';

import * as fromReducer from '../reducers';

export const selectContinuousFilters = createSelector(
  fromReducer.selectedFiltersState,
  state => state.continuousFilter,
);

export const selectContinuousFiltersStatus = createSelector(
  fromReducer.selectedFiltersState,
  state => state.status.continuous,
);

export const selectDeliveryFilters = createSelector(
  fromReducer.selectedFiltersState,
  state => state.deliveryFilter,
);

export const selectDeliveryFiltersStatus = createSelector(
  fromReducer.selectedFiltersState,
  state => state.status.delivery,
);

export const selectBatchFilters = createSelector(
  fromReducer.selectedFiltersState,
  state => state.batchFilter,
);

export const selectBatchFiltersStatus = createSelector(
  fromReducer.selectedFiltersState,
  state => state.status.batch,
);

export const selectStimulationFilters = createSelector(
  fromReducer.selectedFiltersState,
  state => state.stimulationFilter,
);

export const selectStimulationFiltersStatus = createSelector(
  fromReducer.selectedFiltersState,
  state => state.status.stimulation,
);
