import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterStateService } from '@apx-ui/apx-core';
import moment from 'moment/moment';

interface dialogData {
  name: string;
  count: number | string;
  lastName: string;
  fullName: string;
}

@Component({
  selector: 'apx-ui-modal-info-assignments',
  templateUrl: './modal-info-assignments.component.html',
  styleUrls: ['./modal-info-assignments.component.scss'],
})
export class ModalInfoAssignmentsComponent {
  constructor(private readonly dialogRef: MatDialogRef<ModalInfoAssignmentsComponent>,
              @Inject(MAT_DIALOG_DATA) public readonly dialogData: dialogData,
              private readonly routerState: RouterStateService) {
  }

  goToAssignments(): void {
    if (this.dialogData.count) {
      const today = moment().format();
      const query = {
        deliveryPlanningValues: {
          assignedTo: [ this.dialogData.fullName ],
          assignmentStatus: ['Pending'],
          assignmentDateFrom: today,
          assignmentDateTo: today,
        },
      };

      this.routerState.navigate(['/field-tasks/assignments/assignments'], undefined, {
        queryParamsHandling: 'merge',
        queryParams: {
          filter: JSON.stringify(query),
        },
      });
    } else {
      this.routerState.navigate(['/field-tasks/assignments/assignments']);
    }
  }

}
