import { Package } from '@apx-ui/apx-web-api-v1';

import { convertUoM, IProduct, StimulationOrder } from '../interfaces';
import { AbstractOrderModel } from './abstract-order.model';

export class StimulationOrderModel extends AbstractOrderModel<StimulationOrder> {

  constructor(protected override data: StimulationOrder, protected override packages: Package[]) {
    super(data, packages);
  }

  override getId(): string {
    return this.data.Id ?? (this.data as any).OrderId;
  }

  isOnSite(): boolean {
    return this.data.JobType === 'On Site';
  }

  getCompanyDescription(): string {
    const items = [
      this.data.AssetShipToId, this.data.CustomerName, this.data.Field,
    ].filter(i => !!i);

    return items.join(' ᛫ ');
  }

  getJobType(): string {
    return this.data.JobType;
  }

  getInjectionPoint(): string {
    return this.data.InjectionPoint;
  }

  getFrequency(): number {
    return this.data.Frequency;
  }

  getEmptyDate(): Date {
    return this.data.EmptyDate;
  }

  override calcTargetVolumeBase(product: IProduct): number {
    return this.data.TargetVolume * convertUoM(this.data.VolumeUoM, product.UofM);
  }

}
