<div fxLayout="row"
     fxLayoutAlign="center center">

  <ng-container *ngIf="!isRowValid() || error()">
  <span fxLayout="row"
        fxLayoutAlign="center center"
        [matTooltip]="error() ? error() : 'Validation is failed'">
    <mat-icon color="warn">info_outline</mat-icon>
  </span>
  </ng-container>

  <ng-container [ngSwitch]="rowState()">

    <ng-container *ngSwitchCase="'new'">
      <button mat-icon-button
              aria-label="Save"
              matTooltip="Save"
              class="pl-2"
              [class.action-cell-button]="isRowValid()"
              [disabled]="!isRowValid()"
              (click)="btnUpdateHandler()">
        <mat-icon [class.action-cell-button]="isRowValid()">
          save
        </mat-icon>
      </button>

      <button mat-icon-button
              aria-label="Delete"
              matTooltip="Cancel"
              class="action-cell-button"
              (click)="btnCancelHandler()">
        <mat-icon>cancel</mat-icon>
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="'edit'">
      <button mat-icon-button
              aria-label="Save"
              matTooltip="Save"
              [class.action-cell-button]="isRowValid()"
              [disabled]="!isRowValid()"
              (click)="btnUpdateHandler()">
        <mat-icon [class.action-cell-button]="isRowValid()">
          save
        </mat-icon>
      </button>

      <button mat-icon-button
              aria-label="Cancel"
              matTooltip="Cancel"
              class="action-cell-button"
              (click)="btnCancelHandler()">
        <mat-icon class="action-cell-button">cancel</mat-icon>
      </button>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <button mat-icon-button
              aria-label="Delete"
              matTooltip="Delete"
              [class.action-cell-button]="canDelete()"
              [disabled]="!canDelete()"
              (click)="btnDeleteHandler()">
        <mat-icon [class.action-cell-button]="canDelete()">
          delete
        </mat-icon>
      </button>
    </ng-container>

  </ng-container>

</div>
