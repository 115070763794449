import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromNotificationReducer from './notification.reducer';

export const featureName = 'apx-feature-notifications';

export interface State {
  notifications: fromNotificationReducer.NotificationState;
}

export function reducers(state: State | undefined, action: Action): State {
  return combineReducers({
    notifications: fromNotificationReducer.reducer,
  })(state, action);
}

export const selectAPXNotificationFeature = createFeatureSelector<State>(featureName);

export const selectNotificationState = createSelector(selectAPXNotificationFeature, state => state.notifications);
