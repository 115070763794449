import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultPluralizer, EntityDefinitionService, Pluralizer } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { services } from './services';
import { entityMetadataMap, pluralNames } from './stores';
import { effects } from './stores/effects';
import { featureName, reducers } from './stores/reducers';

@NgModule({
  imports: [
    CommonModule,

    StoreModule.forFeature(featureName, reducers),

    EffectsModule.forFeature(effects),
  ],
  providers: [
    ...services,
  ],
})
export class ApxNotificationsStoreModule {
  constructor(
    pluralizer: Pluralizer,
    entityDefinitionService: EntityDefinitionService,
  ) {

    entityDefinitionService.registerMetadataMap(entityMetadataMap);
    (pluralizer as DefaultPluralizer).registerPluralNames(pluralNames);

  }
}
