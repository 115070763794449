<div *ngIf="end; else infinite"
     class="mat-subheading-2 mb-0">
  Suspended {{start | date: 'MM/dd/yyyy'}} - {{end | date: 'MM/dd/yyyy'}}
</div>

<ng-template #infinite>
  <div class="mat-subheading-2 mb-0">
    Suspended {{start | date: 'MM/dd/yyyy'}} - indefinitely
  </div>
</ng-template>
