import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { services } from './services';

@NgModule({
  imports: [
    CommonModule,
  ],
})
export class ApxAuthModule {
  static forRoot(): ModuleWithProviders<ApxAuthModule> {
    return {
      ngModule: ApxAuthModule,
      providers: [
        ...services,
      ],
    };
  }
}
