import { AssetModel, Sample } from '@apx-ui/apx-web-api-v1';
import { createReducer, on } from '@ngrx/store';

import { CreateSampleFormData, CreateSampleStep, OperationType } from '../../interfaces';
import { CreateSampleActions } from '../actions';

export interface CreateSampleState {
  step: CreateSampleStep;
  message: string;
  form_data: CreateSampleFormData | null;
  asset: AssetModel | null;
  result: Sample[] | null;
  operationType: OperationType;
}

const initialState: CreateSampleState = {
  step: CreateSampleStep.SamplesList,
  message: '',
  form_data: null,
  asset: null,
  result: null,
  operationType: OperationType.CreateSample,
};

export const reducer = createReducer(
  initialState,
  on(CreateSampleActions.setStep,
    (state, { step }) => ({
      ...state,
      step,
    })),
  on(CreateSampleActions.saveCreateForm,
    (state, { formData }) => ({
      ...state,
      step: CreateSampleStep.Confirm,
      form_data: { ...state.form_data, ...formData },
    }),
  ),
  on(CreateSampleActions.initForm,
    (state, { asset, operationType, formData }) => ({
      ...state,
      step: CreateSampleStep.SamplesList,
      form_data: { ...state.form_data, ...formData },
      asset: asset,
      operationType: operationType || OperationType.CreateSample,
    }),
  ),
  on(CreateSampleActions.saveConfirm,
    (state) => ({
      ...state,
      step: CreateSampleStep.Pending,
    }),
  ),
  on(CreateSampleActions.updateConfirm,
    (state, { formData }) => ({
      ...state,
      step: CreateSampleStep.Pending,
      form_data: { ...state.form_data, ...formData },
    }),
  ),
  on(CreateSampleActions.failure,
    state => ({
      ...state,
      step: CreateSampleStep.Failure,
    }),
  ),
  on(CreateSampleActions.success,
    (state, { samples }) => ({
      ...state,
      result: samples,
      step: CreateSampleStep.Success,
    }),
  ),
  on(CreateSampleActions.failure,
    (state, { message }) => ({
      ...state,
      message,
    }),
  ),
  on(CreateSampleActions.destroy,
    () => ({ ...initialState }),
  ),
);
