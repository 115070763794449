import { ModuleWithProviders, NgModule } from '@angular/core';

import { ConfigService } from '../services';
import { ENVIRONMENT } from '../tokens';

@NgModule()
export class ConfigTestingModule {

  static withSettings(environment: any = { }): ModuleWithProviders<ConfigTestingModule> {

    return {
      ngModule: ConfigTestingModule,
      providers: [
        { provide: ENVIRONMENT, useValue: environment },
        { provide: ConfigService, useClass: ConfigService },
      ],
    };

  }

  static withDefaultSettings(): ModuleWithProviders<ConfigTestingModule> {

    return {
      ngModule: ConfigTestingModule,
      providers: [
        { provide: ENVIRONMENT, useValue: { } },
        { provide: ConfigService, useClass: ConfigService },
      ],
    };

  }

}
