import { Account } from '@apx-ui/apx-web-api-v1';
import { createReducer, on } from '@ngrx/store';

import { CreateAccountFormData, CreateAccountStep, OperationType } from '../../interfaces';
import { CreateAccountActions } from '../actions';

export interface CreateAccountState {
  step: CreateAccountStep;
  message: string;
  form_data: CreateAccountFormData | null;
  account: Account | null;
  result: Account | null;
  operationType: OperationType;
}

const initialState: CreateAccountState = {
  step: CreateAccountStep.Create,
  message: '',
  form_data: null,
  account: null,
  result: null,
  operationType: OperationType.CreateAccount,
};

export const reducer = createReducer(
  initialState,
  on(CreateAccountActions.setStep,
    (state, { step }) => ({
      ...state,
      step,
    })),
  on(CreateAccountActions.saveCreateForm,
    (state, { account }) => ({
      ...state,
      step: CreateAccountStep.Confirm,
      form_data: { ...state.form_data, ...account },
    }),
  ),
  on(CreateAccountActions.initEditForm,
    (state, { formData, account }) => ({
      ...state,
      step: CreateAccountStep.Edit,
      form_data: { ...state.form_data, ...formData },
      account: account,
      operationType: OperationType.EditAccount,
    }),
  ),
  on(CreateAccountActions.saveConfirm,
    (state, { account }) => ({
      ...state,
      step: CreateAccountStep.Pending,
      form_data: { ...state.form_data, ...account },
    }),
  ),
  on(CreateAccountActions.updateConfirm,
    (state, { formData }) => ({
      ...state,
      step: CreateAccountStep.Pending,
      form_data: { ...state.form_data, ...formData },
    }),
  ),
  on(CreateAccountActions.failure,
    state => ({
      ...state,
      step: CreateAccountStep.Failure,
    }),
  ),
  on(CreateAccountActions.success,
    (state, { accountData }) => ({
      ...state,
      result: accountData,
      step: CreateAccountStep.Success,
    }),
  ),
  on(CreateAccountActions.failure,
    (state, { message }) => ({
      ...state,
      message,
    }),
  ),
  on(CreateAccountActions.destroy,
    () => ({ ...initialState }),
  ),
);
