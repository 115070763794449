import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'apx-ui-shared-custom-non-well-description',
    templateUrl: './custom-non-well-description.component.html',
    styleUrls: ['./custom-non-well-description.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomNonWellDescriptionComponent implements OnInit {
  form: UntypedFormGroup;
  constructor(
      private readonly fb: UntypedFormBuilder,
      private readonly dialogRef: MatDialogRef<CustomNonWellDescriptionComponent>,
      @Inject(MAT_DIALOG_DATA) public dialogData: string,
  ) {}

  get fieldNameCtrl(): AbstractControl | null {
      return this.form.get('FieldName');
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      FieldName: [null, [Validators.required, Validators.maxLength(100)]],
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  handleSave(): void {
    if(this.form.valid) {
      this.dialogRef.close(this.fieldNameCtrl.value);
    } else {
      this.form.markAllAsTouched();
    }
  }
}