import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UILayoutStateService } from '../../stores';

@Component({
  selector: 'apx-ui-shared-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageContainerComponent implements OnInit {
  leftSideBar$!: Observable<boolean>;
  isAsideCollapsed$!: Observable<boolean>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly state: UILayoutStateService,
  ) {
  }

  ngOnInit(): void {
    this.leftSideBar$ = this.route.data.pipe(
      map(data => !!data?.['leftSideBar']),
    );

    this.isAsideCollapsed$ = this.state.isAsideCollapsed$();
  }

  expandAside(): void {
    this.state.expandAside();
  }

  collapseAside(): void {
    this.state.collapseAside();
  }

}
