import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { OrderType } from '../interfaces';
import { OrdersForDeliveryActions } from '../store/actions';
import { OrdersForDeliveryState } from '../store/state';

@Injectable()
export class OrdersGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly ordersForDeliveryState: OrdersForDeliveryState,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    this.ordersForDeliveryState.loadActiveDelivery();

    return this.actions$.pipe(
      ofType(OrdersForDeliveryActions.loadOrdersForDeliverySuccess),
      switchMap(() =>
        combineLatest([this.ordersForDeliveryState.isDeliveryActive$(), this.ordersForDeliveryState.getDeliveryType$()]),
      ),
      map(([active, type]) => {
        if (!active) {
          return true;
        }

        const path = this.getPath(state.url);

        if (path === OrderType.stimulation.toLowerCase()) {
          return this.router.createUrlTree(['/field-tasks/orders/delivery']);
        }

        if (path?.toLowerCase()?.includes(type.toLowerCase())) {
          return true;
        }

        if (path === OrderType.continuous.toLowerCase() || path === OrderType.batch.toLowerCase()) {
          return true;
        }

        return this.router.createUrlTree([`/field-tasks/orders/${type.toLowerCase()}`]);
      }),
      catchError(() => {
        return of(
          this.router.createUrlTree(['/error'], {
            queryParams: { type: 503 },
          }),
        );
      }),
    );
  }

  private getPath(url): string {
    const urls = url.split('/');
    return urls[urls.length - 1];
  }
}
