import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { LeftSidebarActions } from '../actions';
import * as fromLayoutSelector from '../selectors/left-sidebar.selectors';

@Injectable({
  providedIn: 'root',
})
export class UILayoutStateService {

  constructor(
    private store: Store<any>,
  ) {
  }

  expandAside(): void {
    this.store.dispatch(LeftSidebarActions.leftSidebarOpened());
  }

  collapseAside(): void {
    this.store.dispatch(LeftSidebarActions.leftSidebarClosed());
  }

  isAsideExpanded$(): Observable<boolean> {
    return this.store.pipe(select(fromLayoutSelector.selectLayoutLeftSidebarIsExpanded));
  }

  isAsideCollapsed$(): Observable<boolean> {
    return this.store.pipe(select(fromLayoutSelector.selectLayoutLeftSidebarIsCollapsed));
  }

}
