import { HttpErrorResponse } from '@angular/common/http';
import { Reason } from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

import { BatchOrder, ContinuousOrder, IDeliveryData, StimulationOrder } from '../../interfaces';

export const loadTruck = createAction(
  '[APX Orders] Load Truck',
  props<{
    orders: (ContinuousOrder | BatchOrder | StimulationOrder)[];
    products: {
      product: string;
      productName: string;
      total: number;
      amount: number;
    }[];
    equipment: string[];
    deliveryType: string;
    reason: Reason | null,
    email: string;
    deliveryMethod: string;
  }>(),
);

export const loadTruckSuccess = createAction(
  '[APX Orders] Load Truck Success',
  props<{
    orders: (ContinuousOrder | BatchOrder | StimulationOrder)[];
    products: {
      product: string;
      productName: string;
      total: number;
      amount: number;
    }[];
    equipment: string[];
    deliveryType: string;
  }>(),
);

export const loadTruckFailure = createAction(
  '[APX Orders] Load Truck Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const addToDeliveryList = createAction(
  '[APX Orders] Add Orders To Truck',
  props<{
    orders: (ContinuousOrder | BatchOrder | StimulationOrder)[];
    products: {
      product: string;
      productName: string;
      total: number;
      amount: number;
    }[];
    equipment: string[];
    deliveryType: string;
    email: string;
    reason: Reason | null,
    deliveryMethod: string;
  }>(),
);

export const addToDeliveryListSuccess = createAction(
  '[APX Orders] Add Orders To Truck Success',
  props<{
    orders: (ContinuousOrder | BatchOrder | StimulationOrder)[];
    products: {
      product: string;
      productName: string;
      total: number;
      amount: number;
    }[];
    equipment: string[];
    deliveryType: string;
  }>(),
);

export const addToDeliveryListFailure = createAction(
  '[APX Orders] Add Orders To Truck Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const addOneToSelected = createAction(
  '[APX Orders] Add One Order To Selected',
  props<{
    orderId: string;
  }>(),
);

export const addManyToSelected = createAction(
  '[APX Orders] Add Many Orders To Selected',
  props<{
    orderIds: string[];
  }>(),
);

export const removeFromSelected = createAction(
  '[APX Orders] Remove Order To Selected',
  props<{
    orderId: string;
  }>(),
);

export const clear = createAction(
  '[APX Orders] Clear Selected',
);

export const clearStatus = createAction(
  '[APX Orders] Clear Status',
);

export const unloadTruck = createAction(
  '[APX Orders] UnLoad Truck',
  props<{
    payload: IDeliveryData;
  }>(),
);

export const unloadTruckSuccess = createAction(
  '[APX Orders] UnLoad Truck Success',
  props<{
    payload: IDeliveryData;
    completedCount: number;
  }>(),
);

export const unloadTruckFailure = createAction(
  '[APX Orders] UnLoad Truck Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);
