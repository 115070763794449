import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BatchOrderNotification } from '@apx-ui/apx-notifications-store';

@Component({
  selector: 'apx-ui-notifications-summary-batch-desktop',
  templateUrl: './apx-notifications-summary-batch-desktop.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApxNotificationsSummaryBatchDesktopComponent {
  @Input() notifications: BatchOrderNotification[];
  displayedColumns: string[] = [
    'isNonRoutineOrder',
    'accountName',
    'locationName',
    'productName',
    'injectionPoint',
    'estDeliveryDate',
    'estDeliveryVolume',
    'uomName',
  ];
}
