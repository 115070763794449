import { Component } from '@angular/core';

import { UIPopoverOverlayRef } from '../../services';

@Component({
  selector: 'apx-ui-shared-how-to-paste',
  templateUrl: './how-to-paste.component.html',
  styleUrls: ['./how-to-paste.component.scss'],
})
export class HowToPasteComponent {

  constructor(public uiPopoverRef: UIPopoverOverlayRef) {
  }

  close(): void {
    this.uiPopoverRef.close();
  }

}
