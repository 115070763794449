<div draggable="true"
     class="filearea"
     data-test-id="dragArea"
     [ngClass]="dragAreaClass"
     [class.with-file]="!!fileName"
     (click)="onFileAreaClick()">

  <div fxLayout="column" fxLayoutAlign="center center">
    <ng-container *ngIf="fileName; else noFile">
      <div fxLayout="column"
           fxLayoutAlign="center center"
           matTooltip="Download file"
           [matTooltipDisabled]="!downloadable"
           [class.downloadable]="downloadable"
           (click)="onDownloadClick()">
        <mat-icon color="primary" class="mb-4 i-upload">insert_drive_file</mat-icon>

        <a data-test-id="fileName">{{fileName}}</a>
      </div>
    </ng-container>

    <ng-template #noFile>
      <mat-icon class="mb-4 i-upload">upload_file</mat-icon>
      <span data-test-id="noFile">Drag file here or click to select file</span>
    </ng-template>
  </div>

</div>
