import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';

export interface CellIcon extends ICellRendererParams {
  iconName: string;
}

@Component({
  selector: 'apx-ui-shared-icon-cell',
  templateUrl: './icon-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconCellComponent implements ICellRendererAngularComp {
  iconName: string;
  cellValue: string;

  private params: CellIcon;

  agInit(params: CellIcon): void {
    this.params = params;
    this.iconName = params?.iconName;
    this.cellValue = params.valueFormatted ? params.valueFormatted : params.value;
  }

  refresh(params: CellIcon): boolean {
    this.iconName = params?.iconName;
    this.cellValue = params.valueFormatted ? params.valueFormatted : params.value;
    return true;
  }
}
