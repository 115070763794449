import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmDialogData {
  title: string;
  body: string;
  confirmBtn?: string;
  rejectBtn?: string;
  displayBtn?: boolean;
}

@Component({
  selector: 'apx-ui-shared-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent implements OnInit {

  public title = '';
  public body = '';
  public confirmBtn = 'Confirm';
  public rejectBtn = 'Cancel';
  public displayBtn = true;

  public constructor(@Inject(MAT_DIALOG_DATA) private data: ConfirmDialogData,
  ) {
  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.body = this.data.body;
    this.confirmBtn = this.data.confirmBtn ?? this.confirmBtn;
    this.rejectBtn = this.data.rejectBtn ?? this.rejectBtn;
    this.displayBtn = this.data.displayBtn ?? this.displayBtn;
  }

}
