import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { components } from './components';
import { SingleSelectModule } from './components/single-select/single-select.module';
import { directives } from './directives';
import { CustomDateAdapter, services } from './services';
import { reducers } from './stores';
import { effects } from './stores/effects';

const material = [
  FlexLayoutModule,
  ClipboardModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatToolbarModule,
  MatTooltipModule,
  MatIconModule,
  MatBadgeModule,
  MatButtonModule,
  MatInputModule,
  MatCardModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatDividerModule,
  MatDialogModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatListModule,
  MatTableModule,
  MatTabsModule,
  MatNativeDateModule,
  MatDatepickerModule,
  DragDropModule,
  MatCheckboxModule,
  ScrollingModule,
  MatSortModule,
  MatMenuModule,
  MatChipsModule,
  MatAutocompleteModule,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    A11yModule,

    LoadingBarHttpClientModule,
    StoreModule.forFeature('ui', reducers),
    EffectsModule.forFeature(effects),

    ...material,
  ],
  declarations: [
    ...components,
    ...directives,
  ],
  providers: [
    ...services,
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],
  exports: [
    ...components,
    ...material,
    ...directives,
    SingleSelectModule,
  ],
})
export class ApxSharedUiModule {
  static forRoot(): ModuleWithProviders<ApxSharedUiModule> {
    return {
      ngModule: ApxSharedUiModule,
      providers: [
        {
          provide: MAT_DIALOG_DEFAULT_OPTIONS,
          useValue: {
            width: '600px',
            // TODO use default styles for dialogs for future
            // panelClass: 'apx-ui-dialog-container',
            hasBackdrop: true,
          },
        },
      ],
    };
  }

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
  ) {

    this.matIconRegistry.addSvgIcon('halWaterIcon', this.setPath('water'))
      .addSvgIcon('halBacteriaIcon', this.setPath('bacteria'))
      .addSvgIcon('halInfo', this.setPath('info'))
      .addSvgIcon('halOilIcon', this.setPath('oil'))
      .addSvgIcon('halGasIcon', this.setPath('gas'))
      .addSvgIcon('halResidualIcon', this.setPath('residual'))
      .addSvgIcon('halMilliporeIcon', this.setPath('millipore'))
      .addSvgIcon('halPhaseSeparationIcon', this.setPath('phaseSeparation'))
      .addSvgIcon('coupon_icon', this.setPath('coupon'))
      .addSvgIcon('halNounOilIcon', this.setPath('noun_oil'))
      .addSvgIcon('halTankIcon', this.setPath('tank'))
      .addSvgIcon('halTruckIcon', this.setPath('truck'))
      .addSvgIcon('halStimDropOffIcon', this.setPath('stim_drop_off'))
      .addSvgIcon('halStimOnSiteIcon', this.setPath('stim_on_site'))
      .addSvgIcon('docx', this.setPath('documents/docx'))
      .addSvgIcon('csv', this.setPath('documents/csv'))
      .addSvgIcon('jpg', this.setPath('documents/jpg'))
      .addSvgIcon('pdf', this.setPath('documents/pdf'))
      .addSvgIcon('png', this.setPath('documents/png'))
      .addSvgIcon('pptx', this.setPath('documents/pptx'))
      .addSvgIcon('xlsx', this.setPath('documents/xlsx'))
      .addSvgIcon('halStimOnSiteIcon', this.setPath('stim_on_site'))
      .addSvgIcon('halOrderOffCycle', this.setPath('order-off-cycle'))
      .addSvgIcon('barcode', this.setPath('barcode'))
      .addSvgIcon('halOrderScheduled', this.setPath('order-scheduled'))
      .addSvgIcon('halOrderBase', this.setPath('order-base'))
      .addSvgIcon('apxSearchIconPrimary', this.setPath('searchIconPrimary'))
      .addSvgIcon('outforDeliveryicon', this.setPath('OutforDeliveryicon'))
      .addSvgIcon('crew', this.setPath('crew'));
  }

  private setPath(name: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${name}.svg`);
  }

}
