import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { PopoverConfig } from '../../interfaces';
import { UIPopoverService } from '../../services/index';
import { TagsPopoverComponent } from '../index';

export interface Chip {
  offsetTop?: number;
}

@Component({
  selector: 'apx-ui-shared-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent implements OnDestroy {
  @ViewChild('myIdentifier') myIdentifier: ElementRef<HTMLInputElement>;

  @Input() tags: any[];
  @Input() index?;
  @Input() showDeleteOption = false;
  @Output() deletedTag: EventEmitter<string> = new EventEmitter<string>();

  deletedEl = '';

  private subscription = new Subscription();

  constructor(
    private uIPopoverService: UIPopoverService,
  ) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openPopover($event: MouseEvent, showDeleteOption: boolean): void {
    if($event && $event.stopPropagation) {
      $event.stopPropagation();
    }
    let shownCount = 0;

    if (this.myIdentifier?.nativeElement) {
      const chips = this.myIdentifier.nativeElement.children[0].children[0].children as Chip;

      const top = chips[0] && chips[0].offsetTop;

      shownCount = Object.keys(chips).reduce((acc, o, i) =>
        (chips[i].offsetTop === top ? acc + 1 : acc), 0);
    }

    const popoverTags = this.tags.slice(shownCount, this.tags.length);

    const config: PopoverConfig = {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      disableClose: false,
      panelClass: '',
      arrowOffset: 30,
      arrowSize: 20,
      data: {
        tags: popoverTags,
        showDeleteOption,
      },
    };
    this.uIPopoverService.openPopover($event, config, TagsPopoverComponent);
    $event.stopPropagation();
  }
}