import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'apx-ui-shared-search-demand',
  templateUrl: './search-demand.component.html',
  styleUrls: ['./search-demand.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchDemandComponent implements OnInit, OnDestroy, OnChanges {

  @Input() placeholder = 'Search';
  @Input() value: string | null = '';
  @Input()
  set disabled(v: boolean) {
    const newValue = v === true || v as unknown === 'true';
    if (this.innerDisabled === newValue) {
      return;
    }
    this.innerDisabled = newValue;
    if (this.form) {
      this.setDisabledState(this.innerDisabled);
    }
  }

  get disabled(): boolean {
    return this.innerDisabled;
  }

  get valueControl(): AbstractControl {
    return this.form.get('value');
  }

  @Output() update = new EventEmitter<string>();

  form!: UntypedFormGroup;
  private innerDisabled: boolean;
  searchInput$: Subscription;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      value: [{ value: this.value, disabled: this.disabled ?? false }],
    });

    this.searchInput$ = this.valueControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(500),
    ).subscribe(val => this.update.emit(val));
  }

  ngOnDestroy(): void {
    this.searchInput$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateFormValue(changes);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form?.disable();
    } else {
      this.form?.enable();
    }

    this.disabled = isDisabled;
  }

  private updateFormValue(changes: SimpleChanges): void {
    if (!changes['value'] || !this.form) {
      return;
    }

    this.form.patchValue({
      value: this.value || '',
    });
  }

  resetValue(): void {
    this.update.emit('');
  }
}
