import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IDeliveryData, IOrderDeliveryData } from '../../interfaces';

export const loadOrdersForDelivery = createAction(
  '[APX Orders Delivery] Load Orders For Delivery',
  props<{
    unitSystemId: string,
  }>(),
);

export const loadOrdersForDeliverySuccess = createAction(
  '[APX Orders Delivery] Load Orders For Delivery Success',
  props<{
    deliveryData: IDeliveryData;
  }>(),
);

export const loadOrdersForDeliveryFailure = createAction(
  '[APX Orders Delivery] Load Orders For Delivery Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const clear = createAction(
  '[APX Orders Delivery] Clear',
);

export const updateOrder = createAction(
  '[APX Orders Delivery] Update Delivery Order',
  props<{
    order: IOrderDeliveryData;
  }>(),
);
