<table mat-table
       class="w-100"
       [dataSource]="notifications">

  <ng-container matColumnDef="icon">
    <th *matHeaderCellDef
        mat-header-cell
        width="20px"
        class="p-0">
    </th>
    <td *matCellDef="let element"
        mat-cell
        class="pl-0">
        <mat-icon class="md-12"
                  color="primary"
                  [svgIcon]="element.isNonRoutineOrder?'halOrderOffCycle':'halOrderScheduled'">
        </mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="location">
    <th *matHeaderCellDef
        mat-header-cell>
      Location
    </th>
    <td *matCellDef="let element"
        mat-cell
        class="p-0">
      <div class="mat-caption text-truncate">{{element.accountName}}</div>
      <div class="mat-caption text-truncate text-blue">{{element.locationName}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="treatment">
    <th *matHeaderCellDef
        mat-header-cell>
      Treatment
    </th>
    <td *matCellDef="let element"
        mat-cell>
      <div class="mat-caption text-truncate">{{element.productName}}</div>
      <div class="mat-caption text-truncate">{{element.injectionPoint}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="delivery">
    <th *matHeaderCellDef
        mat-header-cell
        class="p-0"
        width="80px">
      Est delivery
    </th>
    <td *matCellDef="let element"
        mat-cell
        class="text-right p-0">
      <div class="mat-caption">{{element.estDeliveryDate | date: 'MM/dd/yyyy'}}</div>
      <div class="mat-caption text-blue"><b>{{element.estDeliveryVolume}}</b></div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
