import { createSelector } from '@ngrx/store';

import * as fromReducer from '../reducers';

export const selectActiveDelivery = createSelector(
  fromReducer.selectedActiveDeliveryState,
  state => state.delivery,
);

export const selectActiveDeliveryStatus = createSelector(
  fromReducer.selectedActiveDeliveryState,
  state => state.status.load,
);

export const selectActiveDeliveryType = createSelector(
  fromReducer.selectedActiveDeliveryState,
  state => state.deliveryType,
);

export const selectOrderForDelivery = createSelector(
  fromReducer.selectedActiveDeliveryState,
  state => state.delivery?.OrderDelivery ?? [],
);

export const selectActiveDeliveryActivity = createSelector(
  fromReducer.selectedActiveDeliveryState,
  state => state.active,
);
