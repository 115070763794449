import { HttpErrorResponse } from '@angular/common/http';
import { Sample } from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

export const createSample = createAction(
  '[APX Samples] Create Sample',
  props<{
    accountId: string;
    sample: Sample;
  }>(),
);

export const createSampleSuccess = createAction(
  '[APX Samples] Create Sample Success',
  props<{
    sample: Sample;
  }>(),
);

export const createSampleFailure = createAction(
  '[APX Samples] Create Sample Failure',
  props<{
    sample?: Sample;
    err: HttpErrorResponse;
  }>(),
);

export const updateSample = createAction(
  '[APX Samples] Update Sample',
  props<{
    accountId: string;
    sample: Sample;
  }>(),
);

export const updateSampleSuccess = createAction(
  '[APX Samples] Update Sample Success',
  props<{
    sample: Sample;
  }>(),
);

export const updateSampleFailure = createAction(
  '[APX Samples] Update Sample Failure',
  props<{
    sample?: Sample;
    err: HttpErrorResponse;
  }>(),
);

export const deleteSample = createAction(
  '[APX Samples] Delete Sample',
  props<{
    sample: Sample;
    accountId: string;
    count: number;
  }>(),
);

export const deleteSampleSuccess = createAction(
  '[APX Samples] Delete Sample Success',
);

export const deleteSampleFailure = createAction(
  '[APX Samples] Delete Sample Failure',
  props<{
    sample?: any;
    err: HttpErrorResponse;
  }>(),
);

export const suspendSample = createAction(
  '[APX Samples] Suspend Sample',
  props<{
    sample: any;
  }>(),
);

export const suspendSampleSuccess = createAction(
  '[APX Samples] Suspend Sample Success',
  props<{
    sample: any;
  }>(),
);

export const suspendSampleFailure = createAction(
  '[APX Samples] Suspend Sample Failure',
  props<{
    sample?: any;
    err: HttpErrorResponse;
  }>(),
);
