import { Injectable } from '@angular/core';
import { ApxSolsticeWebClientService } from '@apx-ui/apx-web-api-v1';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap } from 'rxjs';

import { CustomerActions } from '../actions';

@Injectable()
export class CustomerEffects {

  handleCustomerLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomerActions.loadCustomers,
      ),
      switchMap(() => {

        return this.client.getCustomers().pipe(
          switchMap(customers => {

            return [
              CustomerActions.loadCustomersSuccess({ customers }),
              CustomerActions.addCustomers({ customers }),
            ];
          }),
          catchError(err => of(CustomerActions.loadCustomersFailure({ err }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private readonly client: ApxSolsticeWebClientService,
  ) {
  }

}
