import { PageConfigModel } from '@apx-ui/apx-web-api-v1';
import { createSelector } from '@ngrx/store';

import * as fromReducer from '../reducers';

export const selectPageConfigStatus = createSelector(
  fromReducer.selectPageConfigState,
  state => state.status,
);

export const selectPageConfig = createSelector(
  fromReducer.selectPageConfigState,
  state => state.pageConfig ? new PageConfigModel(state.pageConfig) : null);
