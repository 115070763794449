import { Injectable } from '@angular/core';
import { BaseEntityCollectionService } from '@apx-ui/apx-core';
import { Product } from '@apx-ui/apx-web-api-v1';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable({
  providedIn: 'root',
})
export class ProductsForUserService  extends BaseEntityCollectionService<Product> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('ProductsForUser', serviceElementsFactory);
  }
}
