import { KpiModel } from '../models/kpi.model';

export enum KpiControlNames {
  PUMP_RATE_DELTA = 'Pump Rate Delta',
  PUMP_RATE_VARIANCE = 'Pump Rate Variance',
  BATCH_RATE_DELTA = 'Batch Rate Delta',
  BATCH_RATE_VARIANCE = 'Batch Rate Variance',
  ORDER_NOTIFICATION_VOL = 'Order Notification Volume',
  CRITICAL_TANK_VOL = 'Critical Tank Volume'
}

export interface KpiToggle {
  Name: string;
  Value?: boolean;
  ToggleValue?: string;
  IsActive?: boolean;
  TrueValue: string;
  FalseValue: string;
  InfoIconRequired: boolean;
  InfoMessage: string;
  DefaultValue: boolean;
}

export interface KpiSection {
  PageTitle: string;
  SectionTitle: string;
  IsValid?: boolean;
  Kpis: KpiControl[];
  KpisModels?: KpiModel[];
  Toggles: KpiToggle[];
  TimeStamp?: string;
}

export interface KpiControl {
  Format: string;
  Name: string;
  UOfM: string;
  Validators?: string[];
  IsActive?: boolean;
  ValidatorNames?: string[];
  Required?: boolean;
  Value?: string;
}

export interface ReferenceKpiSection {
  KpiSectionA: KpiSection;
  KpiSectionB: KpiSection;
  KpiSectionC: KpiSection;
  KpiSectionD: KpiSection;
  KpiSectionE: KpiSection;
  KpiSectionF: KpiSection;
  KpiSectionG: KpiSection;
  KpiSectionH: KpiSection;
  KpiSectionI: KpiSection;
}
