import { Injectable } from '@angular/core';
import { BaseEntityCollectionService } from '@apx-ui/apx-core';
import { Tank } from '@apx-ui/apx-web-api-v1';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable({
  providedIn: 'root',
})
export class TanksForUserService  extends BaseEntityCollectionService<Tank> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('TanksForUser', serviceElementsFactory);
  }
}
