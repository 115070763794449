export function calcTargetRate(
  treatment,
  isCalculatedRate,
  fluidPhase,
  concentration,
  productionFluidUoMKoef,
): number | null {
  if (
    !(
      isCalculatedRate &&
      productionFluidUoMKoef &&
      fluidPhase &&
      concentration &&
      treatment.Oil >= 0 &&
      treatment.Water >= 0 &&
      treatment.Gas >= 0
    )
  ) {
    return null;
  }

  let prodFluidVal: number;
  switch (fluidPhase.toLowerCase()) {
    case 'oil': {
      prodFluidVal = treatment.Oil;
      break;
    }
    case 'water': {
      prodFluidVal = treatment.Water;
      break;
    }
    case 'gas': {
      prodFluidVal = treatment.Gas;
      break;
    }
    case 'total fluid': {
      prodFluidVal = treatment.Oil + treatment.Water;
      break;
    }
    default: {
      prodFluidVal = 0;
      break;
    }
  }

  return Math.round((+(prodFluidVal * concentration * productionFluidUoMKoef) / +1000000) * 100) / 100;
}
