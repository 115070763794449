import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { UI_POPOVER_DATA, UIPopoverOverlayRef } from '../../services';

@Component({
  selector: 'apx-ui-shared-tags-popover',
  templateUrl: './tags-popover.component.html',
  styleUrls: ['./tags-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TagsPopoverComponent {
  constructor(
    private popoverRef: UIPopoverOverlayRef,
    @Inject(UI_POPOVER_DATA) public data: any,
    ) {}

    close(): void {
      this.popoverRef.close();
    }
}