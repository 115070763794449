import { Injectable } from '@angular/core';
import { ApxSolsticeWebClientService } from '@apx-ui/apx-web-api-v1';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap } from 'rxjs';

import { AssetFilters } from '../actions';

@Injectable()
export class AssetFiltersEffects {

  handleAssetFiltersLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AssetFilters.loadAssetFilters,
      ),
      switchMap(({ accountId }) => {

        return this.client.getAssetFilters(accountId).pipe(
          switchMap(filters => {

            return [
              AssetFilters.loadAssetFiltersSuccess({ filters }),
            ];
          }),
          catchError(err => of(
            AssetFilters.loadAssetFiltersFailure({ err }),
          )),
        );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly client: ApxSolsticeWebClientService,
  ) {
  }

}
