import { Inject, Injectable } from '@angular/core';

import { CACHE } from '../tokens';

@Injectable()
export class CacheService {

  constructor(@Inject(CACHE) private cache: any) {
  }

  set(key: string, value: any, ttl: number = 0): void {
    this.cache.set(key, value, ttl);
  }

  get(key: string, callback?: () => any): any {
    return this.cache.get(key, callback);
  }

  del(key: string): boolean {
    return this.cache.del(key);
  }

  clear(): number {
    return this.cache.clear();
  }

  size(): number {
    return this.cache.size();
  }

  keys(): string[] {
    return this.cache.keys();
  }

}
