<div *ngIf="form"
     class="overflow-hidden"
     [formGroup]="form">

  <mat-form-field cdkTrapFocus
                  cdkTrapFocusAutoCapture
                  appearance="outline"
                  class="no-space">

    <input matInput
           tabIndex="0"
           type="text"
           maxlength="16"
           autocomplete="false"
           formControlName="input"/>

    <mat-icon *ngIf="inputCtrl.invalid"
              matSuffix
              color="warn"
              [matTooltip]="getErrorMessage(inputCtrl)">
      info_outline
    </mat-icon>

  </mat-form-field>

</div>
