import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAccountReducer from './account.reducer';
import * as fromAccountFiltersReducer from './account-filters.reducer';
import * as fromCreateAccountReducer from './create-account.reducer';
import * as fromCustomersReducer from './customer.reducer';
import * as fromAccountsReducer from './manage-account.reducer';
import * as fromManagersReducer from './manager.reducer';
import * as fromPageConfigReducer from './page-config.reducer';
import * as fromPlantsReducer from './plant.reducer';

export const accountFeature = 'apx-accounts-feature';

export interface State {
  manageAccounts: fromAccountsReducer.ManageAccountsState;
  createAccount: fromCreateAccountReducer.CreateAccountState;
  plant: fromPlantsReducer.PlantState;
  customers: fromCustomersReducer.CustomerState;
  manager: fromManagersReducer.ManagerState;
  filters: fromAccountFiltersReducer.accountFiltersState;
  account: fromAccountReducer.AccountState;
  pageConfig: fromPageConfigReducer.PageConfigState;
}

export function reducers(state: State | undefined, action: Action): State {
  return combineReducers({
    manageAccounts: fromAccountsReducer.reducer,
    createAccount: fromCreateAccountReducer.reducer,
    plant: fromPlantsReducer.reducer,
    customers: fromCustomersReducer.reducer,
    manager: fromManagersReducer.reducer,
    account: fromAccountReducer.reducer,
    pageConfig: fromPageConfigReducer.reducer,
    filters: fromAccountFiltersReducer.reducer,
  })(state, action);
}

export const selectAPXManageAccountsFeature = createFeatureSelector<State>(accountFeature);

export const selectManageAccountsState = createSelector(selectAPXManageAccountsFeature, state => state.manageAccounts);
export const selectPlantState = createSelector(selectAPXManageAccountsFeature, state => state.plant);
export const selectCustomerState = createSelector(selectAPXManageAccountsFeature, state => state.customers);
export const selectManagerState = createSelector(selectAPXManageAccountsFeature, state => state.manager);
export const selectCreateAccountState = createSelector(selectAPXManageAccountsFeature, state => state.createAccount);
export const selectAccountState = createSelector(selectAPXManageAccountsFeature, state => state.account);
export const selectPageConfigState = createSelector(selectAPXManageAccountsFeature, state => state.pageConfig);
export const selectAccountFiltersState = createSelector(selectAPXManageAccountsFeature, state => state.filters);
