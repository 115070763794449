import { Status } from '@apx-ui/apx-core';
import { GenericFilter } from '@apx-ui/apx-web-api-v1';
import { createReducer, on } from '@ngrx/store';

import { OrdersFilters } from '../actions';

export interface OrdersFilterStates {
  continuousFilter: GenericFilter | null;
  batchFilter: GenericFilter | null;
  stimulationFilter: GenericFilter | null;
  deliveryFilter: GenericFilter | null;
  status: {
    continuous: Status;
    batch: Status;
    stimulation: Status;
    delivery: Status;
  };
}

export const initialState: OrdersFilterStates = {
  continuousFilter: null,
  batchFilter: null,
  stimulationFilter: null,
  deliveryFilter: null,
  status: {
    continuous: {
      pending: false,
      resolved: true,
      rejected: false,
      err: null,
    },
    batch: {
      pending: false,
      resolved: true,
      rejected: false,
      err: null,
    },
    stimulation: {
      pending: false,
      resolved: true,
      rejected: false,
      err: null,
    },
    delivery: {
      pending: false,
      resolved: true,
      rejected: false,
      err: null,
    },
  },
};

export const reducer = createReducer(
  initialState,
  on(OrdersFilters.loadContinuousFilters,
    state => {
      return {
        ...state,
        status: {
          ...state.status,
          continuous: {
            pending: true,
            resolved: false,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(OrdersFilters.loadContinuousFiltersSuccess,
    (state, { filters }) => {
      return {
        ...state,
        continuousFilter: filters,
        status: {
          ...state.status,
          continuous: {
            pending: false,
            resolved: true,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(OrdersFilters.loadContinuousFiltersFailure,
    (state, { err }) => {
      return {
        ...state,
        status: {
          ...state.status,
          continuous: {
            pending: false,
            resolved: false,
            rejected: true,
            err,
          },
        },
      };
    },
  ),
  on(OrdersFilters.loadBatchFilters,
    state => {
      return {
        ...state,
        status: {
          ...state.status,
          batch: {
            pending: true,
            resolved: false,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(OrdersFilters.loadBathcFiltersSuccess,
    (state, { filters }) => {
      return {
        ...state,
        batchFilter: filters,
        status: {
          ...state.status,
          batch: {
            pending: false,
            resolved: true,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(OrdersFilters.loadBatchFiltersFailure,
    (state, { err }) => {
      return {
        ...state,
        status: {
          ...state.status,
          batch: {
            pending: false,
            resolved: false,
            rejected: true,
            err,
          },
        },
      };
    },
  ),
  on(OrdersFilters.loadDeliveryFilters,
    state => {
      return {
        ...state,
        status: {
          ...state.status,
          delivery: {
            pending: true,
            resolved: false,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(OrdersFilters.loadDeliveryFiltersSuccess,
    (state, { filters }) => {
      return {
        ...state,
        deliveryFilter: filters,
        status: {
          ...state.status,
          delivery: {
            pending: false,
            resolved: true,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(OrdersFilters.loadDeliveryFiltersFailure,
    (state, { err }) => {
      return {
        ...state,
        status: {
          ...state.status,
          delivery: {
            pending: false,
            resolved: false,
            rejected: true,
            err,
          },
        },
      };
    },
  ),
  on(OrdersFilters.loadStimulationFilters,
    state => {
      return {
        ...state,
        status: {
          ...state.status,
          stimulation: {
            pending: true,
            resolved: false,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(OrdersFilters.loadStimulationFiltersSuccess,
    (state, { filters }) => {
      return {
        ...state,
        stimulationFilter: filters,
        status: {
          ...state.status,
          stimulation: {
            pending: false,
            resolved: true,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(OrdersFilters.loadStimulationFiltersFailure,
    (state, { err }) => {
      return {
        ...state,
        status: {
          ...state.status,
          stimulation: {
            pending: false,
            resolved: false,
            rejected: true,
            err,
          },
        },
      };
    },
  ),
  on(OrdersFilters.clear,
    state => ({
      ...state,
      ...initialState,
    }),
  ),
);
