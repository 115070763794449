import { Injectable } from '@angular/core';
import { RouterActions } from '@apx-ui/apx-core';
import { NotificationActions } from '@apx-ui/apx-shared-ui';
import { ApxSolsticeWebClientService } from '@apx-ui/apx-web-api-v1';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, of, switchMap } from 'rxjs';

import { AssetActions, SampleActions } from '../actions';

@Injectable()
export class SamplesEffects {

  handleCreateSample$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SampleActions.createSample,
      ),
      concatMap(({ sample, accountId }) => {
        return this.client.createAnalysisSample({
          ...sample,
          IsActive: true,
        }, sample.LocationId, accountId).pipe(
          switchMap(res => {
            return [
              SampleActions.createSampleSuccess({ sample }),
              AssetActions.loadAssetById({ assetId: res.LocationId }),
              RouterActions.navigate({
                path: [
                  '/',
                  accountId,
                  'manage',
                  'assets',
                  res.LocationId,
                  // TODO Eric&Emi asked :D
                  // 'samples',
                  // res.Id,
                ],
              }),
              NotificationActions.success({
                message: 'Scheduled sample was successfully created.',
              }),
            ];
          }),
          catchError(err => of(
            SampleActions.createSampleFailure({ err }),
            NotificationActions.error({
              message: err.error.message ?? err.message ?? `Scheduled sample was not successfully created`,
            }))),
        );
      }),
    ),
  );

  handleUpdateSample$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SampleActions.updateSample,
      ),
      concatMap(({ sample, accountId }) => {
        return this.client.updateAnalysisSample({
          ...sample,
        }, sample.LocationId, accountId).pipe(
          switchMap(res => {

            return [
              SampleActions.updateSampleSuccess({ sample }),
              AssetActions.loadAssetById({ assetId: res.LocationId }),
              RouterActions.navigate({
                path: [
                  '/',
                  accountId,
                  'manage',
                  'assets',
                  res.LocationId,
                  'samples',
                  res.Id,
                ],
              }),
              NotificationActions.success({
                message: `Scheduled sample successfully updated`,
              }),
            ];
          }),
          catchError(err => of(
            SampleActions.updateSampleFailure({ err }),
            NotificationActions.error({
              message: err.error.message ?? err.message ?? `Scheduled sample was not successfully updated`,
            }),
          )),
        );
      }),
    ),
  );

  handleDeleteSample$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SampleActions.deleteSample,
      ),
      concatMap(({ sample, accountId, count }) => {
        return this.client.deleteAnalysisSample({
          ...sample,
          IsActive: false,
        }, sample.LocationId, accountId).pipe(
          switchMap(res => {
            return [
              SampleActions.deleteSampleSuccess(),
              AssetActions.loadAssetById({ assetId: res.LocationId }),
              RouterActions.navigate({
                path: count > 1 ?
                  ['/', accountId, 'manage', 'assets', res.LocationId, 'samples'] :
                  ['/', accountId, 'manage', 'assets', res.LocationId],
              }),
              NotificationActions.success({
                message: `Scheduled sample was deleted`,
              }),
            ];
          }),
          catchError(err => of(
            SampleActions.deleteSampleFailure({ err }),
            NotificationActions.error({
              message: err.error.message ?? err.message ?? `Scheduled sample was not deleted`,
            }),
          )),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private readonly client: ApxSolsticeWebClientService,
  ) {
  }

}
