import { Status } from '@apx-ui/apx-core';
import { Plant } from '@apx-ui/apx-web-api-v1';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { PlantActions } from '../actions';

export const adapter: EntityAdapter<Plant> = createEntityAdapter<Plant>({
  selectId: a => a.plantID,
  sortComparer: (a, b) => a.plantName.localeCompare(b.plantName),
});

export interface PlantState extends EntityState<Plant> {
  plants: string[];
  status: Status;
}

export const initialState: PlantState = adapter.getInitialState({
  plants: [],
  status: {
    pending: false,
    resolved: false,
    rejected: false,
    err: null,
  },
});

export const reducer = createReducer(
  initialState,
  on(PlantActions.addPlant,
    (state, { plant }) => {
      return adapter.upsertOne(plant, state);
    },
  ),
  on(PlantActions.addPlants,
    (state, { plants }) => {
      return adapter.upsertMany(plants, state);
    },
  ),
  on(PlantActions.loadPlants, state => ({
      ...state,
      status: {
        ...state.status,
        pending: true,
        err: null,
      },
    }),
  ),
  on(PlantActions.loadPlantsSuccess,
    (state, { plants }) => ({
        ...state,
        plants: plants.map(a => a.plantID),
        status: {
          pending: false,
          resolved: true,
          rejected: false,
          err: null,
        },
      }
    ),
  ),
  on(PlantActions.loadPlantsFailure,
    (state, { err }) => ({
      ...state,
      plants: [],
      status: {
        ...state.status,
        pending: false,
        rejected: true,
        err,
      },
    }),
  ),
  on(PlantActions.clearState,
    (state) => ({
      ...state,
      status: {
        pending: false,
        resolved: false,
        rejected: false,
        err: null,
      },
    }),
  ),
);

const { selectAll, selectEntities } = adapter.getSelectors();

export const selectPlants = selectAll;
export const selectPlantEntities = selectEntities;
