import { AbstractModel } from '@apx-ui/apx-core';

import { Site } from '../interfaces';

export class SiteModel extends AbstractModel<Site> {

  constructor(protected override data: Site) {
    super(data);
  }

  getId(): string {
    return this.data.SiteName;
  }

  getDisplayName(): string {
    return this.data.SiteName;
  }

  getSiteType(): string {
    return this.data.SiteType;
  }

  getPlantId(): string {
    return this.data.PlantId;
  }

  getField(): string {
    return this.data.Field;
  }

  getShipToID(): number {
    return this.data.ShipToID;
  }

  getSoldTo(): number {
    return this.data.SoldTo;
  }

  getListName(): string {
    return `${this.data.SiteName} ᛫ ${this.data.SiteType} ᛫ ${this.data.Field}`;
  }

}
