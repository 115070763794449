<div class="overflow-hidden"
     [style.width.px]="width"
     [formGroup]="form">

  <mat-form-field class="no-space"
                  appearance="outline">
    <mat-icon matPrefix
              svgIcon="apxSearchIconPrimary">

    </mat-icon>
    <input matInput
           type="text"
           placeholder="search"
           formControlName="search"/>
  </mat-form-field>

  <mat-selection-list formControlName="options"
                      class="list">
    <mat-list-option *ngFor="let item of filteredList"
                     class="no-wrap text-overflow-ellipsis"
                     color="primary"
                     checkboxPosition="before"
                     [value]="item[keyField]">
      {{ item[displayField] }}
    </mat-list-option>
  </mat-selection-list>

</div>
