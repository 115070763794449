import { Status } from '@apx-ui/apx-core';
import { Notifications } from '@apx-ui/apx-web-api-v1';
import { createReducer, on } from '@ngrx/store';

import { NotificationAction } from '../actions';

export interface NotificationState {
  count: number;
  notifications: Notifications | null;
  status: {
    loadCount: Status;
    loadNotification: Status;
    mark: Status;
  };
}

export const initialState: NotificationState = {
  count: 0,
  notifications: null,
  status: {
    loadCount: {
      pending: false,
      resolved: false,
      rejected: false,
      err: null,
    },
    loadNotification: {
      pending: false,
      resolved: false,
      rejected: false,
      err: null,
    },
    mark: {
      pending: false,
      resolved: false,
      rejected: false,
      err: null,
    },
  },
};

export const reducer = createReducer(
  initialState,
  on(NotificationAction.loadNotificationsCount, state => ({
      ...state,
      status: {
        ...state.status,
        loadCount: {
          ...state.status.loadCount,
          pending: true,
          err: null,
        },
      },
    }),
  ),
  on(NotificationAction.loadNotificationsCountFailure,
    (state, { err }) => ({
      ...state,
      status: {
        ...state.status,
        loadCount: {
          ...state.status.loadCount,
          pending: false,
          resolved: false,
          rejected: true,
          err,
        },
      },
    }),
  ),
  on(NotificationAction.loadNotificationsCountSuccess,
    (state, { count }) => ({
      ...state,
      count,
      status: {
        ...state.status,
        loadCount: {
          ...state.status.loadCount,
          pending: false,
          resolved: true,
          rejected: false,
        },
      },
    }),
  ),
  on(NotificationAction.loadNotifications, state => ({
      ...state,
      status: {
        ...state.status,
        loadNotification: {
          ...state.status.loadNotification,
          pending: true,
          err: null,
        },
      },
    }),
  ),
  on(NotificationAction.loadNotificationsFailure,
    (state, { err }) => ({
      ...state,
      status: {
        ...state.status,
        loadNotification: {
          ...state.status.loadNotification,
          pending: false,
          resolved: false,
          rejected: true,
          err,
        },
      },
    }),
  ),
  on(NotificationAction.loadNotificationsSuccess,
    (state, { notifications }) => ({
      ...state,
      notifications,
      status: {
        ...state.status,
        loadNotification: {
          ...state.status.loadNotification,
          pending: false,
          resolved: true,
          rejected: false,
        },
      },
    }),
  ),
  on(NotificationAction.loadNotifications, state => ({
      ...state,
      status: {
        ...state.status,
        mark: {
          ...state.status.mark,
          pending: true,
          err: null,
        },
      },
    }),
  ),
  on(NotificationAction.loadNotificationsFailure,
    (state, { err }) => ({
      ...state,
      status: {
        ...state.status,
        mark: {
          ...state.status.mark,
          pending: false,
          resolved: false,
          rejected: true,
          err,
        },
      },
    }),
  ),
  on(NotificationAction.markNotificationsSuccess,
    state => ({
      ...state,
      status: {
        ...state.status,
        mark: {
          ...state.status.mark,
          pending: false,
          resolved: false,
          rejected: true,
        },
      },
    }),
  ),
  on(NotificationAction.clear,
    () => ({
      ...initialState,
    }),
  ),
);
