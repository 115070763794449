import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheHttpRequestService } from '@apx-ui/cache';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()
export class ApxClientCacheInterceptor implements HttpInterceptor {

  private url = '/';
  private urlCacheTtl: number = 30 * 1000;

  constructor(
    private cache: CacheHttpRequestService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!req.url.includes(this.url)) {
      return next.handle(req);
    }

    if (this.shouldInterceptReadRequest(req)) {
      return this.cache.intercept(req, next, this.urlCacheTtl).pipe(
        // mimic a slight delay so that route guards work as expected.
        delay(1),
      );
    }

    if (this.shouldInterceptWriteRequest(req)) {
      this.cache.clear();
    }

    return next.handle(req);

  }

  private shouldInterceptReadRequest(req: HttpRequest<any>): boolean {

    if (!['GET'].includes(req.method)) {
      return false;
    }

    if (req.headers.get('X-Lift-Client-Cache') === 'no-cache') {
      return false;
    }

    return true;

  }

  private shouldInterceptWriteRequest(req: HttpRequest<any>): boolean {

    if (!['POST', 'PATCH', 'PUT', 'DELETE'].includes(req.method)) {
      return false;
    }

    return true;

  }

}
