import { createSelector } from '@ngrx/store';

import * as fromReducer from '../reducers';

export const selectNotificationsCountStatus = createSelector(
  fromReducer.selectNotificationState,
  state => state.status.loadCount,
);

export const selectNotificationsStatus = createSelector(
  fromReducer.selectNotificationState,
  state => state.status.loadNotification,
);

export const selectMarkNotificationsStatus = createSelector(
  fromReducer.selectNotificationState,
  state => state.status.mark,
);

export const selectNotificationsCount = createSelector(
  fromReducer.selectNotificationState,
  state => state.count,
);

export const selectNotifications = createSelector(
  fromReducer.selectNotificationState,
  state => state.notifications,
);
