<mat-form-field appearance="outline"
                data-test-id="samplesList"
                class="no-space mr-4">
  <mat-label>Select row count</mat-label>
  <mat-select data-test-id="rowItem"
              [formControl]="rowsSelectCtrl">
    <mat-option *ngFor="let item of rows | async"
                [value]="item">{{item}}</mat-option>
  </mat-select>
</mat-form-field>
