import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import {
  BatchOrderNotification,
  BatchOrderNotificationService,
  NotificationStateService,
} from '@apx-ui/apx-notifications-store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'apx-ui-notifications-summary-batch-orders',
  templateUrl: './apx-notifications-summary-batch.component.html',
  styleUrls: ['./apx-notifications-summary-batch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApxNotificationsSummaryBatchComponent implements OnInit {
  @Input() data: any;
  notifications$: Observable<BatchOrderNotification[]>;
  status$!: Observable<Status>;

  constructor(
    private readonly orderNotificationState: BatchOrderNotificationService,
    private readonly notificationState: NotificationStateService,
  ) {
  }

  ngOnInit(): void {
    this.notifications$ = this.orderNotificationState.getWithQuery(
      {
        checked_date: this.data.checked_date,
        unitSystemId: this.data.unitSystemId,
        ...this.data.account_id && { account_id: this.data.account_id },
      },
    ).pipe(
      tap(notifications => {
          this.notificationState
            .markNotificationAsRead(notifications.map((n) => ({ id: n.id })), this.data.account_id);
        },
      ),
    );

    this.status$ = this.orderNotificationState.status$;
  }

}
