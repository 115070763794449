import { IDateAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams, IDateParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import moment from 'moment';

interface InitParams extends ICellEditorParams {
  minDate: Date;
  maxDate: Date;
}

@Component({
  selector: 'apx-ui-shared-custom-date-cell-local-time',
  templateUrl: './data-picker-cell-local-time.component.html',
  styleUrls: ['./data-picker-cell-local-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataPickerCellLocalTimeComponent implements IDateAngularComp {

  @ViewChild('datePicker') datePicker: MatDatepicker<Date>;

  minDate: Date;
  maxDate: Date;
  dateCtrl: UntypedFormControl = new UntypedFormControl();
  private params: InitParams & IDateParams;

  agInit(params: InitParams & IDateParams): void {
    this.params = params;
    this.minDate = params.minDate;
    this.maxDate = params.maxDate;
    this.dateCtrl.setValue(params.value ? new Date(params.value) : new Date());
  }

  getDate(): Date {
    return this.dateCtrl.value;
  }

  setDate(date: Date): void {
    this.dateCtrl.setValue(date ?? null);
  }

  getValue(): string {
    return moment(this.dateCtrl.value).format();
  }

  afterGuiAttached(): void {
    this.datePicker.open();
  }

  onClose(): void {
    this.params?.api?.stopEditing();
  }

}
