import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClientBaseService } from '@apx-ui/apx-core';
import { UIDialogService } from '@apx-ui/apx-shared-ui';
import { UserModel, UserStateService } from '@apx-ui/apx-web-api-v1';
import { Observable, Subscription } from 'rxjs';

import { ModalInfoAssignmentsComponent } from '../modal-info-assignments/modal-info-assignments.component';

@Component({
  selector: 'apx-ui-assignment-counter',
  templateUrl: './assignment-counter.component.html',
  styleUrls: ['./assignment-counter.component.scss'],
})
export class AssignmentCounterComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();

  user$!: Observable<UserModel | null>;

  constructor(
    private readonly userState: UserStateService,
    private readonly dialog: UIDialogService,
    private readonly clientBaseService: ClientBaseService,
  ) {}

  ngOnInit(): void {
    this.user$ = this.userState.getUser$();

    this.subscriptions.add(this.clientBaseService.get('/delivery-planning/assignments/my-today-count').subscribe(res => {
      if(res) {
        this.visitVerification(res.TodayAssignments, res.FullName);
      }
    }));
  }

  visitVerification(count: number | string, fullName: string): void {
    if(count) {
      let userData;
      const today = new Date().toISOString().slice(0, 10);

      this.subscriptions.add(this.user$.subscribe(res => {
        userData = res;

        if(userData.data.email) {
          const localStorageUserId = localStorage.getItem(`user_${userData.data.email}`);
          const localStorageUserVisitDate = localStorage.getItem(`user_date_visit_${userData.data.email}`);
          if (!localStorageUserId || !localStorageUserVisitDate) {
            this.showAssignmentTodayModal(
              userData.data.firstName, userData.data.lastName, fullName, count,
            );
            this.updateLocStorage(userData.data.email, today);
          } else {
            if (localStorageUserVisitDate !== today) {
              this.showAssignmentTodayModal(
                userData.data.firstName, userData.data.lastName, fullName, count,
              );
              this.updateLocStorage(userData.data.email, today);
            }
          }
        }
      }));
    }
  }

  showAssignmentTodayModal(name: string, lastName: string, fullName: string, count: number | string): void {
    this.dialog.open(ModalInfoAssignmentsComponent, {
      width: '300px',
      minHeight: '300px',
      data: { name: name, count: count, lastName: lastName, fullName: fullName },
    });
  }

  updateLocStorage(userId, date): void {
    localStorage.setItem(`user_${userId}`, userId);
    localStorage.setItem(`user_date_visit_${userId}`, date);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
