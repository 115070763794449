import { Injectable } from '@angular/core';
import { ApxSolsticeWebClientService } from '@apx-ui/apx-web-api-v1';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap } from 'rxjs';

import { AccountActions, ManageAccountActions } from '../actions';

@Injectable()
export class ManageAccountEffects {

  handleAccountsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ManageAccountActions.loadAccounts,
      ),
      switchMap(() => {

        return this.client.getAccounts().pipe(
          switchMap(accounts => {

            return [
              ManageAccountActions.loadAccountsSuccess({ accounts }),
              AccountActions.addAccounts({ accounts }),
            ];
          }),
          catchError(err => of(ManageAccountActions.loadAccountsFailure({ err }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private readonly client: ApxSolsticeWebClientService,
  ) {
  }

}
