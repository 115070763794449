import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BatchTreatment, ContinuousTreatment } from '@apx-ui/apx-web-api-v1';

import { CancelDialogComponent, ConfirmDialogComponent } from '../../components';
import { SlowReportDialogComponent } from '../../components/slow-report-dialog/slow-report-dialog.component';

@Injectable()
export class UIDialogService {

  constructor(private dialog: MatDialog) {
  }

  open<T, D = any, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<D>):
    MatDialogRef<T, R> {
    return this.dialog.open(componentOrTemplateRef, config);
  }

  closeAll(): void {
    this.dialog.closeAll();
  }

  getDialogById(id: string): MatDialogRef<any> | undefined {
    return this.dialog.getDialogById(id);
  }

  deleteContinuousTreatment(treatment: ContinuousTreatment): MatDialogRef<ConfirmDialogComponent, boolean> {
    return this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete Continuous Treatment',
        body: `Are you sure you want to delete the <em>${treatment?.tankModel?.getDisplayName() }</em>
                treatment? This action cannot be undone.`,
        confirmBtn: 'Delete',
      },
    });
  }

  deleteBatchTreatment(treatment: BatchTreatment): MatDialogRef<ConfirmDialogComponent, boolean> {
    return this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete Batch Treatment',
        body: `Are you sure you want to delete the <em>${treatment?.productModel?.getProductName() }</em>
                treatment? This action cannot be undone.`,
        confirmBtn: 'Delete',
      },
    });
  }

  deleteSettingItem(): MatDialogRef<ConfirmDialogComponent, boolean> {
    return this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete',
        body: `Are you sure you want to delete the data? This action cannot be undone.`,
        confirmBtn: 'Delete',
      },
    });
  }

  denyDeleteSettingItem(): MatDialogRef<CancelDialogComponent, boolean> {
    return this.dialog.open(CancelDialogComponent, {
      data: {
        title: 'Delete',
        body: `You cannot delete this tank.`,
      },
    });
  }

  denyEditSettingItem(): MatDialogRef<CancelDialogComponent, boolean> {
    return this.dialog.open(CancelDialogComponent, {
      data: {
        title: 'Edit',
        body: `You cannot edit this tank.`,
      },
    });
  }

  deleteStimulationTreatment(productName: string): MatDialogRef<ConfirmDialogComponent, boolean> {
    return this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete the Product on Stimulation Treatment',
        body: `Are you sure you want to delete the the product <em>${productName}</em>
                Stimulation treatment? This action cannot be undone.`,
        confirmBtn: 'Delete',
      },
    });
  }

  deleteSample(message: string): MatDialogRef<ConfirmDialogComponent, boolean> {
    return this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete Sample',
        body: `Are you sure you want to delete the <em>${message}</em>? This action cannot be undone.`,
        confirmBtn: 'Delete',
      },
    });
  }

  openSlowReportConfirmation(): MatDialogRef<SlowReportDialogComponent, boolean | number | string> {
    return this.dialog.open(SlowReportDialogComponent);
  }

  proceedSample(message: string, isValid: boolean): MatDialogRef<ConfirmDialogComponent, boolean> {
    return this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Proceed with sample',
        body: message,
        confirmBtn: isValid ?  'Yes' : 'Close',
        rejectBtn: isValid ? 'No' : null,
        displayBtn: isValid,
      },
    });
  }

}
