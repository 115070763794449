import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { SuspendReason } from '@apx-ui/apx-web-api-v1';
import moment from 'moment/moment';
import { Subscription } from 'rxjs';

function validator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formModel = control.value as { startDate: string; endDate: string; endAtEnable: boolean; };

    return valueValidator(formModel);
  };
}

function valueValidator(model: { startDate: string; endDate: string; endAtEnable: boolean; }): ValidationErrors | null {
  const startAtMoment = moment(model.startDate);
  const endAtMoment = moment(model.endDate);

  const datesValid = !model.endAtEnable ||
    model.endAtEnable
    && model.startDate
    && endAtMoment.diff(startAtMoment, 'days') >= 0;

  return datesValid ? null : {
    invalidDate: true,
  };
}

@Component({
  selector: 'apx-ui-shared-suspend-dialog-form',
  templateUrl: './suspend-dialog-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuspendDialogFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  minDate = new Date();
  startDate: string;
  endDate: string;
  @Input() reasons: SuspendReason[];
  @Input() minBackDate: Date;
  @Input() data;
  @Output() submitSuspension = new EventEmitter<any>();

  private formChangesSubscription?: Subscription;
  private subscription = new Subscription();

  public constructor(
    private readonly fb: UntypedFormBuilder,
  ) {
  }

  get getReason(): AbstractControl | null {
    return this.form.get('reason');
  }

  get getStartDate(): AbstractControl | null {
    return this.form.get('startDate');
  }

  get getEndDate(): AbstractControl | null {
    return this.form.get('endDate');
  }

  get endAtEnable(): AbstractControl | null {
    return this.form.get('endAtEnable');
  }

  onStartDateChange(): void {
    if(!this.getStartDate.value){
      this.form.get('startDate').setValue(new Date());
    }
    const day = moment(this.getStartDate.value).hours(0).minutes(0).seconds(0).milliseconds(0);
    this.startDate = `${day.format('YYYY-MM-DDTHH:mm:ss.SSS')  }Z`;
  }

  onEndDateChange(): void {
    if(!this.getEndDate.value){
      this.form.get('endDate').setValue(new Date());
    }
    const day = moment(this.getEndDate.value);
    this.endDate = `${day.format('YYYY-MM-DDTHH:mm:ss.SSS')  }Z`;
  }

  startDateSuspendFilter = (d: Date | null): boolean => {
    const day = d?.setHours(0, 0, 0, 0);
    const startDate = new Date(this.getStartDate?.value).setHours(0, 0, 0, 0);
    const minDate = this.minBackDate.setHours(0, 0, 0, 0);

    return day === startDate || minDate <= day;
  };

  ngOnInit(): void {
    this.initForm();
    this.onStartDateChange();
  }

  ngOnDestroy(): void {
    this.formChangesSubscription?.unsubscribe();
    this.subscription.unsubscribe();
  }

  onSubmit(): void {
    // eslint-disable-next-line max-len
    const defaultEndDate = this.data.PauseEndDate ? `${moment(this.data.PauseEndDate).format('YYYY-MM-DDTHH:mm:ss.SSS') }Z` : null;
    this.form.get('startDate').setValue(this.startDate);
    // eslint-disable-next-line no-unused-expressions
    this.endDate ? this.form.get('endDate').setValue(this.endDate) : this.form.get('endDate').setValue(defaultEndDate);

    this.submitSuspension.emit(this.form.value);
  }

  private initForm(): void {
    const endAt = this.data.PauseEndDate ?? null;

    this.form = this.fb.group({
      startDate: [this.data.PauseStartDate ?? new Date()],
      endAtEnable: [!!endAt],
      endDate: [{ value: this.data.PauseEndDate ?? null, disabled: !endAt }],
      reason: [{ reason: this.data.ReasonId }, Validators.required],
    }, { validator: validator() });

    this.formChangesSubscription = this.endAtEnable.valueChanges.subscribe(enable => {
      if (enable) {
        this.getEndDate?.enable();
        this.getEndDate?.setValue(new Date());
      } else {
        this.getEndDate?.setValue(null);
        this.getEndDate?.disable();
      }
    });

  }
}
