import { BatchOrderModel } from './../models/batch-order.model';
import { ContinuousOrderModel } from './../models/continuous-order.model';
import { DeliveryOrderModel } from './../models/delivery-order.model';

const MAX_VALUE = 999999;
/**
 * Convert UoMs.
 * @param firstOrder
 * @param secondOrder
 */
export function sortOrdersBySequence(
  firstOrder: DeliveryOrderModel | BatchOrderModel | ContinuousOrderModel,
  secondOrder: DeliveryOrderModel | BatchOrderModel | ContinuousOrderModel,
): number {
  const {
    routePlantOrderIndex: routePlantOrderIndexA,
    routeOrderIndex: routeOrderIndexA,
    LocationName: LocationNameA,
  } = firstOrder.getData();

  const {
    routePlantOrderIndex: routePlantOrderIndexB,
    routeOrderIndex: routeOrderIndexB,
    LocationName: LocationNameB,
  } = secondOrder.getData();

  const plantOrderIndexA = routePlantOrderIndexA && routeOrderIndexA
    ? routePlantOrderIndexA
    : MAX_VALUE;

  const plantOrderIndexB = routePlantOrderIndexB && routeOrderIndexB
    ? routePlantOrderIndexB
    : MAX_VALUE;

  return plantOrderIndexA - plantOrderIndexB
  || (routeOrderIndexA || MAX_VALUE) - (routeOrderIndexB || MAX_VALUE)
  || LocationNameA?.localeCompare(LocationNameB);
}
