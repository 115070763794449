/**
 * Round value.
 * @param value
 * @param precision
 */
export function getRoundedValue(value, precision): number {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

/**
 * Calc difference between dates in days.
 * @param date1
 * @param date2
 */
export function calcDaysDiff(date1, date2): number {
  const diffTime = date2 - date1;
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}
