import { HttpErrorResponse } from '@angular/common/http';
import { Account, Asset } from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

export const loadAssets = createAction(
  '[APX Assets] Load',
  props<{
    accountId: string;
    unitSystemID?: string;
  }>(),
);

export const loadAssetsSuccess = createAction(
  '[APX Assets] Load Success',
  props<{
    assets: Asset[];
  }>(),
);

export const loadAssetsFailure = createAction(
  '[APX Assets] Load Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const loadAssetById = createAction(
  '[APX Assets] Load by ID',
  props<{
    assetId: string;
    unitSystemID?: string;
  }>(),
);

export const loadAssetByIdSuccess = createAction(
  '[APX Assets] Load by ID Success',
  props<{
    asset: Asset;
    assetId: string;
  }>(),
);

export const loadAssetByIdFailure = createAction(
  '[APX Assets] Load by ID Failure',
  props<{
    assetId: string;
    err: HttpErrorResponse;
  }>(),
);

export const createAsset = createAction(
  '[APX Assets] Create Asset',
  props<{
    asset: Asset;
  }>(),
);

export const addAsset = createAction(
  '[APX Assets] Add Asset',
  props<{
    asset: Asset;
  }>(),
);

export const addAssets = createAction(
  '[APX Assets] Add Assets',
  props<{
    assets: Asset[];
  }>(),
);

export const updateAsset = createAction(
  '[APX Assets] Update Asset',
  props<{
    assetId: string;
    changes: Partial<Asset>;
  }>(),
);

export const setAsset = createAction(
  '[APX Assets] Set Current Asset',
  props<{
    assetId: string;
  }>(),
);

export const deselectAsset = createAction(
  '[APX Assets] Deselect Asset',
);

export const clear = createAction(
  '[APX Assets] Clear Assets',
);

export const updateWell = createAction(
  '[APX Assets] Update Well',
  props<{
    accountId: string;
    well: Partial<Asset>;
  }>(),
);

export const updateWellSuccess = createAction(
  '[APX Assets] Update Well Success',
  props<{
    accountId: string;
    well: Partial<Asset>;
  }>(),
);

export const updateWellFailure = createAction(
  '[APX Assets] Update Well Failure',
  props<{
    accountId: string;
    well: Partial<Asset>;
    err: HttpErrorResponse;
  }>(),
);

export const createWell = createAction(
  '[APX Assets] Create Well',
  props<{
    account: Account;
    well: Partial<Asset>;
    wellType: string;
  }>(),
);

export const createWellSuccess = createAction(
  '[APX Assets] Create Well Success',
  props<{
    accountId: string;
    well: Partial<Asset>;
  }>(),
);

export const createWellFailure = createAction(
  '[APX Assets] Create Well Failure',
  props<{
    accountId: string;
    well: Partial<Asset>;
    err: HttpErrorResponse;
  }>(),
);

export const loadAssetsForUser = createAction(
  '[APX Assets] Load For User',
);

export const loadAssetsForUserSuccess = createAction(
  '[APX Assets] Load For User Success',
  props<{
    assets: Asset[];
  }>(),
);

export const loadAssetsForUserFailure = createAction(
  '[APX Assets] Load For User Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);
