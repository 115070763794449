<apx-ui-shared-layout>

  <ng-container header>

    <apx-ui-app-header [user]="user$ | async"
                       [account]="account$ | async">
    </apx-ui-app-header>

  </ng-container>

  <ng-container body>

    <router-outlet></router-outlet>

  </ng-container>

</apx-ui-shared-layout>
