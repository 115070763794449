export interface Sequencing {
  locationList: SequencingLocation[];
  routeList: SequencingRoute[];
}

export interface UpdateSequencingResponseItem {
  errors: Record<string, string[]>;
  id: string;
  plantRouteId: number;
  success: boolean;
  uuid: string;
}

export interface SequencingLocation {
  fieldName: string;
  id: string;
  name: string;
  routeId: string;
  routeOrderIndex: number;
  siteName: string;

  routeUuid?: string;
  routeName?: string;
  deleted?: boolean;
  needSave?: boolean;
}

export interface SequencingRoute {
  id?: string;
  isDeleted: boolean;
  name: string;
  plantNumber: string;
  plantOrderIndex: number;
  plantRouteId?: number;
  uuid?: string;
}

export interface RouteSequencingCloseData {
  selectedRoute: SequencingRoute;
  selectedLocation: SequencingLocation;
}

export enum RouteSequencingStep {
  Routes,
  Locations,
  Pending,
  Failure,
  Success
}

export enum RouteSequencingOperationType {
  EditRouteSequencing,
  AddRoute,
  EditRoute
}

export enum RouteSequencingSource {
  Settings,
  AssetDetails,
  NewAssetDetails
}
