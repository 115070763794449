import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultPluralizer, EntityDefinitionService, Pluralizer } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AssetGuard } from './guards';
import { states } from './services';
import { entityMetadataMap, pluralNames } from './stores';
import { effects } from './stores/effects';
import { assetFeature, reducers } from './stores/reducers';

@NgModule({
  imports: [
    CommonModule,

    // Ngrx Store Imports.
    StoreModule.forFeature(assetFeature, reducers),

    // Ngrx Effects Imports.
    EffectsModule.forFeature(effects),
  ],
  providers: [
    ...states,
    AssetGuard,
  ],
})
export class ApxAssetStoreModule {
  constructor(
    pluralizer: Pluralizer,
    entityDefinitionService: EntityDefinitionService,
  ) {

    entityDefinitionService.registerMetadataMap(entityMetadataMap);
    (pluralizer as DefaultPluralizer).registerPluralNames(pluralNames);

  }
}
