import { createSelector } from '@ngrx/store';

import { selectOffCycleBatchState } from '../reducers';

export const selectOffCycleBatchOrdersStep = createSelector(
  selectOffCycleBatchState,
  state => state.step,
);

export const selectOffCycleBatchOrdersValidationInfo = createSelector(
  selectOffCycleBatchState,
  (state) => state.validationInfo ?? null,
);

export const selectOffCycleBatchOrdersFormData = createSelector(
  selectOffCycleBatchState,
  (state) => state.form_data,
);

export const selectOffCycleBatchOrdersStatus = createSelector(
  selectOffCycleBatchState,
  state => state.status,
);

export const selectOffCycleBatchOrdersResult = createSelector(
  selectOffCycleBatchState,
  state => state.result,
);

export const selectOffCycleBatchOrdersMessage = createSelector(
  selectOffCycleBatchState,
  state => state.message,
);
