export interface IFlush {
  flushVolume: number;
  flushUofM: string;
  perUomName?: string;
  unitSystemId?: string;
  unitTypeId?: string;
  uoMPerDayAbbr?: string;
  uoMPerDayName?: string;
  uoMPluralName?: string;
  uomAbbr?: string;
  uomId?: string;
  uomName?: string;
}

export interface IProduct {
  ProductId: string;
  ProductName: string;
  DilutionPercentage: number;
  UofM: string;
  ProductType?: string;
  TargetVolume: number;
  TotalDeliveryVolume?: number;
  uomAbbr?: string;
  uomName?: string;
  uoMPluralName?: string;
}

export interface IComment {
  comment: string;
  timeStamp: string;
  authorName: string;
  authorId: string;
}

export interface IDeliveryProducts extends IProduct {
  TargetVolume: number;
  TotalDeliveryVolume?: number;
}

export const UNIT_CONVERSION = {
  gallon: {
    gallon: 1,
    quart: 0.25,
    pint: 0.125,
    cup: 0.06339948,
    'fluid ounce': 0.0078125,
    liter: 0.264200793,
    pound: 1,
  },
  quart: {
    gallon: 4,
    quart: 1,
    pint: 0.5,
    cup: 0.253613999,
    'fluid ounce': 0.03125,
    liter: 1.057,
    pound: 1,
  },
  pint: {
    gallon: 8,
    quart: 2,
    pint: 1,
    cup: 0.507099391,
    'fluid ounce': 0.0625,
    liter: 2.113,
    pound: 1,
  },
  cup: {
    gallon: 15.773,
    quart: 3.943,
    pint: 1.972,
    cup: 1,
    'fluid ounce': 0.123228589,
    liter: 4.167,
    pound: 1,
  },
  'fluid ounce': {
    gallon: 128,
    quart: 32,
    pint: 16,
    cup: 8.115,
    'fluid ounce': 1,
    liter: 33.814,
    pound: 1,
  },
  liter: {
    gallon: 3.785,
    quart: 0.946073794,
    pint: 0.473260767,
    cup: 0.239980802,
    'fluid ounce': 0.029573549,
    liter: 1,
    pound: 1,
  },
  pound: {
    gallon: 1,
    quart: 1,
    pint: 1,
    cup: 1,
    'fluid ounce': 1,
    liter: 1,
    pound: 1,
  },
  gallons: {
    gallons: 1,
    quart: 0.25,
    pint: 0.125,
    cup: 0.06339948,
    'fluid ounce': 0.0078125,
    liter: 0.264200793,
    pound: 1,
  },
};

export function convertUoM(from: string, to: string): number {
  return UNIT_CONVERSION[from][to];
}
