import { Injectable } from '@angular/core';
import { BaseEntityCollectionService } from '@apx-ui/apx-core';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { ContinuousOrderNotification } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class ContinuousOrderNotificationService extends BaseEntityCollectionService<ContinuousOrderNotification> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('ContinuousOrderNotifications', serviceElementsFactory);
  }

}
