import { createSelector } from '@ngrx/store';

import * as fromReducer from '../reducers';

export const selectSuspendContinuousTreatmentStatus = createSelector(
  fromReducer.selectSuspendState,
  state => state.saveContinuousTreatment,
);

export const selectSuspendBatchTreatmentStatus = createSelector(
  fromReducer.selectSuspendState,
  state => state.saveBatchTreatment,
);

export const selectSuspendAssetStatus = createSelector(
  fromReducer.selectSuspendState,
  state => state.saveAsset,
);

export const selectSuspendSampleStatus = createSelector(
  fromReducer.selectSuspendState,
  state => state.saveSample,
);

export const selectSuspendStep = createSelector(
  fromReducer.selectSuspendState,
  state => state.step,
);

export const selectCloseStatus = createSelector(
  fromReducer.selectSuspendState,
  state => state.close,
);
