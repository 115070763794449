import { EntitySelectorsFactory } from '@ngrx/data';
import { createSelector } from '@ngrx/store';

import { BatchOrder, ContinuousOrder, StimulationOrder } from '../../interfaces';
import { BatchOrderModel, ContinuousOrderModel, StimulationOrderModel } from '../../models';
import * as fromReducer from '../reducers';

export const continuousOrdersSelectors = new EntitySelectorsFactory().create<ContinuousOrder>('ContinuousOrder');
export const batchOrdersSelectors = new EntitySelectorsFactory().create<BatchOrder>('BatchOrder');
export const stimulationOrdersSelectors = new EntitySelectorsFactory().create<StimulationOrder>('StimulationOrder');

export const selectDeliveryList = createSelector(
  fromReducer.selectedDeliveryListState,
  state => state.orderIds,
);

export const selectDeliveryListStatus = createSelector(
  fromReducer.selectedDeliveryListState,
  state => state.status.save,
);

export const selectUnloadTruckStatus = createSelector(
  fromReducer.selectedDeliveryListState,
  state => state.status.unload,
);

export const selectAddToDeliveryStatus = createSelector(
  fromReducer.selectedDeliveryListState,
  state => state.status.append,
);

export const selectContinuousDeliveryList = createSelector(
  continuousOrdersSelectors.selectEntityMap,
  fromReducer.selectedDeliveryListState,
  (ordersMap, state) => state.orderIds
    .filter(id => ordersMap[id])
    .sort((a, b) => {
      const f = ordersMap[a].routeOrderIndex ?? 0;
      const s = ordersMap[b].routeOrderIndex ?? 0;

      return Number(f) - Number(s);
    })
    .map(id => new ContinuousOrderModel(ordersMap[id], [])),
);

export const selectBatchDeliveryList = createSelector(
  batchOrdersSelectors.selectEntityMap,
  fromReducer.selectedDeliveryListState,
  (ordersMap, state) => state.orderIds
    .filter(id => ordersMap[id])
    .sort((a, b) => {
      const f = ordersMap[a].routeOrderIndex ?? 0;
      const s = ordersMap[b].routeOrderIndex ?? 0;

      return Number(f) - Number(s);
    })
    .map(id => new BatchOrderModel(ordersMap[id], [])),
);

export const selectStimulationDeliveryList = createSelector(
  stimulationOrdersSelectors.selectEntityMap,
  fromReducer.selectedDeliveryListState,
  (ordersMap, state) => state.orderIds
    .filter(id => ordersMap[id])
    .map(id => new StimulationOrderModel(ordersMap[id], [])),
);
