<h1 mat-dialog-title>
  Custom {{dialogData}}
</h1>

<mat-dialog-content class="dialog-body">

  <div *ngIf="form" 
        fxLayout="column" 
        fxLayoutAlign="start start" 
        [formGroup]="form">

    <mat-form-field *ngIf="fieldNameCtrl as control" 
                    fxFlexFill 
                    fxFlex.gt-md 
                    appearance="outline">

      <mat-label>{{dialogData}}</mat-label>

      <input matInput 
             data-test-id="fieldName" 
             placeholder="{{dialogData}}" 
             type="number" 
             formControlName="FieldName">

      <mat-error *ngIf="control?.hasError('required')">
        Required
      </mat-error>
      <mat-error *ngIf="!control?.hasError('min') && control?.hasError('notInteger')">
        Invalid Integer
      </mat-error>
      <mat-error *ngIf="control?.hasError('min')">
        Minimum value is 1
      </mat-error>

    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">

  <button cancel 
          mat-stroked-button 
          type="button" 
          (click)="cancel()">
    Cancel
  </button>

  <button [disabled]="!form.valid" 
          submit 
          mat-flat-button 
          type="submit" 
          color="primary" 
          data-test-id="confirmAcBtn" 
          (click)="handleSave()">
    Add
  </button>

</mat-dialog-actions>