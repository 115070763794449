import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultPluralizer, EntityDefinitionService, Pluralizer } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DeliveryListGuard } from './guards/delivery-list.guard';
import { OrdersGuard } from './guards/orders.guard';
import { entityMetadataMap, pluralNames } from './store';
import { effects } from './store/effects';
import { featureName, reducers } from './store/reducers';
import { services } from './store/state';

@NgModule({
  imports: [
    CommonModule,

    StoreModule.forFeature(featureName, reducers),
    EffectsModule.forFeature(effects),
  ],
  providers: [
    ...services,
    DeliveryListGuard,
    OrdersGuard,
  ],
})
export class ApxOrdersStoreModule {

  constructor(
    pluralizer: Pluralizer,
    entityDefinitionService: EntityDefinitionService,
  ) {

    entityDefinitionService.registerMetadataMap(entityMetadataMap);
    (pluralizer as DefaultPluralizer).registerPluralNames(pluralNames);

  }

}
