import { ApxSolsticeWebNotificationClientService } from './apx-solstice-web-client.notification.service';
import { ApxSolsticeWebClientService } from './apx-solstice-web-client.service';
import { ApxSolsticeWebFileClientService } from './apx-solstice-web-client-file.service';

export const clients: any[] = [
  ApxSolsticeWebClientService,
  ApxSolsticeWebFileClientService,
  ApxSolsticeWebNotificationClientService,
];

export * from './apx-solstice-web-client.notification.service';
export * from './apx-solstice-web-client.service';
export * from './apx-solstice-web-client-file.service';
