import { Routes } from '@angular/router';
import { AccountGuard } from '@apx-ui/apx-account-store';
import { AccRoles, AppRoles } from '@apx-ui/apx-config';
import { PageContainerComponent } from '@apx-ui/apx-shared-ui';
import { RoleGuard } from '@apx-ui/apx-web-api-v1';

import { SideBarFieldTaskComponent, SignInCallBackComponent } from './components';
import * as fromContainers from './containers';
import { AccountSideBarComponent } from './containers';

export const routes: Routes = [
  {
    path: '',
    component: fromContainers.AppLayoutContainerComponent,
    children: [
      {
        path: 'error',
        component: fromContainers.AppErrorContainerComponent,
      },
      {
        path: 'authcallback',
        component: SignInCallBackComponent,
      },
      {
        path: 'postlogout',
        component: SignInCallBackComponent,
      },
      {
        path: 'landing',
        loadChildren: () => import('@apx-ui/apx-account').then(m => m.ApxAccountModule),
        canActivate: [RoleGuard],
        data: {
          roles: [
            AppRoles.SuperAdmin, AppRoles.Admin, AppRoles.Internal, AppRoles.External,
            AccRoles.SuperVisor, AccRoles.PowerUser, AccRoles.GeneralUser, AccRoles.PowerUserBI,
          ],
        },
      },
      {
        path: 'field-tasks',
        component: PageContainerComponent,
        canActivate: [RoleGuard],
        data: {
          leftSideBar: true,
          roles: [
            AppRoles.SuperAdmin, AppRoles.Admin, AppRoles.Internal, AppRoles.External,
            AccRoles.SuperVisor, AccRoles.PowerUser, AccRoles.GeneralUser, AccRoles.PowerUserBI,
          ],
        },
        children: [
          {
            path: '',
            component: SideBarFieldTaskComponent,
            outlet: 'sideBar',
            data: { preload: true },
          },
          {
            path: 'task-list',
            loadChildren: () => import('@apx-ui/apx-tasks').then(m => m.ApxTasksModule),
            canActivate: [RoleGuard],
            data: {
              roles: [
                AppRoles.SuperAdmin, AppRoles.Admin, AppRoles.Internal, AppRoles.GeneralUserFt, AppRoles.PowerUserFt,
                AccRoles.SuperVisor, AccRoles.PowerUser, AccRoles.PowerUserBI,
              ],
            },
          },
          {
            path: 'orders',
            loadChildren: () => import('@apx-ui/apx-orders').then(m => m.ApxOrdersModule),
          },
          {
            path: 'generate-label-ids',
            loadChildren: () => import('@apx-ui/apx-generate-label-ids').then(m => m.ApxGenerateLabelIdsModule),
          },
          {
            path: 'activity-log',
            loadChildren: () => import('@apx-ui/apx-report').then(m => m.ApxReportModule),
            data: { preload: true, fieldTask: true },
          },
          {
            path: 'support',
            loadChildren: () => import('@apx-ui/apx-support').then(m => m.ApxSupportModule),
            data: {
              preload: true,
              roles: [
                AppRoles.SuperAdmin, AppRoles.Admin, AppRoles.Internal,
                AccRoles.SuperVisor, AccRoles.PowerUser, AccRoles.GeneralUser, AccRoles.PowerUserBI,
              ],
            },
          },
          {
            path: 'assignments',
            loadChildren: () => import('@apx-ui/apx-delivery-planning').then(m => m.ApxDeliveryPlanningModule),
            data: {
              preload: true,
              roles: [
                AppRoles.SuperAdmin, AppRoles.Admin, AppRoles.Internal,
                AccRoles.SuperVisor, AccRoles.PowerUser, AccRoles.GeneralUser,
              ],
            },
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'task-list',
          },
        ],
      },
      {
        path: 'settings',
        loadChildren: () => import('@apx-ui/apx-user-settings').then(m => m.ApxUserSettingsModule),
        canActivate: [RoleGuard],
        data: {
          roles: [
            AppRoles.SuperAdmin, AppRoles.Admin, AppRoles.Internal, AppRoles.External,
            AccRoles.SuperVisor, AccRoles.PowerUser, AccRoles.GeneralUser, AccRoles.PowerUserBI,
          ],
        },
      },
      {
        path: ':accountId',
        component: PageContainerComponent,
        canActivate: [AccountGuard, RoleGuard],
        canDeactivate: [AccountGuard],
        data: {
          leftSideBar: true,
          roles: [
            AppRoles.SuperAdmin, AppRoles.Admin, AppRoles.Internal,
            AppRoles.External, AccRoles.SuperVisor, AccRoles.PowerUser, AccRoles.GeneralUser, AccRoles.PowerUserBI,
          ],
        },
        children: [
          {
            path: '',
            component: AccountSideBarComponent,
            outlet: 'sideBar',
            data: { preload: true },
          },
          {
            path: 'manage/assets',
            loadChildren: () => import('@apx-ui/apx-asset').then(m => m.ApxAssetModule),
            canActivate: [RoleGuard],
            data: {
              preload: true,
              roles: [
                AppRoles.SuperAdmin, AppRoles.Admin, AppRoles.Internal,
                AccRoles.SuperVisor, AccRoles.PowerUser, AccRoles.GeneralUser, AccRoles.PowerUserBI,
              ],
            },
          },
          {
            path: 'manage/kpi',
            loadChildren: () => import('@apx-ui/apx-kpi').then(m => m.ApxKpiModule),
            data: { preload: true },
          },
          {
            path: 'manage/contacts',
            loadChildren: () => import('@apx-ui/apx-contacts').then(m => m.ApxContactsModule),
            data: { preload: true },
          },
          {
            path: 'performance',
            canActivate: [RoleGuard],
            data: {
              roles: [
                AppRoles.SuperAdmin, AppRoles.Admin, AppRoles.Internal, AppRoles.External,
                AccRoles.SuperVisor, AccRoles.PowerUser, AccRoles.GeneralUser, AccRoles.ExternalUser,
                AccRoles.PowerUserBI, AccRoles.ExternalUserBI,
              ],
            },
            children: [
              {
                path: 'advanced-reports',
                loadChildren: () => import('@apx-ui/apx-advanced-reports').then(m => m.ApxAdvancedReportsModule),
              },
              {
                path: 'customization-reports',
                loadChildren: () => import('@apx-ui/apx-customization-reports')
                  .then(m => m.ApxCustomizationReportsModule),
              },
              {
                path: 'reports',
                loadChildren: () => import('@apx-ui/apx-report').then(m => m.ApxReportModule),
                canActivate: [RoleGuard],
                data: {
                  preload: true,
                  fieldTask: false,
                  roles: [
                    AppRoles.SuperAdmin, AppRoles.Admin, AppRoles.Internal, AppRoles.External,
                    AccRoles.SuperVisor, AccRoles.PowerUser, AccRoles.GeneralUser, AccRoles.ExternalUser,
                    AccRoles.PowerUserBI, AccRoles.ExternalUserBI,
                  ],
                },
              },
              {
                path: 'documents',
                loadChildren: () => import('@apx-ui/apx-documents').then(m => m.ApxDocumentsModule),
              },
              {
                path: '',
                redirectTo: 'reports',
                pathMatch: 'full',
              },
            ],
          },
          {
            path: 'support',
            loadChildren: () => import('@apx-ui/apx-support').then(m => m.ApxSupportModule),
            canActivate: [RoleGuard],
            data: {
              preload: true,
              roles: [
                AppRoles.SuperAdmin, AppRoles.Admin, AppRoles.Internal,
                AccRoles.SuperVisor, AccRoles.PowerUser, AccRoles.GeneralUser, AccRoles.PowerUserBI,
              ],
            },
          },
          {
            path: 'settings',
            loadChildren: () => import('@apx-ui/apx-settings').then(m => m.ApxSettingsModule),
            data: { preload: true },
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'manage/assets',
          },
        ],
      },
      {
        path: '**',
        redirectTo: 'landing',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },
];
