import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate, Router,
  UrlTree,
} from '@angular/router';
import { Actions } from '@ngrx/effects';
import { map, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UserStateService } from '../stores/states';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly userStateService: UserStateService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.userStateService.getAllRoles$().pipe(
      map(roles => {
        if (roles.some(role => route.data?.roles.indexOf(role) > -1)) {
          return true;
        }
        return this.router.createUrlTree(['/error'], { queryParams: { type: 503 } });
      }),
      catchError(() => {
        return of(
          this.router.createUrlTree(['/error'], {
            queryParams: { type: 503 },
          }),
        );
      }),
    );

  }
}
