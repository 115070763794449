import { Component, Input } from '@angular/core';
import { SampleNotification } from '@apx-ui/apx-notifications-store';

@Component({
  selector: 'apx-ui-notifications-summary-samples-desktop',
  templateUrl: './apx-notifications-summary-samples-desktop.component.html',
})
export class ApxNotificationsSummarySamplesDesktopComponent {
  @Input() notifications: SampleNotification[];
  displayedColumns: string[] = [
    'accountName',
    'locationName',
    'samplePoint',
    'expiredDayCount',
  ];
}
