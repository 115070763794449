import { Injectable } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import { OrderPartialUpdate } from '@apx-ui/apx-web-api-v1';
import { CaptureDeliveryData } from '@apx-ui/apx-web-api-v1';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { BatchOrder, ContinuousOrder, StimulationOrder, TaskOrder } from '../../interfaces';
import { OrderModels } from '../../models';
import { OrderActions } from '../actions';
import * as fromOrderSelectors from '../selectors/order.selector';

@Injectable()
export class OrderState {

  constructor(private readonly store: Store<any>) {
  }

  addComment(authorId: string,
             authorName: string,
             comment: string,
             order: TaskOrder,
             timeStamp?: string,
  ): void {
    this.store.dispatch(OrderActions.addComment({ authorId, authorName, comment, order, timeStamp }));
  }

  cancelOrder(authorId: string,
              authorName: string,
              comment: string,
              order: TaskOrder,
  ): void {
    this.store.dispatch(OrderActions.cancelOrder({ authorId, authorName, comment, order }));
  }

  cancelStimulationOrder(order: TaskOrder): void {
    this.store.dispatch(OrderActions.cancelStimulationOrder({ order }));
  }

  captureDeliveryOrder(order: OrderModels, captureData: CaptureDeliveryData, mode: 'edit' | 'report' | 'new'): void {
    this.store.dispatch(OrderActions.captureDeliveryOrder({ order, captureData, mode }));
  }

  captureOrder(order: OrderModels, captureData: CaptureDeliveryData, mode: 'edit' | 'report' | 'new'): void {
    this.store.dispatch(OrderActions.captureOrder({ order, captureData, mode }));
  }

  adjustCaptureOrder(order: OrderModels, captureData: CaptureDeliveryData): void {
    this.store.dispatch(OrderActions.adjustCaptureOrder({ order, captureData }));
  }

  orderApprove(order: StimulationOrder, volume: number): void {
    this.store.dispatch(OrderActions.approveOrder({ order, volume }));
  }

  cancelStimulationCompletedOrder(order: TaskOrder): void {
    this.store.dispatch(OrderActions.cancelStimulationCompletedOrder({ order }));
  }

  updateOrder(order: ContinuousOrder | BatchOrder, payload: OrderPartialUpdate): void {
    this.store.dispatch(OrderActions.partialUpdate({ order, payload }));
  }

  clearStatus(): void {
    this.store.dispatch(OrderActions.clearStatus());
  }

  getSaveStatus$(): Observable<Status> {
    return this.store.pipe(select(fromOrderSelectors.selectAddCommentStatus));
  }

}
