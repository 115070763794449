import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { AssetFilterDetails, AssetFormFilter, GenericFilter, SiteFilter } from '@apx-ui/apx-web-api-v1';
import { BehaviorSubject, distinctUntilChanged, noop, Subscription } from 'rxjs';

@Component({
  selector: 'apx-ui-shared-asset-filter',
  templateUrl: './asset-filter.component.html',
  styleUrls: ['./asset-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssetFilterComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AssetFilterComponent),
      multi: true,
    },
  ],
})
export class AssetFilterComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy, OnChanges {
  @Input() filters: GenericFilter;
  @Input() disableCustomTag: boolean;
  @Input() disableCustomer: boolean;
  @Input() showCustomer = true;

  @Output() valueChange = new EventEmitter<AssetFormFilter>();

  assetList$ = new BehaviorSubject<AssetFilterDetails[]>([]);
  sitesList$ = new BehaviorSubject<SiteFilter[]>([]);

  form: UntypedFormGroup;
  private formChangesSubscription?: Subscription;
  private innerValue: AssetFormFilter;
  private innerDisabled = false;
  private changeFn: (v: AssetFormFilter) => void = noop;
  private touchedFn: () => void = noop;

  constructor(
    private readonly fb: UntypedFormBuilder,
  ) {
  }

  @Input() set disabled(v: boolean | string) {
    const newValue = v === true || v === 'true';
    if (this.innerDisabled === newValue) {
      return;
    }
    this.innerDisabled = newValue;
    if (this.form) {
      this.setDisabledState(this.innerDisabled);
    }
  }

  @Input()
  set value(v: AssetFormFilter) {
    if (this.innerValue === v) {
      return;
    }
    this.innerValue = v;
    if (this.form) {
      const selectedSites = this.innerValue?.siteName?.map(s => s.SiteName) || [];
      const sites = this.filters.Asset.Site.filter(s => selectedSites.includes(s.SiteName));

      const selectedAssets = this.innerValue?.assetName?.map(a => a.AssetName) || [];
      const assets = this.filters.Asset.AssetNames.filter(a => selectedAssets.includes(a.AssetName));

      this.form.patchValue({ ...this.innerValue, siteName: sites, assetName: assets }, { emitEvent: false });
    }
  }

  get value(): AssetFormFilter {
    return this.innerValue;
  }

  get assetCtrl(): AbstractControl | null {
    return this.form.get('assetName');
  }

  get siteCtrl(): AbstractControl | null {
    return this.form.get('siteName');
  }

  get fieldCtrl(): AbstractControl | null {
    return this.form.get('fieldName');
  }

  get locationTypeCtrl(): AbstractControl | null {
    return this.form.get('locationType');
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      customerName: [{ value: null, disabled: this.disableCustomer }],
      fieldName: [],
      siteName: [],
      assetName: [],
      route: [],
      locationType: [],
      assetDescription: [],
      customTag: [{ value: [], disabled: this.disableCustomTag }],
    });

    this.formChangesSubscription = this.assetCtrl?.valueChanges.subscribe((assets: AssetFilterDetails[]) => {
      if (!assets) {
        return;
      }

      const fields = Object
        .values(assets.reduce((acc, asset) => ({ ...acc, [asset.FieldName]: asset.FieldName }), {}));

      const uniqueFields = new Set([...fields, ...this.fieldCtrl?.value ?? []]);

      const sites = Object
        .values(assets.reduce((acc, asset) => ({ ...acc, [asset.SiteName]: asset.SiteName }), {}));

      const sitesObjects = this.filters.Asset.Site.filter(s => sites.includes(s.SiteName));
      const uniqueSites = new Set([...sitesObjects, ...this.siteCtrl?.value ?? []]);

      this.fieldCtrl?.setValue(
        [...uniqueFields],
        { onlySelf: true, emitEvent: false },
      );
      this.siteCtrl?.setValue(
        [...uniqueSites],
        { onlySelf: true, emitEvent: false },
      );
    });

    this.formChangesSubscription.add(
      this.siteCtrl?.valueChanges.subscribe((sites: SiteFilter[]) => {
        if (!sites) {
          return;
        }

        const sitesNames = sites.map(s => s.SiteName);

        const filteredAssets =
          (this.assetCtrl?.value ?? []).filter(a => !sitesNames?.length || sitesNames.includes(a.SiteName));

        const fields = Object
          .values(sites.reduce((acc, site) => ({ ...acc, [site.FieldName]: site.FieldName }), {}));

        const uniqueFields = new Set([...fields, ...this.fieldCtrl?.value ?? []]);

        const selectedAssetNames = filteredAssets.map(a => a.AssetName) ?? [];
        const selectedAssets = this.filters.Asset.AssetNames
          .filter(a => selectedAssetNames.includes(a.AssetName));
        const assets = this.filters.Asset.AssetNames
          .filter(a => !sitesNames?.length || sitesNames.includes(a.SiteName));

        this.assetList$
          .next([...selectedAssets, ...assets]);

        this.assetCtrl?.setValue(filteredAssets, { onlySelf: true, emitEvent: false });
        this.fieldCtrl?.setValue(
          [...uniqueFields],
          { onlySelf: true, emitEvent: false },
        );
      }),
    );

    this.formChangesSubscription.add(
      this.fieldCtrl?.valueChanges.subscribe((fields: string[]) => {
        const filteredAssets =
          (this.assetCtrl?.value ?? []).filter(a => !fields?.length || fields.includes(a.FieldName));

        const filteredSites =
          (this.siteCtrl?.value ?? []).filter(a => !fields?.length || fields.includes(a.FieldName));

        const selectedAssetNames = filteredAssets.map(a => a.AssetName) ?? [];
        const selectedSiteNames = filteredSites.map(s => s.SiteName) ?? [];

        const selectedAssets = this.filters.Asset.AssetNames
          .filter(a => selectedAssetNames.includes(a.AssetName));
        const assets = this.filters.Asset.AssetNames
          .filter(a => !fields?.length || fields.includes(a.FieldName));

        this.assetList$.next([...selectedAssets, ...assets]);

        const selectedSites = this.filters.Asset.Site
          .filter(s => selectedSiteNames.includes(s.SiteName));
        const sites = this.filters.Asset.Site
          .filter(s => !fields?.length || fields.includes(s.FieldName));

        this.sitesList$
          .next([...selectedSites, ...sites]);

        this.assetCtrl?.setValue(filteredAssets, { onlySelf: true, emitEvent: false });
        this.siteCtrl?.setValue(filteredSites, { onlySelf: true, emitEvent: false });
      }),
    );

    this.formChangesSubscription?.add(this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe(formValue => {
        this.innerValue = formValue;

        this.touchedFn();
        this.changeFn(this.innerValue);
        this.valueChange.emit(this.innerValue);
      }),
    );
  }

  ngOnDestroy(): void {
    this.formChangesSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateData(changes);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid
      ? null
      : {
        assetFilterError: true,
      };
  }

  getAssetDescription(): string[] {
    return this.locationTypeCtrl?.value === 'Well'
      ? this.filters.Asset.WellType
      : this.filters.Asset.NonWellType;
  }

  private updateData(changes: SimpleChanges): void {
    if (!this.filters || !changes.filters) {
      return;
    }

    this.assetList$.next(this.filters.Asset.AssetNames);
    this.sitesList$.next(this.filters.Asset.Site);
  }

}
