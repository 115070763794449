import { Asset, AssetModel } from '@apx-ui/apx-web-api-v1';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import * as fromReducer from '../reducers';
import * as fromAssetReducer from '../reducers/assets.reducer';

const mapToModel = (assets: Dictionary<Asset>, id: any): AssetModel | null => (id && assets[id])
  ? new AssetModel(assets[id] as any)
  : null;

export const selectLoadAssetsStatus = createSelector(
  fromReducer.selectAssetState,
  state => state.status.load,
);

export const selectLoadAssetsForUserStatus = createSelector(
  fromReducer.selectAssetState,
  state => state.status.loadForUser,
);

export const selectAssetsForUser = createSelector(
  fromReducer.selectAssetState,
  state => state.assetsForUser,
);

export const selectPendingAssetsStatus = createSelector(
  fromReducer.selectAssetState,
  state => state.status.save,
);

export const selectLoadAssetsByIdStatus = createSelector(
  fromReducer.selectAssetState,
  state => state.status.loadAssetById,
);

export const selectAssetAll = createSelector(
  fromReducer.selectAssetState,
  fromAssetReducer.selectAssets,
);

export const selectAssetEntities = createSelector(
  fromReducer.selectAssetState,
  fromAssetReducer.selectAssetEntities,
);

export const selectCurrentAssetId = createSelector(
  fromReducer.selectAssetState, state => state.selectedAssetId,
);

export const selectAssets = createSelector(
  selectAssetAll, assets => assets ? assets.map(a => new AssetModel(a)) : [],
);

export const loadedAssets = createSelector(
  fromReducer.selectAssetState,
  selectAssetEntities,
  (state, e) =>
    state.assets && Object.keys(e).length > 0 ? state.assets.map(a => new AssetModel(e[a] as Asset)) : [],
);

export const selectCurrentAsset = createSelector(
  selectAssetEntities,
  selectCurrentAssetId,
  mapToModel,
);

export const selectAssetById = createSelector(
  selectAssetEntities,
  (
    assets: Dictionary<Asset>,
    { id }: any,
  ) => mapToModel(assets, id),
);
