import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import {
  ContinuousOrderNotification,
  ContinuousOrderNotificationService,
  NotificationStateService,
} from '@apx-ui/apx-notifications-store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  DialogData,
} from '../../apx-notifications-summary-container.component';

@Component({
  selector: 'apx-ui-notifications-summary-continuous-orders',
  templateUrl: './apx-notifications-summary-continuous.component.html',
  styleUrls: ['./apx-notifications-summary-continuous.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApxNotificationsSummaryContinuousComponent implements OnInit {
  @Input() data: DialogData;
  notifications$: Observable<ContinuousOrderNotification[]>;
  status$!: Observable<Status>;

  displayedColumns: string[] = [
    'isNonRoutineOrder',
    'accountName',
    'locationName',
    'tankName',
    'estDeliveryDate',
    'estDeliveryVolume',
    'uom',
  ];

  constructor(
    private readonly continuousOrderNotificationState: ContinuousOrderNotificationService,
    private readonly notificationState: NotificationStateService,
  ) {
  }

  ngOnInit(): void {
    this.notifications$ = this.continuousOrderNotificationState.getWithQuery(
      {
        checked_date: this.data.checked_date,
        unitSystemId: this.data.unitSystemId,
        ...this.data.account_id && { account_id: this.data.account_id },
      },
    ).pipe(
      tap(notifications => this.notificationState
        .markNotificationAsRead(notifications.map((n) => ({ id: n.id })), this.data.account_id),
      ),
    );

    this.status$ = this.continuousOrderNotificationState.status$;
  }
}
