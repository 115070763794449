import { Injectable } from '@angular/core';
import { Statuses } from '@apx-ui/apx-core';
import {
  OffCycleContinuousOrder,
  OffCycleContinuousOrderValidationInfo,
} from '@apx-ui/apx-web-api-v1';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import {
  CreateOffCycleContinuousOrderStep,
  OffCycleContinuousOrderFormData,
} from '../../interfaces';
import { OffCycleContinuousOrdersActions } from '../actions';
import {
  selectOffCycleContinuousOrdersFormData,
  selectOffCycleContinuousOrdersMessage,
  selectOffCycleContinuousOrdersResult,
  selectOffCycleContinuousOrdersStatus,
  selectOffCycleContinuousOrdersStep,
  selectOffCycleContinuousOrdersValidationInfo,
} from '../selectors';

@Injectable()
export class OffCycleContinuousOrdersService {

  close$: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store<any>) {
  }

  getStep$(): Observable<CreateOffCycleContinuousOrderStep> {
    return this.store.select(selectOffCycleContinuousOrdersStep);
  }

  getStatus$(): Observable<Statuses> {
    return this.store.select(selectOffCycleContinuousOrdersStatus);
  }

  getResult$(): Observable<OffCycleContinuousOrder> {
    return this.store.select(selectOffCycleContinuousOrdersResult);
  }

  getFormData$(): Observable<OffCycleContinuousOrderFormData> {
    return this.store.select(selectOffCycleContinuousOrdersFormData);
  }

  getValidationInfo$(): Observable<OffCycleContinuousOrderValidationInfo> {
    return this.store.select(selectOffCycleContinuousOrdersValidationInfo);
  }

  getMessage$(): Observable<string> {
    return this.store.select(selectOffCycleContinuousOrdersMessage);
  }

  getClose$(): Observable<boolean> {
    return this.close$;
  }

  setStep(step: CreateOffCycleContinuousOrderStep): void {
    this.store.dispatch(OffCycleContinuousOrdersActions.setStep({ step }));
  }

  saveCreateForm(order: OffCycleContinuousOrderFormData): void {
    this.store.dispatch(OffCycleContinuousOrdersActions.saveCreateForm({ order }));
  }

  saveConfirm(order: OffCycleContinuousOrderFormData): void {
    this.store.dispatch(OffCycleContinuousOrdersActions.saveConfirm({ order }));
  }

  destroy(): void {
    this.store.dispatch(OffCycleContinuousOrdersActions.destroy());
  }

  cleanStatus(): void {
    this.store.dispatch(OffCycleContinuousOrdersActions.cleanStatus());
  }

  validateOrder(locationId: string, tankId: string, injectionPoint: string, accountId: string): void {
    this.store.dispatch(OffCycleContinuousOrdersActions.validateOrder({
      locationId, tankId, injectionPoint, accountId }));
  }

}
