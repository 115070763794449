<form *ngIf="form"
      [formGroup]="form">

  <div fxLayout="column"
       fxLayoutAlign="start start">

    <p>Inventory in Calendar month</p>

    <mat-radio-group formControlName="inventory"
                     class="mb-20">
      <mat-radio-button color="primary"
                        class="mr-32"
                        data-test-id="well"
                        [value]="true">
        Captured
      </mat-radio-button>

      <mat-radio-button color="primary"
                        data-test-id="non-well"
                        class="mr-32"
                        [value]="false">
        Non Captured
      </mat-radio-button>

      <mat-radio-button color="primary"
                        data-test-id="non-well"
                        [value]="null">
        Both
      </mat-radio-button>
    </mat-radio-group>
    
    <apx-ui-shared-multi-select placeholder="Scheduled Samples"
                                formControlName="samples"
                                data-test-id="samples"
                                [items]="samples">
    </apx-ui-shared-multi-select>

  </div>
</form>
