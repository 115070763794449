import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAssetsReducer from './assets.reducer';
import * as fromCreateSampleReducer from './create-sample.reducer';
import * as fromFiltersReducer from './filters.reducer';
import * as fromSampleReducer from './sample.reducer';
import * as fromSuspendReducer from './suspend.reducer';

export const assetFeature = 'apx-assets-feature';

export interface State {
  asset: fromAssetsReducer.AssetState;
  createSample: fromCreateSampleReducer.CreateSampleState;
  sample: fromSampleReducer.SampleState;
  filters: fromFiltersReducer.AssetFilterStates;
  suspend: fromSuspendReducer.SuspendState,
}

export function reducers(state: State | undefined, action: Action): State {
  return combineReducers({
    asset: fromAssetsReducer.reducer,
    createSample: fromCreateSampleReducer.reducer,
    sample: fromSampleReducer.reducer,
    filters: fromFiltersReducer.reducer,
    suspend: fromSuspendReducer.reducer,
  })(state, action);
}

export const selectAssetFeature = createFeatureSelector<State>(assetFeature);

export const selectAssetState = createSelector(selectAssetFeature, state => state.asset);
export const selectCreateSampleState = createSelector(selectAssetFeature, state => state.createSample);
export const selectSampleState = createSelector(selectAssetFeature, state => state.sample);
export const selectFilterState = createSelector(selectAssetFeature, state => state.filters);
export const selectSuspendState = createSelector(selectAssetFeature, state => state.suspend);
