import { Account, AccountExtended, AccountFile } from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

export const addAccount = createAction(
  '[APX API] Add Account',
  props<{
    account: Account;
  }>(),
);

export const addAccounts = createAction(
  '[APX API] Add Accounts',
  props<{
    accounts: Account[];
  }>(),
);

export const setAccount = createAction(
  '[APX API] Set Account',
  props<{
    accountId: string;
    boot?: boolean;
  }>(),
);

export const setExtendedAccount = createAction(
  '[APX API] Set Extended Account',
  props<{
    account: AccountExtended;
  }>(),
);

export const deselectAccount = createAction(
  '[APX API] Deselect Account',
);

export const loadAccountFiles = createAction(
  '[APX API] Load Account Files',
  props<{
    accountId: string;
  }>(),
);

export const setAccountFiles = createAction(
  '[APX API] Set Account Files',
  props<{
    files: AccountFile[];
  }>(),
);
