import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import {
  NotificationStateService,
  SampleNotification,
  SampleNotificationService,
} from '@apx-ui/apx-notifications-store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  DialogData,
} from '../../apx-notifications-summary-container.component';

@Component({
  selector: 'apx-ui-notifications-summary-samples',
  templateUrl: './apx-notifications-summary-samples.component.html',
  styleUrls: ['./apx-notifications-summary-samples.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApxNotificationsSummarySamplesComponent implements OnInit {
  @Input() data: DialogData;
  notifications$: Observable<SampleNotification[]>;
  status$!: Observable<Status>;

  constructor(
    private readonly sampleNotificationState: SampleNotificationService,
    private readonly notificationState: NotificationStateService,
  ) { }

  ngOnInit(): void {
    this.notifications$ = this.sampleNotificationState.getWithQuery(
      {
        sub_category: this.data.sub_category,
        checked_date: this.data.checked_date,
        ...this.data.account_id && { account_id: this.data.account_id },
      },
    ).pipe(
      tap(notifications => this.notificationState
        .markNotificationAsRead(notifications.map((n) => ({ id: n.id })), this.data.account_id),
      ),
    );

    this.status$ = this.sampleNotificationState.status$;

  }
}
