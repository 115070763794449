import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { SuspendReason } from '@apx-ui/apx-web-api-v1';
import { distinctUntilChanged, noop, Subscription } from 'rxjs';

interface FormData {
  reason: string;
  comment: string;
}

@Component({
  selector: 'apx-ui-shared-reason-input',
  templateUrl: './reason-input.component.html',
  styleUrls: ['./reason-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReasonInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReasonInputComponent),
      multi: true,
    },
  ],
})
export class ReasonInputComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
  @Input() reasons: SuspendReason[];
  @Input() displayComment = true;
  @Output() valueChange = new EventEmitter<FormData>();

  OTHER_ID = '00000000-0000-0000-0000-000000000015';
  form: UntypedFormGroup;
  isOtherOption = false;
  private formChangesSubscription?: Subscription;
  private innerValue: FormData;
  private innerDisabled = false;
  private changeFn: (v: FormData) => void = noop;
  private touchedFn: () => void = noop;

  constructor(
    private readonly fb: UntypedFormBuilder,
  ) {
  }

  @Input() set disabled(v: boolean | string) {
    const newValue = v === true || v === 'true';
    if (this.innerDisabled === newValue) {
      return;
    }
    this.innerDisabled = newValue;
    if (this.form) {
      this.setDisabledState(this.innerDisabled);
    }
  }

  @Input()
  set value(v: any) {
    if (this.innerValue === v) {
      return;
    }
    this.innerValue = v;
    if (this.form) {
      this.form.patchValue({ ...this.innerValue }, { emitEvent: false });
    }
  }

  get value(): any {
    return this.innerValue;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      reason: [this.value?.reason?.Id, Validators.required],
      comment: [{ value: '', disabled: true }, Validators.required],
    });

    this.formChangesSubscription = this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe(formValue => {
        this.innerValue = { ...formValue };

        this.touchedFn();
        this.changeFn(this.innerValue);
        this.valueChange.emit(this.innerValue);
      });

    this.formChangesSubscription.add(
      this.reasonCtrl?.valueChanges.subscribe(value => {
        if (value === this.OTHER_ID) {
          this.commentCtrl?.enable();
          this.isOtherOption = true;
        } else {
          this.commentCtrl?.disable();
          this.isOtherOption = false;
        }
      }));
  }

  get reasonCtrl(): AbstractControl | null {
    return this.form.get('reason');
  }

  get commentCtrl(): AbstractControl | null {
    return this.form.get('comment');
  }

  ngOnDestroy(): void {
    this.formChangesSubscription?.unsubscribe();
  }

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid
      ? null
      : {
        reasonError: true,
      };
  }

}
