import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContinuousOrderNotification } from '@apx-ui/apx-notifications-store';

@Component({
  selector: 'apx-ui-notifications-summary-continuous-mobile',
  templateUrl: './apx-notifications-summary-continuous-mobile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApxNotificationsSummaryContinuousMobileComponent {
  @Input() notifications: ContinuousOrderNotification[];
  displayedColumns: string[] = [
    'icon',
    'location',
    'tank',
    'delivery',
  ];
}
