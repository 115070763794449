import { Injectable } from '@angular/core';
import { NotificationActions } from '@apx-ui/apx-shared-ui';
import { ApxSolsticeWebNotificationClientService } from '@apx-ui/apx-web-api-v1';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { NotificationStateService } from '../../services';
import { NotificationAction } from '../actions';

@Injectable()
export class NotificationEffects {

  handleCountNotificationLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NotificationAction.loadNotificationsCount,
      ),
      switchMap(({ accountId }) => {

        const params = { };

        if (accountId) {
          params['account_id'] = accountId;
        }

        return this.client.getCount({ params }).pipe(
          switchMap(count => {

            return [
              NotificationAction.loadNotificationsCountSuccess({ accountId, count }),
            ];
          }),
          catchError(err => of(NotificationAction.loadNotificationsCountFailure({ accountId, err }))),
        );
      }),
    ),
  );

  handleNotificationLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NotificationAction.loadNotifications,
      ),
      switchMap(({ accountId }) => {

        const params = { };

        if (accountId) {
          params['account_id'] = accountId;
        }

        return this.client.getNotifications({ params }).pipe(
          switchMap(notifications => {

            return [
              NotificationAction.loadNotificationsSuccess({ accountId, notifications }),
            ];
          }),
          catchError(err => of(NotificationAction.loadNotificationsFailure({ accountId, err }))),
        );
      }),
    ),
  );

  handleMarkNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NotificationAction.markNotifications,
      ),
      switchMap(({ ids, accountId }) => {

        return this.client.markAsRead(ids).pipe(
          switchMap(() => {

            this.notificationStateService.loadNotificationCount(accountId ?? '');

            return [
              NotificationAction.markNotificationsSuccess({ ids }),
            ];
          }),
          catchError(err => of(NotificationAction.markNotificationsFailure({ err }))),
        );
      }),
    ),
  );

  handleExportContinuousOrderNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NotificationAction.exportContinuousOrderNotifications,
      ),
      switchMap(({ checkedDate, accountId, unitSystemId }) => {
        const params = {
          unitSystemID: unitSystemId,
          ...accountId && { account_id: accountId },
        };
        return this.client.downloadConiOrderNotifications(checkedDate, { params } ).pipe(
          switchMap(document => {
            saveAs(new Blob([document.body]), document.headers.get('name'));
            return [
              NotificationActions.success({
                message: `File was successfully downloaded.`,
              }),
            ];
          }),
          catchError(err => {
            const decoder = new TextDecoder('utf-8');
            err.error = JSON.parse(decoder.decode(err.error));

            return of(
              NotificationActions.error({
                message: err.error?.errorMessage || `Cannot download file.`,
              }),
            );
          }),
        );
      }),
    ),
  );

  handleExportBatchOrderNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NotificationAction.exportBatchOrderNotifications,
      ),
      switchMap(({ checkedDate, accountId, unitSystemId }) => {
        const params = {
          unitSystemID: unitSystemId,
          ...accountId && { account_id: accountId },
        };
        return this.client.downloadBatchOrderNotifications(checkedDate, { params }).pipe(
          switchMap(document => {
            saveAs(new Blob([document.body]), document.headers.get('name'));
            return [
              NotificationActions.success({
                message: `File was successfully downloaded.`,
              }),
            ];
          }),
          catchError(err => {
            const decoder = new TextDecoder('utf-8');
            err.error = JSON.parse(decoder.decode(err.error));

            return of(
              NotificationActions.error({
                message: err.error?.errorMessage || `Cannot download file.`,
              }),
            );
          }),
        );
      }),
    ),
  );

  handleExportSamplesNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NotificationAction.exportSamplesNotifications,
      ),
      switchMap(({ subCategory, checkedDate, accountId }) => {
        const params = {
          ...accountId && { account_id: accountId },
        };
        return this.client.downloadSampleNotifications(subCategory, checkedDate, { params }).pipe(
          switchMap(document => {
            saveAs(new Blob([document.body]), document.headers.get('name'));
            return [
              NotificationActions.success({
                message: `File was successfully downloaded.`,
              }),
            ];
          }),
          catchError(err => {
            const decoder = new TextDecoder('utf-8');
            err.error = JSON.parse(decoder.decode(err.error));

            return of(
              NotificationActions.error({
                message: err.error?.errorMessage || `Cannot download file.`,
              }),
            );
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private readonly client: ApxSolsticeWebNotificationClientService,
    private readonly notificationStateService: NotificationStateService,
  ) {
  }

}
