<table mat-table
       class="w-100"
       [dataSource]="notifications">
  <ng-container matColumnDef="isNonRoutineOrder">
    <th *matHeaderCellDef
        mat-header-cell
        width="40px"></th>
    <td *matCellDef="let element"
        mat-cell>
      <mat-icon color="primary"
                class="pr-4"
                [svgIcon]="element.isNonRoutineOrder?'halOrderOffCycle':'halOrderScheduled'">
      </mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="accountName">
    <th *matHeaderCellDef
        mat-header-cell
        class="pr-12">
      Account
    </th>
    <td *matCellDef="let element"
        mat-cell
        class="text-truncate pr-12"
        [title]="element.accountName">
      {{element.accountName}}
    </td>
  </ng-container>

  <ng-container matColumnDef="locationName">
    <th *matHeaderCellDef
        mat-header-cell
        class="pr-12">
      Asset
    </th>
    <td *matCellDef="let element"
        mat-cell
        class="text-truncate pr-12"
        [title]="element.locationName">
      {{element.locationName}}
    </td>
  </ng-container>

  <ng-container matColumnDef="productName">
    <th *matHeaderCellDef
        mat-header-cell
        class="pr-12">
      Product
    </th>
    <td *matCellDef="let element"
        mat-cell
        class="text-truncate pr-12"
        [title]="element.productName">
      {{element.productName}}
    </td>
  </ng-container>

  <ng-container matColumnDef="injectionPoint">
    <th *matHeaderCellDef
        mat-header-cell
        class="pr-12">
      Injection Point
    </th>
    <td *matCellDef="let element"
        mat-cell
        class="pr-12">
      {{element.injectionPoint}}
    </td>
  </ng-container>

  <ng-container matColumnDef="estDeliveryDate">
    <th *matHeaderCellDef
        mat-header-cell
        width="120px"
        class="pr-12">
      Est Delivery Date
    </th>
    <td *matCellDef="let element"
        mat-cell
        class="pr-12">
      {{element.estDeliveryDate | date: 'MM/dd/yyyy'}}
    </td>
  </ng-container>

  <ng-container matColumnDef="estDeliveryVolume">
    <th *matHeaderCellDef
        mat-header-cell
        class="pr-12">
      Est Delivery Volume
    </th>
    <td *matCellDef="let element"
        mat-cell
        class="pr-12">
      {{element.estDeliveryVolume}}
    </td>
  </ng-container>

  <ng-container matColumnDef="uomName">
    <th *matHeaderCellDef
        mat-header-cell
        width="40px"
        class="pr-12">
      UoM
    </th>
    <td *matCellDef="let element"
        mat-cell
        class="pr-12">
      {{element.uomName}}
    </td>
  </ng-container>

  <tr *matHeaderRowDef="displayedColumns"
      mat-header-row></tr>
  <tr *matRowDef="let row; columns: displayedColumns;"
      mat-row></tr>
</table>
