import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'apx-ui-shared-search-immediately',
  templateUrl: './search-immediately.component.html',
  styleUrls: ['./search-immediately.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchImmediatelyComponent implements OnInit, OnDestroy, OnChanges {

  @Input() placeholder = 'Search';
  @Input() value: string | null = '';
  @Output() update = new EventEmitter<string>();

  form!: UntypedFormGroup;

  private subscriptions = new Subscription();

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      value: [this.value],
    });

    this.subscriptions.add(
      this.form.get('value').valueChanges
        .pipe(
          distinctUntilChanged(),
          debounceTime(150),
        )
        .subscribe(value => this.update.emit(value)),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateFormValue(changes);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private updateFormValue(changes: SimpleChanges): void {
    if (!changes['value'] || !this.form) {
      return;
    }

    this.form.patchValue({
      value: this.value || '',
    });
  }

}
