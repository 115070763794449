export interface ContactFilter {
  CompanyName: string[];
  JobTitle: string[];
}

export const contactFilterURLParams = [
  'companyName',
  'jobTitle',
  'crew',
];
