import { Injectable } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import { BatchTreatment, ContinuousTreatment, Sample, SuspendReasonForm } from '@apx-ui/apx-web-api-v1';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SuspendActions } from '../stores/actions';
import {
  selectCloseStatus,
  selectSuspendAssetStatus,
  selectSuspendBatchTreatmentStatus,
  selectSuspendContinuousTreatmentStatus, selectSuspendSampleStatus,
  selectSuspendStep,
} from '../stores/selectors';

@Injectable()
export class SuspendService {

  constructor(private readonly store: Store<any>) {
  }

  suspendContinuousTreatment(
    accountId: string,
    locationId,
    treatment: ContinuousTreatment,
    reasonForm: SuspendReasonForm,
    startAt: string,
    endAt: string,
    leaveOrdersAsPending: boolean,
    unitSystemId?: string,
  ): void {
    this.store.dispatch(
      SuspendActions.suspendContinuousTreatment({
        accountId, locationId, treatment, reasonForm, startAt, endAt, leaveOrdersAsPending, unitSystemId,
      }),
    );
  }

  suspendBatchTreatment(
    accountId: string,
    locationId,
    treatment: BatchTreatment,
    reasonForm: SuspendReasonForm,
    startAt: string,
    endAt: string,
    leaveOrdersAsPending: boolean,
    unitSystemId?: string,
  ): void {
    this.store.dispatch(
      SuspendActions.suspendBatchTreatment({
        accountId, locationId, treatment, reasonForm, startAt, endAt, leaveOrdersAsPending, unitSystemId,
      }),
    );
  }

  suspendAsset(
    accountId: string,
    locationId: string,
    reasonForm: SuspendReasonForm,
    startAt: string,
    endAt: string,
    leaveOrdersAsPending: boolean,
  ): void {
    this.store.dispatch(
      SuspendActions.suspendAsset({
        accountId, locationId, reasonForm, startAt, endAt, leaveOrdersAsPending,
      }),
    );
  }

  suspendSample(
    accountId: string,
    locationId: string,
    sample: Sample,
    reasonForm: SuspendReasonForm,
    startAt: string,
    endAt: string,
  ): void {
    this.store.dispatch(
      SuspendActions.suspendSample({
        accountId, locationId, sample, reasonForm, startAt, endAt,
      }),
    );
  }

  checkAsset(
    accountId: string,
    locationId: string,
    reasonForm: SuspendReasonForm,
    startAt: string,
    endAt: string,
  ): void {
    this.store.dispatch(SuspendActions.checkAsset({
      accountId, locationId, reasonForm, startAt, endAt,
    }));
  }

  checkBatchTreatment(
    accountId: string,
    locationId,
    treatment: BatchTreatment,
    reasonForm: SuspendReasonForm,
    startAt: string,
    endAt: string,
    unitSystemId?: string,
  ): void {
    this.store.dispatch(
      SuspendActions.checkBatchTreatment({
        accountId, locationId, treatment, reasonForm, startAt, endAt, unitSystemId,
      }),
    );
  }

  checkContinuousTreatment(
    accountId: string,
    locationId,
    treatment: ContinuousTreatment,
    reasonForm: SuspendReasonForm,
    startAt: string,
    endAt: string,
    unitSystemId?: string,
  ): void {
    this.store.dispatch(
      SuspendActions.checkContinuousTreatment({
        accountId, locationId, treatment, reasonForm, startAt, endAt, unitSystemId,
      }),
    );
  }

  destroy(): void {
    this.store.dispatch(SuspendActions.destroy());
  }

  suspendContinuousTreatmentState$(): Observable<Status> {
    return this.store.pipe(select(selectSuspendContinuousTreatmentStatus));
  }

  suspendBatchTreatmentState$(): Observable<Status> {
    return this.store.pipe(select(selectSuspendBatchTreatmentStatus));
  }

  suspendAssetState$(): Observable<Status> {
    return this.store.pipe(select(selectSuspendAssetStatus));
  }

  suspendSampleState$(): Observable<Status> {
    return this.store.pipe(select(selectSuspendSampleStatus));
  }

  getStep$(): Observable<any> {
    return this.store.select(selectSuspendStep);
  }

  getCloseStatus$(): Observable<boolean> {
    return this.store.select(selectCloseStatus);
  }

}
