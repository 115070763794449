import { ModuleWithProviders, NgModule } from '@angular/core';

import { cacheFactory } from './factories';
import { services } from './services';
import { CACHE } from './tokens';

@NgModule()
export class CacheModule {

  static forRoot(): ModuleWithProviders<CacheModule> {
    return {
      ngModule: CacheModule,
      providers: [
        { provide: CACHE, useFactory: cacheFactory },
        ...services,
      ],
    };
  }

}
