import { Account, AccountModel } from '@apx-ui/apx-web-api-v1';
import { createSelector } from '@ngrx/store';

import * as fromReducer from '../reducers';
import * as fromAccountReducer from '../reducers/account.reducer';

export const selectAccountAll = createSelector(
  fromReducer.selectAccountState,
  fromAccountReducer.selectAccounts,
);

export const selectAccountEntities = createSelector(
  fromReducer.selectAccountState,
  fromAccountReducer.selectAccountEntities,
);

export const selectCurrentAccountId = createSelector(
  fromReducer.selectAccountState, state => state.selectedAccountId,
);

export const selectCurrentExtendedAccount = createSelector(
  fromReducer.selectAccountState, state => state.extendedAccount,
);

export const selectCurrentExtendedAccountContacts = createSelector(
  selectCurrentExtendedAccount, state => state?.HumanResources,
);

export const selectCurrentAccountFiles = createSelector(
  fromReducer.selectAccountState, state => state.selectedAccountFiles,
);

export const selectAccounts = createSelector(
  selectAccountAll, accounts => accounts ? accounts.map(a => new AccountModel(a)) : [],
);

export const selectCurrentAccount = createSelector(
  selectCurrentAccountId,
  selectAccountEntities,
  (id, e) => id && e[id] ? new AccountModel(e[id] as Account) : null,
);
