import { Injectable } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import {
  Account,
  AccountExtended,
  Asset,
  AssetModel,
  BatchTreatment,
  ContinuousTreatment,
  KpiControlNames,
  StimulationTreatment,
  TREATMENT_TYPE,
} from '@apx-ui/apx-web-api-v1';
import { select, Store } from '@ngrx/store';
import { map, Observable, of } from 'rxjs';

import { AssetActions } from '../stores/actions';
import * as fromSelectors from '../stores/selectors';

@Injectable()
export class AssetService {

  constructor(private store: Store<never>) {
  }

  loadAssets(accountId: string, unitSystemID = ''): void {
    this.store.dispatch(AssetActions.loadAssets({ accountId, unitSystemID }));
  }

  loadAssetsForUser(): void {
    this.store.dispatch(AssetActions.loadAssetsForUser());
  }

  loadAssetById(assetId: string, unitSystemID = ''): void {
    this.store.dispatch(AssetActions.loadAssetById({ assetId, unitSystemID }));
  }

  setAsset(assetId: string): void {
    this.store.dispatch(
      AssetActions.setAsset({ assetId }),
    );
  }

  deselectAsset(): void {
    this.store.dispatch(
      AssetActions.deselectAsset(),
    );
  }

  clear(): void {
    this.store.dispatch(AssetActions.clear());
  }

  update(accountId: string, asset: Partial<Asset>): void {
    this.store.dispatch(AssetActions.updateWell({ accountId, well: asset }));
  }

  create(account: Account, asset: Partial<Asset>, wellType: string): void {
    this.store.dispatch(AssetActions.createWell({ account, well: asset, wellType }));
  }

  createNew(unitSystemId: string): Observable<AssetModel> {
    const newWell = {
      LocationType: 'Well',
      Status: '',
      SiteName: '',
      LocationDescription: '',
      UIDNumber: null,
      ShipTo: null,
      Latitude: null,
      Longitude: null,
      Id: null,
      Field: '',
      LocationName: '',
      APINumber: null,
      Samples: [],
      unitSystemId: unitSystemId,
    };

    return of(new AssetModel({ ...newWell } as any));
  }

  createNewTreatment(
    treatmentType: TREATMENT_TYPE,
    account: AccountExtended,
    asset: AssetModel,
  ): ContinuousTreatment | BatchTreatment | StimulationTreatment {
    switch (treatmentType) {
      case TREATMENT_TYPE.BATCH:
        return {
          Delta: account.KpiSectionA.Kpis.find(k => k.Name === KpiControlNames.BATCH_RATE_DELTA)?.Value ?? null,
          Variance: account.KpiSectionA.Kpis.find(k => k.Name === KpiControlNames.BATCH_RATE_VARIANCE)?.Value ?? null,
          Oil: asset.getOilProductionFluid(),
          Water: asset.getWaterProductionFluid(),
          Gas: asset.getGasProductionFluid(),
          productionFluidUoMKoef: asset.getProductionFluidUoMKoef(),
        } as any;
      case TREATMENT_TYPE.CONTINUOUS:
        return {
          Delta: account.KpiSectionA.Kpis.find(k => k.Name === KpiControlNames.PUMP_RATE_DELTA)?.Value ?? null,
          Variance: account.KpiSectionA.Kpis.find(k => k.Name === KpiControlNames.PUMP_RATE_VARIANCE)?.Value ?? null,
          Oil: asset.getOilProductionFluid(),
          Water: asset.getWaterProductionFluid(),
          Gas: asset.getGasProductionFluid(),
          productionFluidUoMKoef: asset.getProductionFluidUoMKoef(),
        } as any;
      default:
        return {} as any;
    }
  }

  getAssets$(): Observable<AssetModel[]> {
    return this.store.pipe(select(fromSelectors.loadedAssets));
  }

  getAssetsForUser$(): Observable<Asset[]> {
    return this.store.pipe(select(fromSelectors.selectAssetsForUser));
  }

  getStatus$(): Observable<Status> {
    return this.store.pipe(select(fromSelectors.selectLoadAssetsStatus));
  }

  getStatusLoadingForUser$(): Observable<Status> {
    return this.store.pipe(select(fromSelectors.selectLoadAssetsForUserStatus));
  }

  getPendingStatus$(): Observable<Status> {
    return this.store.pipe(select(fromSelectors.selectPendingAssetsStatus));
  }

  getLoadByIdStatus$(): Observable<Status> {
    return this.store.pipe(select(fromSelectors.selectLoadAssetsByIdStatus));
  }

  getAssetById$(id: string): Observable<AssetModel | null> {
    return this.store.pipe(select(fromSelectors.selectAssets)).pipe(
      map(assets => assets?.find(a => a.getId() === id) ?? null),
    );
  }

  getCurrentAsset(): Observable<AssetModel> {
    return this.store.pipe(select(fromSelectors.selectCurrentAsset));
  }

  getTreatmentFromAssetById$(assetId: string, treatmentId: string): Observable<ContinuousTreatment | null> {
    return this.store.pipe(
      select(fromSelectors.selectAssetById, { id: assetId }),
      map(asset => {
        return asset.continuousTreatments().find(t => `${t.Id}` === `${treatmentId}`);
      }),
    );
  }

}
