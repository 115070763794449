import { Injectable } from '@angular/core';

import { ClientBaseService } from './client-base.service';

@Injectable()
export class ClientFileService extends ClientBaseService {

  protected override getUrl(): string {
    const url = this.config.getEnvironment().api_file_url ?? null;

    if (!url) {
      // Throw an error since the application can not proceed any further.
      throw new Error(`API url is not defined.`);
    }

    return `${url}`;
  }

}
