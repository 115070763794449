import { HttpErrorResponse } from '@angular/common/http';
import { AccountFilter } from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

export const loadAccountFilters = createAction(
  '[APX Account Filters] Load',
);

export const loadAccountFiltersSuccess = createAction(
  '[APX Account Filters] Load Success',
  props<{
    filters: AccountFilter;
  }>(),
);

export const loadAccountFiltersFailure = createAction(
  '[APX Account Filters] Load Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const clearState = createAction(
  '[APX Account Filters] Clear State',
);
