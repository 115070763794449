export enum AppRoles {
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  Internal = 'Internal',
  External = 'External',
  PowerUserFt = 'PowerUserFt',
  GeneralUserFt = 'GeneralUserFt'
}

export enum AccRoles {
  ExternalUser = 'ExternalUser',
  GeneralUser = 'GeneralUser',
  PowerUser = 'PowerUser',
  SuperVisor = 'Supervisor',
  PowerUserBI = 'PowerUserBI',
  ExternalUserBI = 'ExternalUserBI',
  AccountCustomReportView = 'AccountCustomReportView'
}

export enum Permissions {
  // IFieldTasksAppPermissions
  FieldTasksViewNavButton = 'FieldTasksViewNavButton', // (accessFieldTasks)
  // --- // Changes the route of tasks button (header) to conti treatments
  FieldTasksViewServiceTasks = 'FieldTasksViewServiceTasks', // (viewServiceTasks)
  FieldTasksViewContiOrders = 'FieldTasksViewContiOrders', // (viewContinuousOrders) Not used
  FieldTasksViewBatchOrders = 'FieldTasksViewBatchOrders', // (viewBatchOrders) Not used
  FieldTasksViewStimOrders = 'FieldTasksViewStimOrders', // (viewStimulationOrders) Not used
  FieldTasksViewDeliveryList = 'FieldTasksViewDeliveryList', // (viewDeliveryList) Not used
  // Not implemented. Shows controls for selecting orders on batch/conti/stim treatments pages.
  FieldTasksSelectOrders = 'FieldTasksSelectOrders', // (selectOrders)
  // Not implemented. Adds 'Load trucks' button on treatments pages.
  FieldTasksLoadProduct = 'FieldTasksLoadProduct', // (loadProduct)
  // Not implemented. Adds 'Complete deliveries' button on delivery page.
  FieldTasksUnloadProduct = 'FieldTasksUnloadProduct', // (unloadProduct)
  // Not implemented. All rights for batch/conti/stim/deliveries.
  FieldTasksAllAccessOrders = 'FieldTasksAllAccessOrders', // (ordersAllAccess)
  FieldTasksAllAccessServiceTasks = 'FieldTasksAllAccessServiceTasks', // (serviceTasksAllAccess) Partly implemented.

  // ILandingPagePermissions
  LandingCreateAccount = 'LandingCreateAccount', // (createAccount) +
  LandingAddSoldTo = 'LandingAddSoldTo', // (addSoldTo) +
  LandingDeactivateAccount = 'LandingDeactivateAccount', // (deactivateAccount) Not implemented.
  LandingReactivateAccount = 'LandingReactivateAccount', // (reactivateAccount) Not implemented.

  // IAccPermissions
  AccountView = 'AccountView', // (viewAccount) +
  AccountViewDashboard = 'AccountViewDashboard', // (viewDashboard) +
  AccountViewManagement = 'AccountViewManagement', // (viewManagement) +
  AccountViewPerformance = 'AccountViewPerformance', // (viewPerformance) +
  AccountViewSupport = 'AccountViewSupport', // (viewSupport) +
  AccountViewSettings = 'AccountViewSettings', // (viewSettings) +
  AccountViewNotifications = 'AccountViewNotifications', // (viewNotifications) Not implemented.
  AccountViewCalendar = 'AccountViewCalendar', // (viewCalendar) Not implemented.
  AccountCreateNote = 'AccountCreateNote', // (createNote) +
  AccountViewKpiControls = 'AccountViewKpiControls', // (viewKpiControls) +
  AccountViewCustomizationReports = 'AccountViewCustomizationReports',
  AccountEditCustomizationReports = 'AccountEditCustomizationReports',
  // --- The kpi page is implemented but not merged to master.
  AccountEditKpiControls = 'AccountEditKpiControls', // (editKpiControls)
  AccountViewAllAssetsData = 'AccountViewAllAssetsData', // (viewAllAssetsData) --- Think to use roles for routes.
  // Partly. Edit dicts in settings?
  // show edit asset button, add treatment button in treatment list, view treatment details from treatments list,
  // add new asset, add new treatment, add sample
  AccountWriteAllAssetsData = 'AccountWriteAllAssetsData', // (writeAllAssetsData)
  AccountViewContacts = 'AccountViewContacts', // (viewContacts) Not implemented. Remove access from a contact.
  AccountCreateContact = 'AccountCreateContact', // (createContact) Not implemented.
  AccountEditContact = 'AccountEditContact', // (editContact) Not Implemented.
  AccountRemoveContact = 'AccountRemoveContact', // (removeContact) Not implemented.
  AccountRequestAccessForContact = 'AccountRequestAccessForContact', // (requestAccessForContact) Not used.
  AccountApproveAccessForContact = 'AccountApproveAccessForContact', // (approveAccessForContact) Not implemented.
  AccountSyncCrew = 'AccountSyncCrew', // (syncCrew) Not used.
  AccountSaveChart = 'AccountSaveChart', // (saveChart) Not used.
  AccountRemoveBatchTreatment = 'AccountRemoveBatchTreatment', // (removeBatchTreatment) +
  AccountRemoveContinuousTreatment = 'AccountRemoveContinuousTreatment', // (removeContinuousTreatment) +
  AccountRemoveStimTreatments = 'AccountRemoveStimTreatments', // (removeStimTreatments) Not implemented.
  AccountSuspendContinuousTreatment = 'AccountSuspendContinuousTreatment',
  ApproveChangesKpi = 'ApproveChangesKpi',

  // IServiceTaskAccPermissions
  ServiceTaskAccountViewInventories = 'ServiceTaskAccountViewInventories', // (viewInventories) +
  ServiceTaskAccountViewSamples = 'ServiceTaskAccountViewSamples', // (viewSamples) Not used.
  ServiceTaskCaptureInventory = 'ServiceTaskCaptureInventory', // (captureInventory) Not used (?).
  ServiceTaskCaptureSample = 'ServiceTaskCaptureSample', // (captureSample) Not used.

  // IOrderAccPermissions
  OrderAccountViewOrderStatus = 'OrderAccountViewOrderStatus', // (viewOrderStatus) Not implemented.
  OrderAccountViewOrderTargetVolume = 'OrderAccountViewOrderTargetVolume', // (viewOrderTargetVolume) Not implemented.
  OrderAccountEditPendingOrder = 'OrderAccountEditPendingOrder', // (editPendingOrder) Not implemented.
  OrderAccountEditCompletedOrder = 'OrderAccountEditCompletedOrder', // (editCompletedOrder) Not implemented.
  OrderAccountCancelOrder = 'OrderAccountCancelOrder', // (cancelOrder)
  OrderAccountCreateNonRoutineOrder = 'OrderAccountCreateNonRoutineOrder', // (createNonRoutineOrder) Not used
  OrderAccountViewOrderComments = 'OrderAccountViewOrderComments', // (viewOrderComments) Not implemented.
  OrderAccountCreateOrderComment = 'OrderAccountCreateOrderComment', // (createOrderComment) Not used
  OrderAccountBypassDeliveryCapture = 'OrderAccountBypassDeliveryCapture', // (bypassDeliveryCapture) Not implemented.
  OrderAccountDeliveryCapture = 'OrderAccountDeliveryCapture', // (deliveryCapture) Not implemented.
  OrderAccountSelectOrder = 'OrderAccountSelectOrder', // (selectOrder) Not implemented.

  // documentsPermissions
  DocumentsShowAccess = 'DocumentsShowAccess', // (selectOrder) Not implemented.
  DocumentsUpdateItems = 'DocumentsUpdateItems', // (selectOrder) Not implemented.
  // Off cycle orders
  OffCycleOrderAdd = 'OffCycleOrderAdd', // Add off cycle order.
  //Contacts
  ContactsUpdateItems = 'ContactsUpdateItems', // (selectOrder) Not implemented.
  ContactsGrantAccessItems = 'ContactsGrantAccessItems',
  //notifications
  NotificationsView = 'NotificationsView', // (showNotificationButton)
  //samples
  SamplesEdit = 'SamplesEdit' // (showNotificationButton)
}
