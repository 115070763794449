import { Status } from '@apx-ui/apx-core';
import { AccountPageConfig } from '@apx-ui/apx-web-api-v1';
import { createReducer, on } from '@ngrx/store';

import { PageConfigActions } from '../actions';

export interface PageConfigState {
  accountId: string | null;
  pageConfig: AccountPageConfig | null;
  status: Status;
}

export const initialState: PageConfigState = {
  accountId: null,
  pageConfig: null,
  status: {
    pending: false,
    resolved: false,
    rejected: false,
    err: null,
  },
};

export const reducer = createReducer(
  initialState,
  on(PageConfigActions.loadPageConfig, state => ({
      ...state,
      status: {
        ...state.status,
        pending: true,
        err: null,
      },
    }),
  ),
  on(PageConfigActions.loadPageConfigSuccess,
    (state, { accountId, pageConfig }) => ({
        accountId,
        pageConfig,
        status: {
          pending: false,
          resolved: true,
          rejected: false,
          err: null,
        },
      }
    ),
  ),
  on(PageConfigActions.loadPageConfigFailure,
    (state, { err }) => ({
      ...state,
      customers: [],
      status: {
        ...state.status,
        pending: false,
        rejected: true,
        err,
      },
    }),
  ),
  on(PageConfigActions.clearState,
    (state) => ({
      ...state,
      ...initialState,
    }),
  ),
);
