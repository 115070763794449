import { AbstractModel } from '@apx-ui/apx-core';

import { Tank } from '../interfaces';

export const TANK_CAPACITY_COEFFICIENT = 1.1;

export class TankModel extends AbstractModel<Tank> {

  constructor(protected override data: Tank) {
    super(data);
  }

  getId(): string {
    return this.data.Id;
  }

  getTankName(): string {
    return this.data.TankName;
  }

  getTankCapacity(): number {
    return this.data.TankCapacity;
  }

  getTankMaxFill(): number {
    return this.data.TankMaxFill;
  }

  getOrderMethod(): string {
    return this.data.createOrderBySchedule ? 'Frequency' : 'Tank inventory';
  }

  getDisplayName(): string {
    return this.data.PrimaryAssetName ? `${this.data.TankName} @ ${this.data.PrimaryAssetName}` : this.data.TankName;
  }

  getUoms(): {uoMPerDayAbbr: string, uomName: string} {
    return {
      uoMPerDayAbbr: this.data.uoMPerDayAbbr,
      uomName: this.data.uomName,
    };
  }
}
