import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { OrdersForDeliveryActions } from '../store/actions';
import { OrdersForDeliveryState } from '../store/state';

@Injectable()
export class DeliveryListGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly ordersForDeliveryState: OrdersForDeliveryState,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    this.ordersForDeliveryState.loadActiveDelivery();

    return this.actions$.pipe(
      ofType(OrdersForDeliveryActions.loadOrdersForDeliverySuccess),
      switchMap(() => this.ordersForDeliveryState.isDeliveryActive$()),
      map((active: boolean) => {
        if (active) {
          return true;
        }
        return this.router.createUrlTree(['/field-tasks/orders/continuous']);
      }),
      catchError(() => {
        return of(
          this.router.createUrlTree(['/error'], {
            queryParams: { type: 503 },
          }),
        );
      }),
    );
  }
}
