import { Injectable } from '@angular/core';
import { ApxSolsticeWebClientService } from '@apx-ui/apx-web-api-v1';
import { EntityActionFactory, EntityOp } from '@ngrx/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { CreateOffCycleContinuousOrderStep, entityTypes } from '../../interfaces';
import { OffCycleContinuousOrdersActions } from '../actions';

@Injectable()
export class OffCycleContinuousOrdersEffects {
  handleLoadContinuousTreatments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OffCycleContinuousOrdersActions.validateOrder),
      switchMap(({ locationId, tankId, injectionPoint, accountId }) => {
        return this.client
          .getContinuousOrderOffCycleValidationInfo(locationId, tankId, injectionPoint, {
            headers: {
              accounts: accountId,
            },
          })
          .pipe(
            switchMap((validationInfo) => {
              const payload = { locationId, tankId, injectionPoint, validationInfo };

              return validationInfo.errors?.length
                ? [
                    OffCycleContinuousOrdersActions.validateOrderFailure(payload),
                    OffCycleContinuousOrdersActions.failure({ message: validationInfo.errors?.join(' ') }),
                  ]
                : [
                    OffCycleContinuousOrdersActions.validateOrderSuccess(payload),
                    OffCycleContinuousOrdersActions.setStep({ step: CreateOffCycleContinuousOrderStep.Confirm }),
                  ];
            }),
            catchError((err) =>
              of(
                OffCycleContinuousOrdersActions.validateOrderFailure({ locationId, tankId, injectionPoint, err }),
                OffCycleContinuousOrdersActions.failure({
                  message: Object.values(err.error?.FieldErrors || [])?.join(' ') || err.message,
                }),
              ),
            ),
          );
      }),
    );
  });

  handleSaveConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OffCycleContinuousOrdersActions.saveConfirm),
      switchMap(({ order }) => {
        return this.client
          .createOffCycleContinuousOrders(order, {
            headers: {
              accounts: order.accountId,
            },
            params: {
              unitSystemID: order.unitSystemId,
            },
          })
          .pipe(
            switchMap((res) => {
              return [
                this.entityActionFactory.create(entityTypes['Continuous'], EntityOp.QUERY_MANY, {
                  unitSystemID: order.unitSystemId,
                }),
                OffCycleContinuousOrdersActions.success({ orderData: res }),
              ];
            }),
            catchError((err) => {
              return of(
                OffCycleContinuousOrdersActions.failure({
                  message: Object.values(err.error?.FieldErrors || [])?.join(' ') || err.message,
                }),
              );
            }),
          );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private client: ApxSolsticeWebClientService,
    private readonly entityActionFactory: EntityActionFactory,
  ) {}
}
