<form data-test-id="searchForm"
      [formGroup]="form">

  <mat-form-field appearance="outline"
                  class="no-space">
    <input data-test-id="searchInput"
           type="search"
           formControlName="value"
           matInput
           [placeholder]="placeholder">
    <button type="submit"
            mat-icon-button
            matSuffix>
      <mat-icon matSuffix>search</mat-icon>
    </button>
  </mat-form-field>

</form>
