import { AbstractModel } from '@apx-ui/apx-core';

import { CustomTag, StimulationJob, TankOnTreatment, Task } from '../interfaces';
import { SampleModel } from './sample.model';

export class TaskModel extends AbstractModel<Task> {

  private samples!: SampleModel[];

  constructor(protected override data: Task) {
    super(data);
    this.samples = data.samples.map(s => new SampleModel(s));
  }

  getAccountId(): string {
    return this.data.accountId;
  }

  getAccountRoles(): string[] {
    return this.data.accountRoles;
  }

  getCustomTags(): string[] {
    return this.data.customTags;
  }

  getAggregatedCustomTags(): CustomTag[] {
    return this.data.aggregatedCustomTags;
  }

  getActiveStimJobs(): StimulationJob[] {
    return this.data.activeStimJobs;
  }

  isAlert(): boolean {
    return this.data.alert;
  }

  getAssetDescription(): string {
    return this.data.assetDescription;
  }

  getAssetType(): string {
    return this.data.assetType;
  }

  getCustomerName(): string {
    return this.data.customerName;
  }

  getFieldName(): string {
    return this.data.fieldName;
  }

  isWell(): boolean {
    return this.data.isWell;
  }

  getLocationId(): string {
    return this.data.locationId;
  }

  getLocationName(): string {
    return this.data.locationName;
  }

  getProductDetails(): any {
    return this.data.productDetails;
  }

  getProductName(): string[] {
    return this.data.productName;
  }

  getProductType(): string[] {
    return this.data.productType;
  }

  getRoute(): string {
    return this.data.route;
  }

  getRouteOrderIndex(): number {
    return this.data.routeOrderIndex;
  }

  getRoutePlantOrderIndex(): number {
    return this.data.routePlantOrderIndex;
  }

  getSamples(): SampleModel[] {
    return this.data.samples
      .sort((a, b) => a.AnalysisType?.localeCompare(b.AnalysisType))
      .map(s => new SampleModel(s));
  }

  getSite(): string {
    return this.data.site;
  }

  getTanksOnTreatment(): TankOnTreatment[] {
    return this.data.tanksOnTreatment || [];
  }

  getInjectionPoints(): string[] {
    return this.getTanksOnTreatment()?.map(t => t.treatmentInjectionPoint)
      .reduce((acc: string[], currentValue) => {
        const injectionPoints = currentValue?.map(i => i.injectionPoint) || [];
        return [...acc, ...injectionPoints];
      }, []) ?? [];
  }

  getAnalysisTypeList(): string[] {
    return Array.from(new Set(this.samples.map(s => s.getAnalysisIcon())));
  }

  countProductsOfActiveStimJobs(): number {
    return this.getActiveStimJobs()?.reduce((acc, currentValue) => {
      return acc + (currentValue.products?.length || 0);
    }, 0) || 0;
  }

  countTanksOnTreatment(): number {
    return this.getTanksOnTreatment()?.length || 0;
  }
}
