import { HttpErrorResponse } from '@angular/common/http';
import { Manager } from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

export const loadManagers = createAction(
  '[APX Managers] Load',
);

export const loadManagersSuccess = createAction(
  '[APX Managers] Load Success',
  props<{
    managers: Manager[];
  }>(),
);

export const loadManagersFailure = createAction(
  '[APX Managers] Load Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const addManager = createAction(
  '[APX Managers] Add Manager',
  props<{
    manager: Manager;
  }>(),
);

export const addManagers = createAction(
  '[APX Managers] Add Managers',
  props<{
    managers: Manager[];
  }>(),
);
