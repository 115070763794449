import { Injectable } from '@angular/core';
import { BaseEntityCollectionService } from '@apx-ui/apx-core';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { SampleNotification } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class SampleNotificationService extends BaseEntityCollectionService<SampleNotification> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('SampleNotifications', serviceElementsFactory);
  }

}
