import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApxAssetStoreModule } from '@apx-ui/apx-asset-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { guards } from './guards';
import { effects } from './stores/effects';
import { accountFeature, reducers } from './stores/reducers';
import { states } from './stores/states';

@NgModule({
  imports: [
    CommonModule,

    // Ngrx Store Imports.
    StoreModule.forFeature(accountFeature, reducers),

    // Ngrx Effects Imports.
    EffectsModule.forFeature(effects),
    ApxAssetStoreModule,
  ],
  providers: [
    ...states,
    ...guards,
  ],
})
export class ApxAccountStoreModule {
}
