import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import moment from 'moment';

export const APX_NUM_MAX = 1000000;
export const APX_NUM_MIN = -2147483648;
export const APX_PERCENT_MIN = -100;
export const APX_PERCENT_MAX = 100;
export const APX_RATE_DELTA_MIN = -10000;
export const APX_RATE_DELTA_MAX = 10000;
export const APX_ROUNDED_DECIMAL_POINT_MAX = 4;
export const TANK_PRODUCTS_NUMBER = 5;

function customAllowedValidator(reg: RegExp, error: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const notValid = control.value ? !reg.test(control.value) : false;
    return notValid ? { [error]: { value: control.value } } : null;
  };
}

function customForbiddenValidator(reg: RegExp, error: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const notValid = control.value ? reg.test(control.value) : false;
    return notValid ? { [error]: { value: control.value } } : null;
  };
}

export function noEmptyValidator(): ValidatorFn {
  return customForbiddenValidator(/^[ \t\n]*$/, 'noEmpty');
}

export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control?.value?.toString().startsWith(' ') || control?.value?.toString().endsWith(' ')) {
      return { foundWhitespace: true };
    } else {
      return null;
    }
  };
}

export function noSpecialCharactersValidator(): ValidatorFn {
  return customForbiddenValidator(/[!@#$%^&*(),.?":{}|<>]/, 'foundSpecialCharacters');
}

export function noSpecialCharactersValidatorForWellName(): ValidatorFn {
  return customForbiddenValidator(/[!%^*?{}|<>]/, 'foundSpecialCharacters');
}

export function alphanumericalValidator(): ValidatorFn {
  return customAllowedValidator(/^([a-zA-Z0-9 _-]+)$/, 'notAlphaNumerical');
}

export function integerValidator(): ValidatorFn {
  return customAllowedValidator(/^\d+$/, 'notInteger');
}

export function coordinateValidator(): ValidatorFn {
  return customAllowedValidator(/^-?[0-9]\d*(\.\d{1,8})?$/, 'notCoordinate');
}

// Control value >= 0.00..0
export function positiveNumericalValidator(): ValidatorFn {
  return customAllowedValidator(/^\d*(\.?\d*)?$/, 'negativeNumerical');
}

export function noExtraSpecialCharactersValidator(): ValidatorFn {
  return customAllowedValidator(/^([a-zA-Z0-9 "@.,#&();:_-]+)$/, 'foundSpecialCharacters');
}

export function twoDecimalPointValidator(): ValidatorFn {
  // return customAllowedValidator(/^\d+(\.\d{1,2})?$/, 'notTwoDecimalPoint');
  return customAllowedValidator(/^[0-9]?\d*(\.?\d{1,2})?$/, 'notTwoDecimalPoint');
}

export function fourDecimalPointValidator(): ValidatorFn {
  return customAllowedValidator(/^[0-9]?\d*(\.?\d{1,4})?$/, 'notFourDecimalPoint');
}

export function fourDecimalPointNegativeValidator(): ValidatorFn {
  return customAllowedValidator(/^(-)?\d+(\.\d{1,4})?$/, 'notFourDecimalNegativePoint');
}

export function signedTwoPointDecimalValidator(): ValidatorFn {
  // return customAllowedValidator(/^-?[0-9]\d*(\.\d{1,2})?$/, 'notSignedTwoDecimalPointValue');
  return customAllowedValidator(/^-?\d*(\.?\d{1,2})$/, 'notSignedTwoDecimalPointValue');
}

// Control value is valid if it is a signed integer value (negatives allowed)
export function signedIntegerValidator(): ValidatorFn {
  return customAllowedValidator(/^-?\d+$/, 'notSignedInteger');
}

export function durationIn60MinsAndSecs(): ValidatorFn {
  return customAllowedValidator(/^([0-5][0-9]:(?:[0-5][0-9]))$/, 'durationIn60MinsAndSecs');
}

export function barCodeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const extractedYear = String(control.value)?.substring(0, 4);

    if (extractedYear) {
      const valid = new Date('2023') <= new Date(extractedYear);

      return valid ? null: { invalidBarCode: true };
    }

    return null;

  };
}

export function equalValidator(dateField: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if(!control.parent || !(dateField in control.parent.controls)) {
      return null;
    }

    if(control.parent.get(dateField).value === control.value) {

      return { equal: true };
    }

    return null;
  };
}

export function timeFutureValidator(dateField: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if(!control.parent || !(dateField in control.parent.controls)) {
      return null;
    }

    if(control.parent.get(dateField).value) {
      const currentDate = moment(new Date());
      const selectedDate = moment(control.parent.get('sampleDate').value);
      const selectedTime = moment(`${currentDate.format('YYYY-MM-DD')  } ${  control.value}`).toDate();
      const diffDate = currentDate.diff(selectedDate, 'days');
      const diffTime = currentDate.diff(selectedTime, 'minutes');

      if(diffDate > 0 || diffTime > -1) {
        return null;
      }

      return { invalidDate: true };
    }

    return null;
  };
}

export function threeDecimalPointValidator(): ValidatorFn {
  return customAllowedValidator(/^[0-9]?\d*(\.?\d{1,3})?$/, 'notThreeDecimalPoint');
}

export function requiredIfValidator(fieldName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control?.parent || !(fieldName in control.parent.controls)) {
      return null;
    }
    return !control.value && control?.parent?.get(fieldName).value ? Validators.required(control) : null;
  };
}

export function tankCapacityValidator(fieldName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control?.parent || !(fieldName in control.parent.controls)) {
      return null;
    }
    return control.value > control?.parent?.get(fieldName).value ? { outOfCapacity: true } : null;
  };
}

export function dateNotPastValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if(!control.value) {
      return null;
    }

    const today = moment(new Date()).format('MM/DD/YYYY');
    const day = moment(control.value).format('MM/DD/YYYY');

    if(moment(day).diff(today, 'days') >= 0) {
      return null;
    }

    return { invalidDate: true };
  };
}

export function calcMaxValidator(dateField: string, maxValue: number, coefficient: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if(!control.parent || !(dateField in control.parent.controls)) {
      return null;
    }

    if(control.value > maxValue * coefficient[control.parent.get(dateField).value]) {
      return { minValue: true };
    }

    return null;
  };
}

export function calcMaxCapacityValidator(maxValue: number, coefficient: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {

    return control.value > maxValue * coefficient ? { maxCapacity: true } : null;
  };
}

export function differentLabelIdValidator(siblingFieldName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent) { return null; }

    const currentValue = control.value;
    const siblingField = control.parent.get(siblingFieldName)?.value;

    return currentValue !== siblingField.labelId ? null : { plantIdsAreEqual: true };
  };
}

export function differentLabelIdFromDifferentFormsValidator(otherControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control || !control?.value || !otherControl?.value) { return null; }
    return control.value === otherControl.value ? { plantIdsAreEqual: true } : null;
  };
}

export function isValueInlistValidator(list: string[], error = 'valueNotExistInTheList'): ValidatorFn {
  return (columnName: AbstractControl): ValidationErrors | null => {
    return list.includes(columnName.value) ?  null : { [error]: true } ;
  };
}

export function maxLabelIdValueValidator(maxValue: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlsValue = +control.value;
    return controlsValue > maxValue ? {  maxLabelIdValue: true } : null;
  };
}
