import { Tank } from '@apx-ui/apx-web-api-v1';

export const TANK_FIXTURES: Tank[] = [
  {
    'Id':'6192fc8480d9b82a38afac82',
    'AccountId':'5f1e12c13b01321b4cfdb8b5',
    'SensorId':null,
    'Height':0,
    'Width':0,
    'Length':0,
    'TankCapacity':6000,
    'TankType':null,
    'TankName':'2K7 SOLUTION W-M ᛫ 130',
    'Latitude':0,
    'Longitude':0,
    'IsMeasurementAtTop':false,
    'OrderNotificationVolume':30,
    'CriticalVolumeThreshold':15,
    'MaximumFillVolume':0,
    'PrimaryAssetId':'6176ea3c80d9b810f0a1a2ec',
    'PrimaryAssetName':'0-test-1-3-1_2',
    'TankDisplayName': '',
    'TankProducts':[{ 'ProductId':'610d45a302243a4f58673500', 'DilutionAmount':100, 'DilutionUnitOfMeasurement':'%' }],
    'CustomTags':[],
    'InActive':false,
    'packageIds':['60ff2a1980d9b819d41bffd3'],
    'orderStartDateTimeUtc':null,
    'orderFrequencyByDays':null,
    'createOrderBySchedule':false,
    'sharedTankDataList':[
      { 'locationId':'60b7c11a971c353c946a9734', 'locatinName':'0001QA2', 'injectionPoint':'Capillary' },
      { 'locationId':'6176ea3c80d9b810f0a1a2ec', 'locatinName':'0-test-1-3-1_2', 'injectionPoint':'Backside/Annular' },
      { 'locationId':'6176ea3c80d9b810f0a1a2ec', 'locatinName':'0-test-1-3-1_2', 'injectionPoint':'Capillary' },
      {
        'locationId':'617be79880d9c1013c6d7e69',
        'locatinName':'0-test-1-3-1_2_a2',
        'injectionPoint':'Backside/Annular',
      },
    ],
  },
];
