import { Injectable } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import { Sample } from '@apx-ui/apx-web-api-v1';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { SampleActions } from '../stores/actions';
import * as fromSamplesSelectors from '../stores/selectors/sample.selectors';

@Injectable()
export class SampleState {

  close$: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store<never>) {
  }

  create(sample: Sample, accountId: string): void {
    this.store.dispatch(SampleActions.createSample({ sample, accountId }));
  }

  update(sample: Sample, accountId: string): void {
    this.store.dispatch(SampleActions.updateSample({ sample, accountId }));
  }

  delete(sample: Sample, accountId: string, count: number): void {
    this.store.dispatch(SampleActions.deleteSample({ sample, accountId, count }));
  }

  suspend(sample: Sample): void {
    this.store.dispatch(SampleActions.suspendSample({ sample }));
  }

  getSamplesSave$(): Observable<Status> {
    return this.store.pipe(select(fromSamplesSelectors.selectSampleStatus));
  }
}
