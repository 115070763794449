import { Account, AssetModel, Sample } from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

import {
  CreateSampleFormData,
  CreateSampleStep, OperationType,
} from '../../interfaces';

export const setStep = createAction(
  '[APX Create Sample Dialog] Set Step',
  props<{
    step: CreateSampleStep;
  }>(),
);

export const initForm = createAction(
  '[APX Create Sample Dialog] Init Form',
  props<{
    asset: AssetModel;
    operationType: OperationType;
    formData: CreateSampleFormData;
  }>(),
);

export const saveCreateForm = createAction(
  '[APX Create Sample Dialog] Create Form',
  props<{
    formData: CreateSampleFormData;
  }>(),
);

export const saveConfirm = createAction(
  '[APX Create Sample Dialog] Save Confirm',
);

export const updateConfirm = createAction(
  '[APX Create Sample Dialog] Update Confirm',
  props<{
    formData: CreateSampleFormData;
    account: Account | null;
  }>(),
);

export const success = createAction(
  '[APX Create Sample Dialog] Success',
  props<{
    samples: Sample[];
  }>(),
);

export const failure = createAction(
  '[APX Create Sample Dialog] Failure',
  props<{
    message: string;
  }>(),
);

export const destroy = createAction(
  '[APX Create Sample Dialog] Destroy',
);
