<mat-form-field appearance="outline"
                class="no-space">
  <mat-label>Unit system</mat-label>
  <mat-select [(value)]="value">
    <mat-option *ngFor="let unitSystem of unitSystems"
                [value]="unitSystem.unitSystemId"
                (click)="changeUS(unitSystem.name)">
      {{unitSystem.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
