export interface UomInterface {
  abbr: string;
  baseUOM: string;
  id: string;
  koef: number;
  name: UomNames;
  isDefault?: boolean;
  unitSystemId?: string;
  unitTypeId?: string;
  uoMPerDayAbbr?: string;
  uoMPluralName?: string;
}

export enum UomNames {
  GALLONS = 'gallons',
  QUARTS = 'quarts',
  LITERS = 'liters',
  METRIC = 'Metric',
  IMPERIAL = 'Imperial'
}
