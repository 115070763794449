import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { removeNullValuesAndEmptyArrays } from '@apx-ui/apx-core';
import { ApxSolsticeWebClientService, LabelData } from '@apx-ui/apx-web-api-v1';
import { catchError, map, Observable, of, switchMap } from 'rxjs';

import { UIDialogService } from '../dialog/dialog.service';

@Injectable()
export class ReportValidatorsService {

  constructor(
    private readonly apxSolsticeWebClientService: ApxSolsticeWebClientService,
    private dialog: UIDialogService,
  ) {}

  asyncMaxLabelIdValueValidator(params: any): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const plantId = control.value?.substring(0, 4);

      if (plantId) {
        return this.apxSolsticeWebClientService.getMaxLabelId(plantId).pipe(
          map(([labelsConfig])  => {
            if (!labelsConfig) {
              return null;
            }
            const labelsConfigValue = labelsConfig.FullMaxLabelId.split('-');
            const maxNumber = parseInt(labelsConfigValue[1]);
            const labelId = control.value?.substring(5);

            if (parseInt(labelId) > maxNumber) {
              params.data.maxLabel = true;
              return { maxLabelIdValue: true };
            }

            return null;
          }),
          catchError(() => {
            params.data.maxLabel = true;
            return of(null);
          }),
        );
      }
      return of(null);
    };
  }

  isUsedLabelIdValidator(
    params: any,
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const plantId = control.value?.substring(0, 4);
      const labelId = control.value?.substring(5);

      const sampleType = {
        Type: params.data.ScheduledSample,
        Category: params.category,
        SubCategory: params.data.SampleMethod,
      };

      const payload : LabelData = {
        LabelId: labelId,
        PlantId: plantId,
        SampleType: sampleType,
        Samplepoint: params.data.SamplePoint,
        LocationId: params.data.locationId,
        AccountId: params.data.accountId,
      };

      return this.apxSolsticeWebClientService.validateLabelId(removeNullValuesAndEmptyArrays(payload)).pipe(
        switchMap((labelData) => {
          if (labelData.ShowModalWindow && labelData.IsValid) {
            return this.dialog.proceedSample(labelData.ErrorMessage, true)
              .afterClosed()
              .pipe(
                map(res => {
                  return res
                    ? labelData
                    : { ErrorMessage: 'The Sample Points do not match', IsValid: false };
                }),
              );
          }

          if (labelData.ShowModalWindow && !labelData.IsValid) {
            return this.dialog.proceedSample(labelData.ErrorMessage, false)
            .afterClosed()
            .pipe(
              map(() => {
                return { ErrorMessage: 'Incorrect Label ID', IsValid: false };
              }),
            );
          }

          if (!labelData.IsValid) {
            params.data.incorrectLabel = true;
          }

          return of(labelData);
        }),
        map(labelData => {
          return labelData.IsValid ? null : { incorrectLableId: labelData.ErrorMessage };
        }),
        catchError(() => {
          params.data.incorrectLabel = true;
          return of(null);
        }),
      );

    };
  }
}
