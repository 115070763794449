import { Status } from '@apx-ui/apx-core';
import { createReducer, on } from '@ngrx/store';

import { SampleActions } from '../actions';

export interface SampleState {
  status: Status,
}

const initialState: SampleState = {
  status: {
    pending: false,
    resolved: false,
    rejected: false,
    err: null,
  },
};

export const reducer = createReducer(
  initialState,
  on(SampleActions.createSample,
    SampleActions.updateSample,
    SampleActions.deleteSample,
    SampleActions.suspendSample, state => ({
      ...state,
      status: {
        pending: true,
        resolved: false,
        rejected: false,
        err: null,
      },
    }),
  ),
  on(SampleActions.createSampleSuccess,
    SampleActions.updateSampleSuccess,
    SampleActions.deleteSampleSuccess,
    SampleActions.suspendSampleSuccess, state => ({
      ...state,
      status: {
        pending: false,
        resolved: true,
        rejected: false,
        err: null,
      },
    }),
  ),
  on(SampleActions.createSampleFailure,
    SampleActions.updateSampleFailure,
    SampleActions.deleteSampleFailure,
    SampleActions.suspendSampleFailure, state => ({
      ...state,
      status: {
        pending: false,
        resolved: false,
        rejected: true,
        err: null,
      },
    }),
  ),
);
