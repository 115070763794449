export enum TIME_ZONES {
  CST = 'Central Standard Time', // consider the daytime saving time.
  EST = 'Eastern Standard Time', // consider the daytime saving time.
  MST = 'Mountain Standard Time', // consider the daytime saving time.
  PST = 'Pacific Standard Time', // consider the daytime saving time.
  IST = 'India Standard Time',
  UTC = 'Coordinated Universal Time',
  CDT = 'Central Daylight Time',
  EDT = 'Eastern Daylight Time',
  MDT = 'Mountain Daylight Time',
  PDT = 'Pacific Daylight Time'
}

export const TIME_ZONES_SHORT = {
  [TIME_ZONES.UTC]: 'UTC',
  [TIME_ZONES.IST]: 'Asia/Calcutta', // IST is not accepted
  [TIME_ZONES.CST]: 'CST6CDT', // consider the daytime saving time.
  [TIME_ZONES.EST]: 'EST5EDT', // consider the daytime saving time.
  [TIME_ZONES.MST]: 'MST7MDT', // consider the daytime saving time.
  [TIME_ZONES.PST]: 'PST8PDT', // consider the daytime saving time.
  [TIME_ZONES.CDT]: 'CST6CDT', // consider the daytime saving time.
  [TIME_ZONES.EDT]: 'EST5EDT', // consider the daytime saving time.
  [TIME_ZONES.MDT]: 'MST7MDT', // consider the daytime saving time.
  [TIME_ZONES.PDT]: 'PST8PDT', // consider the daytime saving time.
};

export function convertFullTimezoneToShort(full: string): string {
  return TIME_ZONES_SHORT[full];
}
