import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {
  FieldTasksFormFilter,
  GenericFilter,
} from '@apx-ui/apx-web-api-v1';
import { distinctUntilChanged, noop, Subscription } from 'rxjs';

@Component({
  selector: 'apx-ui-shared-task-filter',
  templateUrl: './task-filter.component.html',
  styleUrls: ['./task-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaskFilterComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TaskFilterComponent),
      multi: true,
    },
  ],
})
export class TaskFilterComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
  @Input() filters: GenericFilter;
  @Input() disableTank: boolean;
  @Input() disableFrequencies: boolean;

  @Output() valueChange = new EventEmitter<FieldTasksFormFilter>();

  form: UntypedFormGroup;
  private formChangesSubscription?: Subscription;
  private innerValue: FieldTasksFormFilter;
  private innerDisabled = false;
  private changeFn: (v: FieldTasksFormFilter) => void = noop;
  private touchedFn: () => void = noop;

  constructor(
    private readonly fb: UntypedFormBuilder,
  ) {
  }

  @Input() set disabled(v: boolean | string) {
    const newValue = v === true || v === 'true';
    if (this.innerDisabled === newValue) {
      return;
    }
    this.innerDisabled = newValue;
    if (this.form) {
      this.setDisabledState(this.innerDisabled);
    }
  }

  @Input()
  set value(v: FieldTasksFormFilter) {
    if (this.innerValue === v) {
      return;
    }
    this.innerValue = v;
    if (this.form) {
      const selectedTanks = this.innerValue?.tank?.map(p => p.TankId) || [];
      const tanks = this.filters?.FieldTask?.Tank?.filter(p => selectedTanks.includes(p.TankId)) || [];

      this.form.patchValue({ ...this.innerValue, tank: tanks }, { emitEvent: false });
    }
  }

  get value(): FieldTasksFormFilter {
    return this.innerValue;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      injectionPoint: [],
      tank: [{ value: null, disabled: this.disableTank }],
      frequencies: [{ value: null, disabled: this.disableFrequencies }],
    });

    this.formChangesSubscription = this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe(formValue => {
        this.innerValue = formValue;

        this.touchedFn();
        this.changeFn(this.innerValue);
        this.valueChange.emit(this.innerValue);
      });
  }

  ngOnDestroy(): void {
    this.formChangesSubscription?.unsubscribe();
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid
      ? null
      : {
        assetFilterError: true,
      };
  }
}
