export interface FileInfo {
  id: string;
  uuid?: string;
  parentFolderId: string;
  type?: ALLOWED_FILE_TYPE;
  name: string;
  sizeBites?: number,
  isDeleted?: boolean;
  createdBy?: string;
  createdAt?: Date;
  updatedBy?: string;
  updatedAt?: Date;
  selected?: boolean;
  tags?: Tag[];
  tagIds?: string[];
  version: string;
  accessLevel: string;
}

export interface FolderInfo {
  id: string;
  name: string;
  isRoot: boolean;
  totalCount?: number;
  parentFolderId: string;
  items?: FileInfo[];
}

export interface Tag {
  id: string;
  name: string;
  count?: number;
}

export interface PopoverTagComponent {
  tags: Tag[];
  index: number;
  showDeleteOption: boolean;
}
export interface DeletedTag {
  tagId: string;
  index: number;
}

export enum ALLOWED_FILE_TYPE {
  FOLDER = 'folder',
  DOCX = 'docx',
  XLSX = 'xlsx',
  CSV = 'csv',
  PDF = 'pdf',
  PPTX = 'pptx',
  JPG = 'jpg',
  PNG = 'png'
}

export const ALLOWED_FILE_EXTENSIONS = [
  ALLOWED_FILE_TYPE.DOCX,
  ALLOWED_FILE_TYPE.XLSX,
  ALLOWED_FILE_TYPE.CSV,
  ALLOWED_FILE_TYPE.PDF,
  ALLOWED_FILE_TYPE.PPTX,
  ALLOWED_FILE_TYPE.JPG,
  ALLOWED_FILE_TYPE.PNG,
];

export const VERSIONS = {
  Preliminary: 'Preliminary',
  Final: 'Final',
};

export const ACCESS_LEVEL = {
  InternalOnly: 'Internal Only',
  External: 'External',
};
