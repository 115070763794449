<mat-card>
  <div class="mb-12 tagsHeadDiv">
    Tags 
    <div class="position-absolute tag-close">
      <button mat-dialog-close
              mat-icon-button
              aria-label="close"
              data-test-id="transferOutCloseBtn"
              (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <mat-chip-list>
    <mat-chip *ngFor="let chip of data.tags">
      {{chip.Tag}}
    </mat-chip>
  </mat-chip-list>
</mat-card>
