import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RouterStateService, Status } from '@apx-ui/apx-core';
import { NotificationStateService } from '@apx-ui/apx-notifications-store';
import { AccountUnitSystemService, UserUnitSystemService } from '@apx-ui/apx-user-settings-store';
import { Notifications, UnitSystems } from '@apx-ui/apx-web-api-v1';
import { filter, Observable, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'apx-ui-notifications-list-container',
  templateUrl: './apx-notifications-list-container.component.html',
  styleUrls: ['./apx-notifications-list-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApxNotificationsListContainerComponent implements OnInit {
  notifications$: Observable<Notifications>;
  count$!: Observable<number>;
  status$!: Observable<Status>;
  accountId$: Observable<string>;
  userUnitSystem$!: Observable<UnitSystems[]>;
  accountUnitSystem$!: Observable<UnitSystems>;

  constructor(
    private readonly dialogRef: MatDialogRef<ApxNotificationsListContainerComponent>,
    private readonly notificationState: NotificationStateService,
    private readonly routeState: RouterStateService,
    private readonly userUnitSystemService: UserUnitSystemService,
    private readonly accountUnitSystemService: AccountUnitSystemService,
  ) {
  }

  ngOnInit(): void {
    this.accountId$ = this.routeState.getParam$('accountId')
      .pipe(tap(accountId => {
          this.notificationState.loadNotification(accountId ?? '');
          this.notificationState.loadNotificationCount(accountId ?? '');
      }),
    );

    this.accountUnitSystem$ = this.routeState.getParam$('accountId').pipe(
      filter(accountId => !!accountId),
      switchMap(accountId => this.accountUnitSystemService.getByKey(accountId)));

    this.userUnitSystem$ = this.userUnitSystemService.getAll();

    this.notifications$ = this.notificationState.getNotifications$();
    this.count$ = this.notificationState.getNotificationCount$();

    this.status$ = this.notificationState.getNotificationStatus$();
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  reportUrl(accountId: string): string {
    return accountId
      ? `/${ accountId }/performance/reports/notification-log`
      : `/field-tasks/activity-log/notification-log`;
  }

  getCount(countNotifications: number): string {
    const count = countNotifications.toString();

    return countNotifications < 1000 ? count : `..${count.slice(count.length - 2, count.length)}`;
  }
}
