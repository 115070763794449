/* eslint-disable no-console */
import { LicenseManager } from '@ag-grid-enterprise/core';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// eslint-disable-next-line max-len
LicenseManager.setLicenseKey('CompanyName=SHI International Corp_on_behalf_of_Halliburton Company,LicensedApplication=APX,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-017367,ExpiryDate=1_March_2023_[v2]_MTY3NzYyODgwMDAwMA==985aca2784c82428d11e9344c72c6c6a');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
