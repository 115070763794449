import { ModuleWithProviders, NgModule } from '@angular/core';

import { services } from './services';
import { ENVIRONMENT } from './tokens';

@NgModule()
export class ApxConfigModule {

  static forRoot(environment: any): ModuleWithProviders<ApxConfigModule> {
    return {
      ngModule: ApxConfigModule,
      providers: [
        ...services,
        { provide: ENVIRONMENT, useValue: environment },
      ],
    };
  }

}
