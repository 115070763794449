import { createSelector } from '@ngrx/store';

import * as fromReducer from '../reducers';

export const selectAccountFiltersStatus = createSelector(
  fromReducer.selectAccountFiltersState,
  state => state.status,
);

export const selectAccountFilters = createSelector(
  fromReducer.selectAccountFiltersState,
  state => state.filters,
);
