export enum CreateAccountStep {
  Create = 'create',
  Edit = 'edit',
  Pending = 'pending',
  Confirm = 'confirm',
  Success = 'success',
  Failure = 'failure'
}

export enum OperationType {
  CreateAccount = 'create',
  EditAccount = 'edit'
}
