import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationStateService } from '@apx-ui/apx-notifications-store';
import moment from 'moment';

export interface DialogData {
  category: string,
  sub_category: string,
  checked_date: string,
  account_id: string,
  unitSystemId: string,
}

@Component({
  selector: 'apx-ui-notifications-summary-container',
  templateUrl: './apx-notifications-summary-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApxNotificationsSummaryContainerComponent {

  constructor(
    private readonly dialogRef: MatDialogRef<ApxNotificationsSummaryContainerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly notificationStateService: NotificationStateService,
  ) { }

  onExport(): void {
    const date = moment(this.data.checked_date).format();

    switch (this.data.sub_category) {

      case 'Continuous Order': {
        this.notificationStateService.exportContinuousOrderNotifications(
          date, this.data.account_id, this.data.unitSystemId);
        break;
      }

      case 'Batch Order': {
        this.notificationStateService.exportBatchOrderNotifications(
          date, this.data.account_id, this.data.unitSystemId);
        break;
      }

      default:
        this.notificationStateService.exportSamplesNotifications(this.data.sub_category, date, this.data.account_id);
        break;
    }

  }
}
