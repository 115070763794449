import { createSelector } from '@ngrx/store';

import { selectCreateAccountState } from '../reducers';

export const selectCreateAccountStep = createSelector(
  selectCreateAccountState,
  state => state.step,
);

export const selectCreateAccountFormData = createSelector(
  selectCreateAccountState,
  (state) => state.form_data,
);

export const selectEditedAccount = createSelector(
  selectCreateAccountState,
  (state) => state.account,
);

export const selectOperationType = createSelector(
  selectCreateAccountState,
  (state) => state.operationType,
);

export const selectCreateAccountResult = createSelector(
  selectCreateAccountState,
  state => state.result,
);

export const selectCreateAccountMessage = createSelector(
  selectCreateAccountState,
  state => state.message,
);
