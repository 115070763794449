import { Injectable } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import { PageConfigModel } from '@apx-ui/apx-web-api-v1';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { PageConfigActions } from '../actions';
import * as fromPageConfigSelectors from '../selectors/page-config.selectors';

@Injectable()
export class PageConfigStateService {

  constructor(private readonly store: Store<never>) {
  }

  loadPageConfig(accountId: string): void {
    this.store.dispatch(
      PageConfigActions.loadPageConfig({ accountId }),
    );
  }

  clear(): void {
    this.store.dispatch(
      PageConfigActions.clearState(),
    );
  }

  getStatus$(): Observable<Status> {
    return this.store.pipe(
      select(fromPageConfigSelectors.selectPageConfigStatus),
    );
  }

  getPageConfig$(): Observable<PageConfigModel | null> {
    return this.store.pipe(
      select(fromPageConfigSelectors.selectPageConfig),
    );
  }

}
