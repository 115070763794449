import { Status } from '@apx-ui/apx-core';
import { AccountFilter } from '@apx-ui/apx-web-api-v1';
import { createReducer, on } from '@ngrx/store';

import { AccountFiltersActions } from '../actions';

export interface accountFiltersState {
  filters: AccountFilter | null;
  status: Status;
}

export const initialState: accountFiltersState = {
  filters: null,
  status: {
    pending: false,
    resolved: false,
    rejected: false,
    err: null,
  },
};

export const reducer = createReducer(
  initialState,
  on(AccountFiltersActions.loadAccountFilters, state => ({
      ...state,
      status: {
        ...state.status,
        pending: true,
        err: null,
      },
    }),
  ),
  on(AccountFiltersActions.loadAccountFiltersSuccess,
    (state, { filters }) => ({
        filters: filters,
        status: {
          pending: false,
          resolved: true,
          rejected: false,
          err: null,
        },
      }
    ),
  ),
  on(AccountFiltersActions.loadAccountFiltersFailure,
    (state, { err }) => ({
      ...state,
      filters: null,
      status: {
        ...state.status,
        pending: false,
        rejected: true,
        err,
      },
    }),
  ),
  on(AccountFiltersActions.clearState,
    (state) => ({
      ...state,
      status: {
        pending: false,
        resolved: false,
        rejected: false,
        err: null,
      },
    }),
  ),
);
