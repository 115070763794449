<mat-form-field fxFlexFill
                fxFlex.gt-md
                appearance="outline"
                class="no-space right-margin">

  <mat-label>Enter a date</mat-label>

  <mat-date-range-input [rangePicker]="picker">

    <input matStartDate
            [formControl]="fromSelectCtrl"
            placeholder="Start date">
    <input matEndDate
            [formControl]="toSelectCtrl"
            placeholder="End date">

  </mat-date-range-input>

  <mat-datepicker-toggle matSuffix
                          [for]="picker">
  </mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
