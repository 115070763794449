import { HttpErrorResponse } from '@angular/common/http';
import { Plant } from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

export const loadPlants = createAction(
  '[APX Plants] Load',
);

export const loadPlantsSuccess = createAction(
  '[APX Plants] Load Success',
  props<{
    plants: Plant[];
  }>(),
);

export const loadPlantsFailure = createAction(
  '[APX Plants] Load Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const clearState = createAction(
  '[APX Plants] Clear State',
);

export const addPlant = createAction(
  '[APX Plants] Add Plant',
  props<{
    plant: Plant;
  }>(),
);

export const addPlants = createAction(
  '[APX Plants] Add Plants',
  props<{
    plants: Plant[];
  }>(),
);
