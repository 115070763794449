import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef, HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { noop, Subject } from 'rxjs';

@Component({
  selector: 'apx-ui-shared-range-input',
  templateUrl: './range-input.component.html',
  styleUrls: ['./range-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MatFormFieldControl, useExisting: RangeInputComponent },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RangeInputComponent),
      multi: true,
    },
  ],
})
export class RangeInputComponent<T extends Record<string, unknown> | string>
  implements ControlValueAccessor, Validator, OnInit {

  @HostBinding('class.floated')
  get shouldLabelFloat(): boolean {
    return true;
  }

  /**
   * Emits an event when value changes.
   */
  @Output() valueChange = new EventEmitter<T[]>();

  /**
   * Sets the value
   */
  @Input() set value(v: T[]) {
    if (this.value === v) {
      return;
    }
    this.innerCtrlFrom.setValue(v[0]);
    this.innerCtrlTo.setValue(v[1]);
  }

  get value(): T[] {
    if (this.innerCtrlFrom.value || this.innerCtrlTo.value) {
      return [this.innerCtrlFrom.value, this.innerCtrlTo.value];
    } else {
      return [];
    }
  }

  focused = false;
  touched = false;

  innerCtrlFrom: FormControl;
  innerCtrlTo: FormControl;

  stateChanges = new Subject<void>();

  /**
   * Holds form.
   */
  private changeFn: (v: T[]) => void = noop;
  private touchedFn: () => void = noop;

  onFocusIn(): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(): void {
      this.touched = true;
      this.focused = false;
      this.touchedFn();
      this.stateChanges.next();
  }

  setDescribedByIds(): void {
    return;
  }

  /**
   *  Constructor
   */
  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  /**
   * On Init.
   */
  ngOnInit(): void {

    this.innerCtrlFrom = new FormControl('');
    this.innerCtrlTo = new FormControl('');
  }

  onChange(): void {
    this.touchedFn();
    this.changeFn(this.value);
    this.valueChange.emit(this.value);
  }

  onBlur(): void {
    this.touchedFn();
  }

  /**
   * Write value.
   */
  writeValue(value: T[]): void {
    this.value = value ? value : [];
    this.changeFn(this.value);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Register on change.
   */
  registerOnChange(fn: any): void {

    this.changeFn = fn;

  }

  /**
   * Register on touched.
   */
  registerOnTouched(fn: any): void {

    this.touchedFn = fn;

  }

  /**
   * Validate.
   */
  validate(): ValidationErrors | null {

    return this.innerCtrlFrom.valid && this.innerCtrlFrom.valid
      ? null
      : {
        ...this.innerCtrlFrom.errors,
        ...this.innerCtrlTo.errors,
      };

  }

}
