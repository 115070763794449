import { CustomTag, Package } from '@apx-ui/apx-web-api-v1';

import { IComment, IProduct } from './utils.interface';

export enum OrderType {
  continuous = 'Continuous',
  batch = 'Batch',
  stimulation = 'Stimulation'
}

export enum STATUS_CLASS {
  PENDING = 'status-pending',
  COMPLETE = 'status-completed',
  OUT_FOR_DELIVERY = 'status-out-delivery',
  CANCELLED = 'status-cancelled',
  PENDING_APPROVAL = 'status-pending-approval'
}

export enum DELIVERY_STATUS {
  PENDING = 'Pending',
  OUT_OF_DELIVERY = 'Out for Delivery',
  COMPLETE = 'Completed',
  CANCELLED = 'Cancelled',
  ORDER_COMPLETE = 'order complete',
  PENDING_APPROVAL = 'Pending Approval'
}

export enum DELIVERY_MANAGMENT_TYPE {
  ROUTINE_ORDER = 'Routine Order',
  OFF_CYCLE_ORDER = 'Off-Cycle Order'
}

export interface TaskOrder {
  Id: string;
  LocationId: string;
  CustomerName: string;
  LocationName: string;
  DeliveryStatus: string;
  PrevDeliveryStatus?: string;
  Route: string;
  Field: string;
  Comment: IComment;
  TargetVolume: number;
  VolumeUoM: string;
  DeliveryType?: string;
  PrimaryProduct: IProduct;
  Site?: string;
  AssetType: string;
  AssetDescription: string;
  AccountCustomTags: CustomTag[];
  aggregatedCustomTags?: any[];
  AccountRoles?: string[];
  AccountId: string;
  TotalDeliveryVolume?: number;
  packageIds?: string[];
  packages?: Package[];
  isNonRoutineOrder?: boolean;
  routeOrderIndex?: number;
  routePlantOrderIndex?: number;
  TimeZone: string;
  TankId: string;
  assignmentId?: string;
  assignmentDate?: Date;
  assignmentAssigneeName?: string;
  assignmentAssigneeEmail?: string;
  unitSystemId?: string;
  unitTypeId?: string;
  uomName?: string;
  uoMPerDayAbbr?: string;
  uoMPerDayName?: string;
  uoMPluralName?: string;
  uomAbbr?: string;
  Flush?: any;
}
