import { createSelector } from '@ngrx/store';

import * as fromReducer from '../reducers';
import * as fromCustomerReducer from '../reducers/customer.reducer';

export const selectLoadCustomersStatus = createSelector(
  fromReducer.selectCustomerState,
  state => state.status,
);

export const selectCustomerAll = createSelector(
  fromReducer.selectCustomerState,
  fromCustomerReducer.selectCustomers,
);

export const selectCustomerEntities = createSelector(
  fromReducer.selectCustomerState,
  fromCustomerReducer.selectCustomerEntities,
);

export const selectCustomers = createSelector(
  fromReducer.selectCustomerState,
  selectCustomerEntities,
    (s, e) => s.customers ? s.customers.map(c => e[c]) : [],
);
