import { Plant } from '@apx-ui/apx-web-api-v1';
import { createSelector } from '@ngrx/store';

import * as fromReducer from '../reducers';
import * as fromPlantReducer from '../reducers/plant.reducer';

export const selectPlantAll = createSelector(
  fromReducer.selectPlantState,
  fromPlantReducer.selectPlants,
);

export const selectPlantEntities = createSelector(
  fromReducer.selectPlantState,
  fromPlantReducer.selectPlantEntities,
);

export const selectPlants = createSelector(
  selectPlantAll, plants => plants ? plants : [],
);

export const selectPlantStatus = createSelector(
  fromReducer.selectPlantState,
  state => state.status,
);

export const selectLoadedPlants = createSelector(
  fromReducer.selectPlantState,
  selectPlantEntities,
  (s, e) => s.plants.map(a => e[a] as Plant),
);
