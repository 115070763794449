import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {
  FTProduct,
  GenericFilter,
  Package,
  ProductFormFilter,
} from '@apx-ui/apx-web-api-v1';
import { BehaviorSubject, distinctUntilChanged, noop, Subscription } from 'rxjs';

@Component({
  selector: 'apx-ui-shared-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductFilterComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ProductFilterComponent),
      multi: true,
    },
  ],
})
export class ProductFilterComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy, OnChanges {
  @Input() filters: GenericFilter;
  @Input() packages: Package[];
  @Input() disablePackages: boolean;

  @Output() valueChange = new EventEmitter<ProductFormFilter>();

  productsList$ = new BehaviorSubject<FTProduct[]>([]);
  productsTypesList$ = new BehaviorSubject<string[]>([]);

  form: UntypedFormGroup;
  private formChangesSubscription?: Subscription;
  private innerValue: ProductFormFilter;
  private innerDisabled = false;
  private changeFn: (v: ProductFormFilter) => void = noop;
  private touchedFn: () => void = noop;

  constructor(
    private readonly fb: UntypedFormBuilder,
  ) {
  }

  @Input() set disabled(v: boolean | string) {
    const newValue = v === true || v === 'true';
    if (this.innerDisabled === newValue) {
      return;
    }
    this.innerDisabled = newValue;
    if (this.form) {
      this.setDisabledState(this.innerDisabled);
    }
  }

  @Input()
  set value(v: ProductFormFilter) {
    if (this.innerValue === v) {
      return;
    }
    this.innerValue = v;
    if (this.form) {
      const selectedProducts = this.innerValue?.products?.map(p => p.ProductName) || [];
      const products = this.filters?.Product?.ProductName?.filter(p => selectedProducts.includes(p.ProductName));

      this.form.patchValue({ ...this.innerValue, products }, { emitEvent: false });
    }
  }

  get value(): ProductFormFilter {
    return this.innerValue;
  }

  get productsTypesCtrl(): AbstractControl | null {
    return this.form.get('productTypes');
  }

  get productsCtrl(): AbstractControl | null {
    return this.form.get('products');
  }

  get packagesCtrl(): AbstractControl | null {
    return this.form.get('packages');
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      productTypes: [],
      products: [],
      packages: [{ value: null, disabled: this.disablePackages }],
    });

    this.formChangesSubscription = this.productsTypesCtrl?.valueChanges.subscribe((productTypes: string[]) => {
      if (!productTypes) {
        return;
      }

      const products = this.filters.Product.ProductName
        .filter(p => !productTypes?.length || productTypes.includes(p.ProductType));
      const selectedProducts = (this.productsCtrl?.value ?? [])
        .filter(p => !productTypes?.length || productTypes.includes(p.ProductType));

      this.productsCtrl?.setValue(selectedProducts, { onlySelf: true, emitEvent: false });
      this.productsList$.next([...products, ...selectedProducts]);
    });

    this.formChangesSubscription.add(
      this.productsCtrl?.valueChanges.subscribe((products: FTProduct[]) => {
        if (!products) {
          return;
        }

        const productTypes = products.reduce((acc, c) => ({ ...acc, [c.ProductType]: c.ProductType }), {});
        const uniqueProductTypes = new Set([...Object.values(productTypes), ...this.productsTypesCtrl?.value ?? []]);
        this.productsTypesCtrl?.setValue(
          [...uniqueProductTypes],
          { onlySelf: true, emitEvent: false },
          );
      }),
    );

    this.formChangesSubscription?.add(this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe(formValue => {
        this.innerValue = formValue;

        this.touchedFn();
        this.changeFn(this.innerValue);
        this.valueChange.emit(this.innerValue);
      }),
    );
  }

  ngOnDestroy(): void {
    this.formChangesSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateData(changes);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid
      ? null
      : {
        assetFilterError: true,
      };
  }

  private updateData(changes: SimpleChanges): void {
    if (!this.filters || !changes.filters) {
      return;
    }

    this.productsList$.next(this.filters.Product.ProductName);
    this.productsTypesList$.next(this.filters.Product.ProductType);
  }
}
