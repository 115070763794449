<form fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="16"
      [formGroup]="form">

  <mat-form-field appearance="outline"
                  fxFlex="48">
    <mat-label>{{ datePlaceHolder ?? 'Date' }}</mat-label>
    <input matInput
           formControlName="date"
           [max]="maxDate"
           [min]="minDate"
           [matDatepicker]="picker">
    <mat-datepicker-toggle matSuffix
                           [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error>
      Invalid entry
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline"
                  fxFlex>
    <mat-label>{{ timePlaceHolder ?? 'Time' }}</mat-label>
    <input matInput
           formControlName="time"
           type="time">
  </mat-form-field>

</form>
