import { Injectable } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import { GenericFilter } from '@apx-ui/apx-web-api-v1';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AssetFilters } from '../stores/actions';
import * as fromFiltersSelectors from '../stores/selectors/filters.selector';

@Injectable()
export class AssetFiltersState {

  constructor(private readonly store: Store<any>) {
  }

  loadAssetFilters(accountId: string): void {
    this.store.dispatch(AssetFilters.loadAssetFilters({ accountId }));
  }

  clear(): void {
    this.store.dispatch(AssetFilters.clear());
  }

  getStatus$(): Observable<Status> {
    return this.store.pipe(select(fromFiltersSelectors.selectAssetFiltersStatus));
  }

  getAssetFilters$(): Observable<GenericFilter> {
    return this.store.pipe(select(fromFiltersSelectors.selectAssetFilters));
  }

}
