import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PageConfigStateService } from '@apx-ui/apx-account-store';
import { Permissions } from '@apx-ui/apx-config';
import { UILayoutStateService } from '@apx-ui/apx-shared-ui';
import { PageConfig, UserStateService } from '@apx-ui/apx-web-api-v1';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'apx-ui-shared-account-side-bar',
  templateUrl: './account-side-bar.component.html',
  styleUrls: ['./account-side-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSideBarComponent implements OnInit {

  expanded$!: Observable<boolean>;
  performanceConfig$!: Observable<PageConfig[]>;

  showDashboardNavButton$: Observable<boolean>;
  showManagementNavButton$: Observable<boolean>;
  showPerformanceNavButton$: Observable<boolean>;
  showSupportNavButton$: Observable<boolean>;
  showSettingsNavButton$: Observable<boolean>;
  showKpiControlsNavButton$: Observable<boolean>;
  showCustomizationReportsNavButton$: Observable<boolean>;

  constructor(
    private readonly state: UILayoutStateService,
    private readonly pageConfigState: PageConfigStateService,
    private readonly userStateService: UserStateService,
  ) {
  }

  ngOnInit(): void {
    this.expanded$ = this.state.isAsideExpanded$();
    this.performanceConfig$ = combineLatest([
      this.pageConfigState.getStatus$(),
      this.pageConfigState.getPageConfig$(),
    ]).pipe(
      filter(([status]) => status.resolved),
      map(([, c]) => c?.getPageConfigs() ?? []),
      map(p => p.filter(f => f.module === 'performance' && !f.NotActive)),
    );

    this.showDashboardNavButton$ = this.userStateService.hasRight$(Permissions.AccountViewDashboard);
    this.showManagementNavButton$ = this.userStateService.hasRight$(Permissions.AccountViewManagement);
    this.showPerformanceNavButton$ = this.userStateService.hasRight$(Permissions.AccountViewPerformance);
    this.showSupportNavButton$ = this.userStateService.hasRight$(Permissions.AccountViewSupport);
    this.showSettingsNavButton$ = this.userStateService.hasRight$(Permissions.AccountViewSettings);
    this.showKpiControlsNavButton$ = this.userStateService.hasRight$(Permissions.AccountViewKpiControls);
    this.showCustomizationReportsNavButton$ =
      this.userStateService.hasRight$(Permissions.AccountViewCustomizationReports);
  }

  onHeaderClick(expanded: boolean): void {

    if (expanded) {
      this.state.collapseAside();
    } else {
      this.state.expandAside();
    }

  }

}
