import { Injectable } from '@angular/core';
import { BaseEntityCollectionService } from '@apx-ui/apx-core';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { IDeliveryData } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class DeliveryOrdersService extends BaseEntityCollectionService<IDeliveryData> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('DeliveryOrder', serviceElementsFactory);
  }

}
