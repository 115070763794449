import { createSelector } from '@ngrx/store';

import { selectOffCycleContinuousState } from '../reducers';

export const selectOffCycleContinuousOrdersStep = createSelector(
  selectOffCycleContinuousState,
  state => state.step,
);

export const selectOffCycleContinuousOrdersValidationInfo = createSelector(
  selectOffCycleContinuousState,
  (state) => state.validationInfo ?? null,
);

export const selectOffCycleContinuousOrdersFormData = createSelector(
  selectOffCycleContinuousState,
  (state) => state.form_data,
);

export const selectOffCycleContinuousOrdersStatus = createSelector(
  selectOffCycleContinuousState,
  state => state.status,
);

export const selectOffCycleContinuousOrdersResult = createSelector(
  selectOffCycleContinuousState,
  state => state.result,
);

export const selectOffCycleContinuousOrdersMessage = createSelector(
  selectOffCycleContinuousState,
  state => state.message,
);
