export const environment = {
  production: false,
  api_url: 'https://apxstage-back.halliburton.com/APXApi/api',
  common_api_url: 'https://apxstage-back.halliburton.com/APXCommonApi/api',
  api_file_url: 'https://apxstage-back.halliburton.com/APXFileApi/api',
  api_notification_url: 'https://apxstage-back.halliburton.com/APXNotificationApi/api',
  authSettings: {
    authority: 'https://myappstest.halliburton.com/oauth2/ausk4axfvsdNLJ8rU0h7',
    client_id: '0oasir1xb0RjfbVdt0h7',
    redirect_uri: 'https://apxstage.halliburton.com/authcallback',
    post_logout_redirect_uri: 'https://apxstage.halliburton.com/postlogout',
  },
};
