import { HttpErrorResponse } from '@angular/common/http';
import { AccountPageConfig } from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

export const loadPageConfig = createAction(
  '[APX Account Page Config] Load',
  props<{
    accountId: string;
  }>(),
);

export const loadPageConfigSuccess = createAction(
  '[APX Account Page Config] Load Success',
  props<{
    accountId: string;
    pageConfig: AccountPageConfig;
  }>(),
);

export const loadPageConfigFailure = createAction(
  '[APX Account Page Config] Load Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const clearState = createAction(
  '[APX Account Page Config] Clear State',
);
