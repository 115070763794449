<form data-test-id="searchForm"
      [formGroup]="form">

  <mat-form-field appearance="outline"
                  class="no-space">
    <mat-icon matPrefix>search</mat-icon>

    <input matInput
           data-test-id="searchInput"
           type="search"
           formControlName="value"
           [placeholder]="placeholder">
    <button matSuffix
            mat-icon-button
            aria-label="resetValue"
            type="button"
            [ngClass]="{'pointer-events opacity-0': !valueControl.value}"
            data-test-id="closeBtn"
            (click)="resetValue()">
      <mat-icon matSuffix>close</mat-icon>
    </button>
  </mat-form-field>

</form>
