import { User } from '../../interfaces';

export const USER_PROFILE_FIXTURE: User = {
  sub: '12345',
  name: 'user-1',
  email: 'user-email@mail.com',
  ver: 1,
  jti: '112233',
  idp: '11111',
  preferred_username: 'user-1',
  auth_time: 12345,
  lastName: 'userLastName',
  firstName: 'userFirstName',
  upn: 'upn',
  displayName: 'displayName',
  userType: 'user',
  locale: 'us',
  given_name: 'given_name',
  family_name: 'family_name',
  zoneinfo: 'zoneinfo',
  updated_at: 12345,
  email_verified: true,
};

export const OIDC_USER_FIXTURE: any = {
  id_token: 'id_token',
  session_state: 'session_state',
  access_token: 'access_token',
  refresh_token: 'refresh_token',
  token_type: 'token_type',
  scope: 'scope',
  profile: USER_PROFILE_FIXTURE as any,
  expires_at: 12345,
  state: { },
  expires_in: 12345,
  expired: false,
  scopes: ['scope1', 'scope2'],
} as any;
