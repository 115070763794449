import { UIDialogService } from './dialog/dialog.service';
import { NotificationService } from './notification/notification.service';
import { UIPopoverService } from './popover';

export const services = [
  UIDialogService,
  UIPopoverService,
  NotificationService,
];

export * from './date-adapter/custom-data-adapter.services';
export * from './dialog/dialog.service';
export * from './notification/notification.service';
export * from './popover';
