import { AbstractModel } from '@apx-ui/apx-core';

import { AccountPageConfig, PageConfig } from '../interfaces';

export class PageConfigModel extends AbstractModel<AccountPageConfig> {

  constructor(protected override data: AccountPageConfig) {
    super(data);
  }

  getPageConfigs(): PageConfig[] {
    return this.data.PageConfig;
  }

}
