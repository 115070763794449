import { Reason } from '@apx-ui/apx-web-api-v1';

import {
  BatchOrder,
  ContinuousOrder,
  IDeliveryProducts,
  StimulationOrder,
  TaskOrder,
} from '../interfaces';
import { ContinuousOrderModel, OrderModels, OrdersFactory } from '../models';

export class OrdersUtils {
  static getProducts(taskOrders: OrderModels[]): IDeliveryProducts[] {
    const deliveryMap = taskOrders.reduce((acc, c) => {
      acc[c.getPrimaryProduct().ProductId] = {
        ...acc[c.getPrimaryProduct().ProductId] ?? c.getPrimaryProduct(),
        TargetVolume: (acc[c.getPrimaryProduct().ProductId]?.TargetVolume ?? 0) + c.calcTargetVolume(),
      };

      if (c instanceof ContinuousOrderModel) {
        (c as ContinuousOrderModel)?.getDilutionProduct()?.forEach(d => {
          acc[d.ProductId] = {
            ...acc[d.ProductId] ?? d,
            ProductType: c.getPrimaryProduct().ProductType,
            TargetVolume: (acc[d.ProductId]?.TargetVolume ?? 0) + c.calcTargetVolumeBase(d),
          };
        });
      }

      return acc;
    }, {});
    return Object.values(deliveryMap);
  }

  static getAccounts(taskOrders: TaskOrder[]): string[] {
    return Array.from(new Set(taskOrders.map(o => o.AccountId)));
  }

  static creteDeliveryListPayload(
    orders: (ContinuousOrder | BatchOrder | StimulationOrder)[],
    products: { product: string; productName: string; total: number; amount: number; }[],
    equipment: string[],
    deliveryType: string,
    reason: Reason | null,
    email: string,
    deliveryMethod: string,
  ): any {

    return {
      Id: '',
      LoadDate: new Date().toISOString(),
      UserEmail: email,
      DeliveryType: deliveryType,
      Active: false,
      Products: products.map(p => ({
        LoadedVolume: p.amount,
        OrdersFulfilled: null,
        ProductId: p.product,
        ProductName: p.productName,
        RateUofM: 'gallon',
        UnloadedVolume: null,
      })),
      EquipmentIds: equipment,
      OrderDelivery: orders.map(order => OrdersFactory.create(order)?.createOrder()),
      ...reason && {
        assignmentReAssignReasonId: reason.reason,
        ...(reason.comment && { assignmentReAssignCustomReason: reason.comment }),
      },
      deliveryMethod,
      unitSystemId: orders[0].unitSystemId,
    };
  }

}
