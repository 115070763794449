import { ACCOUNT_STATUSES, ACCOUNT_TYPES } from './';

export interface District {
  District: string;
  Region: string;
}

export interface Warehouse {
  District: string;
  Plant: string;
  Region: string;
}

export interface AccountFilter {
  AccountStatus: ACCOUNT_STATUSES[];
  AccountType: ACCOUNT_TYPES[];
  CustomerName: string[];
  MCChemicalAccountManager: string[];
  MCDistrict: District[];
  MCRegion: string[];
  MCWarehouse: Warehouse[];
}

export const accountFilterURLParams = [
  'customerName',
  'accountType',
  'mCRegion',
  'mCDistrict',
  'mCWarehouse',
  'mCChemicalAccountManager',
  'accountStatus',
];
