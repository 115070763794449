export const sampleAllowed = { yes: 'Yes', no: 'No' };

export const H2SPresent = { yes: 'Yes', no: 'No' };

export const SCHEDULED_SAMPLE = {
  Scheduled: 'APX SCHEDULED',
  NonRoutine: 'APX NON-ROUTINE',
};

export const sampleStatuses = {
  inProgress: 'In Progress',
  readyForCapture: 'Ready for Capture',
  complete: 'Completed',
};

export interface SampleType {
  Type: string;
  Category: string;
  SubCategory: string;
}

export interface LabelData {
  [key: string]: any;
  PlantId: string;
  SampleType: SampleType;
}

export type SampleTypeDescription =
  'Asset Failures' | 'Acid Gases' | 'Alkalinity' | 'ATP' | 'Complete Water Analysis' | 'Corrosion Residual' |
  'Gas Analysis' | 'Millipore Analysis' | 'Oil Analysis' | 'Oil in Water' | 'Partial Water (Metals)' |
  'Scale Residual' | 'BS&W' | 'Corrosion Coupon' | 'Paraffin Coupon' | 'Scale Coupon' |
  'Serial Dilution Bottles' | 'Misc Labs Analysis' | 'Solids Analysis';

export const SampleFilters = {
  'Due in next 7 days' : {
    title: 'Due in next 7 days',
    dueText: ' days until due',
    daysFrom: 0,
    daysTo: 7,
  },
  'Due in next 30 days' : {
    title: 'Due in next 30 days',
    dueText: ' days until due',
    daysFrom: 0,
    daysTo: 30,
  },
  'Due in next 90 days' : {
    title: 'Due in next 90 days',
    dueText: ' days until due',
    daysFrom: 0,
    daysTo: 90,
  },
  '1 to 7 days past due' : {
    title: '1 to 7 days past due',
    dueText: ' days late',
    daysFrom: 1,
    daysTo: 7,
  },
  '8 to 30 days past due' : {
    title: '8 to 30 days past due',
    dueText: ' days late',
    daysFrom: 8,
    daysTo: 30,
  },
  '31 - 90 days past due' : {
    title: '31 - 90 days past due',
    dueText: ' days late',
    daysFrom: 31,
    daysTo: 90,
  },
  '91+ days past due' : {
    title: '91+ days past due',
    dueText: ' days late',
    daysFrom: 91,
    daysTo: Infinity,
  },
  'No Defined Due Date' : {
    title: 'No Defined Due Date',
    dueText: '',
    daysFrom: 0,
    daysTo: 0,
  },
};
