/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  ConnectedOverlayPositionChange,
  FlexibleConnectedPositionStrategy,
  OverlayRef,
} from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { PopoverConfig } from '../../../interfaces';

export class UIPopoverOverlayRef<T = any> {

  private beforeCloseSubject$ = new Subject<T>();
  private afterClosedSubject$ = new Subject<T>();

  constructor(
    private overlayRef: OverlayRef,
    private positionStrategy: FlexibleConnectedPositionStrategy,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public config: PopoverConfig,
  ) {

    this.overlayRef.keydownEvents()
      .pipe(
        takeUntil(this.afterClosedSubject$),
        filter(event => event.key === 'Escape'),
      )
      .subscribe(() => this.close());

    this.overlayRef.backdropClick()
      .pipe(
        takeUntil(this.afterClosedSubject$),
      )
      .subscribe(() => this.close());

  }

  close(data?: T): void {
    this.beforeCloseSubject$.next(data as any);

    this.overlayRef.dispose();

    this.afterClosedSubject$.next(data as any);

    this.beforeCloseSubject$.complete();
    this.afterClosedSubject$.complete();
  }

  afterClosed(): Observable<T> {
    return this.afterClosedSubject$.asObservable();
  }

  beforeClose(): Observable<T> {
    return this.beforeCloseSubject$.asObservable();
  }

  positionChanges(): Observable<ConnectedOverlayPositionChange> {
    return this.positionStrategy.positionChanges;
  }

}
