import { ClientBaseService } from './client-base.service';
import { ClientFileService } from './client-file.service';
import { ClientNotificationService } from './client-notification.service';

export const clients: any[] = [
  ClientBaseService,
  ClientFileService,
  ClientNotificationService,
];

export * from './client-base.service';
export * from './client-file.service';
export * from './client-notification.service';
export * from './interfaces';
