<div fxLayout="row"
     fxLayoutAlign="start center"
     class="br mr-8"
     data-test-id="pageBreadCrumb">
  <ng-container *ngFor="let breadcrumb of breadcrumbs$ | async as bc; let i = index;">
    <a *ngIf="!breadcrumb?.textField; else textItem"
       fxLayout="row"
       class="breadcrumb breadcrumb-link text-no-wrap"
       [class.min-width-auto]="breadcrumb.text?.length < 10 || !i"
       [routerLink]="breadcrumb.url"
       [queryParams]="breadcrumb.queryParams">
      <span class="mat-subheading-1 m-0 text-pre"
            [class.text-truncate]="getBreadcrumbRightText(breadcrumb.text)?.length && i"
      >{{ getBreadcrumbLeftText(breadcrumb.text) }}</span>
      <span class="mat-subheading-1 m-0 text-pre">{{ getBreadcrumbRightText(breadcrumb.text) }}</span>
    </a>

    <ng-template #textItem>
      <div fxLayout="row"
           class="mat-body-2 breadcrumb text-no-wrap"
           [class.min-width-auto]="breadcrumb.text?.length < 10">
        <span class="mat-subheading-1 m-0 text-pre"
              [class.text-truncate]="getBreadcrumbRightText(breadcrumb.text)?.length"
        >{{ getBreadcrumbLeftText(breadcrumb.text) }}</span>
        <span class="mat-subheading-1 m-0 text-pre">{{ getBreadcrumbRightText(breadcrumb.text) }}</span>
      </div>
    </ng-template>

    <mat-icon *ngIf="i < bc?.length -1">navigate_next</mat-icon>
  </ng-container>
</div>
