import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AbstractModel, integerValidator, signedIntegerValidator } from '@apx-ui/apx-core';

import { KpiControl, KpiSection } from '../interfaces';

export class KpiSectionModel extends AbstractModel<KpiSection> {
  public form: UntypedFormGroup;
  public kpiModels: KpiModel[];
  public key: string;

  constructor(key: string, data: KpiSection) {
    super(data);

    this.key = key;
    this.form = new UntypedFormGroup({});
    this.kpiModels = data.Kpis.map(control => {
      const kpiControlModel = new KpiModel(control);
      this.form.addControl(kpiControlModel.getName() + kpiControlModel.getUOM(), kpiControlModel.control);
      return kpiControlModel;
    });
  }

  getPageTitle(): string {
    return this.data.PageTitle;
  }

  getSectionTitle(): string {
    return this.data.SectionTitle;
  }

  makeRequestValue(): KpiSection {
    return {
      ...this.data,
      Kpis: this.kpiModels?.map(m => m.makeRequestValue()),
    };
  }
}

export class KpiModel extends AbstractModel<KpiControl> {
  public control: UntypedFormControl;
  private minMax?: [number, number];

  constructor(data: KpiControl) {
    super(data);
    this.control = new UntypedFormControl(this.getValue(), this.getValidators());
  }

  getName(): string {
    return this.data.Name;
  }

  getIsActive(): boolean {
    return this.data.IsActive;
  }

  getUOM(): string {
    return this.data.UOfM;
  }

  getInputType(): string {
    switch (this.data.Format) {
      case 'Integer':
      case 'Decimal':
      case 'Percentage':
      case 'Currency':
        return 'number';
      default:
        return 'text';
    }
  }

  getValue(): string {
    return this.data.Value;
  }

  getMinMax(): [number, number] {
    return this.minMax;
  }

  isRequired(): boolean {
    return this.data.Required;
  }

  getValidators(): ValidatorFn[] {
    const formValidators = [];
    const kpiValidators = this.data.ValidatorNames || this.data.Validators || [];
    kpiValidators.forEach(v => {
      if (v.startsWith('minMax')) {
        const minMaxValue = v.match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g);
        formValidators.push(
          Validators.min(+minMaxValue[0]),
          Validators.max(+minMaxValue[1]),
        );
        this.minMax = [+minMaxValue[0], +minMaxValue[1]];
      } else if(v.includes('Signed Integer')) {
        formValidators.push(
          signedIntegerValidator(),
        );
      } else if(v.includes('Integer')) {
        formValidators.push(
          integerValidator(),
        );
      }
    });
    return formValidators;
  }

  makeRequestValue(): KpiControl {
    return {
      ...this.data,
      Value: this.control.value,
    };
  }
}
