import { Injectable } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import { GenericFilter } from '@apx-ui/apx-web-api-v1';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { OrdersFilters } from '../actions';
import * as fromFiltersSelectors from '../selectors/filters.selector';

@Injectable()
export class OrdersFiltersState {

  constructor(private readonly store: Store<any>) {
  }

  loadContinuousFilters(): void {
    this.store.dispatch(OrdersFilters.loadContinuousFilters());
  }

  loadStimulationFilters(): void {
    this.store.dispatch(OrdersFilters.loadStimulationFilters());
  }

  clear(): void {
    this.store.dispatch(OrdersFilters.clear());
  }

  getContinuousStatus$(): Observable<Status> {
    return this.store.pipe(select(fromFiltersSelectors.selectContinuousFiltersStatus));
  }

  getStimulationStatus$(): Observable<Status> {
    return this.store.pipe(select(fromFiltersSelectors.selectStimulationFiltersStatus));
  }

  getContinuousFilters$(): Observable<GenericFilter> {
    return this.store.pipe(select(fromFiltersSelectors.selectContinuousFilters));
  }

  loadBatchFilters(): void {
    this.store.dispatch(OrdersFilters.loadBatchFilters());
  }

  getBatchStatus$(): Observable<Status> {
    return this.store.pipe(select(fromFiltersSelectors.selectBatchFiltersStatus));
  }

  getBatchFilters$(): Observable<GenericFilter> {
    return this.store.pipe(select(fromFiltersSelectors.selectBatchFilters));
  }

  loadDeliveryFilters(): void {
    this.store.dispatch(OrdersFilters.loadDeliveryFilters());
  }

  getDeliveryStatus$(): Observable<Status> {
    return this.store.pipe(select(fromFiltersSelectors.selectDeliveryFiltersStatus));
  }

  getDeliveryFilters$(): Observable<GenericFilter> {
    return this.store.pipe(select(fromFiltersSelectors.selectDeliveryFilters));
  }

  getStimulationFilters$(): Observable<GenericFilter> {
    return this.store.pipe(select(fromFiltersSelectors.selectStimulationFilters));
  }

}
