import {
  HttpClient,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@apx-ui/apx-config';
import {
  ClientNotificationService,
  ClientOptions,
} from '@apx-ui/apx-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Notifications } from '../interfaces';

@Injectable()
export class ApxSolsticeWebNotificationClientService extends ClientNotificationService {

  constructor(
    http: HttpClient,
    config: ConfigService,
  ) {
    super(http, config);
  }

  getCount(options: ClientOptions = { }): Observable<number> {
    const _options = {
      ...options,
      params: {
        ...options.params,
      },
    };

    return this.get(`/notification/get-notification-count`, _options).pipe(
      map(response => response.count ?? 0),
    );
  }

  markAsRead(ids: { id: string }[], options: ClientOptions = { }): Observable<boolean> {
    const _options = { ...options, params: { ...options.params } };

    return this.post(`/notification/mark-notifications-as-read`, ids, _options).pipe(
      map(response => !!response?.success),
    );
  }

  downloadSampleNotifications(
    subCategory: string,
    checkedDate: string,
    options: ClientOptions = {},
  ): Observable<HttpRequest<string>> {

    const _options = {
      ...options,
      observe: 'response',
      responseType: 'arraybuffer',
      params: {
        ...options.params,
        sub_category: subCategory,
      },
    };

    return this.get(`/sample-notification/export-expired-notifications?checked_date=${encodeURIComponent(checkedDate)}`,
      _options);
  }

  downloadConiOrderNotifications(
    checkedDate: string,
    options: ClientOptions = {}): Observable<HttpRequest<string>> {

    const _options = {
      ...options,
      observe: 'response',
      responseType: 'arraybuffer',
      params: {
        ...options.params,
      },
    };

    const encodeDate = encodeURIComponent(checkedDate);

    return this.get(`/order-notification/export-expired-continuous-order-notifications?checked_date=${encodeDate}`,
      _options);
  }

  downloadBatchOrderNotifications(
    checkedDate: string,
    options: ClientOptions = {}): Observable<HttpRequest<string>> {

    const _options = {
      ...options,
      observe: 'response',
      responseType: 'arraybuffer',
      params: {
        ...options.params,
      },
    };

    return this.get(
      `/order-notification/export-expired-batch-order-notifications?checked_date=${encodeURIComponent(checkedDate)}`,
      _options);
  }

  getNotifications(options: ClientOptions = { }): Observable<Notifications> {
    const _options = {
      ...options,
      params: {
        ...options.params,
      },
    };

    return this.get(`/notification/get-notification-previews`, _options);
  }

}
