import { Injectable } from '@angular/core';
import { AssetModel, Sample } from '@apx-ui/apx-web-api-v1';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { CreateSampleFormData, CreateSampleStep, OperationType } from '../interfaces';
import { CreateSampleActions } from '../stores/actions';
import {
  selectCreateSampleFormData,
  selectCreateSampleMessage,
  selectCreateSampleResult,
  selectCreateSampleStep,
  selectEditedAsset,
  selectOperationType,
} from '../stores/selectors';

@Injectable()
export class CreateSampleState {

  close$: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store<never>) {
  }

  getStep$(): Observable<CreateSampleStep> {
    return this.store.select(selectCreateSampleStep);
  }

  getResult$(): Observable<Sample[] | null> {
    return this.store.select(selectCreateSampleResult);
  }

  getFormData$(): Observable<CreateSampleFormData> {
    return this.store.select(selectCreateSampleFormData);
  }

  getEditedAsset$(): Observable<AssetModel | null> {
    return this.store.select(selectEditedAsset);
  }

  getOperationType$(): Observable<OperationType> {
    return this.store.select(selectOperationType);
  }

  getMessage$(): Observable<string> {
    return this.store.select(selectCreateSampleMessage);
  }

  getClose$(): Observable<boolean> {
    return this.close$;
  }

  setStep(step: CreateSampleStep): void {
    this.store.dispatch(CreateSampleActions.setStep({ step }));
  }

  initForm(asset: AssetModel, operationType: OperationType, formData: CreateSampleFormData): void {
    this.store.dispatch(CreateSampleActions.initForm({ asset, operationType, formData }));
  }

  saveForm(formData: CreateSampleFormData): void {
    this.store.dispatch(CreateSampleActions.saveCreateForm({ formData }));
  }

  saveConfirm(): void {
    this.store.dispatch(CreateSampleActions.saveConfirm());
  }

  destroy(): void {
    this.store.dispatch(CreateSampleActions.destroy());
  }

}
