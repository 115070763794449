import { Injectable } from '@angular/core';
import { ACCOUNT_STATUSES, ApxSolsticeWebClientService } from '@apx-ui/apx-web-api-v1';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap } from 'rxjs';

import { AccountActions, CreateAccountActions, ManageAccountActions } from '../actions';

@Injectable()
export class CreateAccountEffects {

  handleSaveConfirm$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(
          CreateAccountActions.saveConfirm,
        ),
        switchMap(({ account }) => {

          const newAccount = {
            AccountManager: '',
            AccountManagerID: 0,
            AccountRoles: [],
            AccountStatus: ACCOUNT_STATUSES[ACCOUNT_STATUSES.ACTIVE],
            AccountStatusID: ACCOUNT_STATUSES.ACTIVE.valueOf(),
            AccountType: account.accountType,
            CrewId: +account.crewId || 0,
            CustomerName: account.customerName,
            DistrictID: 0,
            DistrictName: account.plant?.districtName ?? '',
            Id: '',
            PlantID: account.plantId,
            PlantName: account.plant?.plantName ?? '',
            RegionID: 0,
            RegionName: account.plant?.regionName ?? '',
            ShipTo: 0,
            SoldTo: account.soldTo || 0,
            SupervisorEmail: '',
            unitSystemID: account.unitSystems.unitSystemId,
          };

          return this.client.createAccount({ ...newAccount }, {
          }).pipe(
            switchMap(res => {
              return [
                AccountActions.addAccount({ account: res }),
                ManageAccountActions.addAccount({ account: res }),
                CreateAccountActions.success({ accountData: res }),
              ];
            }),
            catchError(err => {
              return of(
                CreateAccountActions.failure({ message: err.error?.Message ?? err.message ?? 'Something went wrong' }),
              );
            }),
          );
        }),
      );
    },
  );

  handleUpdateSaveConfirm$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(
          CreateAccountActions.updateConfirm,
        ),
        switchMap(({ account, formData }) => {

          const newAccount = {
            ...account,
            CustomerName: formData.customerName,
            SoldTo: formData.soldTo,
          };

          return this.client.updateAccount({ ...newAccount }, {
          }).pipe(
            switchMap(res => {
              return [
                AccountActions.addAccount({ account: res }),
                CreateAccountActions.success({ accountData: res }),
              ];
            }),
            catchError(err => {
              return of(
                CreateAccountActions.failure({ message: err.error?.Message ?? err.message ?? 'Something went wrong' }),
              );
            }),
          );
        }),
      );
    },
  );

  constructor(
    private actions$: Actions,
    private readonly client: ApxSolsticeWebClientService,
  ) {
  }

}
