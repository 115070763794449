import { UnitSystems } from '@apx-ui/apx-web-api-v1';

export const USER_UNIT_SYSTEMS_FIXTURE: UnitSystems[] = [
  {
    'userId': '62879a787df26e23dd927bdf',
    'unitSystemId': '00000000-0000-0000-0000-000000000001',
    'name': 'Imperial',
    'isDefault': true,
  },
];
