import { Injectable } from '@angular/core';
import { RouterActions } from '@apx-ui/apx-core';
import { NotificationActions } from '@apx-ui/apx-shared-ui';
import { ApxSolsticeWebClientService } from '@apx-ui/apx-web-api-v1';
import { EntityActionFactory, EntityOp } from '@ngrx/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap } from 'rxjs';

import { entityTypes } from '../../interfaces';
import { OrdersUtils } from '../../models';
import { DeliveryListActions, OrdersForDeliveryActions } from '../actions';

@Injectable()
export class DeliveryListEffects {

  handleCreateDeliveryList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeliveryListActions.loadTruck,
      ),
      switchMap(({ orders, products, equipment, deliveryType, reason, email, deliveryMethod }) => {

        const payload = OrdersUtils.creteDeliveryListPayload(
          orders,
          products,
          equipment,
          deliveryType,
          reason,
          email,
          deliveryMethod,
        );

        const options = {
          params: {
            unitSystemID: orders[0].unitSystemId,
          },
        };

        return this.client.createDeliveryList(OrdersUtils.getAccounts(orders), payload, options).pipe(
          switchMap(() => {

            return [
              DeliveryListActions.loadTruckSuccess({ orders, products, equipment, deliveryType }),
            ];
          }),
          catchError(err => of(
            DeliveryListActions.loadTruckFailure({ err }),
            NotificationActions.error({ message: 'Cannot create delivery list.' }),
          )),
        );
      }),
    ),
  );

  handleAddDeliveryList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeliveryListActions.addToDeliveryList,
      ),
      switchMap(({ orders, products, equipment, deliveryType, reason, email, deliveryMethod }) => {

        const payload = OrdersUtils.creteDeliveryListPayload(
          orders,
          products,
          equipment,
          deliveryType,
          reason,
          email,
          deliveryMethod,
        );

        return this.client.addOrdersToDeliveryList(OrdersUtils.getAccounts(orders), payload).pipe(
          switchMap(() => {

            return [
              DeliveryListActions.addToDeliveryListSuccess({ orders, products, equipment, deliveryType }),
            ];
          }),
          catchError(err => of(
            DeliveryListActions.addToDeliveryListFailure({ err }),
            NotificationActions.error({ message: 'Cannot add orders(s) to delivery list.' }),
          )),
        );
      }),
    ),
  );

  handleUnloadTruck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeliveryListActions.unloadTruck,
      ),
      switchMap(({ payload }) => {

        const accounts = payload.OrderDelivery.reduce((acc, c) => ({ ...acc, [c.AccountId]: { ...c } }), {});

        return this.client.unLoadTruck(payload, Object.keys(accounts)).pipe(
          switchMap(completedCount => {

            return [
              DeliveryListActions.unloadTruckSuccess({ payload, completedCount }),
            ];
          }),
          catchError(err => of(
            DeliveryListActions.unloadTruckFailure({ err }),
            NotificationActions.error({ message: 'Cannot unload truck.' }),
          )),
        );
      }),
    ),
  );

  handleUnloadTruckSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeliveryListActions.unloadTruckSuccess,
      ),
      switchMap(({ payload, completedCount }) => {
        const plural = ['deliveries', 'delivery'];
        const incomplete = payload.OrderDelivery.length - completedCount;
        const moveBack = `${incomplete} ${plural[incomplete === 1 ? 1 : 0]} have been moved back to the Orders List`;
        const success = `Success! You completed ${completedCount} ${plural[completedCount === 1 ? 1 : 0]} `;

        return [
          OrdersForDeliveryActions.loadOrdersForDelivery({ unitSystemId: payload.unitSystemId }),
          NotificationActions.success({ message: incomplete > 0 ? moveBack : success }),
          RouterActions.navigate({ path: ['field-tasks', 'orders', payload.DeliveryType.toLowerCase()] }),
        ];
      }),
    ),
  );

  handleLoadTruckSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeliveryListActions.loadTruckSuccess,
      ),
      switchMap(({ orders, deliveryType }) => {

        return [
          this.entityActionFactory.create(entityTypes[deliveryType], EntityOp.REMOVE_ALL),
          OrdersForDeliveryActions.loadOrdersForDelivery({ unitSystemId: orders[0].unitSystemId }),
          NotificationActions.success({ message: 'Delivery list was successfully created.' }),
          RouterActions.navigate({ path: ['field-tasks', 'orders', 'delivery'] }),
        ];
      }),
    ),
  );

  handleAddToDeliveryListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeliveryListActions.addToDeliveryListSuccess,
      ),
      switchMap(({ orders, deliveryType }) => {

        return [
          this.entityActionFactory.create(entityTypes[deliveryType], EntityOp.QUERY_ALL),
          OrdersForDeliveryActions.loadOrdersForDelivery({ unitSystemId: orders[0].unitSystemId }),
          NotificationActions.success({ message: 'Delivery list was successfully created.' }),
          RouterActions.navigate({ path: ['field-tasks', 'orders', 'delivery'] }),
        ];
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private readonly client: ApxSolsticeWebClientService,
    private readonly entityActionFactory: EntityActionFactory,
  ) {
  }

}
