import { Injectable } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

import { IDeliveryData, IOrderDeliveryData } from '../../interfaces';
import { OrdersForDeliveryActions } from '../actions';
import * as fromActiveDeliverySelectors from '../selectors';

@Injectable()
export class OrdersForDeliveryState {

  constructor(private readonly store: Store<any>) {
  }

  loadActiveDelivery(unitSystemId = ''): void {
    this.store.dispatch(OrdersForDeliveryActions.loadOrdersForDelivery({ unitSystemId }));
  }

  clear(): void {
    this.store.dispatch(OrdersForDeliveryActions.clear());
  }

  getStatus$(): Observable<Status> {
    return this.store.pipe(select(fromActiveDeliverySelectors.selectActiveDeliveryStatus));
  }

  getActiveDelivery$(): Observable<IDeliveryData> {
    return this.store.pipe(select(fromActiveDeliverySelectors.selectActiveDelivery));
  }

  isDeliveryActive$(): Observable<boolean> {
    return this.store.pipe(select(fromActiveDeliverySelectors.selectActiveDeliveryActivity));
  }

  getDeliveryType$(): Observable<string> {
    return this.store.pipe(select(fromActiveDeliverySelectors.selectActiveDeliveryType));
  }

  getOrders$(): Observable<IOrderDeliveryData[]> {
    return this.store.pipe(select(fromActiveDeliverySelectors.selectOrderForDelivery));
  }

  showContinuousDelivery$(): Observable<boolean> {
    return this.store.pipe(
      select(fromActiveDeliverySelectors.selectActiveDelivery),
      map(delivery => {
        return (delivery?.Active && delivery?.DeliveryType === 'Continuous') || !delivery?.Active;
      }),
    );
  }

  showBatchDelivery$(): Observable<boolean> {
    return this.store.pipe(
      select(fromActiveDeliverySelectors.selectActiveDelivery),
      map(delivery => {
        return (delivery?.Active && delivery?.DeliveryType === 'Batch') || !delivery?.Active;
      }),
    );
  }

  showStimulationDelivery$(): Observable<boolean> {
    return this.store.pipe(
      select(fromActiveDeliverySelectors.selectActiveDelivery),
      map(delivery => {
        return (delivery?.Active && delivery?.DeliveryType === 'Stimulation') || !delivery?.Active;
      }),
    );
  }

}
