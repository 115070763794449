import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NavCategory } from '../../interfaces';

@Component({
  selector: 'apx-ui-shared-page-category-list',
  templateUrl: './page-category-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageCategoryListComponent {
  @Input() option: NavCategory;
}
