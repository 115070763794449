import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApxConfigModule } from '@apx-ui/apx-config';

import { clients } from './clients';
import { states } from './stores/states';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ApxConfigModule,
  ],
})
export class ApxCoreModule {
  static forRoot(): ModuleWithProviders<ApxCoreModule> {
    return {
      ngModule: ApxCoreModule,
      providers: [
        ...clients,
        ...states,
      ],
    };
  }
}
