import { AbstractModel } from '@apx-ui/apx-core';

import { FieldInterface } from '../interfaces';

export class FieldModel extends AbstractModel<FieldInterface> {

  constructor(protected override data: FieldInterface) {
    super(data);
  }

  getId(): string {
    return this.data.Id;
  }

  getName(): string {
    return this.data.FieldName;
  }

}
