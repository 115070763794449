import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'apx-ui-shared-upload-box',
  templateUrl: './upload-box.component.html',
  styleUrls: ['./upload-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadBoxComponent implements OnInit {
  @Input() fileName: string;
  @Input() downloadable = true;
  @Output() dragEnd: EventEmitter<FileList> = new EventEmitter();
  @Output() download: EventEmitter<void> = new EventEmitter();
  @Output() selectFile: EventEmitter<void> = new EventEmitter();

  dragAreaClass: string;

  ngOnInit(): void {
    this.dragAreaClass = 'drag';
  }

  @HostListener('dragover', ['$event']) onDragOver(event: any): void {
    this.dragAreaClass = 'drop';
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event: any): void {
    this.dragAreaClass = 'drop';
    event.preventDefault();
  }

  @HostListener('dragend', ['$event']) onDragEnd(event: any): void {
    this.dragAreaClass = 'drag';
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: any): void {
    this.dragAreaClass = 'drag';
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) onDrop(event: any) : void{
    this.dragAreaClass = 'drag';
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer?.files) {
      this.dragEnd.emit(event.dataTransfer.files);
    }
  }

  onDownloadClick(): void {
    if (this.downloadable) {
      this.download.emit();
    }
  }

  onFileAreaClick(): void {
    if (!this.fileName) {
      this.selectFile.emit();
    }
  }
}
