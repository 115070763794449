import { Injectable } from '@angular/core';
import { ClientOptions, RouterActions } from '@apx-ui/apx-core';
import { NotificationActions } from '@apx-ui/apx-shared-ui';
import { Account, ApxSolsticeWebClientService, Asset } from '@apx-ui/apx-web-api-v1';
import { EntityOp, ofEntityOp, ofEntityType } from '@ngrx/data';
import { EntityAction } from '@ngrx/data/src/actions/entity-action';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, Observable, of, switchMap } from 'rxjs';

import { AssetActions } from '../actions';

function createRecords(account: Account, well: Asset): Observable<any> {
  return of(
    AssetActions.addAsset({ asset: well }),
    AssetActions.createWellSuccess({ accountId: account.Id, well }),
    NotificationActions.success({
      message: 'Asset was successfully created.',
    }),
    RouterActions.navigate({
      path: [ '/', account.Id, 'manage', 'assets', well.Id],
    }),
  );
}

function updateRecords(accountId: string, well: Asset): Observable<any> {
  return of(
    AssetActions.addAsset({ asset: well }),
    AssetActions.updateWellSuccess({ accountId, well }),
    NotificationActions.success({
      message: 'Asset was successfully updated.',
    }),
    RouterActions.navigate({
      path: [ '/', accountId, 'manage', 'assets', well.Id],
      query: { edit: Date.now() },
    }),
  );
}

function wellPayload(account: Account, well: Asset, wellType: string): Asset {
  return {
    AccountId: account.Id,
    LocationType: wellType,
    PlantId: account.PlantID,
    ShipTo: well.ShipTo ?? account.ShipTo,
    SoldTo: well.SoldTo ?? account.SoldTo,
    LocationName: wellType === 'Non-Well'
      // eslint-disable-next-line max-len
      ? `${well.SiteName ? `${well.SiteName}.` : ''}${well.LocationDescription}${well.UIDNumber ? `.${well.UIDNumber}` : ''}`
      : well.LocationName,
    Job: [] as any,
    OilProductionFluid: null,
    Samples: [],
    StimulationJobs: [],
    BatchTreatments: [],
    ContinuousTreatments: [],
    WaterProductionFluid: null,
    APINumber: well.APINumber ?? null,
    GasProductionFluid: null,
    Id: '',
    ...well,
  };
}

@Injectable()
export class LoadAssetsEffects {

  handleAssetsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AssetActions.loadAssets,
      ),
      switchMap(({ accountId, unitSystemID }) => {
        const options: ClientOptions = {
          params: {
            unitSystemID: unitSystemID,
          },
        };

        return this.client.getAssets(accountId, options).pipe(
          switchMap(assets => {

            return [
              AssetActions.loadAssetsSuccess({ assets }),
              AssetActions.addAssets({ assets }),
            ];
          }),
          catchError(err => of(AssetActions.loadAssetsFailure({ err }))),
        );
      }),
    ),
  );

  handleAssetLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AssetActions.loadAssetById,
      ),
      switchMap(({ assetId, unitSystemID }) => {
        const options: ClientOptions = {
          params: {
            ...unitSystemID && { unitSystemID: unitSystemID },
          },
        };

        return this.client.getAssetsById(assetId, options).pipe(
          switchMap(asset => {

            return [
              AssetActions.loadAssetByIdSuccess({ asset, assetId: asset.Id }),
              AssetActions.addAsset({ asset }),
            ];
          }),
          catchError(err => of(AssetActions.loadAssetByIdFailure({ err, assetId: '' }))),
        );
      }),
    ),
  );

  handleCreateWell$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AssetActions.createWell,
      ),
      switchMap(({ account, well, wellType }) => {

        return this.client.createWell(account.Id, { ...well, ...wellPayload(account, well as any, wellType) } as any)
          .pipe(
            switchMap(res => createRecords(account, res)),
            catchError(err => of(
              AssetActions.createWellFailure({ accountId: account.Id, well, err }),
              NotificationActions.error({
                message: `Cannot create asset ${err?.error}.`,
              }),
            )),
          );
      }),
    ),
  );

  handleUpdateWell$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AssetActions.updateWell,
      ),
      switchMap(({ accountId, well }) => {

        return this.client.updateWell(accountId, { ...well, PlantId: (well as any).PlantNumber } as any).pipe(
          switchMap(res => updateRecords(accountId, res)),
          catchError(err => of(
            AssetActions.updateWellFailure({ accountId, well, err }),
            NotificationActions.error({
              message: `Cannot create asset ${err?.error}.`,
            }),
          )),
        );
      }),
    ),
  );

  handleAssetsLoadForUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AssetActions.loadAssetsForUser,
      ),
      switchMap(() => {

        return this.client.getAssetsForUser().pipe(
          switchMap(assets => {

            return [
              AssetActions.loadAssetsForUserSuccess({ assets }),
            ];
          }),
          catchError(err => of(AssetActions.loadAssetsForUserFailure({ err }))),
        );
      }),
    ),
  );

  handleAssetLoadByKey$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType('AssetsList') as any,
      ofEntityOp(EntityOp.QUERY_BY_KEY_SUCCESS),
      switchMap((data: EntityAction<Asset>) => {
        return [
          AssetActions.addAsset({ asset: data.payload.data }),
        ];
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private readonly client: ApxSolsticeWebClientService,
  ) {
  }

}
