import { Injectable } from '@angular/core';
import { Account } from '@apx-ui/apx-web-api-v1';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { CreateAccountFormData, CreateAccountStep, OperationType } from '../../interfaces';
import { CreateAccountActions } from '../actions';
import {
  selectCreateAccountFormData,
  selectCreateAccountMessage,
  selectCreateAccountResult,
  selectCreateAccountStep,
  selectEditedAccount,
  selectOperationType,
} from '../selectors';

@Injectable()
export class CreateAccountService {

  close$: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store<never>) {
  }

  getStep$(): Observable<CreateAccountStep> {
    return this.store.select(selectCreateAccountStep);
  }

  getResult$(): Observable<Account | null> {
    return this.store.select(selectCreateAccountResult);
  }

  getFormData$(): Observable<any> {
    return this.store.select(selectCreateAccountFormData);
  }

  getEditedAccount$(): Observable<Account | null> {
    return this.store.select(selectEditedAccount);
  }

  getOperationType$(): Observable<OperationType> {
    return this.store.select(selectOperationType);
  }

  getMessage$(): Observable<string> {
    return this.store.select(selectCreateAccountMessage);
  }

  getClose$(): Observable<boolean> {
    return this.close$;
  }

  setStep(step: CreateAccountStep): void {
    this.store.dispatch(CreateAccountActions.setStep({ step }));
  }

  initEditForm(formData: CreateAccountFormData, account: Account): void {
    this.store.dispatch(CreateAccountActions.initEditForm({ formData, account }));
  }

  saveCreateForm(account: CreateAccountFormData): void {
    this.store.dispatch(CreateAccountActions.saveCreateForm({ account }));
  }

  saveConfirm(account: CreateAccountFormData): void {
    this.store.dispatch(CreateAccountActions.saveConfirm({ account }));
  }

  updateConfirm(formData: CreateAccountFormData, account: Account | null): void {
    this.store.dispatch(CreateAccountActions.updateConfirm({ formData, account }));
  }

  destroy(): void {
    this.store.dispatch(CreateAccountActions.destroy());
  }

}
