import { Injectable } from '@angular/core';
import { ApxSolsticeWebClientService } from '@apx-ui/apx-web-api-v1';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap } from 'rxjs';

import { OrdersForDeliveryActions } from '../actions';

@Injectable()
export class OrdersForDeliveryEffects {

  handleOrdersDeliveryLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrdersForDeliveryActions.loadOrdersForDelivery,
      ),
      switchMap(({ unitSystemId }) => {
        const params = {
          ...unitSystemId && { unitSystemID: unitSystemId },
        };

        return this.client.getActiveDelivery({ params }).pipe(
          switchMap(deliveryData => {

            return [
              OrdersForDeliveryActions.loadOrdersForDeliverySuccess({ deliveryData }),
            ];
          }),
          catchError(err => of(
            OrdersForDeliveryActions.loadOrdersForDeliveryFailure({ err }),
          )),
        );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly client: ApxSolsticeWebClientService,
  ) {
  }

}
