import { HttpErrorResponse } from '@angular/common/http';
import { Account } from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

export const loadAccounts = createAction(
  '[APX Accounts] Load',
);

export const loadAccountsSuccess = createAction(
  '[APX Accounts] Load Success',
  props<{
    accounts: Account[];
  }>(),
);

export const loadAccountsFailure = createAction(
  '[APX Accounts] Load Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const addAccount = createAction(
  '[APX Accounts] Add Account',
  props<{
    account: Account;
  }>(),
);
