import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultPluralizer, EntityDefinitionService, Pluralizer } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';

import { entityMetadataMap, pluralNames } from './stores';
import { effects } from './stores/effects';

@NgModule({
  imports: [
    CommonModule,

    EffectsModule.forFeature(effects),
  ],
})
export class ApxUserSettingsStoreModule {
  constructor(
    pluralizer: Pluralizer,
    entityDefinitionService: EntityDefinitionService,
  ) {

    entityDefinitionService.registerMetadataMap(entityMetadataMap);
    (pluralizer as DefaultPluralizer).registerPluralNames(pluralNames);

  }
}
