<form *ngIf="form"
      [formGroup]="form">

  <div fxLayout="column"
       fxLayoutAlign="start start">

    <apx-ui-shared-multi-select *ngIf="showCustomer"
                                placeholder="Customer Name"
                                formControlName="customerName"
                                data-test-id="customerName"
                                [items]="filters.Asset.CustomerNames">
    </apx-ui-shared-multi-select>

    <apx-ui-shared-multi-select placeholder="Field Name"
                                formControlName="fieldName"
                                data-test-id="fieldName"
                                [items]="filters.Asset.Field">
    </apx-ui-shared-multi-select>

    <apx-ui-shared-multi-select placeholder="Site Name"
                                formControlName="siteName"
                                data-test-id="siteName"
                                bindLabel="SiteName"
                                [items]="sitesList$ | async">
    </apx-ui-shared-multi-select>

    <apx-ui-shared-multi-select placeholder="Asset Name"
                                formControlName="assetName"
                                data-test-id="assetName"
                                bindLabel="AssetName"
                                [items]="assetList$ | async">
    </apx-ui-shared-multi-select>

    <apx-ui-shared-multi-select placeholder="Route"
                                formControlName="route"
                                data-test-id="route"
                                [items]="filters.Asset.Route">
    </apx-ui-shared-multi-select>

    <mat-radio-group formControlName="locationType"
                     class="mb-20">
      <mat-radio-button color="primary"
                        class="mr-32"
                        data-test-id="well"
                        [value]="'Well'">
        Well
      </mat-radio-button>

      <mat-radio-button color="primary"
                        data-test-id="non-well"
                        class="mr-32"
                        [value]="'Non-Well'">
        Non-Well
      </mat-radio-button>

      <mat-radio-button color="primary"
                        data-test-id="non-well"
                        [value]="null">
        Both
      </mat-radio-button>
    </mat-radio-group>

    <apx-ui-shared-multi-select placeholder="Asset Description"
                                formControlName="assetDescription"
                                data-test-id="assetDescription"
                                [items]="getAssetDescription()">
    </apx-ui-shared-multi-select>

    <apx-ui-shared-multi-select placeholder="Custom Tag"
                                formControlName="customTag"
                                data-test-id="customTag"
                                bindLabel="AssetName"
                                [items]="filters.Asset.aggregatedCustomTags">
    </apx-ui-shared-multi-select>

  </div>

</form>
