import { AccountModel } from '@apx-ui/apx-web-api-v1';
import { createSelector } from '@ngrx/store';

import * as fromReducer from '../reducers';
import { selectAccountEntities } from './account.selectors';

export const selectManageAccountsStatus = createSelector(
  fromReducer.selectManageAccountsState,
  state => state.status,
);

export const selectManageAccounts = createSelector(
  fromReducer.selectManageAccountsState,
  selectAccountEntities,
  (s, e) => s.accounts.map(a => new AccountModel(e[a] as never)),
);
