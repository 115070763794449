import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'apx-ui-shared-list',
  templateUrl: './list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent {
  @Input() items?: any[];
  @Input() field?: string;
}
