import { AbstractModel } from '@apx-ui/apx-core';

import { Product } from '../interfaces';

export class ProductModel extends AbstractModel<Product> {

  constructor(protected override data: Product) {
    super(data);
  }

  getProductName(): string {
    return this.data.ProductName;
  }

  getId(): string {
    return this.data.Id;
  }

  getProductType(): string {
    return this.data.ProductType;
  }

  getPackagesIds(): string[] {
    return this.data.packageIds;
  }

  getUnitSystemId(): string {
    return this.data.unitSystemId;
  }

  getUnitTypeId(): string {
    return this.data.unitTypeId;
  }

  getUomName(): string {
    return this.data.uomName;
  }

  getuoMPerDayAbbr(): string {
    return this.data.uoMPerDayAbbr;
  }
}
