import { Account } from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

import {
  CreateAccountFormData,
  CreateAccountStep,
} from '../../interfaces';

export const setStep = createAction(
  '[APX Create Account Dialog] Set Step',
  props<{
    step: CreateAccountStep;
  }>(),
);

export const initEditForm = createAction(
  '[APX Create Account Dialog] Init Edit Form',
  props<{
    formData: CreateAccountFormData;
    account: Account;
  }>(),
);

export const saveCreateForm = createAction(
  '[APX Create Account Dialog] Create Form',
  props<{
    account: CreateAccountFormData;
  }>(),
);

export const saveConfirm = createAction(
  '[APX Create Account Dialog] Save Confirm',
  props<{
    account: CreateAccountFormData;
  }>(),
);

export const updateConfirm = createAction(
  '[APX Create Account Dialog] Update Confirm',
  props<{
    formData: CreateAccountFormData;
    account: Account | null;
  }>(),
);

export const success = createAction(
  '[APX Create Account Dialog] Success',
  props<{
    accountData: Account;
  }>(),
);

export const failure = createAction(
  '[APX Create Account Dialog] Failure',
  props<{
    message: string;
  }>(),
);

export const destroy = createAction(
  '[APX Create Account Dialog] Destroy',
);
