export interface AllowedMimeTypes {
  'mime': string;
  'extension': string;
}

export enum FILE_FORMATS {
  Excel = 'excel',
  Csv = 'csv'
}

export const  MIME_TYPES = {
  [FILE_FORMATS.Excel]: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  [FILE_FORMATS.Csv]: 'text/csv',
};
