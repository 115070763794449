<div *ngIf="showResolved"
     fxLayout="column"
     class="apx-ui-shared-state-container__resolved"
     data-test-id="resolved"
     [@slideInOut]="'in'">
  <ng-container *ngTemplateOutlet="resolved ? resolved : resolvedDefault"></ng-container>
</div>

<div *ngIf="showRejected"
     fxLayout="column"
     class="apx-ui-shared-state-container__rejected"
     data-test-id="rejected"
     [@slideInOut]="'in'">
  <ng-container *ngTemplateOutlet="rejected ? rejected : rejectedDefault"></ng-container>
</div>

<div *ngIf="showPending"
     fxLayout="column"
     class="apx-ui-shared-state-container__pending"
     data-test-id="pending"
     [@slideInOut]="'in'"
     [style.min-height]="height">
  <ng-container *ngTemplateOutlet="pending ? pending : pendingDefault"></ng-container>
</div>

<div *ngIf="showUpdating"
     fxLayout="column"
     class="apx-ui-shared-state-container__updating"
     data-test-id="updating"
     [@fadeInOut]="'in'">
  <ng-container *ngTemplateOutlet="updating ? updating : updatingDefault"></ng-container>
</div>

<ng-template #resolvedDefault>
  <pre>{{ state | json }}</pre>
</ng-template>

<ng-template #rejectedDefault>
  <apx-ui-shared-error-placeholder [error]="defaultStatus?.err"></apx-ui-shared-error-placeholder>
</ng-template>

<ng-template #pendingDefault>
  <apx-ui-shared-loader></apx-ui-shared-loader>
</ng-template>

<ng-template #updatingDefault>
  <ng-container *ngIf="allowShowUpdating">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-container>
</ng-template>
