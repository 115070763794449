import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, Router, UrlTree } from '@angular/router';
import { RouterStateService } from '@apx-ui/apx-core';
import { AccountUnitSystemService } from '@apx-ui/apx-user-settings-store';
import { filter, map, Observable, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AssetListService, AssetService } from '../services';

@Injectable()
export class AssetGuard implements CanActivate, CanDeactivate<any> {

  private currentAssetId?: string;

  constructor(
    private readonly router: Router,
    private readonly assetService: AssetService,
    private readonly assetListService: AssetListService,
    private readonly routerState: RouterStateService,
    private readonly accountUnitSystemService: AccountUnitSystemService,
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> {

    return this.routerState.getParams$().pipe(
      filter(params => !!params.accountId),
      switchMap(params => this.accountUnitSystemService.getByKey(params.accountId).pipe(
        switchMap(aus => {
          if (params.assetId === 'add') {
            return of(true);
          }

          return this.assetListService.getByKey(`${params.assetId}?unitSystemId=${aus.unitSystemId}`).pipe(
            map(asset => {
              this.currentAssetId = asset.Id;
              this.assetService.setAsset(asset.Id);

              return true;
            }),
          );

        }),
        catchError(() => {
            return of(
              this.router.createUrlTree(['/error'], {
                queryParams: { type: 503 },
              }),
            );
          }),
    )));
  }

  canDeactivate(): Observable<boolean> {
    if (this.currentAssetId) {
      this.assetService.deselectAsset();
      this.currentAssetId = undefined;
    }

    return of(true);
  }

}
