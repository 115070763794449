import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { Component, HostBinding } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'apx-ui-shared-ag-grid-error-tooltip',
  templateUrl: './ag-grid-error-tooltip.component.html',
  styleUrls: ['./ag-grid-error-tooltip.component.scss'],
})
export class AgGridErrorTooltipComponent implements ITooltipAngularComp {
  params: any;
  data: any;
  defaultColor: any;
  valueToDisplay: SafeHtml;
  @HostBinding('style.border') ngBorder = '0.0625rem solid #cc0000';
  @HostBinding('style.max-width') maxWidth = '100%';

  constructor(protected sanitizer: DomSanitizer) {
  }

  agInit(params): void {
    this.params = params;

    this.data = params.api.getDisplayedRowAtIndex(params.rowIndex)?.data;
    if (this.data && this.params && this.params.color) {
      this.defaultColor = this.params.color;
      this.data.color = this.defaultColor;
    }

    if (!this.params.isErrorTooltip) {
      this.ngBorder = '0.0625rem solid #a8afb5';
      this.maxWidth = '31.25em';
    }

    this.valueToDisplay = this.sanitizer.bypassSecurityTrustHtml(params.value);
  }

}
