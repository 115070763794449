<ng-container *ngIf="{
        notifications: notifications$ | async,
        status: status$ | async
        } as dataNotifications">

  <div fxLayout="row"
       fxLayoutAlign="space-between center"
       class="px-16 py-8">
    <div class="pr-16">Past Due {{data.sub_category}}</div>
    <div class="text-right">
      {{data.checked_date | date: 'MM/dd/yyyy' : 'UTC+0' }}
      <span *ngIf="dataNotifications?.notifications?.length"
            class="d-inline-block">
        {{dataNotifications.notifications[0]?.checkedDateTime | date: 'hh:mm a' }}
      </span>
    </div>
  </div>

  <mat-divider color="primary"></mat-divider>

  <apx-ui-shared-state-container height="300px"
                       [resolved]="resolved"
                       [state]="dataNotifications.status">
    <ng-template #resolved>
      <apx-ui-notifications-summary-continuous-desktop fxHide
                                                       fxShow.gt-sm
                                                       [notifications]="dataNotifications.notifications">
      </apx-ui-notifications-summary-continuous-desktop>

      <apx-ui-notifications-summary-continuous-mobile fxShow
                                                      fxHide.gt-sm
                                                      [notifications]="dataNotifications.notifications">
      </apx-ui-notifications-summary-continuous-mobile>
    </ng-template>
  </apx-ui-shared-state-container>
</ng-container>
