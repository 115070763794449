import { Injectable } from '@angular/core';
import { Status } from '@apx-ui/apx-core';
import { AccountFilter } from '@apx-ui/apx-web-api-v1';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AccountFiltersActions } from '../actions';
import { selectAccountFilters, selectAccountFiltersStatus } from '../selectors';

@Injectable()
export class AccountFiltersStateService {

  constructor(
    private store: Store<any>,
  ) {
  }

  loadAccountFilters(): void {
    this.store.dispatch(AccountFiltersActions.loadAccountFilters());
  }

  getAccountFilters$(): Observable<AccountFilter | null> {
    return this.store.pipe(select(selectAccountFilters));
  }

  getStatus$(): Observable<Status> {
    return this.store.pipe(select(selectAccountFiltersStatus));
  }

}
