import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CancelDialogData {
  title: string;
  body: string;
  rejectBtn?: string;
}

@Component({
  selector: 'apx-ui-shared-cancel-dialog',
  templateUrl: './cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelDialogComponent implements OnInit {

  public title = '';
  public body = '';
  public rejectBtn = 'Cancel';

  public constructor(@Inject(MAT_DIALOG_DATA) private data: CancelDialogData,
  ) {
  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.body = this.data.body;
    this.rejectBtn = this.data.rejectBtn ?? this.rejectBtn;
  }

}
