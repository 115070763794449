import { InjectionToken } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';

export interface BreadCrumb {
  text: string;
  url: string[];
  queryParams?: Params;
  textField?: boolean;
  currentFolder?: boolean;
}

export interface BreadcrumbsProvider {
  getBreadcrumbs(): Observable<BreadCrumb[]>;
}

export const BREADCRUMBS_PROVIDER = new InjectionToken<BreadcrumbsProvider[]>('BreadcrumbsProvider');
