import { IDateAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams, IDateParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import moment from 'moment';

interface InitParams extends ICellEditorParams {
  pickerType: string;
  placeholder: string;
}

@Component({
  selector: 'apx-ui-shared-custom-date',
  templateUrl: './data-time-picker-cell.component.html',
  styleUrls: ['./data-time-picker-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTimePickerCellComponent implements IDateAngularComp {

  pickerType: string;
  dateCtrl: UntypedFormControl = new UntypedFormControl();
  private params: InitParams & IDateParams;

  agInit(params: InitParams & IDateParams): void {
    this.params = params;
    this.pickerType = params.pickerType;
    this.dateCtrl.setValue(moment(params.value).format('HH:mm'));
  }

  getDate(): Date {
    return this.dateCtrl.value;
  }

  setDate(date: Date): void {
    this.dateCtrl.setValue(date ?? null);
  }

  getValue(): string {
    const [hours, minutes] = this.dateCtrl.value.split(':');
    return moment().set({ hours, minutes }).format();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  afterGuiAttached(): void {
  }
}
