import { Status } from '@apx-ui/apx-core';
import { GenericFilter } from '@apx-ui/apx-web-api-v1';
import { createReducer, on } from '@ngrx/store';

import { AssetFilters } from '../actions';

export interface AssetFilterStates {
  assetFilter: GenericFilter | null;
  status: {
    asset: Status;
  };
}

export const initialState: AssetFilterStates = {
  assetFilter: null,
  status: {
    asset: {
      pending: false,
      resolved: true,
      rejected: false,
      err: null,
    },
  },
};

export const reducer = createReducer(
  initialState,
  on(AssetFilters.loadAssetFilters,
    state => {
      return {
        ...state,
        status: {
          ...state.status,
          asset: {
            pending: true,
            resolved: false,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(AssetFilters.loadAssetFiltersSuccess,
    (state, { filters }) => {
      return {
        ...state,
        assetFilter: filters,
        status: {
          ...state.status,
          asset: {
            pending: false,
            resolved: true,
            rejected: false,
            err: null,
          },
        },
      };
    },
  ),
  on(AssetFilters.loadAssetFiltersFailure,
    (state, { err }) => {
      return {
        ...state,
        status: {
          ...state.status,
          asset: {
            pending: false,
            resolved: false,
            rejected: true,
            err,
          },
        },
      };
    },
  ),
  on(AssetFilters.clear,
    state => ({
      ...state,
      ...initialState,
    }),
  ),
);
