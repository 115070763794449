import { HttpErrorResponse } from '@angular/common/http';
import { Notifications } from '@apx-ui/apx-web-api-v1';
import { createAction, props } from '@ngrx/store';

export const loadNotificationsCount = createAction(
  '[APX Notifications] Load Notifications Count',
  props<{
    accountId?: string;
  }>(),
);

export const loadNotificationsCountSuccess = createAction(
  '[APX Notifications] Load Notifications Success Count',
  props<{
    accountId: string;
    count: number;
  }>(),
);

export const loadNotificationsCountFailure = createAction(
  '[APX Notifications] Load Notifications Failure Count',
  props<{
    accountId: string;
    err: HttpErrorResponse;
  }>(),
);

export const loadNotifications = createAction(
  '[APX Notifications] Load Notifications',
  props<{
    accountId?: string;
  }>(),
);

export const loadNotificationsSuccess = createAction(
  '[APX Notifications] Load Notifications Success',
  props<{
    accountId: string;
    notifications: Notifications;
  }>(),
);

export const loadNotificationsFailure = createAction(
  '[APX Notifications] Load Notifications Failure',
  props<{
    accountId: string;
    err: HttpErrorResponse;
  }>(),
);

export const markNotifications = createAction(
  '[APX Notifications] Mark Notifications',
  props<{
    ids: { id: string }[];
    accountId: string;
  }>(),
);

export const markNotificationsSuccess = createAction(
  '[APX Notifications] Mark Notifications Success',
  props<{
    ids: { id: string }[];
  }>(),
);

export const markNotificationsFailure = createAction(
  '[APX Notifications] Mark Notifications Failure',
  props<{
    err: HttpErrorResponse;
  }>(),
);

export const clear = createAction(
  '[APX Notifications] Clear',
);

export const exportBatchOrderNotifications = createAction(
  '[APX Notifications] Export Expired Batch Order Notifications',
  props<{
    accountId: string;
    checkedDate: string;
    unitSystemId: string;
  }>(),
);

export const exportContinuousOrderNotifications = createAction(
  '[APX Notifications] Export Expired Continuous Order Notifications',
  props<{
    accountId: string;
    checkedDate: string;
    unitSystemId: string;
  }>(),
);

export const exportSamplesNotifications = createAction(
  '[APX Notifications] Export Expired Samples Notifications',
  props<{
    accountId: string;
    checkedDate: string;
    subCategory: string;
  }>(),
);
