import {
  ApxOrder,
  BatchOrder,
  ContinuousOrder,
  DELIVERY_STATUS,
  IDeliveryOrderProduct,
  StimulationOrder,
  TaskOrder,
} from '../interfaces';
import { ContinuousOrderModel } from './continuous-order.model';

export const orderTemplate = {
  CustomerName: '',
  TargetVolume: null,
  OrderId: null,
  DeliveryCaptured: false,
  PreTankLevel: null,
  PostTankLevel: null,
  ReasonId: null,
  Comment: null,
  CompanySoldTo: null,
  AssetShipToId: null,
  OrderType: null,
  TankId: null,
  TankName: null,
  LocationId: null,
  LocationName: null,
  FlushVolume: null,
  CapturedTimeStamp: null,
  Id: null,
  CriticalDate: null,
  EmptyDate: null,
  DeliveryStatus: null,
  Route: null,
  Field: null,
  VolumeUoM: null,
  PrimaryProduct: null,
  DilutionProduct: null,
  Flush: null,
  DueDate: null,
  InjectionPoint: null,
  SoldTo: null,
  SiteName: null,
  AssetType: null,
  AssetDescription: null,
  AccountCustomTags: null,
  GeoLocation: null,
  AccountRoles: [],
  AccountId: null,
  TimeZone: null,
  Frequency: null,
};

export class OrdersFactory {
  protected order: TaskOrder;

  static create(order: ApxOrder): OrdersFactory | null {
    switch (order.DeliveryType) {
      case 'Continuous':
        return new ContinuousDeliveryOrder(order as ContinuousOrder);
      case 'Batch':
        return new BatchDeliveryOrder(order as BatchOrder);
      case 'Stimulation':
        return new StimulationDeliveryOrder(order as StimulationOrder);
      default:
        return null;
    }
  }

  createOrder(): any {
    return {
      ...orderTemplate,
      TargetVolume: this.order.TargetVolume,
      OrderId: this.order.Id,
      Comment: this.order.Comment || null,
      OrderType: this.order.DeliveryType,
      LocationId: this.order.LocationId,
      LocationName: this.order.LocationName,
      Id: this.order.Id,
      DeliveryStatus: DELIVERY_STATUS.OUT_OF_DELIVERY,
      Route: this.order.Route,
      Field: this.order.Field,
      VolumeUoM: this.order.VolumeUoM,
      AccountId: this.order.AccountId,
      assignmentId: this.order.assignmentId,
    };
  }

  getDeliveryProduct(): IDeliveryOrderProduct {
    return  {
      CapturedVolume: null,
      DilutionPercentage: 0,
      InjectionPoint: null,
      LocationId: this.order.LocationId,
      LocationName: this.order.LocationName,
      OrderId: this.order.Id,
      ProductId: this.order.PrimaryProduct.ProductId,
      ProductName: this.order.PrimaryProduct.ProductName,
      ReconciledVolume: null,
      TankId: null,
      TargetVolume: this.order.TargetVolume,
      TotalDeliveryVolume: 0,
      UofM: this.order.PrimaryProduct.UofM,
    };
  }
}

export class ContinuousDeliveryOrder extends OrdersFactory {
  constructor(protected override order: ContinuousOrder) {
    super();
  }

  override createOrder(): any {
    return {
      ...super.createOrder(),
      Frequency: this.order.Frequency,
      CriticalDate: this.order.CriticalDate,
      EmptyDate: this.order.EmptyDate,
      PrimaryProduct: this.getDeliveryProduct(),
      DilutionProduct: this.getDilutionProducts(),
    };
  }

  override getDeliveryProduct(): IDeliveryOrderProduct {
    return  {
      ...super.getDeliveryProduct(),
      DilutionPercentage: this.order.PrimaryProduct.DilutionPercentage,
      TargetVolume: ContinuousOrderModel.calcTargetVolumeBase(this.order.PrimaryProduct, this.order),
    };
  }

  getDilutionProducts(): IDeliveryOrderProduct[] | null {
    return this.order.DilutionProduct.map(d => ({
      CapturedVolume: null,
      DilutionPercentage: d.DilutionPercentage,
      InjectionPoint: null,
      LocationId: this.order.LocationId,
      LocationName: this.order.LocationName,
      OrderId: this.order.Id,
      ProductId: d.ProductId,
      ProductName: d.ProductName,
      ReconciledVolume: null,
      TankId: null,
      TargetVolume: ContinuousOrderModel.calcTargetVolumeBase(d, this.order),
      TotalDeliveryVolume: 0,
      UofM: d.UofM,
    }));
  }

}

export class BatchDeliveryOrder extends OrdersFactory {
  constructor(protected override order: BatchOrder) {
    super();
  }

  override createOrder(): any {
    return {
      ...super.createOrder(),
      PrimaryProduct: this.getDeliveryProduct(),
      DueDate: this.order.DueDate,
      InjectionPoint: this.order.InjectionPoint,
      Flush: this.order.Flush,
    };
  }

  override getDeliveryProduct(): IDeliveryOrderProduct {
    return  {
      ...super.getDeliveryProduct(),
      InjectionPoint: this.order.InjectionPoint,
    };
  }
}

export class StimulationDeliveryOrder extends OrdersFactory {
  constructor(protected override order: StimulationOrder) {
    super();
  }

  override createOrder(): any {
    return {
      ...super.createOrder(),
      PrimaryProduct: this.getDeliveryProduct(),
      InjectionPoint: this.order.InjectionPoint,
    };
  }

  override getDeliveryProduct(): IDeliveryOrderProduct {
    return  {
      ...super.getDeliveryProduct(),
      InjectionPoint: this.order.InjectionPoint,
    };
  }

}
