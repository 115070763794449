<form *ngIf="form"
      [formGroup]="form">

  <div fxLayout="column"
       fxLayoutAlign="start start">

<!--    <mat-form-field appearance="outline">-->
<!--      <mat-label>Injection Point</mat-label>-->
<!--      <mat-select formControlName="injectionPoint"-->
<!--                  data-test-id="injectionPoint"-->
<!--                  multiple>-->
<!--        <mat-option *ngFor="let filter of filters.FieldTask.InjectionPoint"-->
<!--                    [value]="filter">-->
<!--          {{filter}}-->
<!--        </mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->

    <apx-ui-shared-multi-select placeholder="Injection Point"
                                formControlName="injectionPoint"
                                data-test-id="injectionPoint"
                                [items]="filters.FieldTask.InjectionPoint">
    </apx-ui-shared-multi-select>

    <apx-ui-shared-multi-select *ngIf="!disableTank"
                                placeholder="Tank"
                                formControlName="tank"
                                data-test-id="tank"
                                bindLabel="Tank"
                                [items]="filters.FieldTask.Tank">
    </apx-ui-shared-multi-select>

    <mat-form-field appearance="outline">
      <mat-label>Treatment Frequency</mat-label>
      <mat-select formControlName="frequencies"
                  data-test-id="frequencies"
                  multiple>
        <mat-option *ngFor="let item of filters.FieldTask.Frequencies"
                    [value]="item">
          {{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>
</form>
