import { Injectable } from '@angular/core';
import { BaseEntityCollectionService } from '@apx-ui/apx-core';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { BatchOrder } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class BatchOrdersService extends BaseEntityCollectionService<BatchOrder> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('BatchOrder', serviceElementsFactory);
  }

}
