import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BatchOrderNotification } from '@apx-ui/apx-notifications-store';

@Component({
  selector: 'apx-ui-notifications-summary-batch-mobile',
  templateUrl: './apx-notifications-summary-batch-mobile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApxNotificationsSummaryBatchMobileComponent {
  @Input() notifications: BatchOrderNotification[];
  displayedColumns: string[] = [
    'icon',
    'location',
    'treatment',
    'delivery',
  ];
}
