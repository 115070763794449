<mat-nav-list *ngIf="{
 expanded: expanded$ | async,
 isDeliveryActive: isDeliveryActive$ | async,
 showStimulation: showStimulation$ | async
} as data">

  <mat-list-item routerLinkActive="active-nav-list-item"
                 routerLink="task-list">
    <span *ngIf="data.expanded">Task List</span>
    <mat-icon mat-list-icon
              fontSet="material-icons-outlined"
              class="mr-12"
              title="Task list">
      task
    </mat-icon>
  </mat-list-item>

  <mat-list-item routerLinkActive="active-nav-list-item"
                 routerLink="generate-label-ids">
    <span *ngIf="data.expanded">Label IDs</span>
    <mat-icon mat-list-icon
              fontSet="material-icons-outlined"
              class="mr-12"
              title="Label IDs"
              svgIcon="barcode">
    </mat-icon>
  </mat-list-item>

  <mat-list-item routerLinkActive="active-nav-list-item"
                 routerLink="activity-log">
    <span *ngIf="data.expanded">Activity Log</span>
    <mat-icon mat-list-icon
              fontSet="material-icons-outlined"
              class="mr-12"
              title="Activity log">
      article
    </mat-icon>
  </mat-list-item>

  <mat-list-item routerLinkActive="active-nav-list-item"
                 routerLink="./assignments">
    <span *ngIf="data.expanded">Assignments</span>
    <mat-icon mat-list-icon
              fontSet="material-icons-checklist"
              class="mr-12"
              title="Assignment">
      <span class="material-icons">
        checklist
      </span>
    </mat-icon>
  </mat-list-item>

  <mat-list-item routerLinkActive="active-nav-list-item"
                 routerLink="./orders">
    <span *ngIf="data.expanded">Orders</span>
    <mat-icon mat-list-icon
              fontSet="material-icons-outlined"
              class="mr-12"
              title="Orders">
      view_list
    </mat-icon>
  </mat-list-item>

  <div *ngIf="data.expanded">
    <a mat-list-item routerLinkActive="active-nav-list-item"
       routerLink="./orders/continuous">
      <span class="pl-44">Continuous</span>
    </a>
    <a mat-list-item
       routerLinkActive="active-nav-list-item"
       routerLink="./orders/batch">
      <span class="pl-44">Batch</span>
    </a>
    <!-- TODO: return back when stim treatment will be fixed-->
    <!-- <a *ngIf="data.showStimulation && !data.isDeliveryActive" -->
       <!-- mat-list-item -->
       <!-- routerLinkActive="active-nav-list-item" -->
       <!-- routerLink="./orders/stimulation"> -->
      <!-- <span class="pl-44">Stimulation</span> -->
    <!-- </a> -->
    <a *ngIf="data.isDeliveryActive"
       mat-list-item
       routerLinkActive="active-nav-list-item"
       routerLink="./orders/delivery">
      <span class="pl-44">Delivery List</span>
    </a>
  </div>

  <mat-list-item routerLinkActive="active-nav-list-item"
                 routerLink="support">
    <span *ngIf="data.expanded">Support</span>
    <mat-icon mat-list-icon
              fontSet="material-icons-outlined"
              class="mr-12"
              title="Support">
      info
    </mat-icon>
  </mat-list-item>

</mat-nav-list>
