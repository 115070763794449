export enum SuspendDialogType {
  Continuous = 'Continuous Treatment',
  Batch = 'Batch Treatment',
  Sample = 'Sample',
  Asset = 'Asset'
}

export interface SuspendReason {
  Id: string;
  Name: string;
}

export interface SuspendReasonForm {
  reason: string;
  comment?: string;
}
