import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApxConfigModule } from '@apx-ui/apx-config';
import { StoreModule } from '@ngrx/store';

import { clients } from './clients';
import { guards } from './guards';
import { featureName, reducers } from './stores/reducers';
import { states } from './stores/states';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,

    StoreModule.forFeature(featureName, reducers),

    ApxConfigModule,
  ],
})
export class ApxWebApiV1Module {

  static forRoot(): ModuleWithProviders<ApxWebApiV1Module> {
    return {
      ngModule: ApxWebApiV1Module,
      providers: [
        ...clients,
        ...states,
        ...guards,
      ],
    };
  }

}
