import { AgEditorComponent } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

interface InitParams extends ICellEditorParams {
  field: string;
  validators?: any[] | any;
}

@Component({
  selector: 'apx-ui-shared-custom-cell-editor',
  templateUrl: './validate-editor-cell.component.html',
  styleUrls: ['./validate-editor-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidateEditorCellComponent implements AgEditorComponent {

  form: UntypedFormGroup;
  private params: InitParams;

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  get inputCtrl(): AbstractControl {
    return this.form.get('input');
  }

  agInit(params: any): void {
    this.params = params;

    const validator = typeof this.params.validators === 'function'
      ? this.params.validators(params)
      : this.params.validators ?? [];

    this.form = this.formBuilder.group({
      input: [params.data[params.field], [...validator]],
    });

    if (params.data[params.field]) {
      this.inputCtrl.markAsTouched();
      this.inputCtrl.markAsDirty();
    }
  }

  getValue(): any {
    return this.inputCtrl.value;
  }

  getErrorMessage(inputCtrl: AbstractControl): string {
    for (const [key] of Object.entries(inputCtrl.errors)) {

      switch (key) {
        case 'min': {
          return `The ${this.params.colDef.headerName} should be more than ${inputCtrl.errors[key][key]}`;
        }
        case 'max': {
          return `The ${this.params.colDef.headerName} should be less than ${inputCtrl.errors[key][key]}`;
        }
        case 'minlength': {
          return 'Minimum number of symbols not reached';
        }
        case 'maxlength': {
          return 'You have exceeded the maximum length';
        }
        case 'notInteger': {
          return `${this.params.colDef.headerName} should be integer.`;
        }
        case 'notCoordinate': {
          return 'Incorrect coordinate.';
        }
        case 'foundSpecialCharacters': {
          return `${this.params.colDef.headerName} doesnt allow special characters.`;
        }
        case 'negativeNumerical': {
          return `${this.params.colDef.headerName} should be positive.`;
        }
        case 'required': {
          return `${this.params.colDef.headerName} is required.`;
        }
        case 'notTwoDecimalPoint': {
          return 'Only numbers with 2 decimals are allowed';
        }
        case 'notSignedTwoDecimalPointValue': {
          return 'Please enter two decimal digits';
        }
        default: {
          return `Validation error for ${this.params.colDef.headerName}.`;
        }
      }

    }

    return '';
  }

}
