import { Injectable } from '@angular/core';
import { NotificationActions } from '@apx-ui/apx-shared-ui';
import { EntityOp, ofEntityOp, ofEntityType } from '@ngrx/data';
import { Actions, createEffect } from '@ngrx/effects';
import { switchMap } from 'rxjs';

@Injectable()
export class UnitSystemsEffects {

  handleUpdateUserUnitSystemSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType('UserUnitSystem') as any,
      ofEntityOp(
        EntityOp.SAVE_UPDATE_ONE_SUCCESS,
      ),
      switchMap(() => {
        return [
          NotificationActions.success({
            message: `User Unit System was successfully updated.`,
          }),
        ];
      }),
    ),
  );

  handleUpdateUserUnitSystemError$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType('UserUnitSystem') as any,
      ofEntityOp(
        EntityOp.SAVE_UPDATE_ONE_ERROR,
      ),
      switchMap(error => {
        const err = error.payload.data.error;

        return [
          NotificationActions.error({
            message: err.error?.errorMessage || `Cannot update User Unit System.`,
          }),
        ];
      }),
    ),
  );

  handleUpdateAccountUnitSystemSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType('AccountUnitSystem') as any,
      ofEntityOp(
        EntityOp.SAVE_UPDATE_ONE_SUCCESS,
      ),
      switchMap(() => {
        return [
          NotificationActions.success({
            message: `Account Unit System was successfully updated.`,
          }),
        ];
      }),
    ),
  );

  handleUpdateAccountUnitSystemError$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType('AccountUnitSystem') as any,
      ofEntityOp(
        EntityOp.SAVE_UPDATE_ONE_ERROR,
      ),
      switchMap(error => {
        const err = error.payload.data.error;

        return [
          NotificationActions.error({
            message: err.error?.errorMessage || `Cannot update Account Unit System.`,
          }),
        ];
      }),
    ),
  );

  constructor(
    private actions$: Actions,
  ) {
  }

}
