import { Injectable } from '@angular/core';
import { NotificationActions } from '@apx-ui/apx-shared-ui';
import { ApxSolsticeWebClientService } from '@apx-ui/apx-web-api-v1';
import { EntityActionFactory, EntityOp, MergeStrategy } from '@ngrx/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, Observable, of, switchMap } from 'rxjs';

import { entityTypes, IOrderDeliveryData } from '../../interfaces';
import { CaptureFactory } from '../../models';
import { OrderActions, OrdersForDeliveryActions } from '../actions';

@Injectable()
export class OrderEffects {

  handleAddComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrderActions.addComment,
      ),
      switchMap(({ comment, authorId, authorName, order, timeStamp }) => {

        const payload = {
          comment,
          timeStamp: timeStamp || new Date().toISOString(),
          authorName,
          authorId,
        };

        const addComment = order.Id
          ? this.client.addComment(order.AccountId, order.Id, payload)
          : this.client.addCommentForDelivery(order.AccountId, (order as IOrderDeliveryData).OrderId, payload);

        return addComment.pipe(
          switchMap(res => {

            if (!res) {
              throw new Error('Cannot add comment.');
            }

            const newOrder = { ...order, Comment: { ...payload } };

            if (order.Id) {
              return [
                OrderActions.addCommentSuccess({ comment, order }),
                NotificationActions.success({ message: 'Comment was successfully added.' }),
                this.entityActionFactory.create(entityTypes[order.DeliveryType], EntityOp.UPSERT_ONE, newOrder),
              ];
            } else {
              return [
                OrdersForDeliveryActions.updateOrder({ order: newOrder as IOrderDeliveryData }),
                OrderActions.addCommentSuccess({ comment, order }),
                NotificationActions.success({ message: 'Comment was successfully added.' }),
              ];
            }
          }),
          catchError(err => of(
            OrderActions.addCommentFailure({ err }),
            NotificationActions.error({ message: 'Cannot add comment.' }),
          )),
        );
      }),
    ),
  );

  handleCancelOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrderActions.cancelOrder,
      ),
      switchMap(({ comment, authorId, authorName, order }) => {

        const payload = {
          comment,
          authorName,
          authorId,
        };

        return this.client.cancelOrder(order.AccountId, order.Id, payload).pipe(
          switchMap(res => {

            if (!res) {
              throw new Error('Cannot cancel order.');
            }

            return [
              OrderActions.cancelOrderSuccess({ comment, order }),
              NotificationActions.success({ message: 'Order was successfully canceled.' }),
              this.entityActionFactory.create(entityTypes[order.DeliveryType], EntityOp.REMOVE_ONE, order.Id),
            ];
          }),
          catchError(err => of(
            OrderActions.cancelOrderFailure({ err }),
            NotificationActions.error({ message: 'Cannot cancel order.' }),
          )),
        );
      }),
    ),
  );

  handleCancelStimulationOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrderActions.cancelStimulationOrder,
      ),
      switchMap(({ order }) => {

        return this.client.cancelStimulationOrder(order.AccountId, order.Id).pipe(
          switchMap(res => {

            if (!res) {
              throw new Error('Cannot cancel order.');
            }

            return [
              OrderActions.cancelOrderSuccess({ order }),
              NotificationActions.success({ message: 'Order was successfully canceled.' }),
              this.entityActionFactory.create(entityTypes[order.DeliveryType], EntityOp.QUERY_ALL),
            ];
          }),
          catchError(err => of(
            OrderActions.cancelOrderFailure({ err }),
            NotificationActions.error({ message: 'Cannot cancel order.' }),
          )),
        );
      }),
    ),
  );

  handleCancelStimulationCompletedOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrderActions.cancelStimulationCompletedOrder,
      ),
      switchMap(({ order }) => {

        return this.client.cancelStimulationCompletedOrder(order.AccountId, order.Id).pipe(
          switchMap(res => {

            if (!res) {
              throw new Error('Cannot cancel order.');
            }

            return [
              OrderActions.cancelOrderSuccess({ order }),
              NotificationActions.success({ message: 'Order was successfully canceled.' }),
              this.entityActionFactory.create(entityTypes[order.DeliveryType], EntityOp.REMOVE_ONE, order.Id),
            ];
          }),
          catchError(err => of(
            OrderActions.cancelOrderFailure({ err }),
            NotificationActions.error({ message: 'Cannot cancel order.' }),
          )),
        );
      }),
    ),
  );

  handlePartialOrderUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrderActions.partialUpdate,
      ),
      switchMap(({ order, payload }) => {
        const options = {
          params: {
            unitSystemID: order.unitSystemId,
          },
        };

        const methodType: { [key: string]: Observable<boolean> } = {
          'Batch': this.client.updateBatchOrderNonRoutine(order.AccountId, payload, options),
          'Continuous': this.client.updateContinuousOrderNonRoutine(order.AccountId, payload, options),
          'default': this.client.updateOrderVolume(order.AccountId, order.Id, payload, options),
        };

        const method = order.isNonRoutineOrder ? methodType[order.DeliveryType] : methodType['default'];

        return method.pipe(
          switchMap(res => {

            if (!res) {
              throw new Error('Cannot update order.');
            }

            return [
              OrderActions.partialUpdateSuccess({ order, payload }),
              NotificationActions.success({ message: 'Order was successfully update.' }),
              this.entityActionFactory.create(entityTypes[order.DeliveryType], EntityOp.UPSERT_ONE, order),
            ];
          }),
          catchError(err => of(
            OrderActions.cancelOrderFailure({ err }),
            NotificationActions.error({ message: 'Cannot update order.' }),
          )),
        );
      }),
    ),
  );

  handleCaptureDeliveryOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrderActions.captureDeliveryOrder,
      ),
      switchMap(({ order, captureData, mode }) => {
        const payload = CaptureFactory.create(order)?.createOrder(captureData);
        const options = {
          params: {
            unitSystemID: captureData.unitSystemId,
          },
        };

        return this.client.captureDelivery(order.getAccountId(), payload, options).pipe(
          switchMap(res => {

            if (!res) {
              throw new Error('Cannot capture order.');
            }

            const actions = [
              OrderActions.captureDeliveryOrderSuccess({ order: res }),
              NotificationActions.success({ message: 'Order was successfully captured.' }),
            ];

            if (mode !== 'report') {
              actions.push(
                OrdersForDeliveryActions.updateOrder({ order: res }) as any,
              );
            }

            return actions;
          }),
          catchError(err => of(
            OrderActions.captureDeliveryOrderFailure({ err }),
            NotificationActions.error({ message: 'Cannot capture order.' }),
          )),
        );
      }),
    ),
  );

  handleCaptureOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrderActions.captureOrder,
      ),
      switchMap(({ order, captureData, mode }) => {
        const payload = CaptureFactory.create(order)?.createOrder(captureData);
        const options = {
          params: {
            unitSystemID: captureData.unitSystemId,
          },
        };

        return this.client.captureDeliveryCAM(order.getAccountId(), payload, options).pipe(
          switchMap(res => {

            if (!res) {
              throw new Error('Cannot capture order.');
            }

            const actions = [
              OrderActions.captureOrderSuccess({ order: res }),
              NotificationActions.success({ message: 'Order was successfully captured.' }),
            ];

            if (mode !== 'report') {

              actions.push(
                this.entityActionFactory.create(entityTypes[order.getDeliveryType()], EntityOp.QUERY_MANY,
                  { unitSystemID: captureData.unitSystemId },
                  { mergeStrategy: MergeStrategy.OverwriteChanges }) as any,
              );
            }

            return actions;
          }),
          catchError(err => of(
            OrderActions.captureOrderFailure({ err }),
            NotificationActions.error({ message: 'Cannot capture order.' }),
          )),
        );
      }),
    ),
  );

  handleAdjustCaptureOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrderActions.adjustCaptureOrder,
      ),
      switchMap(({ order, captureData }) => {
        const payload = CaptureFactory.create(order)?.createOrder(captureData);
        const options = {
          params: {
            unitSystemID: captureData.unitSystemId,
          },
        };

        return this.client.captureDelivery(order.getAccountId(), payload, options).pipe(
          switchMap(() => {
            return this.client.captureDeliveryComplete(order.getAccountId(), payload, options).pipe(
              switchMap(orders => {
                return [
                  this.entityActionFactory.create(entityTypes[order.getDeliveryType()], EntityOp.UPSERT_MANY, orders),
                  OrderActions.captureOrderSuccess({ order: payload }),
                  NotificationActions.success({ message: 'Order was successfully captured.' }),
                ];
              }),
              catchError(err => of(
                OrderActions.captureOrderFailure({ err }),
                NotificationActions.error({ message: 'Cannot capture order.' }),
              )),
            );
          }),
          catchError(err => of(
            OrderActions.captureOrderFailure({ err }),
            NotificationActions.error({ message: 'Cannot capture order.' }),
          )),
        );
      }),
    ),
  );

  handleApproveOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrderActions.approveOrder,
      ),
      switchMap(({ order, volume }) => {

        const payload = {
          id: order.Id,
          volume: volume,
        };

        return this.client.approveStimulationOrder(order.AccountId, payload).pipe(
          switchMap(orders => {

            if (!orders) {
              throw new Error('Cannot capture order.');
            }

            return [
              OrderActions.approveOrderSuccess({ order }),
              NotificationActions.success({ message: 'Order was successfully approved.' }),
              this.entityActionFactory.create(entityTypes[order.DeliveryType], EntityOp.UPSERT_MANY, orders),
            ];
          }),
          catchError(err => of(
            OrderActions.captureDeliveryOrderFailure({ err }),
            NotificationActions.error({ message: 'Cannot approve order.' }),
          )),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private readonly client: ApxSolsticeWebClientService,
    private readonly entityActionFactory: EntityActionFactory,
  ) {
  }

}
