import { IDateAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams, IDateParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

interface InitParams extends ICellEditorParams {
  minDate: Date;
  maxDate: Date;
  customDateFilter: any;
}

@Component({
  selector: 'apx-ui-shared-custom-date',
  templateUrl: './data-picker-cell.component.html',
  styleUrls: ['./data-picker-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataPickerCellComponent implements IDateAngularComp {

  @ViewChild('datePicker') datePicker: MatDatepicker<Date>;

  minDate: Date;
  maxDate: Date;
  dateCtrl: UntypedFormControl = new UntypedFormControl();
  customDateFilter: any;
  private params: InitParams & IDateParams;

  agInit(params: InitParams & IDateParams): void {
    this.params = params;
    this.minDate = params.minDate;
    this.maxDate = params.maxDate;
    this.customDateFilter = params.customDateFilter ? params.customDateFilter(params) : null;
    this.dateCtrl.setValue(params.value ? new Date(params.value) : new Date());
  }

  getDate(): Date {
    return this.dateCtrl.value;
  }

  setDate(date: Date): void {
    this.dateCtrl.setValue(date ?? null);
  }

  getValue(): string {
    const now = new Date();
    const date = new Date(this.dateCtrl.value);

    date.setHours(now.getHours());
    date.setMinutes(now.getMinutes());
    date.setSeconds(now.getSeconds());

    return date.toISOString();
  }

  afterGuiAttached(): void {
    this.datePicker.open();
  }

  onClose(): void {
    this.params?.api?.stopEditing();
  }

}
