<form *ngIf="form"
      [formGroup]="form">

  <div fxLayout="column"
       fxLayoutAlign="start start">

    <apx-ui-shared-multi-select placeholder="Select Product Type"
                                formControlName="productTypes"
                                data-test-id="productTypes"
                                bindLabel=""
                                [items]="productsTypesList$ | async">
    </apx-ui-shared-multi-select>

    <apx-ui-shared-multi-select placeholder="Select Product"
                                formControlName="products"
                                data-test-id="products"
                                bindLabel="ProductName"
                                [items]="productsList$ | async">
    </apx-ui-shared-multi-select>

    <apx-ui-shared-multi-select placeholder="Select Packages"
                                formControlName="packages"
                                data-test-id="packages"
                                bindValue="Id"
                                bindLabel="Name"
                                [items]="packages">
    </apx-ui-shared-multi-select>

  </div>

</form>
