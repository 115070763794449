import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { ApxAccountStoreModule } from '@apx-ui/apx-account-store';
import { ApxAuthModule } from '@apx-ui/apx-auth';
import { ApxConfigModule, ConfigService } from '@apx-ui/apx-config';
import { ApxCoreModule, dataServiceConfigFactory } from '@apx-ui/apx-core';
import {
  ApxApiHttpUrlGenerator,
  DataServiceFactory,
  ENTITY_COLLECTION_REDUCER_METHODS_FACTORY_PROVIDER,
  ENTITY_DISPATCHER_DEFAULT_OPTIONS,
  ENTITY_DISPATCHER_FACTORY_PROVIDER,
} from '@apx-ui/apx-core';
import { ApxNotificationsModule } from '@apx-ui/apx-notifications';
import { ApxNotificationsStoreModule } from '@apx-ui/apx-notifications-store';
import { ApxOrdersStoreModule } from '@apx-ui/apx-orders-store';
import { ApxSharedUiModule } from '@apx-ui/apx-shared-ui';
import { ApxWebApiV1Module } from '@apx-ui/apx-web-api-v1';
import { CacheModule } from '@apx-ui/cache';
import { DefaultDataServiceConfig, DefaultDataServiceFactory, EntityDataModule, HttpUrlGenerator } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { DefaultOAuthInterceptor, OAuthModule } from 'angular-oauth2-oidc';
import { ToastrModule } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { routes } from './app.route';
import { extModules } from './build-specifics';
import { components } from './components';
import { containers } from './containers';
import { ApxClientCacheInterceptor } from './interceptors';
import { CustomRouterStateSerializer } from './serializers';
import { effects } from './stores/effects';
import { ROOT_REDUCERS } from './stores/reducers';
import { states } from './stores/states';
import { CustomRoutePreloadingStrategy, CustomRouteReuseStrategy } from './strategies';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'corrected',
      preloadingStrategy: CustomRoutePreloadingStrategy,
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          environment.api_url,
          environment.common_api_url,
          environment.api_file_url,
          environment.api_notification_url,
        ],
        sendAccessToken: true,
      },
    }),

    StoreModule.forRoot(ROOT_REDUCERS, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    extModules,

    // Ngrx Effects Imports.
    EffectsModule.forRoot(effects),

    // Ngrx Data Imports.
    EntityDataModule.forRoot({}),

    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),

    ToastrModule.forRoot(),

    CacheModule.forRoot(),
    ApxConfigModule.forRoot(environment),

    ApxSharedUiModule.forRoot(),
    ApxCoreModule.forRoot(),
    ApxAuthModule.forRoot(),
    ApxWebApiV1Module.forRoot(),
    ApxAccountStoreModule,
    ApxNotificationsModule,
    ApxNotificationsStoreModule,
    ApxOrdersStoreModule,
  ],
  declarations: [AppComponent, ...components, ...containers],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    // Http Interceptors.
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultOAuthInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApxClientCacheInterceptor,
      multi: true,
    },

    { provide: DefaultDataServiceFactory, useClass: DataServiceFactory },
    { provide: DefaultDataServiceConfig, useFactory: dataServiceConfigFactory, deps: [ConfigService] },
    { provide: HttpUrlGenerator, useClass: ApxApiHttpUrlGenerator },

    ENTITY_COLLECTION_REDUCER_METHODS_FACTORY_PROVIDER,
    ENTITY_DISPATCHER_DEFAULT_OPTIONS,
    ENTITY_DISPATCHER_FACTORY_PROVIDER,

    // Router Customizations.
    CustomRoutePreloadingStrategy,
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },

    ...states,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
