import { TaskOrder } from './order.interface';
import { IProduct } from './utils.interface';

export interface ContinuousOrder extends TaskOrder {
  CriticalDate: Date;
  EmptyDate: Date;
  DilutionProduct: IProduct[];
  PreTankLevel: number;
  PostTankLevel: number;
  TankName: string;
  DeliveryDate: Date;
  TankCapacity: number;
  TankCreatedDate?: string;
  TankMaxFill: number;
  isNonRoutineOrder?: boolean;
  packageIds?: string[];
  isScheduleContinuous?: boolean;
  Frequency: number;
  InjectionPoint?: string;
  CapturedTimeStamp?: string;
  IsUpdate?: boolean;
}

export enum ContinuousOrderType {
  OFF_CYCLE = 'halOrderOffCycle',
  SCHEDULED = 'halOrderScheduled',
  BASE = 'halOrderBase'
}
