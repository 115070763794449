import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, Router, UrlTree } from '@angular/router';
import { AssetService } from '@apx-ui/apx-asset-store';
import { Permissions } from '@apx-ui/apx-config';
import { RouterStateService } from '@apx-ui/apx-core';
import { UserStateService } from '@apx-ui/apx-web-api-v1';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { AccountStateService, PageConfigStateService } from '../stores/states';

@Injectable()
export class AccountGuard implements CanActivate, CanDeactivate<any> {

  private currentAccountId?: string;

  constructor(
    private readonly router: Router,
    private readonly accountState: AccountStateService,
    private readonly pageConfigState: PageConfigStateService,
    private readonly routerState: RouterStateService,
    private readonly assetService: AssetService,
    private readonly userStateService: UserStateService,
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> {

    return combineLatest([
      this.accountState.getAccounts$().pipe(
        filter(accounts => accounts.length !== 0),
      ),
      this.routerState.getParam$('accountId').pipe(
        filter(accountId => !!accountId),
      ),
      this.userStateService.hasRight$(Permissions.AccountView),
    ]).pipe(
      map(([accounts, accountId, permissions]) => {
        const account = accounts.find(a => a.getId() === accountId);

        if (permissions && account) {
          this.currentAccountId = accountId;
          this.accountState.setAccount(account.getId());

          return true;
        }

        if (!permissions && account) {
          return true;
        }

        return this.router.createUrlTree(['/error'], { queryParams: { type: 404 } });
      }),
      catchError(() => {

        return of(
          this.router.createUrlTree(['/error'], {
            queryParams: { type: 503 },
          }),
        );
      }),
    );

  }

  canDeactivate(): Observable<boolean> {
    if (this.currentAccountId) {
      this.accountState.deselectAccount();
      this.pageConfigState.clear();
      this.currentAccountId = undefined;
      this.assetService.clear();
    }

    return of(true);
  }

}
